import Qna from "../data-types/qna";

const serialize = (data: Record<string, any>): Qna => {
  return {
    id: data.id,
    category: data.category,
    title: data.title,
    content: data.content,
  };
};

export default serialize;

import VoteCategory from "../data-types/vote-category";
import {createServerError, ServerError} from "../utils/server-error";
import axios from 'axios';
import {apiAddr} from "../config";
import serializeVoteCategory from '../from-server-serializers/vote-category';

const ReadVoteCategoriesServerError = createServerError();
type ReadVoteCategoriesServerError = ServerError;

const useReadVoteCategories = () => {
  return async (): Promise<VoteCategory[]> => {
    try {
      const axiosResult = await axios({
        method: 'get',
        url: `${apiAddr}/v1/vote-categories`,
        params: {limit: 100},
      });

      const voteCategories = axiosResult.data.map(serializeVoteCategory);

      return voteCategories;
    } catch (error) {
      throw new ReadVoteCategoriesServerError(error);
    }
  };
};

export default useReadVoteCategories;
export {ReadVoteCategoriesServerError};

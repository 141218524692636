import Banner from "../../data-types/banner";
import { useEffect, useState } from "react";
import useReadMainBanners from "../../services/read-main-banners";
import * as CompoundLayout from "../../data-types/compound-layout";
import useReadMainPageContents from "../../services/read-main-page-contents";
import useToVoteEventPage from "../../services/to-vote-event-page";
import useToLoginPage from "../../services/to-login-page";
import useLogoutIfLoggedIn from "../../services/logout-if-logged-in";
import useToMainPage from "../../services/to-main-page";
import convertToKorDate from "../../utils/current-kor-time";
import axios from "axios";
import { apiAddr } from "../../config";
import EventVote from "../../data-types/event-vote-main";
import useReadDemandingVoteStatistics from "../../services/read-demanding-vote-statistics";
// @ts-ignore
import useMainPushData from "../../services/main-push-data";
import DemandingVoteStatistics from "../../data-types/demanding-vote-statistics";
// @ts-ignore
import { PushInput } from "../../data-types/main-push-data";
import useReadEventRewardingTicketCount from "../../services/read-event-rewarding-ticket-count";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  statistics: DemandingVoteStatistics;
  onMultilingualPress: () => void;
  onLoginPress: () => void;
  onLogoutPress: () => void;
  banners: Banner[];
  currentBanner: Banner;
  currentBannerIndex: number;
  mainPageContents: CompoundLayout.Page;
  isSelectLanguageVisible: boolean;
  closeSelectLanguage: () => void;
  modalState: boolean;
  setModalState: (data: boolean) => void;
  goToEventPage: () => void;
  eventVoteData: EventVote | any;
  mainPushData: (data: PushInput | null) => void;
  eventRewardingTicketCount: any;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  statistics: DemandingVoteStatistics;
  banners: Banner[];
  currentBannerIndex: number;
  mainPageContents: CompoundLayout.Page;
  isSelectLanguageVisible: boolean;
  modalState: boolean;
  setModalState: (data: boolean) => void;
  recentEventId: string;
  eventVoteData: EventVote | undefined;
  eventRewardingTicketCount: number;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({
    status: "LOADING",
  });
  const [modalState, setModalState] = useState<boolean>(true);

  const readMainBanners = useReadMainBanners();
  const readMainPageContents = useReadMainPageContents();
  const toLoginPage = useToLoginPage();
  const toMainPage = useToMainPage();
  const readStatistics = useReadDemandingVoteStatistics();
  const toEventPage = useToVoteEventPage();
  const logoutService = useLogoutIfLoggedIn();
  const mainPushData = useMainPushData();
  const readEventRewardingTicketCount = useReadEventRewardingTicketCount();

  const init = async () => {
    const banners = await readMainBanners();
    const mainPageContents = await readMainPageContents();
    const eventRewardingTicketCount = await readEventRewardingTicketCount();
    const eventVoteData = await axios.get(`${apiAddr}/v1/votes/recent/event`);
    let statisticsData: any;

    if (eventVoteData?.data) {
      statisticsData = await readStatistics(eventVoteData?.data.id);

      //기간 지난 투표는 제외
      const thisDate = new Date();

      if (eventVoteData?.data?.period) {
        setModalState(
          convertToKorDate(new Date(eventVoteData?.data?.period.to)) >=
            thisDate &&
            convertToKorDate(new Date(eventVoteData?.data?.period?.from)) <=
              thisDate
        );
      }
    }

    if (!Object.keys(eventVoteData.data).length) {
      setModalState(false);
    }

    setState({
      recentEventId: eventVoteData?.data.id,
      status: "LOADED",
      banners,
      currentBannerIndex: 0,
      mainPageContents,
      isSelectLanguageVisible: false,
      modalState,
      setModalState,
      eventVoteData: eventVoteData?.data,
      statistics: statisticsData,
      eventRewardingTicketCount: eventRewardingTicketCount,
    });
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return {
      status: "LOADING",
    };
  }

  if (state.status === "FAILED") {
    return {
      status: "FAILED",
    };
  }

  const onPushDataSubmit = async (data: PushInput | null) => {
    if (data && data !== null) {
      const result = await mainPushData(data);
      if (result) {
        const { pushAllow } = result;
      }
    }
  };

  const onMultilingualPress = () => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, isSelectLanguageVisible: true };
    });
  };

  const onLoginPress = toLoginPage;
  const onLogoutPress = async () => {
    await logoutService();
    toMainPage();
    window.location.reload();
  };

  const closeSelectLanguage = () => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }
      return { ...oldState, isSelectLanguageVisible: false };
    });
  };

  return {
    status: "LOADED",
    onMultilingualPress,
    onLoginPress,
    onLogoutPress,
    banners: state.banners,
    currentBanner: state.banners[state.currentBannerIndex],
    currentBannerIndex: state.currentBannerIndex,
    mainPageContents: state.mainPageContents,
    isSelectLanguageVisible: state.isSelectLanguageVisible,
    closeSelectLanguage,
    modalState,
    setModalState,
    goToEventPage: () => toEventPage(state.recentEventId),
    eventVoteData: state.eventVoteData,
    statistics: state.statistics,
    mainPushData: onPushDataSubmit,
    eventRewardingTicketCount: state.eventRewardingTicketCount,
  };
};

export default useLogic;
export type { Logic, LoadingLogic, LoadedLogic, FailedLogic };

import {useEffect, useState} from "react";
import {strict as assert} from 'assert';
import useHandleAppError from "../../../../services/handle-app-error";
import Magazine from "../../../../data-types/magazine";
import useReadMagazines from "../../../../services/read-magazines";
import { fanupMediaAddr } from "../../../../config";

interface MagazineLogic extends Magazine {
  onClick: () => void;
}

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  magazines: MagazineLogic[];
  onDetails: () => void;
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  magazines: Magazine[];
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const initialState: State = {status: 'LOADING'};
  const [state, setState] = useState<State>(initialState);
  const handleAppError = useHandleAppError();
  const readMagazines = useReadMagazines();

  const tryInit = async () => {
    const magazines = await readMagazines();

    setState({status: 'LOADED', magazines});
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  if (state.status === 'LOADING') {
    return {
      status: 'LOADING',
    };
  }

  if (state.status === 'FAILED') {
    return {
      status: 'FAILED',
    };
  }

  const onDetails = () => {
    window.open(fanupMediaAddr);
  };

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      magazines: state.magazines.map((magazine) => {
        return {
          ...magazine,
          onClick: () => {
            window.open(magazine.detailsLink);
          },
        };
      }),
      onDetails,
    };
  }

  assert.fail();
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};

import axios, { AxiosResponse } from "axios";
import { apiAddr } from "../config";
import { AuthData } from "../utils/auth";
import { createServerError, ServerError } from "../utils/server-error";
import useLoginWithAuthData from "./login-with-auth-data";

const LoginServerError = createServerError();
type LoginServerError = ServerError;

const useOAuthLogin = () => {
  const loginWithAuthData = useLoginWithAuthData();

  return async (key: string | null | (string | null)[]) => {
    let axiosResult: AxiosResponse;

    const getAuthDataFromAxiosResult = () => {
      const authData = axiosResult.data as AuthData;
      return authData;
    };

    try {
      axiosResult = await axios({
        method: "get",
        url: `${apiAddr}/v1/auth/sns-login-complete?key=${key}`,
      });
    } catch (error) {
      throw new LoginServerError(error);
    }

    await loginWithAuthData(getAuthDataFromAxiosResult());
  };
};

export default useOAuthLogin;
export { LoginServerError };

import React from "react";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import DemandingVoteStatistics from "../../components/demanding-vote-statistics";
import { primary } from "../../styles";
import useLogic from "./use-logic";
import useToCurrentLocale from "../../services/to-current-locale";
import TournamentVoteChoice from "../../data-types/tournament-vote-choice";
import voteDateLeft from "../../utils/vote-date-left";
import VoteShareModal from "./share-modal/index";

const TournamentVoteStatisticsPage: React.FC = () => {
  const logic = useLogic();
  const toCurrentLocale = useToCurrentLocale();

  if (logic.status !== "LOADED") {
    return null; // todo
  }

  return (
    <NavigationWithGoBackBottomTabLayout title={"WORLD CUP"}>
      <DemandingVoteStatistics
        color={primary}
        title={toCurrentLocale(logic.vote.title)}
        choices={logic.statistics.choices.map((choiceStat) => {
          const findById = (id: string) => (choice: TournamentVoteChoice) =>
            choice.id === id;
          const choice: TournamentVoteChoice = logic.vote.choices.find(
            findById(choiceStat.choice)
          )!;

          return {
            id: choiceStat.choice,
            name: toCurrentLocale(choice.content.title),
            scale: choiceStat.voteCount / logic.statistics.totalVoteCount,
          };
        })}
        openShareModal={logic.openVoteModal}
        dateLeft={voteDateLeft(logic.vote)}
        description={`${logic.vote.period.from.toLocaleDateString()} ~ ${logic.vote.period.to.toLocaleDateString()}`}
      />
      <VoteShareModal
        logic={logic}
        title={toCurrentLocale(logic.vote.title)}
        image={""}
        url={window.location.href}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default TournamentVoteStatisticsPage;

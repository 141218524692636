import { strict as assert } from "assert";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TournamentVote from "../../data-types/tournament-vote";
import DemandingVoteStatistics from "../../data-types/demanding-vote-statistics";
import useReadVoteById from "../../services/read-vote-by-id";
import useReadDemandingVoteStatistics from "../../services/read-demanding-vote-statistics";
import useHandleAppError from "../../services/handle-app-error";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  vote: TournamentVote;
  statistics: DemandingVoteStatistics;
  show: boolean;
  openVoteModal: () => void;
  closeVoteModal: () => void;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  vote: TournamentVote;
  statistics: DemandingVoteStatistics;
  show: boolean;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const urlParams = useParams();
  const readVote = useReadVoteById();
  const readStatistics = useReadDemandingVoteStatistics();
  const handleAppError = useHandleAppError();

  const voteId = urlParams.id!;

  const tryInit = async () => {
    const vote = (await readVote(voteId)) as TournamentVote;
    const statistics = await readStatistics(voteId);

    setState({
      status: "LOADED",
      vote,
      statistics,
      show: false,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const openVoteModal = () => {
    setState((oldState) => ({ ...oldState, show: true }));
  };

  const closeVoteModal = () => {
    setState((oldState) => ({ ...oldState, show: false }));
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      vote: state.vote,
      statistics: state.statistics,
      show: state.show,
      openVoteModal,
      closeVoteModal,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

import NavigationWithGoBackBottomTabLayout from "../../../components/navigation-with-go-back-bottom-tab-layout";
import {
  AttractionButton,
  AttractionItem,
  AttractionItemAddress,
  AttractionItemBtnGroup,
  AttractionItemButton,
  AttractionItemTitle,
  AttractionMap,
  AttractionMapWrap,
  AttractionWrap,
  InnerWrap,
  RoundedBtn,
  RoundedBtnGroup,
  SearchBox,
  Title,
} from "./dom-styled";
import React, { useState } from "react";
import { ReactComponent as IconSearch } from "../svg/icon_search.svg";
import { ReactComponent as IconArrow } from "../svg/icon_arrow_white.svg";
import IconMarkerSelected from "../svg/icon_marker_selected.svg";
import {
  keywordAttractionList,
  nearByAttractionList,
} from "../../../services/fanup-map/fanup-openapi-repository";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { NavigateFunction, useNavigate } from "react-router-dom";
import useGeoLocation, {
  UserLocationType,
} from "../../../utils/user-geoLocation";
import {
  FanUpAttractionType,
  GovAttractionDataType,
} from "../../../data-types/attraction";
import { useTranslation } from "react-i18next";
import MessageModal, { MessageModalHook } from "../../../modals/message-modal";

let _setShowMap: React.Dispatch<React.SetStateAction<GovAttractionDataType>>;
let _setDataList: React.Dispatch<React.SetStateAction<GovAttractionDataType[]>>;
let _setState: React.Dispatch<React.SetStateAction<string>>;
let _navigate: NavigateFunction;
let _isLoading: boolean = false;
let _userLocation: UserLocationType;
let _setShowModal: React.Dispatch<
  React.SetStateAction<MessageModalHook | undefined>
>;

const MapView = (data: GovAttractionDataType): React.PropsWithChildren<any> => {
  if (data.mapx == 0 || data.mapy == 0) return null;
  const mapIcon = new L.Icon({
    iconUrl: IconMarkerSelected,
    iconSize: [25, 40],
  });

  return (
    <AttractionMapWrap>
      <AttractionMap>
        <MapContainer
          style={{ width: "100%", height: "100vh" }}
          center={[data.mapy!, data.mapx!]}
          zoom={16}
          zoomControl={false}
        >
          <TileLayer
            attribution={
              '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[data.mapy!, data.mapx!]} icon={mapIcon}>
            <Popup>
              {data.title}
              <br />
              {data.addr1} {data.addr2}
            </Popup>
          </Marker>
        </MapContainer>
      </AttractionMap>
      <AttractionButton
        onClick={() => {
          _setShowMap({ mapx: 0, mapy: 0 });
        }}
      >
        {"닫기"}
      </AttractionButton>
    </AttractionMapWrap>
  );
};

const _movePage = (item: GovAttractionDataType) => {
  _navigate("/maps/add", {
    state: {
      data: {
        name: item.title,
        address: `${item.addr1} ${item.addr2}`,
        latitude: item.mapy,
        longitude: item.mapx,
      } as FanUpAttractionType,
    },
  });
};
/**
 * 근처 어트랙션 데이터 개별아이템
 * @param item
 */
const _attractionItem = (
  item: GovAttractionDataType,
  index: number
): React.PropsWithChildren<any> => {
  return (
    <AttractionItem key={index}>
      <AttractionItemTitle>{item.title}</AttractionItemTitle>
      <AttractionItemAddress>
        {item.addr1} {item.addr2}
      </AttractionItemAddress>
      <AttractionItemBtnGroup>
        <AttractionItemButton
          style={{ marginRight: "11px" }}
          onClick={() => {
            _movePage(item);
          }}
        >
          Enter information{" "}
          <IconArrow
            style={{ position: "absolute", right: "15px", top: "10px" }}
          />
        </AttractionItemButton>
        <AttractionItemButton
          onClick={() => {
            _setShowMap(item);
          }}
        >
          Check location
          <IconArrow
            style={{ position: "absolute", right: "15px", top: "10px" }}
          />
        </AttractionItemButton>
      </AttractionItemBtnGroup>
    </AttractionItem>
  );
};

/**
 * 검색박스
 * @constructor
 */
interface InputBoxProperties {
  isClearValue?: boolean | undefined;
}
const InputBox: React.FC<InputBoxProperties> = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation("pageFanUpMap");
  return (
    <div
      id={"srchBox"}
      style={{
        position: "relative",
        paddingTop: "28px",
        width: "100%",
      }}
    >
      <input
        type={"text"}
        style={{
          width: "calc(100% - 32px)",
          position: "relative",
          border: "0",
          borderRadius: "6px",
          padding: "16px",
          boxShadow:
            "0px 4px 8px 0px rgba(108, 118, 128, 0.16), 0px 2px 4px 0px rgba(108, 118, 128, 0.16)",
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            _setState("keyword");
            _searchEvent(e.currentTarget.value!);
          }
        }}
        placeholder={t("srchField")}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <button
        style={{
          background: "transparent",
          border: 0,
          position: "absolute",
          right: "10px",
          top: "40px",
        }}
        onClick={(e) => {
          _setState("keyword");
          _searchEvent(searchValue);
        }}
      >
        <IconSearch className="svgIcon" />
      </button>
    </div>
  );
};
const _searchEvent = (keyword: string) => {
  if (!_isLoading) {
    _isLoading = true;
    keywordAttractionList(keyword).then((result) => {
      _isLoading = false;
      if (result.totalCount > 0) {
        _setDataList(result.items!.item);
      } else {
        _setDataList([]);
      }
    });
  }
};
const _searchNearByEvent = () => {
  nearByAttractionList(_userLocation.lat, _userLocation.lng).then((result) => {
    if (result.totalCount > 0) {
      _setDataList(result.items?.item!);
    } else {
      _setDataList([]);
    }
  });
};

const FanUpSearchPage: React.PropsWithChildren<any> = () => {
  const [state, setState] = useState("");
  const [mapInfo, setShowMap] = useState<GovAttractionDataType>({});
  const [dataList, setDataList] = useState<GovAttractionDataType[]>([]);
  const { t } = useTranslation("pageFanUpMap");
  _userLocation = useGeoLocation();
  _navigate = useNavigate();
  _setShowMap = setShowMap;
  _setDataList = setDataList;
  _setState = setState;

  const isShowMap = (mapInfo.mapx || 0) != 0 && (mapInfo.mapy || 0) != 0;

  if (isShowMap) {
    document
      .getElementById("content")!
      .parentElement!.scrollTo({ top: 0, left: 0 });
  }
  return (
    <NavigationWithGoBackBottomTabLayout
      title={"FanUP MAP"}
      disabledSafePadding={true}
      hideScroll={isShowMap}
    >
      <InnerWrap id={"content"}>
        <SearchBox>
          <Title>Search / Add Places</Title>
          <InputBox />
          <RoundedBtnGroup>
            <RoundedBtn
              active={state === "nearBy"}
              style={{ marginRight: "15px" }}
              onClick={() => {
                if (!_userLocation.isLoaded) {
                  _setShowModal({
                    messageContent: t("message.findingLocation"),
                  });
                  return false;
                }
                setState("nearBy");
                _searchNearByEvent();
              }}
            >
              Nearby Attractions
            </RoundedBtn>
            <RoundedBtn
              active={false}
              onClick={() => {
                _navigate("/maps/direct");
              }}
            >
              Enter Directly
            </RoundedBtn>
          </RoundedBtnGroup>
        </SearchBox>
        <AttractionWrap>
          {dataList.map((item, index) => _attractionItem(item, index))}
        </AttractionWrap>
        {isShowMap ? MapView(mapInfo) : null}
      </InnerWrap>
      <MessageModal
        onHook={(fn) => {
          _setShowModal = fn;
        }}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default FanUpSearchPage;

import IdolVote from "../data-types/idol-vote";
import axios from 'axios';
import {createServerError, ServerError} from "../utils/server-error";
import {apiAddr} from "../config";
import serializeIdolVote from '../from-server-serializers/idol-vote';

const ReadIdolVotesServerError = createServerError();
type ReadIdolVotesServerError = ServerError;

const useReadIdolVotes = () => {
  return async (): Promise<IdolVote[]> => {
    try {
      const axiosResult = await axios({
        method: 'get',
        url: `${apiAddr}/v1/votes`,
        params: {
          limit: 100,
          onlyIdol: true,
        },
      });

      const result: IdolVote[] = axiosResult.data.map(serializeIdolVote);

      return result;
    } catch (error) {
      throw new ReadIdolVotesServerError(error);
    }
  };
};

export default useReadIdolVotes;
export {ReadIdolVotesServerError};

import React from 'react';
import styled from 'styled-components';
import {LoadedLogic} from './use-logic';
import Announcement from './announcement';
import AnnouncementData from '../../../../data-types/announcement';

const Cont = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

interface Props {
  logic: LoadedLogic;
}

const Announcements: React.FC<Props> = (props) => {
  return (
    <Cont>
      {props.logic.announcements.map(
        (announcement: AnnouncementData, index: number) => {
          return <Announcement
            key={announcement.id}
            announcement={announcement}
            onClick={() => props.logic.onAnnouncementOfIndexClick(index)}
          />;
        }
      )}
    </Cont>
  );
};

export default Announcements;

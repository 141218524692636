import {
    ButtonGroups,
    CalendarButton,
    CalendarWrap,
    Inner,
    Months,
    Body,
    CalendarRow,
    Weeks, TextLabel, Days,
} from "./dom-styled";
import {ReactComponent as IconLeft} from "./svg/icon_calendar_left.svg";
import {ReactComponent as IconRight} from "./svg/icon_calendar_right.svg";
import {useEffect, useState} from "react";
import dateToYyyyMmDd from "../../utils/date-to-yyyy-mm-dd";
const MonthName:{[key:number]:string}={
    0:'January',
    1:'February',
    2:'March',
    3:'April',
    4:'May',
    5:'Jun',
    6:'July',
    7:'August',
    8:'September',
    9:'October',
    10:'November',
    11:'December'
}
let weekQueue: CalendarWeek[] =[];
let _onChangeHandler: ((date: Date) => void) | undefined

/**
 * 달력 일 옵션
 */
interface CalendarDay{
    day : number,
    format:string,
    date : Date,
    isEnabled : boolean,
    isHidden : boolean
}

/**
 * 한주옵션
 */
interface CalendarWeek{
    index:number,
    days : CalendarDay[]
}
/**
 * 달력 기본 옵션
 */
interface CalendarOptions{
    disableYears?:number[],
    selectedHandler? : Function,
    selectedYear? : number,
    selectedMonth? : number,
    selectedDay? : number
    minDate?:string,
    maxDate?:string
}

/**
 * 달력일자 초기화
 * @param nowDate
 * @param option
 */
const _initCalendarQueue = (nowDate : Date,option:CalendarOptions)=>{
    const dayQueue :CalendarDay[] = [];
    let lastDay = new Date(nowDate.getFullYear(),nowDate.getMonth()+1,0).getDate();
    let nDate = new Date();
    let prevDate = new Date();

    let minDate = new Date((option.minDate||'2000-01-01'));
    let maxDate = new Date((option.maxDate||'2099-12-31'));


    nDate.setTime(nowDate.getTime());
    prevDate.setDate(nowDate.getDate());

    for(let i=0;i<nDate.getDay();i++){

        prevDate.setDate(prevDate.getDate()-1);
        dayQueue.push({
            day:prevDate.getDate(),
            format : dateToYyyyMmDd(prevDate),
            date : prevDate,
            isHidden:true,
            isEnabled:false
        });
    }
    for(let i=1;i<=lastDay;i++){
        let qDate :Date = new Date();
        qDate.setTime(nDate.getTime());
        dayQueue.push({
            day:i,
            format : dateToYyyyMmDd(qDate),
            date : qDate,
            isHidden:false,
            isEnabled:(qDate.getTime() >= minDate.getTime() && qDate.getTime() <= maxDate.getTime())
        });
        nDate.setDate(nDate.getDate()+1);
    }
    nDate.setDate(nDate.getDate()-1);
    if(nDate.getDay()<6){
        let qDate :Date = new Date();
        qDate.setTime(nDate.getTime())
        for(let i=0;i<6-nDate.getDay();i++){
            qDate.setDate(qDate.getDate()+1);
            dayQueue.push({
                day:qDate.getDate(),
                format : dateToYyyyMmDd(qDate),
                date : qDate,
                isHidden:true,
                isEnabled:false
            });
        }
    }
    weekQueue = [];
    dayQueue.forEach((item,idx)=>{
        let qIdx = Math.floor(idx /7);
        if(!weekQueue[qIdx]){
            weekQueue[qIdx] = {
                index :qIdx,
                days:[]
            }
        }
        weekQueue[qIdx].days.push(item);
    });
}
const RowItem : React.FC<any> = (props)=>{
    return (
        <CalendarRow>
            {props.weeks.days.map((item:CalendarDay)=>
                <Days onClick={()=>{
                    props.onChange(item.format);
                    _onChangeHandler&&_onChangeHandler(item.date);
                }}>
                    <TextLabel display={!(item.isHidden||false)} active={item.format == props.format}>{item.day}</TextLabel>
                </Days>
            )}
        </CalendarRow>
    )
}
/**
 * 캘랜더 프로퍼티
 */
interface CalendarViewProps {
    year:number,
    month:number,
    day?:number,
    onChangeHandler? :(date:Date)=>void;
}
const CalendarView : React.FC<CalendarViewProps> = (props)=>{
    const [calDate , setCalDate] = useState(new Date(props.year,props.month-1,props.day||1));
    const [modifyFlag,setModifyFlag] = useState<string|undefined>(undefined);
    const [selDate,setSelDate] = useState<string|undefined>();

    _onChangeHandler = props.onChangeHandler;
    _initCalendarQueue(calDate,{});
    return (
        <CalendarWrap>
            <Inner>
                <Months>{MonthName[calDate.getMonth()]} {calDate.getFullYear()}</Months>
                <ButtonGroups>
                    <CalendarButton onClick={()=>{
                        calDate.setMonth(calDate.getMonth()-1);
                        setCalDate(calDate);
                        setModifyFlag(`${calDate}`);
                    }}><IconLeft/></CalendarButton>
                    <CalendarButton onClick={()=>{
                        calDate.setMonth(calDate.getMonth()+1);
                        setCalDate(calDate);
                        setModifyFlag(`${calDate}`);
                    }}><IconRight/></CalendarButton>
                </ButtonGroups>
                <Body>
                    <CalendarRow>
                        <Weeks>Mo</Weeks>
                        <Weeks>Tu</Weeks>
                        <Weeks>We</Weeks>
                        <Weeks>Th</Weeks>
                        <Weeks>Fr</Weeks>
                        <Weeks>Sa</Weeks>
                        <Weeks>Su</Weeks>
                    </CalendarRow>
                    {
                        weekQueue.map((item,idx)=>
                                <RowItem weeks={item} key={`weeks${idx}`}
                                    format={selDate}
                                    onChange={(format:string)=>{
                                        setSelDate(format);
                                    }}
                                />
                        )
                    }
                </Body>
            </Inner>
        </CalendarWrap>
    )
};

export default CalendarView;

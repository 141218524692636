import React from 'react';
import FullNavigationGoBackBottomTabLayout from "../../components/full-navigation-bottom-tab-layout";
import useLogic from './use-logic';
import Tabs from './fragments/tabs';
import Votes from './fragments/votes';

const DemandingVotesPage: React.FC = () => {
  const logic = useLogic();

  if (logic.status !== 'LOADED') {
    return null; // todo fix this.
  }

  return (
    <FullNavigationGoBackBottomTabLayout title={'WORLD CUP'}>
      <Tabs logic={logic}/>
      <Votes logic={logic}/>
    </FullNavigationGoBackBottomTabLayout>
  );
};

export default DemandingVotesPage;

import AppTabKind from "../utils/app-tab-kind";
import useToMainPage from "./to-main-page";
import useToFanupTourPage from "./to-fanup-tour-page";
import useToIdolVotesPage from "./to-idol-votes-page";
import useToDemandingVotesPage from "./to-demanding-votes-page";
import useToMyAccountPage from "./to-my-account-page";
import useAuth from "./auth";
import useToLogin from "./to-login-page";
import useToFanMapPage from "./to-fan-map-page";

const useToAppTab = () => {
  const toIdolVotes = useToIdolVotesPage();
  const toFanupTourPage = useToFanupTourPage();
  const toMain = useToMainPage();
  const toMap = useToFanMapPage();
  const toDemandingVotes = useToDemandingVotesPage();
  const toMyAccount = useToMyAccountPage();
  const toLogin = useToLogin();
  const auth = useAuth();

  return (appTab: AppTabKind) => {
    switch (appTab) {
      case "CHART":
        toIdolVotes();
        break;

      case "FANUP":
        toFanupTourPage();
        break;

      case "MAIN":
        toMain();
        break;
      case "MAP":
        toMap();
      break;
      case "DEMANDING":
        toDemandingVotes();
        break;

      case "MY_ACCOUNT":
        if (!auth.isLoggedIn) {
          toLogin();
          break;
        }

        toMyAccount();
        break;
    }
  };
};

export default useToAppTab;

import styled from "styled-components";

interface CustomAttribute{
    key : string
}
const InnerWrap = styled.div`
    position:relative;
    height:auto;
    min-height:100vh;
    background:#EDEDED
`;
const SearchBox = styled.div`
    padding: 24px 16px;
    background: #fff;
`;
const Title = styled.div`
    color: #12161A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
`;
const RoundedBtnGroup = styled.div`
    display:flex;
    margin-top:20px;
`;
const RoundedBtn = styled.button<{active:boolean}>`
    width:100%;
    border-radius: 18px;
    border: 1px solid #12161A;
    padding:10px 25px;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: 0.15px;
    background:${(props)=>props.active?'#12161A':'#fff'};
    color:${(props)=>props.active?'#fff':'#000'};
`;
const AttractionItem = styled.div`
    background:#fff;
    padding:16px;
    margin-bottom:16px;
`;
const AttractionItemTitle= styled.h3`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
`;
const AttractionItemAddress = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
`;
const AttractionItemBtnGroup = styled.div`
    display:flex;
`;
const AttractionItemButton = styled.button`
    width: 100%;
    position:relative;
    padding: 8px 12px;
    background: #000;
    border: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    color:#fff;
    border-radius:23px;
    
`
const AttractionWrap = styled.div`
    padding:16px;
`;

const AttractionMapWrap = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(173, 173, 173, 0.54);
    backdrop-filter: blur(2px);
    overflow: hidden;
`;
const AttractionMap = styled.div`
    margin-top:16px;
    padding: 34px 16px;
    height:calc(100vh - 300px);
    overflow: hidden;
`;
const AttractionButton = styled.button`
    margin: 16px;
    padding: 16px;
    width: calc(100% - 32px);
    border: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #FF176B;
    color: #fff;
    border-radius: 6px 6px 0px 0px;
`;

export {
    InnerWrap, SearchBox,Title,RoundedBtnGroup,RoundedBtn,
    AttractionWrap,AttractionItem,AttractionItemTitle,AttractionItemButton,AttractionItemAddress,AttractionItemBtnGroup,
    AttractionMap,AttractionMapWrap,AttractionButton
}

import React from "react";
import styled from "styled-components";
import DemandingVote from "../../../../../data-types/demanding-vote";
import useToCurrentLocale from "../../../../../services/to-current-locale";
import {
  black,
  cg300,
  eventLeft,
  fadedVoteRepresentingColor,
  mainColor,
  voteRepresentingColor,
} from "../../../../../styles";
import { LoadedLogic } from "../../../use-logic";
import DemandingVoteLog from "../../../../../data-types/demanding-vote-log";
import Button from "../../../components/button";
import EventButton from "../../../components/eventButton";
import demandingVoteVotableStatus from "../../../../../utils/demanding-vote-votable-status";
import { useTranslation } from "react-i18next";
import { strict as assert } from "assert";

const Cont = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.span`
  margin-top: 8px;
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: ${black};
`;

const Subcontents = styled.div`
  display: flex;
  margin-top: 8px;
`;

const Thumbnail = styled.img`
  border-radius: 8px;
  width: 104px;
  height: 104px;
  object-fit: cover;
`;

const DescriptionAndButtonCont = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: stretch;
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 140%;
  color: ${cg300};
`;

const TheButton = styled(Button)`
  align-self: end;
`;

const EventBtn = styled(EventButton)`
  align-self: end;
  background-image: linear-gradient(110deg, #00dede 20%, #ff0099 70%);
`;

interface Props {
  logic: LoadedLogic;
  vote: DemandingVote;
  log: DemandingVoteLog | undefined;
}

const Contents: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();
  const { t } = useTranslation("pageDemandingVotes");

  const getButtonTitle = (): string => {
    const status = demandingVoteVotableStatus(props.vote, props.log);

    if (status === "ENDED") {
      return t("pageDemandingVotes:checkResult");
    }

    if (status === "PARTICIPATED" || status === "CAN_PARTICIPATE") {
      return t("pageDemandingVotes:start");
    }

    assert.fail();
  };

  return (
    <Cont>
      <Text>{toCurrentLocale(props.vote.title)}</Text>
      <Subcontents>
        <Thumbnail src={props.vote.thumbnailUrl} />
        <DescriptionAndButtonCont>
          <Description>{toCurrentLocale(props.vote.description)}</Description>
          {props.vote.voteKind !== "EVENT" ? (
            <TheButton
              title={`${getButtonTitle()} >`}
              color={"#ffffff"}
              backgroundColor={mainColor}
              onClick={() => props.logic.onVoteClick(props.vote)}
            />
          ) : (
            <EventBtn
              title={`${getButtonTitle()} >`}
              color={""}
              backgroundColor={
                "linear-gradient(110deg, #00dede 20%, #ff0099 80%)"
              }
              onClick={() => props.logic.onVoteClick(props.vote)}
            ></EventBtn>
          )}
        </DescriptionAndButtonCont>
      </Subcontents>
    </Cont>
  );
};

export default Contents;

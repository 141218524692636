import Props from './props';
import Language from "./language";
import {useEffect, useState} from "react";
import i18n from 'i18next';
import useHandleAppError from "../../services/handle-app-error";

interface Logic {
  selectedLanguage: Language;
  onLanguageSelect: (language: Language) => void;
}

interface State {
  selectedLanguage: Language;
}

const useLogic = (props: Props): Logic => {
  const [state, setState] = useState<State>({selectedLanguage: i18n.language as Language});
  const handleAppError = useHandleAppError();

  const init = () => {
    if (props.isVisible) {
      setState({selectedLanguage: i18n.language as Language});
    }
  };

  useEffect(init, [props.isVisible]);

  const onLanguageSelect = async (language: Language) => {
    try {
      await i18n.changeLanguage(language);
      setState({
        selectedLanguage: language,
      });
    } catch (error) {
      await handleAppError(error);
    }
  };

  return {
    selectedLanguage: state.selectedLanguage,
    onLanguageSelect,
  };
};

export default useLogic;
export type {Logic};

import { useState } from "react";
import { useTranslation } from "react-i18next";
import useLogin, { LoginServerError } from "../../services/login";
import useToCreateAccount from "../../services/to-create-account-page";
import useToRequestResetPassword from "../../services/to-request-reset-password-page";
import useToMainPage from "../../services/to-main-page";
import useHandleAppError from "../../services/handle-app-error";
import noProblem from "../../utils/no-problem";
import checkEmail from "../../errors/check-email";
import checkPassword from "../../errors/check-password";

interface Logic {
  email: string;
  password: string;
  setEmail: (newValue: string) => void;
  setPassword: (newValue: string) => void;

  onLogin: () => void;
  isLoggingIn: boolean;

  onCreateAccount: () => void;
  onResetPassword: () => void;
  isFormValid: boolean;
}

interface State {
  email: string;
  password: string;
  isLoggingIn: boolean;
}

const useLogic = (): Logic => {
  const { t } = useTranslation("pageLogin");
  const serviceLogin = useLogin();
  const toCreateAccount = useToCreateAccount();
  const toResetPassword = useToRequestResetPassword();
  const toMainPage = useToMainPage();
  const handleAppError = useHandleAppError();

  const [state, setState] = useState<State>({
    email: "",
    password: "",
    isLoggingIn: false,
  });

  const setEmail = (newEmail: string) => {
    setState((oldState) => ({ ...oldState, email: newEmail }));
  };

  const setPassword = (newPassword: string) => {
    setState((oldState) => ({ ...oldState, password: newPassword }));
  };

  const startLogin = () => {
    setState((oldState) => ({ ...oldState, isLoggingIn: true }));
  };
  const endLogin = () => {
    setState((oldState) => ({ ...oldState, isLoggingIn: false }));
  };

  const onLogin = async () => {
    try {
      startLogin();
      await serviceLogin({
        email: state.email,
        password: state.password,
      });
      toMainPage();
    } catch (error) {
      if (error instanceof LoginServerError) {
        alert(t("pageLogin:serverErrorOccurred"));
        return;
      }

      await handleAppError(error);
    } finally {
      endLogin();
    }
  };

  const isFormValid = noProblem(
    () => checkEmail(state.email),
    () => checkPassword(state.password)
  );

  return {
    email: state.email,
    password: state.password,
    setEmail,
    setPassword,
    onLogin,
    isLoggingIn: state.isLoggingIn,
    onCreateAccount: toCreateAccount,
    onResetPassword: toResetPassword,
    isFormValid,
  };
};

export default useLogic;
export type { Logic };

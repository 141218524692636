import DemandingVote from "../data-types/demanding-vote";
import DemandingVoteLog from "../data-types/demanding-vote-log";
import demandingVoteVotableStatus from "../utils/demanding-vote-votable-status";
import { strict as assert } from "assert";

export const idol = "#FFFFFF";
export const fadedIdol = "#FD7BB2";
export const primary = "#3754A3";
export const fadedPrimary = "#3754A32b";
export const darkBlue = "#3B5EC9";
export const black = "#051331";
export const pink = "#FFBDBD";
export const violet = "#D3A8FF";
export const blue = "#50A4FF";
export const green = "#3BC9C0";
export const gray = "#7E7E7E";
export const darkGray = "#666666";
export const warmGray = "#EDEDED";
export const coolGray = "#edf1f5";
export const white = "#FFFFFF";
export const celebrityMain = "#3754A3";
export const lavenda = "#3754A32B";
export const worldCup = "#FFFFFF";
export const fadedWorldCup = "#FF176B";
export const event = "#FFFFFF";
export const eventLeft = "#FF176B";
export const eventRight = "#09A7A7";
export const fadedEvent = "#FF176B";
export const placeHolder = "#98A5B3";
export const textBox = "#000000";

export const cg20 = "#F7F9FA";
export const cg40 = "#EDF1F5";
export const cg60 = "#E4EAF0";
export const cg100 = "#DAE0E6";
export const cg300 = "#98A5B3";
export const cg500 = "#6C7680";
export const cg600 = "#525C66";
export const cg700 = "#3D454D";
export const cg800 = "#292E33";
export const cg900 = "#12161A";

export const mainEventColor = "#FF176B";
export const pointColor = "#FD7BB2";

export const mainColor = "#FF176B";

export const voteRepresentingColor = (
  vote: DemandingVote,
  voteLog: DemandingVoteLog | undefined
): string => {
  const status = demandingVoteVotableStatus(vote, voteLog);

  if (status === "CAN_PARTICIPATE") {
    if (vote.voteKind === "TOURNAMENT") {
      return worldCup;
    }

    if (vote.voteKind === "EVENT") {
      return event;
    }
  }

  if (status === "PARTICIPATED") {
    return primary;
  }

  if (status === "ENDED") {
    return gray;
  }

  assert.fail();
};

export const fadedVoteRepresentingColor = (
  vote: DemandingVote,
  voteLog: DemandingVoteLog | undefined
): string => {
  const hex17Percent = "2b";

  return voteRepresentingColor(vote, voteLog) + hex17Percent;
};

export const pinkBlueGradation =
  "linear-gradient(166.31deg, rgba(211, 168, 255, 0.54) 1.38%, #92CDFF 89.34%)";
export const blueGradation =
  "linear-gradient(270deg, #92CDFF -7.87%, rgba(97, 183, 255, 0.47) 109.47%)";
export const verticalPinkBlueGradation =
  "linear-gradient(166.31deg, rgba(211, 168, 255, 0.54) 1.38%, #92CDFF 89.34%)";

export const shadow1 = "0px 4px 4px rgba(0, 0, 0, 0.25)";
export const shadow2 = "0px 0px 12px rgba(0, 0, 0, 0.25)";
export const shadow3 = "4px 4px 4px rgba(59, 94, 201, 0.1)";

export const maxTabletSizeInPx = 500;
export const safeEndingPaddingSizeInPx = 60;

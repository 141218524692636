import Association from "../data-types/association";

const serialize = (data: Record<string, any>): Association => {
  return {
    id: data.id,
    name: data.name,
  };
};

export default serialize;

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as Email } from "./svgs/login/email_join.svg";
import { ReactComponent as Facebook } from "./svgs/login/facebook_join.svg";
import { ReactComponent as Google } from "./svgs/login/google_join.svg";
import { ReactComponent as Apple } from "./svgs/login/apple_join.svg";
import { ReactComponent as Line } from "./svgs/login/line_join.svg";
import { ReactComponent as RectLeft } from "./svgs/login/rect-left.svg";
import { ReactComponent as RectRight } from "./svgs/login/rect-right.svg";
import RoundButtonWrapper from "./lib/round-wrapper";
import { maxTabletSizeInPx } from "../../styles/size";
import { apiAddr } from "../../config";
const StyledJoinBlock = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: ${maxTabletSizeInPx};
  margin-top: 50px;

  font-family: "Spoqa Han Sans Neo", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  /* identical to box height, or 150% */

  letter-spacing: 0.15px;
`;

const SnsButtonBlock = styled.div`
  position: relative;

  //div {
  //  vertical-align: middle;
  //}
`;

const TextSns = styled.div<{ ln?: string | undefined }>`
  font-size: 16px;
  font-family: "Spoqa Han Sans Neo", sans-serif;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 35%;
  color: ${(props) => (props.ln ? "#000" : "#FFF")};
`;

const handleLocationSns = (e: React.MouseEvent, location: string): void => {
  e.preventDefault();
  switch (location) {
    case "email":
      window.location.href = `/login`;
      break;
    case "line":
      window.location.href = `${apiAddr}/v1/auth/oauth/line`;
      break;
    case "apple":
      window.location.href = `${apiAddr}/v1/auth/oauth/apple`;
      break;
    case "facebook":
      window.location.href = `${apiAddr}/v1/auth/oauth/facebook`;
      break;
    case "google":
      window.location.href = `${apiAddr}/v1/auth/google`;
      break;
  }
};
const JoinBlock = () => {
  const { t } = useTranslation("pageLogin");
  return (
    <div>
      <StyledJoinBlock>
        <RoundButtonWrapper>
          <div onClick={(e: React.MouseEvent) => handleLocationSns(e, "email")}>
            <SnsButtonBlock>
              <Email />
              <TextSns>{t("pageLogin:joinEmailLogin")}</TextSns>
            </SnsButtonBlock>
          </div>
        </RoundButtonWrapper>
        <RoundButtonWrapper>
          <div onClick={(e: React.MouseEvent) => handleLocationSns(e, "apple")}>
            <SnsButtonBlock>
              <Apple />
              <TextSns>{t("pageLogin:joiAppleLogin")}</TextSns>
            </SnsButtonBlock>
          </div>
        </RoundButtonWrapper>
        <RoundButtonWrapper>
          <div
            onClick={(e: React.MouseEvent) => handleLocationSns(e, "google")}
          >
            <SnsButtonBlock>
              <Google />
              <TextSns ln={`google`}>{t("pageLogin:joinGoogleLogin")}</TextSns>
            </SnsButtonBlock>
          </div>
        </RoundButtonWrapper>
        <RoundButtonWrapper>
          <div
            onClick={(e: React.MouseEvent) => {
              handleLocationSns(e, "line");
            }}
          >
            <SnsButtonBlock>
              <Line />
              <TextSns>{t("pageLogin:joinLineLogin")}</TextSns>
            </SnsButtonBlock>
          </div>
        </RoundButtonWrapper>
        <RoundButtonWrapper>
          <div
            onClick={(e: React.MouseEvent) => handleLocationSns(e, "facebook")}
          >
            <SnsButtonBlock>
              <Facebook />
              <TextSns>{t("pageLogin:joinFacebookLogin")}</TextSns>
            </SnsButtonBlock>
          </div>
        </RoundButtonWrapper>
      </StyledJoinBlock>
    </div>
  );
};

export default JoinBlock;

import React from 'react';
import styled from "styled-components";
import {maxTabletSizeInPx, safeEndingPaddingSizeInPx} from "../../styles";
import BottomTabApp from "../bottom-tab-app";
import NavigationBarWithGoBackApp from "../navigation-bar-with-go-back-app";

const WideCont = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

const Cont = styled.div`
  height: 100%;
  max-width: ${maxTabletSizeInPx}px;

  display: flex;
  flex-direction: column;

  margin: auto;
`;

const ChildrenCont = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SafePadding = styled.div`
  height: ${safeEndingPaddingSizeInPx}px;
`;

interface NavigationWithGoBackBottomTabLayoutProps {
  title: string;
  children?: any;
  disabledSafePadding?: boolean,
  hideScroll? : boolean,
  backHandler?:() => void;
}

const NavigationWithGoBackBottomTabLayout: React.FC<NavigationWithGoBackBottomTabLayoutProps> = (props) => {
  return (
    <WideCont>
      <Cont>
        <NavigationBarWithGoBackApp title={props.title} backHandler={props.backHandler}/>
        <ChildrenCont style={{overflow:(props.hideScroll?'hidden':'')}} >
          {props.children}
          <SafePadding style={{display:(props.disabledSafePadding?'none':'block')}}/>
        </ChildrenCont>
        <BottomTabApp/>
      </Cont>
    </WideCont>
  );
};

export default NavigationWithGoBackBottomTabLayout;
export type {NavigationWithGoBackBottomTabLayoutProps};

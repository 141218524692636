import {useNavigate} from 'react-router-dom'

const useToAnnouncementPage = () => {
  const navigate = useNavigate();

  return (announcementId: string) => {
    navigate(`/announcement/${announcementId}`);
  };
};

export default useToAnnouncementPage;

const shuffleArray = <T>(array_: T[]): T[] => {
  const array = [...array_];
  let curId = array.length;

  while (0 !== curId) {
    // Pick a remaining element
    const randId = Math.floor(Math.random() * curId);
    curId -= 1;
    // Swap it with the current element.
    const tmp = array[curId];
    array[curId] = array[randId];
    array[randId] = tmp;
  }
  return array;
};

export default shuffleArray;

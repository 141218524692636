import useAuth from "./auth";
import { apiAddr, guestObjectId } from "../config";
import { sessionURL } from "logrocket";

interface Params {
  vote: string;
  choice: string;
}

const useVoteEvent = () => {
  const auth = useAuth();

  return async (params: Params) => {
    await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/votes/${params.vote}/vote-event`,
      data: { choice: params.choice, user: auth.userId ?? guestObjectId },
    });
  };
};

export default useVoteEvent;

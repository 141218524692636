import React from "react";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import DemandingVoteRound from "../../components/demanding-vote-round";
import useLogic from "./use-logic";
import useToCurrentLocale from "../../services/to-current-locale";
import { fadedWorldCup, mainColor, worldCup } from "../../styles";
import voteDateLeft from "../../utils/vote-date-left";
import tournamentTotalRoundCount from "../../utils/tournament-total-round-count";
import tournamentCurrentRoundIndex from "../../utils/tournament-current-round-index";
import { useTranslation } from "react-i18next";
import useToLink from "../../services/to-link";

const VoteTournamentPage: React.FC = () => {
  const logic = useLogic();
  const toCurrentLocale = useToCurrentLocale();
  const { t } = useTranslation("pageVoteTournament");
  const toLink = useToLink();

  if (logic.status !== "LOADED") {
    return null; // todo fix this.
  }

  const stepCount: number = tournamentTotalRoundCount(logic.round) + 1;

  return (
    <NavigationWithGoBackBottomTabLayout title={"WORLD CUP"}>
      <DemandingVoteRound
        cateTitle={`${toCurrentLocale(logic.voteCategory.name)} ${
          stepCount > 4
            ? stepCount + t("pageVoteTournament:stepText")
            : stepCount == 2
            ? t("pageVoteTournament:final")
            : t("pageVoteTournament:semiFinal")
        } ${t("pageVoteTournament:cateTitle")}`}
        stepTitle={`${
          stepCount > 4
            ? stepCount + t("pageVoteTournament:stepText")
            : stepCount == 2
            ? t("pageVoteTournament:final")
            : t("pageVoteTournament:semiFinal")
        }`}
        title={toCurrentLocale(logic.vote.title)}
        description={`${logic.vote.period.from.toLocaleDateString()} ~ ${logic.vote.period.to.toLocaleDateString()}`}
        dateLeft={voteDateLeft(logic.vote)}
        totalRoundCount={tournamentTotalRoundCount(logic.round) + 1}
        currentRoundIndex={tournamentCurrentRoundIndex(logic.round) + 1}
        choices={logic.currentChoices.map((choice, choiceIndex) => {
          return {
            imageSrc: choice.content.imageUrl,
            title: toCurrentLocale(choice.content.title),
            description: toCurrentLocale(choice.content.description),
            actionTitle: t("pageVoteTournament:details"),
            onActionPress: () => toLink(choice.content.link),
            onPress: () => logic.onChoose(choiceIndex),
            isSelected: logic.selectedChoiceIndex === choiceIndex,
          };
        })}
        onNextPress={logic.onNext}
        selectedChoiceIndex={logic.selectedChoiceIndex}
        color={mainColor}
        backgroundColor={fadedWorldCup}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default VoteTournamentPage;

import {useNavigate} from "react-router-dom";

const useToEventVoteResultPage = () => {
  const navigate = useNavigate();

  return (voteId: string) => {
    navigate(`/event-vote-result/${voteId}`);
  };
};

export default useToEventVoteResultPage;

import React from "react";
import styled from "styled-components";

const Cont = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: rgba(55, 84, 163, 0.54);
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  padding-left: 12px;
  padding-right: 12px;

  display: block;
  margin-top: 16px;
  margin-left: 28px;
  margin-right: 28px;
  border-radius: 8px;
  border: 1px solid rgba(55, 84, 163, 0.54);
  height: 40px;
  outline: none;
`;

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  placeholder: string;
}

const Label = styled.span`
  font-size: 16px;
`;

const CountInput: React.FC<Props> = (props) => {
  const onChange = (e: any) => {
    props.onChange(e.target.value);
  };

  return (
    <Cont
      type="number"
      value={props.value}
      onChange={onChange}
      placeholder={props.placeholder}
    ></Cont>
  );
};

export default CountInput;

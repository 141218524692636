import React from "react";
import styled from "styled-components";
import NavigationWithGoBackLayout from "../../components/navigation-bar-with-go-back";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../services/auth";

interface FaqNoMemberProps {
  topTitleText: string;
  loginText: string;
  joinText: string;
  voteText: string;
  accountText: string;
  etcText: string;
  qnaSubmitText: string;
}

const Cont = styled.div`
  padding: 30px 16px 100px 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
`;

const TitleCont = styled.div`
  align-self: center;
  font-weight: bold;
  font-size: 24px;
`;

const ButtonsCont = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Bottomlayout = styled.div`
  padding-top: 120px;
  height: 100px;
  display: flex;
  align-items: end;
  bottom: 0;
`;

const StyledButton = styled.button<{ bgColor: string; textColor: string }>`
  width: 100%;
  height: 56px;
  margin: 7px 0px 5px 0px;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};

  border: none;
  border-radius: 4px;
`;

const FaqNoMember: React.FC<FaqNoMemberProps> = (props) => {
  const navigator = useNavigate();
  const auth = useAuth();

  const handleClick = (e: any) => {
    if (auth.isLoggedIn) {
      navigator(`/qna-form/${e.target.value}`);
    } else {
      window.location.href = "mailto:fanupvote@gmail.com";
    }
  };

  return (
    <>
      <Cont>
        <TitleCont>{props.topTitleText}</TitleCont>

        <ButtonsCont>
          <StyledButton
            bgColor={`#EDF1F5`}
            textColor={`#98A5B3`}
            value={`login`}
            onClick={handleClick}
          >
            {props.loginText}
            {!auth.isLoggedIn && " (Email)"}
          </StyledButton>
          <StyledButton
            bgColor={`#EDF1F5`}
            textColor={`#98A5B3`}
            value={"join"}
            onClick={handleClick}
          >
            {props.joinText}
            {!auth.isLoggedIn && " (Email)"}
          </StyledButton>
          <StyledButton
            bgColor={`#EDF1F5`}
            textColor={`#98A5B3`}
            value={"vote"}
            onClick={handleClick}
          >
            {props.voteText}
            {!auth.isLoggedIn && " (Email)"}
          </StyledButton>
          <StyledButton
            bgColor={`#EDF1F5`}
            textColor={`#98A5B3`}
            value={"account"}
            onClick={handleClick}
          >
            {props.accountText}
            {!auth.isLoggedIn && " (Email)"}
          </StyledButton>
          <StyledButton
            bgColor={`#EDF1F5`}
            textColor={`#98A5B3`}
            value={"etc"}
            onClick={handleClick}
          >
            {props.etcText}
            {!auth.isLoggedIn && " (Email)"}
          </StyledButton>
        </ButtonsCont>
        <Bottomlayout>
          <StyledButton
            bgColor={`#95C5EC`}
            textColor={`#FFFFFF`}
            onClick={() => {
              window.location.href = "mailto:fanupvote@gmail.com";
            }}
          >
            {props.qnaSubmitText}
            {!auth.isLoggedIn && " (Email)"}
          </StyledButton>
        </Bottomlayout>
      </Cont>
    </>
  );
};

const EditProfilePage: React.FC = () => {
  const { t } = useTranslation("pageQna");

  return (
    <>
      <NavigationWithGoBackLayout title={t("qnaInCapital")} />
      <FaqNoMember
        topTitleText={t("topTitle")}
        loginText={t("login")}
        joinText={t("join")}
        voteText={t("vote")}
        accountText={t("account")}
        etcText={t("etc")}
        qnaSubmitText={t("qnaEmailSubmitText")}
      />
    </>
  );
};

export default EditProfilePage;

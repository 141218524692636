import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import useLogic from "./use-logic";
import Props from "./props";
import {
  cg800,
  mainColor,
  mainEventColor,
  maxTabletSizeInPx,
  pointColor,
} from "../../styles";
import { ReactComponent as CloseIcon } from "./close.svg";
import CheckBox from "./check-box";
import { useTranslation } from "react-i18next";

interface DumbLanguage {
  id: string;
  label: string;
  isSelected: boolean;
  onPress: () => void;
}

interface DumbProps {
  onClose: () => void;
  languages: DumbLanguage[];
}

const Cont = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const InnerCont = styled.div`
  max-width: ${maxTabletSizeInPx}px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  padding-bottom: 36px;
`;

const Header = styled.div`
  padding: 20px 24px 0px 24px;
  display: flex;
`;

const Title = styled.span`
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  color: ${mainEventColor};
`;

const TheCloseIcon = styled(CloseIcon)`
  margin-top: 4px;
  cursor: pointer;
`;

const Languages = styled.div`
  margin-top: 20px;
`;

const LanguageCont = styled.div`
  height: 56px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #938f99;
  cursor: pointer;
`;

const LanguageLabel = styled.span`
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: ${cg800};
`;

const TheCheckBox = styled(CheckBox)``;

const Dumb: React.FC<DumbProps> = (props) => {
  const { t } = useTranslation("modalSelectLanguageApp");

  return (
    <Cont>
      <InnerCont>
        <Header>
          <Title>{t("pleaseSelectALanguage")}</Title>
          <TheCloseIcon onClick={props.onClose} />
        </Header>
        <Languages>
          {props.languages.map((language) => {
            return (
              <LanguageCont key={language.id} onClick={language.onPress}>
                <LanguageLabel>{language.label}</LanguageLabel>
                <TheCheckBox value={language.isSelected} />
              </LanguageCont>
            );
          })}
        </Languages>
      </InnerCont>
    </Cont>
  );
};

const SelectLanguageModalApp: React.FC<Props> = (props: Props) => {
  const logic = useLogic(props);

  return (
    <Modal
      isOpen={props.isVisible}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: 0,
          backgroundColor: "transparent",
          padding: 0,
          borderRadius: 0,
        },
      }}
    >
      <Dumb
        onClose={props.onRequestClose}
        languages={[
          {
            id: "kr",
            isSelected: logic.selectedLanguage === "kr",
            label: "한국어",
            onPress: () => logic.onLanguageSelect("kr"),
          },
          {
            id: "en",
            isSelected: logic.selectedLanguage === "en",
            label: "English",
            onPress: () => logic.onLanguageSelect("en"),
          },
          {
            id: "zh",
            isSelected: logic.selectedLanguage === "zh",
            label: "汉字",
            onPress: () => logic.onLanguageSelect("zh"),
          },
        ]}
      />
    </Modal>
  );
};

export default SelectLanguageModalApp;

import React from "react";
import styled from "styled-components";
import { LoadedLogic, Logic } from "./use-logic";
import * as CompoundLayout from "../../../../data-types/compound-layout";
import { ReactComponent as VoteIcon } from "./vote.svg";
import { ReactComponent as LocationIcon } from "./location.svg";
import {
  black,
  cg500,
  fadedPrimary,
  pointColor,
  primary,
} from "../../../../styles";
import useToCurrentLocale from "../../../../services/to-current-locale";
import addCommaToNumber from "../../../../utils/add-comma-to-number";
import IdolVoteCandidate from "../../../../data-types/idol-vote-candidate";
import Idol from "../../../../data-types/idol";

const Cont = styled.div`
  position: relative;
`;

const WhiteArea = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  height: 160px;
`;

const Contents = styled.div`
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  height: 24px;
  align-items: center;
`;

const Badge = styled.span`
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  color: #ffffff;
  background-color: ${pointColor};
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  font-weight: bold;
`;

const Title = styled.span`
  font-family: "BalooBhaijaan";
  margin-left: 8px;
  color: ${black};
  font-size: 18px;
  flex: 1;
`;

const More = styled.span`
  color: ${cg500};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`;

const LeadingIdolHeader = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 14px;
  display: flex;
`;

const LeadingRanking = styled.span`
  color: ${black};
  font-size: 48px;
  line-height: 48px;
  font-weight: bold;
`;

const LeadingMetadata = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const LeadingName = styled.span`
  color: ${black};
  font-size: 18px;
  line-height: 23px;
`;

const LeadingSubmetadata = styled.div`
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeadingVoteCountCont = styled.div`
  display: flex;
  align-items: center;
`;

const TheVoteIcon = styled(VoteIcon)``;

const LeadingVoteCount = styled.span`
  margin-left: 4px;
  color: #a0a0a0;
  font-size: 14px;
`;

const LeadingRegionCont = styled.div`
  display: flex;
  align-items: center;
`;

const TheLocationIcon = styled(LocationIcon)``;

const LeadingRegion = styled.span`
  margin-left: 6px;
  color: #a0a0a0;
  font-size: 14px;
`;

const LeadingImage = styled.img`
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 14px;
  border-radius: 8px;
  height: 180px;
  object-fit: cover;
  cursor: pointer;
`;

const NonleadingCandidatesCont = styled.div`
  margin-top: 12px;
  display: flex;
  overflow-x: auto;
  padding-left: 16px;
  padding-right: 16px;
`;

const CandidateCard = styled.div`
  min-width: 276px;
  padding: 8px;
  display: flex;
  background-color: white;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;

  margin-left: 8px;
  &:first-of-type {
    margin-left: 0px;
  }
`;

const CandidateLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const CandidateHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CandidateRanking = styled.span`
  color: ${black};
  font-size: 32px;
  font-weight: bold;
`;

const CandidateName = styled.span`
  margin-left: 14px;
  font-size: 18px;
  color: ${black};
  flex: 1;
`;

const CandidateMetadata = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CandidateVoteCountCont = styled.div`
  display: flex;
  align-items: center;
`;

const TheCandidateVoteIcon = styled(VoteIcon)``;

const CandidateVoteCount = styled.span`
  margin-left: 4px;
  font-size: 12px;
  color: #a0a0a0;
`;

const CandidateRegionCont = styled.div`
  display: flex;
  align-items: center;
`;

const TheCandidateLocationIcon = styled(LocationIcon)``;

const CandidateRegion = styled.span`
  margin-left: 6px;
  font-size: 12px;
  color: #a0a0a0;
`;

const CandidateImage = styled.img`
  margin-left: 10px;
  width: 48px;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

interface DumbCandidate {
  id: string;
  name: string;
  voteCount: number;
  region: string;
  imageUrl: string | undefined;
}

interface DumbProps {
  voteCategoryName: string;
  leadingName: string;
  leadingVoteCount: number;
  leadingRegion: string;
  leadingImageUrl: string | undefined;
  candidates: DumbCandidate[];

  onMorePress: () => void;
  onLeadingPress: () => void;
  onIdolPress: (index: number) => void;
}

const Dumb: React.FC<DumbProps> = (props) => {
  return (
    <Cont>
      <WhiteArea />
      <Contents>
        <Header>
          <Badge>{props.voteCategoryName}</Badge>
          <Title>FAN VOTE</Title>
          <More onClick={props.onMorePress}>MORE</More>
        </Header>
        <LeadingIdolHeader>
          <LeadingRanking>1</LeadingRanking>
          <LeadingMetadata>
            <LeadingName>{props.leadingName}</LeadingName>
            <LeadingSubmetadata>
              <LeadingVoteCountCont>
                <TheVoteIcon />
                <LeadingVoteCount>
                  {addCommaToNumber(props.leadingVoteCount)}
                </LeadingVoteCount>
              </LeadingVoteCountCont>
              <LeadingRegionCont>
                <TheLocationIcon />
                <LeadingRegion>{props.leadingRegion}</LeadingRegion>
              </LeadingRegionCont>
            </LeadingSubmetadata>
          </LeadingMetadata>
        </LeadingIdolHeader>
        <LeadingImage
          src={props.leadingImageUrl}
          onClick={props.onLeadingPress}
        />
        <NonleadingCandidatesCont>
          {props.candidates.map((candidate, candidateIndex) => {
            return (
              <CandidateCard
                key={candidate.id}
                onClick={() => props.onIdolPress(candidateIndex)}
              >
                <CandidateLeft>
                  <CandidateHeader>
                    <CandidateRanking>{candidateIndex + 2}</CandidateRanking>
                    <CandidateName>{candidate.name}</CandidateName>
                  </CandidateHeader>
                  <CandidateMetadata>
                    <CandidateVoteCountCont>
                      <TheCandidateVoteIcon />
                      <CandidateVoteCount>
                        {candidate.voteCount}
                      </CandidateVoteCount>
                    </CandidateVoteCountCont>
                    <CandidateRegionCont>
                      <TheCandidateLocationIcon />
                      <CandidateRegion>{candidate.region}</CandidateRegion>
                    </CandidateRegionCont>
                  </CandidateMetadata>
                </CandidateLeft>
                <CandidateImage src={candidate.imageUrl} />
              </CandidateCard>
            );
          })}
        </NonleadingCandidatesCont>
      </Contents>
    </Cont>
  );
};

interface Props {
  component: CompoundLayout.RandomIdolVoteComponent;
  logic: LoadedLogic;
}

const RandomIdolVoteComponent: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  if (props.logic.candidates.length === 0) {
    return null; // todo
  }

  const leadingCandidate: IdolVoteCandidate = props.logic.candidates[0];
  const leadingIdol: Idol = props.logic.idols[0];

  const idolFullRegionCountryName = (idol: Idol) => {
    if (idol.region == null) {
      return "";
    }

    const region = props.logic.regions[idol.region];
    const country = props.logic.countries[region.country];

    return `${toCurrentLocale(region.name)}, ${toCurrentLocale(country.name)}`;
  };

  const notNull = (a: any) => a !== null;

  return (
    <Dumb
      voteCategoryName={toCurrentLocale(props.logic.vote.tabName)}
      leadingName={toCurrentLocale(leadingIdol.name)}
      leadingVoteCount={leadingCandidate.voteCount}
      leadingRegion={idolFullRegionCountryName(leadingIdol)}
      leadingImageUrl={leadingIdol.thumbnailUrl}
      candidates={
        props.logic.candidates
          .map((candidate, candidateIndex) => {
            if (candidateIndex === 0) {
              return null;
            }

            const idol = props.logic.idols[candidateIndex];

            return {
              id: candidate.idol,
              name: toCurrentLocale(idol.name),
              region: idolFullRegionCountryName(idol),
              voteCount: candidate.voteCount,
              imageUrl: idol.thumbnailUrl,
            };
          })
          .filter(notNull) as DumbCandidate[]
      }
      onMorePress={props.logic.toIdolVotes}
      onLeadingPress={props.logic.toIdolVotes}
      onIdolPress={props.logic.toIdolVotes}
    />
  );
};

export default RandomIdolVoteComponent;
export { default as useLogic } from "./use-logic";

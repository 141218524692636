import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavigationBar from "../../components/navigation-bar";
import NoticeText from "../../components/notice-text";
import ProgressText from "../../components/progress-text";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import { maxTabletSizeInPx } from "../../styles/size";
import useToNextCreateAccountPage from "../../services/to-next-create-account-page";
import checkEmail from "../../errors/check-email";
import noProblem from "../../utils/no-problem";
import useHandleAppError from "../../services/handle-app-error";
import useIsEmailTaken from "../../services/is-email-taken";
import { mainColor, cg300 } from "../../styles";
import { ReactComponent as UnAllCheckIcon } from "./svg/UnAllCheck.svg";
import { ReactComponent as UnCheckIcon } from "./svg/UnCheck.svg";
import { ReactComponent as AllCheckIcon } from "./svg/AllChecked.svg";
import { ReactComponent as CheckIcon } from "./svg/Checked.svg";
import { ReactComponent as AcceptIcon } from "./svg/Accept.svg";
import { ReactComponent as UnAcceptIcon } from "./svg/UnAccept.svg";
import { ReactComponent as HandleIcon } from "./svg/Handle.svg";

const Gap72 = styled.div`
  padding-top: 72px;
`;
const Gap28 = styled.div`
  padding-top: 28px;
`;
const Gap24 = styled.div`
  padding-top: 24px;
`;

const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;
const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  margin-top: 100%;
  width: 100%;
  height: 60%;
  max-width: 400px;
  padding: 0px;
  border-radius: 15px 15px 0px 0px;
  text-align: center;
`;

const ModalTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const AgreementContainer = styled.div`
  display: flex;
  margin: 0px 10px 0px 10px;
  flex-direction: column;
  gap: 10px;
`;

const CheckboxInput = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled.button`
  font-size: 19px;
  margin-top: 10px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  width: 355px;
  height: 55px;
  cursor: pointer;
  background-color: ${(props) => (props.disabled ? "#98A5B3" : "#FF176B")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const cssWhenActive = `
  svg {
    fill: ${mainColor};
  }
  color: ${mainColor};
`;

const CheckboxContainer = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  white-space: break-spaces;
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 5px;
  justify-content: flex-start;
  color: ${cg300};
  cursor: pointer;

  input[type="checkbox"] {
    display: none; /* 기본 체크박스 숨김 */
  }

  svg {
    //fill: #fff;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  :hover svg {
    //fill: ${mainColor};
  }

  :hover {
    color: ${mainColor};
  }
  :checked {
    color: ${mainColor};
  }
`;

const HR = styled.hr`
  width: 100%;
  height: -1px;
  background-color: grey;
`;
const STxt = styled.div`
  text-align: left;
  font-size: 16px;
`;

const CreateAccount1Page = () => {
  const { t } = useTranslation("pageCreateAccount1");
  const toNext = useToNextCreateAccountPage();
  const [email, setEmail] = useState<string>("");
  const isFormValid = noProblem(() => checkEmail(email));
  const handleAppError = useHandleAppError();
  const isEmailTaken = useIsEmailTaken();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [allAgreement, setAllAgreement] = useState(false);
  const [touchStartY, setTouchStartY] = useState(null);
  const [accept, setAccept] = useState("");
  const [agreements, setAgreements] = useState({
    essential1: false,
    essential2: false,
    essential3: false,
    optional: false,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTouchStart = (e: any) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: any) => {
    if (touchStartY !== null) {
      const deltaY = e.touches[0].clientY - touchStartY;
      if (deltaY > 50) {
        // 스와이프 거리가 일정 값 이상이면 모달 닫기
        closeModal();
        setTouchStartY(null);
      }
    }
  };

  const handleTouchEnd = () => {
    setTouchStartY(null);
  };

  const handleAgreementChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    if (name === "all") {
      // "전체 동의하기" 체크박스 클릭 시, 다른 항목들의 상태를 업데이트
      setAgreements({
        essential1: checked,
        essential2: checked,
        essential3: checked,
        optional: checked,
      });
      setAllAgreement(checked); // 전체 동의 상태 업데이트
    } else {
      // 다른 항목들의 체크 이벤트 처리
      setAgreements({
        ...agreements,
        [name]: checked,
      });
      const isAllAgreementsChecked =
        checked &&
        agreements.essential1 &&
        agreements.essential2 &&
        agreements.essential3;
      setAllAgreement(isAllAgreementsChecked);
    }
    // 모든 필수 항목이 체크되었는지 확인하여 전체 동의 상태 업데이트
  };

  const isSubmitButtonDisabled =
    agreements.essential1 && agreements.essential2 && agreements.essential3;

  const agreeComplete = () => {
    if (agreements.optional === true) {
      setAccept("AllAgree");
    } else if (isSubmitButtonDisabled) {
      setAccept("essential");
    }
    if (isSubmitButtonDisabled) {
      closeModal();
    }
  };

  const onSubmit = async () => {
    if (accept === "") {
      alert(t("pleaseAgree"));
      openModal();
    }
    try {
      if (await isEmailTaken(email)) {
        alert(t("givenEmailWasAlreadyTaken"));
        return;
      }

      let regExp =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if (!email.match(regExp)) {
        alert(t("malformedEmail"));
        return;
      }

      await toNext({ email, accept });
    } catch (error) {
      await handleAppError(error);
    }
  };

  return (
    <>
      <NavigationBar text={t("pageCreateAccount1:navigation")}></NavigationBar>
      <RootCont>
        <Gap72 />
        <TextCont>
          <ProgressText />
          <NoticeText
            notice={t("pageCreateAccount1:notice")}
            desciption={t("pageCreateAccount1:description")}
          />
        </TextCont>
        <Gap28 />
        <BoxCont>
          <TextInput
            type="email"
            onChange={setEmail}
            value={email}
            placeholder={t("pageCreateAccount1:emailPlaceholder")}
          />
          <Gap24 />
          <Button
            onClick={onSubmit}
            value={t("pageCreateAccount1:button")}
            disabled={!isFormValid}
          />
        </BoxCont>
        {isModalOpen && (
          <ModalContainer
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <ModalContent>
              <HandleIcon className="svgIcon" />
              <ModalTitle>{t("pageCreateAccount1:joinHeader")}</ModalTitle>

              <AgreementContainer>
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    name="all"
                    checked={allAgreement}
                    onChange={(e) => handleAgreementChange(e)}
                  />
                  {allAgreement ? (
                    <AllCheckIcon className="svgIcon" />
                  ) : (
                    <UnAllCheckIcon className="svgIcon" /> // 선택되지 않았을 때 아이콘
                  )}
                  {t("pageCreateAccount1:allAccept")}
                </CheckboxContainer>
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    name="essential1"
                    checked={agreements.essential1}
                    onChange={(e) => handleAgreementChange(e)}
                  />
                  {agreements.essential1 ? (
                    <CheckIcon className="svgIcon" />
                  ) : (
                    <UnCheckIcon className="svgIcon" /> // 선택되지 않았을 때 아이콘
                  )}
                  {t("pageCreateAccount1:essential1")}
                </CheckboxContainer>
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    name="essential2"
                    checked={agreements.essential2}
                    onChange={(e) => handleAgreementChange(e)}
                  />
                  {agreements.essential2 ? (
                    <CheckIcon className="svgIcon" />
                  ) : (
                    <UnCheckIcon className="svgIcon" /> // 선택되지 않았을 때 아이콘
                  )}
                  {t("pageCreateAccount1:essential2")}
                </CheckboxContainer>
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    name="essential3"
                    checked={agreements.essential3}
                    onChange={(e) => handleAgreementChange(e)}
                  />
                  {agreements.essential3 ? (
                    <CheckIcon className="svgIcon" />
                  ) : (
                    <UnCheckIcon className="svgIcon" /> // 선택되지 않았을 때 아이콘
                  )}
                  {t("pageCreateAccount1:essential3")}
                </CheckboxContainer>
                <HR></HR>
                <STxt>{t("pageCreateAccount1:optional")}</STxt>
                <CheckboxContainer>
                  <input
                    type="checkbox"
                    name="optional"
                    checked={agreements.optional}
                    onChange={(e) => handleAgreementChange(e)}
                  />
                  {agreements.optional ? (
                    <CheckIcon className="svgIcon" />
                  ) : (
                    <UnCheckIcon className="svgIcon" /> // 선택되지 않았을 때 아이콘
                  )}
                  {t("pageCreateAccount1:optional1")}
                </CheckboxContainer>
              </AgreementContainer>

              <SubmitButton
                disabled={!isSubmitButtonDisabled}
                onClick={agreeComplete}
              >
                {t("submit")}
              </SubmitButton>
            </ModalContent>
          </ModalContainer>
        )}
      </RootCont>
    </>
  );
};

export default CreateAccount1Page;

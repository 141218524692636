import Announcement from "../data-types/announcement";
import serializeAnnouncement from '../from-server-serializers/announcement';
import axios from 'axios';
import {apiAddr} from "../config";

const useReadAnnouncement = () => {
  return async (id: string): Promise<Announcement> => {
    const axiosResult = await axios({
      method: 'get',
      url: `${apiAddr}/v1/announcements/${id}`,
    });

    return serializeAnnouncement(axiosResult.data);
  };
};

export default useReadAnnouncement;

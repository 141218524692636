import axios from "axios";
import { PushInput, PushResult } from "../data-types/main-push-data";
import { apiAddr } from "../config";

const useMainPushData = () => {
  return async (param: PushInput): Promise<PushResult | undefined> => {
    const axiosResult = await axios({
      method: "post",
      url: `${apiAddr}/v1/push-info`,
      data: param,
    });

    const result = axiosResult.data;

    if (result) {
      const { pushAllow } = result;
      return pushAllow;
    } else {
      return undefined;
    }
  };
};

export default useMainPushData;

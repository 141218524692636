import useAuth from "../auth";
import { apiAddr } from "../../config";
import { FanUpAttractionPayload } from "../../data-types/attraction";
import serializeAnnouncement from "../../from-server-serializers/announcement";

/**
 * 관광지등록
 */
export const useCreateAttraction = () => {
  const auth = useAuth();

  return async (attraction: FanUpAttractionPayload): Promise<void> => {
    const result = await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/attraction`,
      data: attraction,
    });
  };
};

export interface AttractionReadParams {
  lat?: number | undefined;
  lng?: number | undefined;
  keyword?: string | undefined;
  picks?: string | undefined;
}

/**
 * 관광지 목록 조회
 */
export const useReadAttractions = () => {
  const auth = useAuth();
  return async (
    params: AttractionReadParams
  ): Promise<FanUpAttractionPayload[]> => {
    const result = await auth.callAxios({
      method: "get",
      url: `${apiAddr}/v1/attraction/nearBy`,
      params: {
        lat: params.lat,
        lng: params.lng,
        picks: params.picks,
        keyword: params.keyword,
      },
    });
    if (result.data.totalCount > 0) {
      return result.data.data;
    } else {
      return [];
    }
  };
};

import { useLocation } from "react-router-dom";
import AppTabKind from "../utils/app-tab-kind";

const useCurrentAppTabKind = (): AppTabKind | undefined => {
  const location = useLocation();

  if (location.pathname === "/idol-votes") {
    return "CHART";
  }

  if (location.pathname === "/") {
    return "MAIN";
  }

  if (location.pathname === "/demanding-votes") {
    return "DEMANDING";
  }

  if (location.pathname === "/my-account") {
    return "MY_ACCOUNT";
  }

  if (location.pathname === "/fanup-tour") {
    return "FANUP";
  }
  if (location.pathname === "/maps") {
    return "MAP";
  }

  return undefined;
};

export default useCurrentAppTabKind;

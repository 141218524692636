import {useNavigate} from 'react-router-dom';

const useGoBack = () => {
  const nav = useNavigate();

  return () => {
    nav(-1);
  };
};

export default useGoBack;

import React from 'react';
import BottomTab from '../bottom-tab';
import useToAppTab from "../../services/to-app-tab";
import useCurrentAppTabKind from "../../services/current-app-tab-kind";

interface BottomTabAppProps {
  className?: string | undefined;
}

const BottomTabApp: React.FC<BottomTabAppProps> = (props) => {
  const onTabPress = useToAppTab();
  const activeTab = useCurrentAppTabKind();

  return <BottomTab
    className={props.className}
    onTabPress={onTabPress}
    activeTab={activeTab}
  />;
};

export default BottomTabApp;
export type {BottomTabAppProps};

import React from "react";
import styled from "styled-components";
import { black, cg500 } from "../../styles";

const TopCont = styled.div`
  padding: 24px 24px 12px 24px;
  background-color: white;
`;

// const Kind = styled.span<{ color: string; backgroundColor: string }>`
//   height: 40px;
//   font-size: 18px;
//   line-height: 40px;
//   text-align: center;
//   display: block;
//   border-radius: 4px;
//   background-color: ${({ backgroundColor }) => backgroundColor};
//   color: ${({ color }) => color};
// `;

const Title = styled.span`
  display: block;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.24px;

  padding: 3px;

  /* black */

  color: #051331;
`;

const Description = styled.span`
  display: block;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.24px;

  /* Coolgray/CG500 */

  color: #6c7680;
`;

interface DemandingVoteBriefProps {
  className?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
}

const DemandingVoteBrief: React.FC<DemandingVoteBriefProps> = (props) => {
  return (
    <TopCont className={props.className}>
      {/*<Kind color={props.color} backgroundColor={props.backgroundColor}>*/}
      {/*  {props.kind}*/}
      {/*</Kind>*/}
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </TopCont>
  );
};

export default DemandingVoteBrief;
export type { DemandingVoteBriefProps };

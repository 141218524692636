import Vote from '../data-types/vote';
import useAuth from "./auth";
import serializeVote from '../from-server-serializers/vote';
import {apiAddr} from "../config";

const useReadVoteById = () => {
  const auth = useAuth();

  return async (voteId: string): Promise<Vote> => {
    const result = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/v1/votes/${voteId}`,
    });

    return serializeVote(result.data);
  };
};

export default useReadVoteById;

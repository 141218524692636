import MyUser from "../data-types/my-user";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAuth from "./auth";
import { apiAddr } from "../config";
import serializeMyUser from "../from-server-serializers/my-user";
import { AuthEventHandler, AuthEvent } from "../utils/auth";

interface Result {
  read: () => Promise<MyUser | undefined>;
  updateName: (newName: string) => Promise<void>;
  updateBirth: (newBirth: string) => Promise<void>;
  updatePassword: (newPassword: string) => Promise<void>;
  setTicketCount: (newTicketCount: number) => void;
  refresh: () => void;
  drawal: () => void;
}

const useCurrentUser = (): Result => {
  const [user, setUser] = useState<MyUser | undefined>(undefined);
  const auth = useAuth();

  const readUserFromServer = useCallback(
    async (force = false): Promise<MyUser> => {
      if (!force && user != null) {
        return user;
      }

      const result = await auth.callAxios({
        method: "get",
        url: `${apiAddr}/v1/users/${auth.userId}`,
      });

      const newUser = serializeMyUser(result.data);

      setUser(newUser);

      return newUser;
    },
    [auth]
  );

  const updateUserField = useCallback(
    async <T>(fieldName: string, fieldValue: T) => {
      await readUserFromServer();

      await auth.callAxios({
        method: "patch",
        url: `${apiAddr}/v1/users/${auth.userId}`,
        data: { [fieldName]: fieldValue },
      });

      setUser((oldUser) => {
        if (oldUser == null) {
          return undefined;
        }

        return { ...oldUser, [fieldName]: fieldValue };
      });
    },
    [readUserFromServer, setUser]
  );

  const drawal = async <T>() => {
    await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/auth/withdrawal`,
      data: { id: `${auth.userId}` },
    });
  };

  const updateName = useCallback(
    (newName: string) => updateUserField("name", newName),
    [updateUserField]
  );

  const updateBirth = useCallback(
    (newBirth: string) => updateUserField("birth", newBirth),
    [updateUserField]
  );

  const updatePassword = useCallback(
    (newPassword: string) => updateUserField("password", newPassword),
    [updateUserField]
  );

  const setTicketCount = useCallback(
    (newTicketCount: number) => {
      setUser((oldUser) => {
        if (oldUser == null) {
          return;
        }

        return { ...oldUser, ticketCount: newTicketCount };
      });
    },
    [setUser]
  );

  const authEventHandlerRef = useRef<AuthEventHandler>();
  authEventHandlerRef.current = (event: AuthEvent) => {
    if (event.kind === "LOGOUT") {
      setUser(undefined);
    }
  };
  useEffect(
    () => auth.subscribe(authEventHandlerRef.current!),
    [auth, authEventHandlerRef]
  );

  const prepareAndRead = useCallback(async (): Promise<MyUser | undefined> => {
    if (auth.isLoggedIn) {
      if (user == null) {
        const newUser = await readUserFromServer();
        return newUser;
      }

      return user;
    }

    return undefined;
  }, [user, setUser, auth]);

  const refresh = useCallback(() => {
    readUserFromServer(true);
  }, [readUserFromServer]);

  return useMemo(
    () => ({
      read: prepareAndRead,
      updateName,
      updateBirth,
      updatePassword,
      setTicketCount,
      refresh,
      drawal,
    }),
    [
      prepareAndRead,
      updateName,
      updateBirth,
      updatePassword,
      setTicketCount,
      refresh,
      drawal,
    ]
  );
};

export default useCurrentUser;

import { strict as assert } from "assert";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCurrentCreateAccountStep from "./current-create-account-step";
import encodeQueryParameters from "../utils/encode-query-parameters";
import useToMainPage from "./to-main-page";
import useCreateAccount from "./create-account";
import checkEmail from "../errors/check-email";
import checkPassword from "../errors/check-password";
import checkName from "../errors/check-name";
import checkRegion from "../errors/check-region";
import checkBirth from "../errors/check-birth";
import useSendVerificationEmail from "./send-verification-email";
import useToLogin from "./to-login-page";

interface CreateAccountProcessBase {
  [index: string]: any;
}

interface CreateAccountProcess1 extends CreateAccountProcessBase {
  email: string;
  accept: string;
}

interface CreateAccountProcess2 extends CreateAccountProcessBase {
  password: string;
}

interface CreateAccountProcess3 extends CreateAccountProcessBase {
  name: string;
}

interface CreateAccountProcess31 extends CreateAccountProcessBase {
  country: string;
  region: string;
}

interface CreateAccountProcess4 extends CreateAccountProcessBase {
  birth: string | undefined; // 2000-01-01 포맷으로
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateAccountProcess5 extends CreateAccountProcessBase {}

type CreateAccountProcess =
  | CreateAccountProcess1
  | CreateAccountProcess2
  | CreateAccountProcess3
  | CreateAccountProcess4
  | CreateAccountProcess5;

const useToNextCreateAccountPage = () => {
  const nav = useNavigate();
  const [searchParams] = useSearchParams();
  const createAccount = useCreateAccount();
  const toLogin = useToLogin();
  const sendVerificationEmail = useSendVerificationEmail();

  const getStep = useCurrentCreateAccountStep();

  return async (createAccountProcess: CreateAccountProcess) => {
    if (getStep().currentStartingWith1 === 1) {
      const process = createAccountProcess as CreateAccountProcess1;
      checkEmail(process.email);

      nav(
        `/create-account-2?${window.location.search}&${encodeQueryParameters(
          createAccountProcess
        )}`
      );
      return;
    }

    if (getStep().currentStartingWith1 === 2) {
      const process = createAccountProcess as CreateAccountProcess2;
      checkPassword(process.password);

      nav(
        `/create-account-3?${window.location.search}&${encodeQueryParameters(
          createAccountProcess
        )}`
      );
      return;
    }

    if (getStep().currentStartingWith1 === 3) {
      const process = createAccountProcess as CreateAccountProcess3;
      checkName(process.name);

      nav(
        `/create-account-4?$${window.location.search}&${encodeQueryParameters(
          createAccountProcess
        )}`
      );
      return;
    }

    if (getStep().currentStartingWith1 === 4) {
      const process = createAccountProcess as CreateAccountProcess31;
      checkName(process.country);
      checkRegion(process.region);

      nav(
        `/create-account-5?$${window.location.search}&${encodeQueryParameters(
          createAccountProcess
        )}`
      );
      return;
    }

    if (getStep().currentStartingWith1 === 5) {
      const process = createAccountProcess as CreateAccountProcess4;
      checkBirth(process.birth);

      const email = searchParams.get("email")!;
      const accept = searchParams.get("accept")!;
      const password = searchParams.get("password")!;
      const name = searchParams.get("name")!;
      const country = searchParams.get("country")!;
      const region = searchParams.get("region")!;
      const birth = process.birth!;

      await createAccount({ email,accept, password, name, birth, country, region });
      //await sendVerificationEmail();

      nav(`/verify-account?name=${name}`);
      return;
    }

    if (getStep().currentStartingWith1 === 6) {
      toLogin();
      return;
    }

    assert.fail();
  };
};

export default useToNextCreateAccountPage;

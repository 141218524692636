import { useEffect, useState } from "react";
import { strict as assert } from "assert";
import useCurrentUser from "../../services/current-user";
import useAuth from "../../services/auth";
import useHandleAppError from "../../services/handle-app-error";
import useToTicketLogsPage from "../../services/to-ticket-logs-page";
import { useTranslation } from "react-i18next";
import useToEditProfilePage from "../../services/to-edit-profile-page";
import useToQnaPage from "../../services/to-qna-page";
import useToAnnouncementsPage from "../../services/to-announcements-page";
import useLogout from "../../services/logout";
import useLogoutIfLoggedIn from "../../services/logout-if-logged-in";
import useToMainPage from "../../services/to-main-page";
import useToSettingsPage from "../../services/to-settings-page";

interface LoadingLogic {
  status: "LOADING";
}

interface TheUser {
  name: string;
  email: string;
  ticketCount: number;
}

interface LoadedLogic {
  status: "LOADED";
  user: TheUser;
  openTicketLogs: () => void;
  openAttendance: () => void;
  openQna: () => void;
  openEditProfile: () => void;
  openSettings: () => void;
  openAnnouncements: () => void;
  onLogoutPress: () => void;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  user: TheUser;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const auth = useAuth();
  const currentUser = useCurrentUser();
  const handleAppError = useHandleAppError();
  const toTicketLogs = useToTicketLogsPage();
  const toEditProfile = useToEditProfilePage();
  const toAnnouncements = useToAnnouncementsPage();
  const toQnaPage = useToQnaPage();
  const toMain = useToMainPage();
  const toSettingsPage = useToSettingsPage();
  const logoutService = useLogoutIfLoggedIn();
  const { t } = useTranslation();

  const tryInit = async () => {
    auth.checkIsLoggedIn();

    const user = (await currentUser.read())!;

    setState({
      status: "LOADED",
      user: {
        name: user.name,
        email: user.email,
        ticketCount: user.ticketCount,
      },
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const openTicketLogs = () => {
    toTicketLogs();
  };

  const openAttendance = () => {
    alert(t("pageMyAccount:thisFeatureIsInDevelopment"));
  };

  const openEditProfile = () => {
    toEditProfile();
  };

  const openQna = () => {
    toQnaPage();
  };

  const openSettings = () => {
    toSettingsPage();
  };

  const openAnnouncements = () => {
    toAnnouncements();
  };

  const onLogoutPress = async () => {
    try {
      await logoutService();
      toMain();
    } catch (error) {
      await handleAppError(error);
    }
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      user: state.user,
      openTicketLogs,
      openAttendance,
      openEditProfile,
      openSettings,
      openAnnouncements,
      onLogoutPress,
      openQna,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic };

import React from 'react';

interface IProps {
    text: string;
};

const ModalContent: React.FC<IProps> = ({ text }) => {
    return <>
        <p>{text}</p>
    </>
};

export default ModalContent;
import React from "react";
import styled from "styled-components";
import { ReactComponent as Facebook } from "./svgs/login/facebook.svg";
import { ReactComponent as Google } from "./svgs/login/google.svg";
import { ReactComponent as Apple } from "./svgs/login/apple.svg";
import { ReactComponent as Line } from "./svgs/login/line.svg";
import { ReactComponent as RectLeft } from "./svgs/login/rect-left.svg";
import { ReactComponent as RectRight } from "./svgs/login/rect-right.svg";
import RoundButtonWrapper from "./lib/round-wrapper";
import { maxTabletSizeInPx } from "../../styles/size";
import { apiAddr } from "../../config";
const StyledSNSBlock = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: ${maxTabletSizeInPx};
  margin: auto;
`;

const TextHRBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 0px 40px 0px;
  //flex-direction: column;
  div {
    vertical-align: middle;
    &:nth-child(1) {
      flex-grow: 1;
      svg {
        vertical-align: middle;
      }
      margin-right: 3px;
    }
    &:nth-child(2) {
      font-size: 12px;
      flex-grow: 1;
      color: #555252;
      min-width: 98px;
      align-self: self-end;
    }
    &:nth-child(3) {
      flex-grow: 1;
      svg {
        vertical-align: middle;
      }
      margin-left: 1px;
    }
  }
`;

const handleLocationSns = (e: React.MouseEvent, location: string): void => {
  e.preventDefault();
  switch (location) {
    case "line":
      window.location.href = `${apiAddr}/v1/auth/oauth/line`;
      break;
    case "apple":
      window.location.href = `${apiAddr}/v1/auth/oauth/apple`;
      break;
    case "facebook":
      window.location.href = `${apiAddr}/v1/auth/oauth/facebook`;
      break;
    case "google":
      window.location.href = `${apiAddr}/v1/auth/google`;
      break;
  }
};
const SNSBlock = () => {
  return (
    <div>
      <TextHRBlock>
        <div>
          <RectLeft />
        </div>
        <div>OR Sign up With</div>
        <div>
          <RectRight />
        </div>
      </TextHRBlock>
      <StyledSNSBlock>
        <RoundButtonWrapper>
          <div onClick={(e: React.MouseEvent) => handleLocationSns(e, "apple")}>
            <Apple />
          </div>
        </RoundButtonWrapper>
        <RoundButtonWrapper>
          <div
            onClick={(e: React.MouseEvent) => handleLocationSns(e, "google")}
          >
            <Google />
          </div>
        </RoundButtonWrapper>
        <RoundButtonWrapper>
          <div
            onClick={(e: React.MouseEvent) => {
              handleLocationSns(e, "line");
            }}
          >
            <Line />
          </div>
        </RoundButtonWrapper>
        <RoundButtonWrapper>
          <div
            onClick={(e: React.MouseEvent) => handleLocationSns(e, "facebook")}
          >
            <Facebook />
          </div>
        </RoundButtonWrapper>
      </StyledSNSBlock>
    </div>
  );
};

export default SNSBlock;

import React from "react";
import styled from "styled-components";
import AnnouncementData from "../../../../data-types/announcement";
import useToCurrentLocale from "../../../../services/to-current-locale";
import { cg60, cg300, primary, fadedWorldCup } from "../../../../styles";

const Cont = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  border-top: 1px solid ${cg60};
  padding-left: 8px;
  padding-right: 8px;
`;

const Kind = styled.span`
  width: 54px;
  font-weight: bold;
  color: ${fadedWorldCup};
  font-size: 12px;
`;

// Okay. I know this is evil.
// But that's just... my designer said to do.
// I'm just a developer... No opinioned... (* crying *)
const titleColor = "#525C66";

const Title = styled.span`
  margin-left: 8px;
  flex: 1;
  color: ${titleColor};
  font-size: 14px;
  cursor: pointer;

  /* Make elipsis */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const IssuedAt = styled.span`
  font-size: 12px;
  color: ${cg300};
  margin-left: 8px;
`;

interface Props {
  announcement: AnnouncementData;
  onClick: () => void;
}

const printKind = (kind: AnnouncementData["kind"]): string => {
  switch (kind) {
    case "INFO":
      return "Info";

    case "EVENT":
      return "Event";
  }
};

const Announcement: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  return (
    <Cont onClick={props.onClick}>
      <Kind>{printKind(props.announcement.kind)}</Kind>
      <Title>{toCurrentLocale(props.announcement.title)}</Title>
      <IssuedAt>{props.announcement.issuedDate.toLocaleDateString()}</IssuedAt>
    </Cont>
  );
};

export default Announcement;

import Country from "../data-types/country";
import {apiAddr} from "../config";
import serializeCountry from '../from-server-serializers/country';
import axios from 'axios';
import CountryNotFoundError from "../errors/country-not-found";

const readCountries = async (): Promise<Record<string, Country>> => {
  const axiosResult = await axios({
    method: 'get',
    url: `${apiAddr}/v1/countries`,
    params: { limit: 500 },
  });

  const countries: Country[] = axiosResult.data.map(serializeCountry);

  const countryRecord = Object.fromEntries(countries.map((country) => [country.id, country]));

  return countryRecord;
};

const countriesPromise: Promise<Record<string, Country>> = readCountries();

const useReadCountry = () => {
  return async (id: string): Promise<Country> => {
    const country = (await countriesPromise)[id];

    if (country == null) {
      throw new CountryNotFoundError(id);
    }

    return country;

    // try {
    //   const axiosResult = await axios({
    //     method: 'get',
    //     url: `${apiAddr}/v1/countries/${id}`,
    //   });
    //
    //   const country: Country = serializeCountry(axiosResult.data);
    //
    //   return country;
    // } catch (error) {
    //   throw new ReadCountryServerError(error);
    // }
  };
};

export default useReadCountry;

import React from "react";
import styled from "styled-components";
import {
  cg500,
  darkBlue,
  darkGray,
  mainEventColor,
  pointColor,
} from "../styles";

const Cont = styled.div``;
const Title = styled.div`
  color: ${(props) => props.color};
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
`;
const Description = styled.div`
  color: ${(props) => props.color};
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  white-space: pre-line;
`;
const LinedText = styled.div`
  color: ${(props) => props.color};
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  text-decoration-line: underline;

  cursor: pointer;
`;

type NoticeTextSmallProps = {
  className?: string | undefined;
  title: string;
  description: string;
  linedText: string;
  onActionPress: () => void;
};

const NoticeTextSmall = ({
  className,
  title,
  description,
  linedText,
  onActionPress,
}: NoticeTextSmallProps) => {
  return (
    <Cont className={className}>
      <Title color={pointColor}>{title}</Title>
      <Description color={darkGray}>{description}</Description>
      <LinedText color={mainEventColor} onClick={onActionPress}>
        {linedText}
      </LinedText>
    </Cont>
  );
};

export default NoticeTextSmall;

import React from "react";
import styled from "styled-components";
import DemandingVoteBrief from "../demanding-vote-brief";
import { black, cg100, mainColor } from "../../styles";
import VoteButton from "../vote-button";
import { useTranslation } from "react-i18next";

const Cont = styled.div``;

const GraySpace = styled.div`
  height: 8px;
  background-color: #ededed; // I'm feeling bad.
`;

const BottomCont = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BottomTitle = styled.span`
  display: block;
  color: ${black};
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
`;

const DateLeft = styled.span<{ color: string }>`
  position: absolute;
  top: 18px;
  left: 18px;
  color: ${({ color }) => color};
  font-size: 18px;
  line-height: 23px;
`;

const Card = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

const CardImage = styled.img`
  height: 166px;
  object-fit: cover;
`;

const CardTitle = styled.span`
  display: block;
  height: 86px;
  line-height: 86px;
  border: 1px solid ${cg100};
  text-align: center;
  font-weight: 700;
`;

const CardAction = styled.span<{ color: string; backgroundColor: string }>`
  display: block;
  height: 54px;
  line-height: 54px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: 14px;
  cursor: pointer;
  border: 1px solid ${cg100};
  border-top-width: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
`;

const ShareButton = styled(VoteButton)`
  align-self: center;
  margin-top: 24px;
`;

const RankingButton = styled(VoteButton)`
  align-self: center;
  margin-top: 24px;
`;

interface DemandingVoteResultProps {
  className?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  roundTitle?: string | undefined;
  cardTitle?: string | undefined;
  dateLeft?: string | number | undefined;
  cardImageSrc?: string | undefined;
  cardActionTitle?: string | undefined;
  onCardDetailsPress?: (() => void) | undefined;
  onSharePress?: (() => void) | undefined;
  onRankingPress?: (() => void) | undefined;

  color: string;
  backgroundColor: string;
}

const DemandingVoteResult: React.FC<DemandingVoteResultProps> = (props) => {
  const { t } = useTranslation("componentDemandingVoteStatistics");
  return (
    <Cont className={props.className}>
      <DemandingVoteBrief title={props.title} description={props.description} />
      <GraySpace />

      <BottomCont>
        <BottomTitle>{props.roundTitle}</BottomTitle>
        <DateLeft color={props.color}>D-{props.dateLeft}</DateLeft>
        <Card>
          <CardImage src={props.cardImageSrc} />
          <CardTitle>{props.cardTitle}</CardTitle>
          <CardAction
            onClick={props.onCardDetailsPress}
            color={mainColor}
            backgroundColor={"#ffffff"}
          >
            {props.cardActionTitle}
          </CardAction>
        </Card>

        <ShareButton
          color={"#ffffff"}
          backgroundColor={mainColor}
          title={t("share")}
          onPress={props.onSharePress}
        />
        <RankingButton
          color={mainColor}
          backgroundColor={"#EFFBFF"}
          title={t("ranking")}
          onPress={props.onRankingPress}
        />
      </BottomCont>
    </Cont>
  );
};

export default DemandingVoteResult;
export type { DemandingVoteResultProps };

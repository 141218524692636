import React from 'react';
import styled from 'styled-components';
import { cg60, primary } from "../../styles";

const Cont = styled.div`
  margin-top: 56px;
  border-top: 1px solid ${cg60};
  
  height: 50px;
  display: flex;
  align-items: stretch;
`;

const BarInMiddle = styled.div`
  width: 1px;
  background-color: ${cg60};
`;

const ButtonPrimary = styled.div`
  cursor: pointer;
  background-color: white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonPrimaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #95c5ec;
`;

const ButtonSecondary = styled.div`
  cursor: pointer;  
  background-color: #95c5ec;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonSecondaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

interface Props {
  onSubmit: () => void;
  onCancel: () => void;
}

const Buttons: React.FC<Props> = (props) => {
  return (
    <Cont>
      <ButtonPrimary onClick={props.onCancel}>
        <ButtonPrimaryText>
          취소
        </ButtonPrimaryText>
      </ButtonPrimary>

      <BarInMiddle />

      <ButtonSecondary onClick={props.onSubmit}>
        <ButtonSecondaryText>
          확인
        </ButtonSecondaryText>
      </ButtonSecondary>
    </Cont>
  );
};

export default Buttons;

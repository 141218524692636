import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../components/navigation-bar";
import NoticeText from "../../components/notice-text";
import styled from "styled-components";
import { maxTabletSizeInPx } from "../../styles/size";
import Button from "../../components/button";
import { useSearchParams } from "react-router-dom";
import useVerifyAccount, {
  VerifyAccountServerError,
} from "../../services/verify-account";
import useHandleAppError from "../../services/handle-app-error";
import { strict as assert } from "assert";
import useToMainPage from "../../services/to-main-page";
import i18next from "i18next";

export const Gap72 = styled.div`
  padding-top: 72px;
`;

export const Gap40 = styled.div`
  padding-top: 40px;
`;

export const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;

export const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

type Status = "IN_PROGRESS" | "SUCCEED" | "FAILED";

const VerifyAccountPage = () => {
  const { t } = useTranslation("pageVerifyAccount");
  const [searchParams] = useSearchParams();
  const verifyAccountService = useVerifyAccount();
  const [status, setStatus] = useState<Status>("SUCCEED");
  const handleAppError = useHandleAppError();
  const toMainPage = useToMainPage();

  const verifyAccount = async () => {
    const emailVerificationToken = searchParams.get("token")!;

    try {
      await verifyAccountService({
        verifyAccountToken: emailVerificationToken,
      });
      setStatus("SUCCEED");
    } catch (error) {
      setStatus("FAILED");

      if (error instanceof VerifyAccountServerError) {
        return;
      }

      await handleAppError(error);
    }
  };

  //2022-12-07 이메일 인증 헤제
  //useEffect(() => { verifyAccount() }, []);

  let content;

  if (status === "IN_PROGRESS") {
    content = (
      <>
        <TextCont>
          <NoticeText
            notice={t("pageVerifyAccount:verifyingInProgress")}
            desciption={""}
          />
        </TextCont>
      </>
    );
  }

  if (status === "FAILED") {
    content = (
      <>
        <TextCont>
          <NoticeText
            notice={t("pageVerifyAccount:verifyingFailed")}
            desciption={""}
          />
        </TextCont>
      </>
    );
  }

  if (status === "SUCCEED") {
    content = (
      <>
        {/* */}
        <TextCont>
          <NoticeText
            notice={
              i18next.language === "kr"
                ? `${searchParams.get("name")}님, ${t(
                    "pageVerifyAccount:notice"
                  )}`
                : `${t("pageVerifyAccount:notice")} ${searchParams.get("name")}`
            }
            desciption={t("pageVerifyAccount:description")}
          />
        </TextCont>
        <Gap40 />
        <BoxCont>
          <Button onClick={toMainPage} value={t("pageVerifyAccount:start")} />
        </BoxCont>
      </>
    );
  }

  assert(content != null);

  return (
    <>
      <NavigationBar
        enableGoBack={false}
        text={t("pageVerifyAccount:navigation")}
      />
      <RootCont>
        <Gap72 />
        {content}
      </RootCont>
    </>
  );
};

export default VerifyAccountPage;

import {useMemo, useState} from "react";
import TicketLog from "../../data-types/ticket-log";
import {strict as assert} from 'assert';
import useHandleAppError from "../../services/handle-app-error";
import useReadTicketLogs from "../../services/read-ticket-logs";

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  logs: TicketLog[];
  isLoadingMore: boolean;
  loadMore: () => void;
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  logs: TicketLog[];
  isLoadingMore: boolean;
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({status: 'LOADING'});

  const readUserTicketLogs = useReadTicketLogs();
  const handleAppError = useHandleAppError();

  const limit = 10;

  const tryInit = async () => {
    const logs = await readUserTicketLogs({limit});

    setState({
      status: 'LOADED',
      logs,
      isLoadingMore: false,
    });
  }

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({status: 'FAILED'});
      await handleAppError(error);
    }
  };

  // todo is this work? YES!!!
  // Please change this into effect.
  useMemo(() => { init(); }, []);

  if (state.status === 'LOADING') {
    return {status: 'LOADING'};
  }

  if (state.status === 'FAILED') {
    return {status: 'FAILED'};
  }

  const tryLoadMore = async () => {
    try {
      setState(oldState => ({ ...oldState, isLoadingMore: true}));

      const getFrom = () => {
        if (state.logs.length === 0) {
          return undefined;
        }

        return state.logs[state.logs.length - 1].id;
      };

      const newLogs = await readUserTicketLogs({limit, from: getFrom()});

      setState(oldState => {
        if (oldState.status !== 'LOADED') {
          return oldState;
        }

        return {
          ...oldState,
          logs: [...state.logs, ...newLogs],
        };
      });
    } finally {
      setState(oldState => ({ ...oldState, isLoadingMore: false}));
    }
  };

  const loadMore = async () => {
    try {
      await tryLoadMore();
    } catch (error) {
      await handleAppError(error);
    }
  }

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      logs: state.logs,
      isLoadingMore: state.isLoadingMore,
      loadMore,
    };
  }

  assert.fail();
};

export default useLogic;

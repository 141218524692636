import React from 'react';
import styled from 'styled-components';
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import { black, cg300, cg60, cg600, mainColor } from "../../styles";
import useLogic from "./use-logic";
import useToCurrentLocale from "../../services/to-current-locale";
import { useTranslation } from "react-i18next";

interface DumbProps {
  announcementKind: string;
  announcementTitle: string;
  announcementIssuedDate: Date;
  imageUrl: string | undefined;
}

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 18px 16px 18px 16px;
`;

const Title = styled.span`
  display: block;
  font-size: 18px;
  line-height: 23px;
  color: ${black};
  text-align: center;
`;

const AnnouncementComp = styled.div`
  margin-top: 18px;
  border-top: 1px solid ${cg60};
  border-bottom: 1px solid ${cg60};
  display: flex;
  color: ${black};
  height: 50px;
  align-items: center;
`;

const AnnouncementKind = styled.span`
  width: 48px;
  color: ${mainColor};
  font-size: 12px;
  font-weight: bold;
`;

const AnnouncementTitle = styled.span`
  margin-left: 8px;
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${cg600};
  font-size: 14px;
`;

const AnnouncementIssuedDate = styled.span`
  width: 80px;
  margin-left: 8px;
  color: ${cg300};
  text-align: right;
`;

const AnnouncementImage = styled.img``;

const Dumb: React.FC<DumbProps> = (props) => {
  const { t } = useTranslation('pageAnnouncement');

  return (
    <Cont>
      <Title>
        {t('announcement')}
      </Title>
      <AnnouncementComp>
        <AnnouncementKind>
          {props.announcementKind}
        </AnnouncementKind>
        <AnnouncementTitle>
          {props.announcementTitle}
        </AnnouncementTitle>
        <AnnouncementIssuedDate>
          {props.announcementIssuedDate.toLocaleDateString()}
        </AnnouncementIssuedDate>
      </AnnouncementComp>
      <AnnouncementImage src={props.imageUrl} />
    </Cont>
  );
};

const AnnouncementPage: React.FC = () => {
  const logic = useLogic();

  const toCurrentLocale = useToCurrentLocale();

  if (logic.status !== 'LOADED') {
    return null; // todo
  }

  return (
    <NavigationWithGoBackBottomTabLayout title={'MY'}>
      <Dumb
        announcementKind={logic.announcement.kind}
        announcementTitle={toCurrentLocale(logic.announcement.title)}
        announcementIssuedDate={logic.announcement.issuedDate}
        imageUrl={logic.announcement.imageUrl}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default AnnouncementPage;

import axios, { AxiosResponse } from "axios";
import { createServerError, ServerError } from "../../utils/server-error";
import { apiAddr } from "../../config";
import { AuthData } from "../../utils/auth";
import useLoginWithAuthData from "../login-with-auth-data";
import checkEmail from "../../errors/check-email";
import checkPassword from "../../errors/check-password";
import * as checkServerError from "./check-server-error";
import CreateAccountEmailNotValid from "../../errors/create-account-email-not-valid";
import CreateAccountPasswordShorterThan8 from "../../errors/create-account-password-shorter-than-8";
import CreateAccountPasswordDoesNotContainAtLeastOneLetterOrDigit from "../../errors/create-account-password-does-not-contain-at-least-one-letter-or-digit";
import CreateAccountBirthNotValid from "../../errors/create-account-birth-not-valid";
import CreateAccountEmailAlreadyTaken from "../../errors/create-account-email-already-taken";
import passResponseFromErrorOrFalse from "../../utils/pass-response-from-error-or-false";

interface Params {
  email: string;
  accept: string;
  password: string;
  name: string;
  birth: string;
  country: string;
  region: string;
}

const CreateAccountServerError = createServerError();
type CreateAccountServerError = ServerError;

const useCreateAccount = () => {
  const loginWithAuthData = useLoginWithAuthData();

  return async (params: Params) => {
    checkEmail(params.email);
    checkPassword(params.password);

    let axiosResult;
    try {
      axiosResult = await axios({
        method: "post",
        url: `${apiAddr}/v1/auth/register`,
        data: {
          email: params.email,
          accept: params.accept,
          password: params.password,
          name: params.name,
          birth: params.birth,
          country: params.country,
          region: params.region,
        },
      });
    } catch (error) {
      if (
        passResponseFromErrorOrFalse(error, checkServerError.isEmailNotValid)
      ) {
        throw new CreateAccountEmailNotValid();
      }

      if (
        passResponseFromErrorOrFalse(
          error,
          checkServerError.isPasswordShorterThan8
        )
      ) {
        throw new CreateAccountPasswordShorterThan8();
      }

      if (
        passResponseFromErrorOrFalse(
          error,
          checkServerError.doesPasswordNotContainAtLeastOneLetterOrDigit
        )
      ) {
        throw new CreateAccountPasswordDoesNotContainAtLeastOneLetterOrDigit();
      }

      if (
        passResponseFromErrorOrFalse(error, checkServerError.isBirthNotValid)
      ) {
        throw new CreateAccountBirthNotValid();
      }

      if (
        passResponseFromErrorOrFalse(
          error,
          checkServerError.isEmailAlreadyTaken
        )
      ) {
        throw new CreateAccountEmailAlreadyTaken();
      }

      throw new CreateAccountServerError(error);
    }

    await loginWithAuthData(axiosResult.data as AuthData);
  };
};

export default useCreateAccount;
export { CreateAccountServerError };

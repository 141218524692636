import {useNavigate} from "react-router-dom";

const useToMainPage = () => {
  const nav = useNavigate();

  return () => {
    nav('/');
  };
};

export default useToMainPage;

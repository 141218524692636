import React from 'react';
import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {fallbackLanguage, supportedLanguages} from "./config";

const i18nextOptions: Record<string, any> = {
  supportedLngs: supportedLanguages,
  fallbackLng: fallbackLanguage,
  interpolation: {escapeValue: false},
  backend: {
    loadPath: '/translations/{{lng}}/{{ns}}.json',
  },
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nextOptions);

const wrapWithSuspenseWhichI18nextRequires = (Component: React.FC): React.FC => {
  const NewComponent = () => {
    return <React.Suspense fallback={null}>
      <Component/>
    </React.Suspense>;
  };

  NewComponent.displayName = `I18nSuspenseWrappedComponent`;

  return NewComponent;
};

export {wrapWithSuspenseWhichI18nextRequires};

import styled from "styled-components";

export const Inner = styled.div`
    background:#EDEDED;
    height: 100%;
`;
export const ProfileWrap = styled.div` 
    padding: 16px;
    position: relative;
    padding-left: 110px;
    background: #fff;
    margin-bottom: 16px;
`;
export const ProfileTitle = styled.strong`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #292E33;
`;
export const ProfileAddr = styled.p`
    margin: 5px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;
export const ProfileType = styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #3D454D;
`;
export const ProfileDate = styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #6C7680;
`;
export const ProfileImage = styled.div<{image?:string}>`
    position: absolute;
    width: 80px;
    height: 80px;
    left: 16px;
    top: 16px;
    background-color:#E4EAF0;
    background-image:url(${(props)=>props.image});
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;
export const InputSection = styled.div`
    padding: 16px;
    background: #fff;
`;
export const SectionTitle = styled.h3`
    color: #12161A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Spoqa Han Sans Neo;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 0;
    margin-bottom: 24px;
`;
export const FormBox = styled.div`
    margin-bottom: 24px;
`;
export const FormLabel = styled.label<{type?:string}>`
    display: block;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Spoqa Han Sans Neo;
    font-size: ${(props)=>props.type==='normal'?'14px':'16px'};;
    font-style: normal;
    font-weight: ${(props)=>props.type==='normal'?'400':'700'};
    line-height: 18px;
    letter-spacing: 0.15px;
    color: #12161A;
    margin-bottom: 8px;
`;
export const FormInput = styled.input`
    border-radius: 6px;
    background: var(--gray-00, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(108, 118, 128, 0.16), 0px 2px 4px 0px rgba(108, 118, 128, 0.16);
    width: calc(100% - 32px);
    border: 0;
    padding: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
export const SectionSubTile = styled.h6`
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
    margin:0
`;

export const RadioWrap = styled.ul`display: flex;padding:0`;
export const RadioItem = styled.li<{active:boolean}>`
    display: inline-block;
    width: 100%;
    border-radius: 23px;
    border: 1px solid ${(props)=>props.active?'#FF176B':'#98A5B3'};
    padding: 8px 0;
    color: var(--unable, #D9D9D9);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-left:8px;
    color: ${(props)=>props.active?'#fff':'#98A5B3'};
    background:${(props)=>props.active?'#FF176B':'#D9D9D9'};
    :first-child{margin-left:0px}
`;
export const AgreeLabel = styled.label`
    display: block;
    color: #98A5B3;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
    padding-right:10px;
`;
export const AgreeCheck = styled.input``;

export const ButtonGroup = styled.div``;
export const FormButton = styled.button<{active:boolean}>`
    display: block;
    width: 100%;
    padding: 16px;
    border: 0;
    border-radius: 6px 6px 0px 0px;
    align-items: flex-start;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: ${(props)=>props.active?'#fff':'#98A5B3'};
    margin: 8px 0;
    background:${(props)=>props.active?'#FF176B':'#D9D9D9'};
`;

export const PictureBox = styled.div`
    padding-top:12px;
`;
export const PictureBoxTips = styled.span`
    color: var(--coolgray-cg-300, #98A5B3);
    text-align: right;
    font-family: Spoqa Han Sans Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: inline-block;
    padding-left: 10px;
`;
export const PictureCount = styled.span`
    display: block;
    text-align: right;
    color: var(--gray-300, #98A5B3);
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
export const PictureInner = styled.div`
    padding: 10px; 
    background: #D9D9D9;
    text-align:left;
    margin-top:8px;
    margin-bottom:24px;
`;
export const PictureItem = styled.div<{src?:string}>`
    position: relative;
    width: calc((100% - 25px) / 3);
    border: 1px solid #fff;
    display:inline-block;
    margin-right:9px;
    margin-bottom:9px;
    background-color:#D9D9D9;
    background-image : url(${(props)=>props.src});
    background-size: cover;
    background-position: center;
    :nth-child(3n){margin-right:0px;}
    :after{display:block;content:'';padding-top:100%;}
`;
export const PictureItemDelete = styled.a<{hasImage : boolean}>`
    display: block;
    content: '';
    width: 19px;
    height: 19px;
    position: absolute;
    right: 5px;
    top: 5px;
    border: 1px solid #fff;
    border-radius: 19px;
`
export const PictureButton =styled.button`
    display: block;
    width: 100%;
    padding: 38px 0;
    border: 0;
    background: #FF176B;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    border-radius: 6px;
    `;


import {useEffect, useState} from "react";
import {strict as assert} from 'assert';
import IdolVote from "../../../../data-types/idol-vote";
import useReadRandomActiveIdolVote from "../../../../services/read-random-active-idol-vote";
import useHandleAppError from "../../../../services/handle-app-error";
import useReadIdolVoteCandidates from "../../../../services/read-idol-vote-candidates";
import IdolVoteCandidate from "../../../../data-types/idol-vote-candidate";
import Idol from "../../../../data-types/idol";
import Region from "../../../../data-types/region";
import Country from "../../../../data-types/country";
import useReadIdol from "../../../../services/read-idol";
import useReadRegion from "../../../../services/read-region";
import useReadCountry from "../../../../services/read-country";
import useToIdolVotesPage from "../../../../services/to-idol-votes-page";

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  vote: IdolVote;
  candidates: IdolVoteCandidate[];
  idols: Idol[];
  regions: Record<string, Region>;
  countries: Record<string, Country>;
  toIdolVotes: () => void;
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  vote: IdolVote;
  candidates: IdolVoteCandidate[];
  idols: Idol[];
  regions: Record<string, Region>;
  countries: Record<string, Country>;
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({status: 'LOADING'});

  const handleAppError = useHandleAppError();
  const readRandomIdolVote = useReadRandomActiveIdolVote();
  const readCandidates = useReadIdolVoteCandidates();
  const readIdol = useReadIdol();
  const readRegion = useReadRegion();
  const readCountry = useReadCountry();
  const toIdolVotes = useToIdolVotesPage();

  const tryInit = async () => {
    const vote = await readRandomIdolVote();
    const candidates = await readCandidates(vote.id);
    const idols = await Promise.all(candidates.map(async (candidate) => {
      const result = await readIdol(candidate.idol);
      return result;
    }));
    const regions: Record<string, Region> = {};
    const countries: Record<string, Country> = {};
    await Promise.all(idols.map(async (idol) => {
      if (idol.region == null) { return; }

      if (idol.region in regions) { return; }

      const region = await readRegion(idol.region);
      regions[idol.region] = region;

      if (region.country in countries) { return; }

      countries[region.country] = await readCountry(region.country);
    }));


    setState({
      status: 'LOADED',
      vote,
      candidates,
      idols,
      regions,
      countries,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({status: 'FAILED'});
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  if (state.status === 'LOADING') {
    return {status: 'LOADING'};
  }

  if (state.status === 'FAILED') {
    return {status: 'FAILED'};
  }

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      vote: state.vote,
      candidates: state.candidates,
      idols: state.idols,
      regions: state.regions,
      countries: state.countries,
      toIdolVotes,
    };
  }

  assert.fail();
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};

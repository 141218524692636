import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import FullNavigationGoBackBottomTabLayout from "../../components/full-navigation-bottom-tab-layout";
import { useTranslation } from "react-i18next";
import RoundButtonWrapper from "./lib/round-wrapper";
import axios from "axios";
import { apiAddr } from "../../config";
import { useNavigate } from "react-router-dom";
import { cg100, mainEventColor, pointColor } from "../../styles";

interface DumbProps {}

type ResultVoteCategoryType = {
  id: string;
  name: {
    en: string;
    zh: string;
    kr: string;
  };
};
type FanupVoteT = {
  category: string;
  title: string;
  subtitle: string;
  backgroundImg: string;
  currentRankingImg: string;
  imgFirst: string;
  firstUrlLink: string;
  imgSecond: string;
  secondUrlLink: string;
  imgThird: string;
  thirdUrlLink: string;
  benefits: string;
  beforeRankingImg: string;
};

const Cont = styled.div`
  width: 100%;
  position: relative;
`;

const BodyCont = styled.div`
  width: 100%;
  text-align: center;
`;

const Category = styled.span`
  display: flex;
  font-size: 18px;
  line-height: 23px;
  color: #6c7680;
  text-align: center;
  padding: 2px 2px 0px 2px;
  div {
    vertical-align: middle;
    flex-grow: 1;
    color: #6c7680;
  }
  div.selected_category {
    padding-left: 16px;
    padding-right: 16px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    font-family: "BalooBhaijaan";
    color: black;
  }
  div.selected_category_none {
    padding-left: 16px;
    padding-right: 16px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-family: "BalooBhaijaan";
    font-size: 18px;
  }
`;
// &:nth-child(1) {
//   flex-grow: 1;
//   color: #95c5ec;
// }
// &:nth-child(2) {
//   flex-grow: 1;
//   color: #6c7680;
// }
// &:nth-child(3) {
//   flex-grow: 1;
//   color: #6c7680;
// }
const BackGroundTitle = styled.div`
  position: relative;
  height: 180px;
  background-color: gray;
`;

const BannerImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Contents = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  right: 24px;
`;

const BannerTitle = styled.h3`
  margin: 0px;
  padding: 0px;
  line-height: 130%;
  font-size: 32px;
  font-weight: bold;
  color: white;
  cursor: default;
`;

const BannerTitleBottom = styled.h3`
  margin-top: 70px;
  padding: 0px;
  line-height: 130%;
  font-size: 18px;
  font-weight: normal;
  color: white;
  cursor: default;
`;

const MoreLink = styled.a`
  font-size: 14px;
  font-family: "Spoqa Han Sans Neo";
  color: ${pointColor};
  text-align: center;
`;

const FanupRankImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ImageCont = styled.div`
  text-align: center;
  display: table;
  width: 100%;
  height: auto;
  padding: 44px 0px 32px 0px;
`;

const ImageDiv = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const FanupTourImage = styled.img`
  max-width: 100%;
`;

const FanupBeforeImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const FanupBenefitImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const TopLine = styled.div<{ active: boolean }>`
  height: 2px;
  background-color: ${({ active }) => (active ? "black" : cg100)};
`;

const ItemCont = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
`;

const Dumb: React.FC<DumbProps> = (props) => {
  const { t, i18n } = useTranslation("pageFanupTour");
  const [data, setData] = useState<Partial<FanupVoteT>>();
  const [categoryList, setCategoryList] = useState<ResultVoteCategoryType[]>(
    []
  );
  const [categoryId, setCategoryId] = useState<string>("");
  const language = useMemo(
    () => (["en", "kr", "zh"].includes(i18n.language) ? i18n.language : "en"),
    [i18n.language]
  );
  const navigator = useNavigate();

  const categoryPageData = useCallback(
    async (cetrgoryId: string) => {
      const result = await axios.get(`${apiAddr}/v1/fan-tour/${cetrgoryId}`);

      const data: Partial<FanupVoteT> = {
        title: result.data.title[language] ?? "",
        subtitle: result.data?.subtitle[language] ?? "",
        backgroundImg: result.data.backgroundImg[language] ?? "",
        currentRankingImg: result.data.currentRankingImg[language] ?? "",
        imgFirst: result.data.imgFirst[language] ?? "",
        firstUrlLink: result.data.firstUrlLink ?? "",
        imgSecond: result.data.imgSecond[language] ?? "",
        secondUrlLink: result.data.secondUrlLink ?? "",
        imgThird: result.data.imgThird[language] ?? "",
        thirdUrlLink: result.data.thirdUrlLink ?? "",
        benefits: result.data.benefits[language] ?? "",
        beforeRankingImg: result.data.beforeRankingImg[language] ?? "",
      };
      setData(data);
    },
    [language]
  );

  useEffect(() => {
    async function getData() {
      try {
        const categoryResult = await axios.get(
          `${apiAddr}/v1/tour-categories?limit=10&skip=0`
        );
        if (categoryResult.status === 200 && categoryResult.data.length > 0) {
          setCategoryList(categoryResult.data);
          setCategoryId(categoryResult.data[0].id);
        } else {
          window.alert("Error");
          navigator("/");
        }

        await categoryPageData(categoryResult.data[0].id);
      } catch (error) {
        window.alert("Failed");
        //setData(initalData);
      }
    }

    getData();
  }, [navigator, categoryPageData]);

  return (
    <Cont>
      <Category>
        {categoryList.map((i) => (
          <ItemCont>
            <TopLine active={i.id === categoryId} />
            <div
              className={
                i.id === categoryId
                  ? "selected_category"
                  : "selected_category_none"
              }
              onClick={(e) => {
                setCategoryId((e.target as any).id);
                categoryPageData((e.target as any).id);
              }}
              id={i.id}
              key={i.id}
            >
              {i.name[language as "en" | "zh" | "kr"]}
            </div>
          </ItemCont>
        ))}
      </Category>
      <BackGroundTitle>
        <BannerImage src={data?.backgroundImg} />
        <Contents>
          <BannerTitle>{data?.title}</BannerTitle>
          <BannerTitleBottom>{data?.subtitle}</BannerTitleBottom>
        </Contents>
      </BackGroundTitle>
      <BodyCont>
        <ImageCont>
          <ImageDiv>
            <FanupRankImage src={data?.currentRankingImg} />
          </ImageDiv>
        </ImageCont>

        <ImageCont>
          <ImageDiv>
            <FanupTourImage src={data?.imgFirst} />
          </ImageDiv>
        </ImageCont>

        <RoundButtonWrapper
          onClick={() => {
            if (data?.firstUrlLink) {
              window.location.href = data?.firstUrlLink;
            }
          }}
        >
          {t("link-button")}
        </RoundButtonWrapper>

        <ImageCont>
          <ImageDiv>
            <FanupTourImage src={data?.imgSecond} />
          </ImageDiv>
        </ImageCont>

        <RoundButtonWrapper
          onClick={() => {
            if (data?.secondUrlLink) {
              window.location.href = data?.secondUrlLink;
            }
          }}
        >
          {t("link-button")}
        </RoundButtonWrapper>

        <ImageCont>
          <ImageDiv>
            <FanupTourImage src={data?.imgThird} />
          </ImageDiv>
        </ImageCont>

        <RoundButtonWrapper
          onClick={() => {
            if (data?.thirdUrlLink) {
              window.location.href = data?.thirdUrlLink;
            }
          }}
        >
          {t("link-button")}
        </RoundButtonWrapper>

        <ImageCont>
          <ImageDiv>
            <FanupBenefitImage src={data?.benefits} />
          </ImageDiv>
        </ImageCont>

        <MoreLink href={`/announcements`}>{t("link-more")} &gt;</MoreLink>

        <ImageCont>
          <ImageDiv>
            <FanupBeforeImage src={data?.beforeRankingImg} />
          </ImageDiv>
        </ImageCont>
      </BodyCont>
    </Cont>
  );
};

const FanuTourPage: React.FC = () => {
  //const logic = useLogic();

  //const toCurrentLocale = useToCurrentLocale();

  // if (logic.status !== "LOADED") {
  //   return null; // todo
  // }

  return (
    <FullNavigationGoBackBottomTabLayout title={"FANUP TOUR"}>
      <Dumb />
    </FullNavigationGoBackBottomTabLayout>
  );
};

export default FanuTourPage;

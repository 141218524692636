import DemandingVote from '../data-types/demanding-vote';
import useAuth from "./auth";
import {apiAddr} from "../config";
import serializeVote from '../from-server-serializers/vote';

const useReadRandomActiveDemandingVote = () => {
  const auth = useAuth();

  return async (): Promise<DemandingVote> => {
    const result = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/v1/random-active-demanding-vote`,
    });

    return serializeVote(result.data) as DemandingVote;
  };
};

export default useReadRandomActiveDemandingVote;

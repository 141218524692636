import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../components/navigation-bar';
import NoticeText from '../../components/notice-text';
import PrimaryButton from '../../components/primary-button';
import styled from 'styled-components';
import { maxTabletSizeInPx } from '../../styles/size';
import useToLogin from '../../services/to-login-page';
const Gap72 = styled.div`
  padding-top: 72px;
`;
const Gap40 = styled.div`
  padding-top: 40px;
`;

const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;
const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ResetPasswordDonePage = () => {
  const { t } = useTranslation('pageResetPasswordDone');
  const toNext = useToLogin();
  return (
    <>
      <NavigationBar
        enableGoBack={false}
        text={t('pageResetPasswordDone:navigation')}
      ></NavigationBar>
      <Gap72 />
      <RootCont>
        <TextCont>
          <NoticeText
            notice={t('pageResetPasswordDone:notice')}
            desciption={t('pageResetPasswordDone:description')}
          ></NoticeText>
        </TextCont>
        <Gap40 /*여기 gap 58 맞습니까? 40 */ />
        <BoxCont>
          <PrimaryButton
            value={t('pageResetPasswordDone:button')}
            onClick={toNext}
          ></PrimaryButton>
        </BoxCont>
      </RootCont>
    </>
  );
};

export default ResetPasswordDonePage;

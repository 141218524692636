import React from 'react';
import * as CompoundLayout from '../../data-types/compound-layout';
import styled from 'styled-components';
import Component from './component';
import useLogic from './use-logic';

// Okay. I know every color must be predefined in styles/index.ts file.
// But I think defining the color in here maybe better.
const compoundPageBackgroundColor = '#F2F2F2';

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${compoundPageBackgroundColor};
`;

const ComponentContWithGap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 8px;
`;
const ComponentContWithoutGap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface CompoundPageProps {
  className?: string | undefined;
  page: CompoundLayout.Page;
}


const CompoundPage: React.FC<CompoundPageProps> = (props) => {
  const logic = useLogic(props.page);

  if (logic.status !== 'LOADED') {
    return null;
  }

  return (
    <Cont>
      {props.page.components.map(
        (component: CompoundLayout.Component, componentIndex: number) => {

          const currentLogic = logic.componentLogics[componentIndex];
          if ('status' in currentLogic && currentLogic.status !== 'LOADED') {
            return null;
          }

          const ComponentCont = componentIndex !== 0 ? ComponentContWithGap : ComponentContWithoutGap;
          return <ComponentCont key={componentIndex}>
            <Component component={component} logic={logic.componentLogics[componentIndex]}/>
          </ComponentCont>;
        }
      )}
    </Cont>
  );
};

export default CompoundPage;
export type {CompoundPageProps};


import React from 'react';
import styled from 'styled-components';
import * as CompoundLayout from "../../../../data-types/compound-layout";
import useLogic, {LoadedLogic} from './use-logic';
import Header from './header';
import Announcements from './announcements';

const Cont = styled.div`
  padding: 16px;
  background-color: white;
`;

interface Props {
  component: CompoundLayout.AnnouncementsComponent;
  logic: LoadedLogic;
}

const AnnouncementsComponent: React.FC<Props> = (props) => {
  return (
    <Cont>
      <Header logic={props.logic}/>
      <Announcements logic={props.logic}/>
    </Cont>
  );
};

export default AnnouncementsComponent;
export {useLogic};

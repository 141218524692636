import EventVote from '../data-types/event-vote';
import serializePeriod from './period';
import serializeChoice from './event-vote-choice';

const serialize = (raw: Record<string, any>): EventVote => {
  return {
    id: raw.id,
    title: raw.title,
    categoryId: raw.category,
    choices: raw.choices.map(serializeChoice),
    description: raw.description,
    period: serializePeriod(raw.period),
    thumbnailUrl: raw.thumbnail,
    voteKind: 'EVENT',
  };
};

export default serialize;

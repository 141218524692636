import React from "react";
import styled, { css } from "styled-components";
import { LoadedLogic } from "../../use-logic";
import { cg300, cg40, mainColor, primary, shadow1 } from "../../../../styles";
import useToCurrentLocale from "../../../../services/to-current-locale";
import VoteCategory from "../../../../data-types/vote-category";

const Cont = styled.div`
  padding: 24px;
  display: flex;
`;

const InnerCont = styled.div`
  flex: 1;
  background-color: ${cg40};
  border-radius: 8px;
  display: flex;
`;

const Item = styled.div<{ active: boolean }>`
  flex: 1;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${({ active }) => {
    if (active) {
      return css`
        border-radius: 8px;
        background-color: ${mainColor};
        box-shadow: 4px 2px 10px rgba(80, 164, 255, 0.25);
      `;
    }
  }}
`;

const ItemText = styled.span<{ active: boolean }>`
  font-size: 18px;

  ${({ active }) => {
    if (active) {
      return css`
        color: white;
      `;
    } else {
      return css`
        color: ${cg300};
      `;
    }
  }}
`;

interface Props {
  logic: LoadedLogic;
}

const Tabs: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  return (
    <Cont>
      <InnerCont>
        {props.logic.voteCategories.map(
          (voteCategory: VoteCategory, voteCategoryIndex: number) => {
            const active = voteCategoryIndex === props.logic.currentTabIndex;

            return (
              <Item
                key={voteCategory.id}
                active={active}
                onClick={() => props.logic.onTabOfIndexClick(voteCategoryIndex)}
              >
                <ItemText active={active}>
                  {toCurrentLocale(voteCategory.name)}
                </ItemText>
              </Item>
            );
          }
        )}
      </InnerCont>
    </Cont>
  );
};

export default Tabs;

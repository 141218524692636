import React from 'react';
import styled from 'styled-components';
import useGoBack from '../../../services/go-back';
import Button from '../../button';
import { ReactComponent as Arrow } from './arrow-icon.svg';

type NavigationBarProps = {
  className?: string | undefined;
};

const BackButton = ({ className }: NavigationBarProps) => {
  const goBack = useGoBack();

  return (
    <div>
      <Arrow onClick={goBack} width={'8px'}></Arrow>
    </div>
  );
};

BackButton.defaultProps = {
  className: 'back-button',
};
export default BackButton;

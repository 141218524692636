import React from "react";
import styled from "styled-components";
import { LoadedLogic } from "../../use-logic";
import fanupLogoSrc from "./fanup-logo.svg";
import loginIconSrc from "./login-icon.svg";
import logoutIconSrc from "./logout-icon.svg";
import multilingualIconSrc from "./multilingual-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import BannerData from "../../../../data-types/banner";
import useToCurrentLocale from "../../../../services/to-current-locale";
import useToLink from "../../../../services/to-link";
import useAuth from "../../../../services/auth";

const Cont = styled.div``;

const SlideCont = styled.div`
  position: relative;
  height: 300px;
  background-color: pink;
`;

const FanupLogo = styled.img`
  position: absolute;
  top: 6px;
  left: 24px;
`;

const ButtonsCont = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
  display: flex;
`;

const HeaderButton = styled.img`
  cursor: pointer;
  margin-left: 16px;

  &:first-of-type {
    margin-left: 0px;
  }
`;

const BannerImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Dark = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
`;

const Index = styled.span`
  font-size: 12px;
  color: white;
  position: absolute;
  right: 16px;
  bottom: 16px;
  user-select: none;
`;

const Contents = styled.div`
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 48px;
`;

const BannerTitle = styled.h3`
  margin: 0px;
  padding: 0px;
  line-height: 130%;
  font-size: 32px;
  font-weight: bold;
  color: white;
  cursor: default;
`;

const ActionButton = styled.h4`
  margin: 0px;
  padding: 0px;

  margin-top: 12px;

  color: white;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
`;

interface Props {
  logic: LoadedLogic;
}

const BannerHeader: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();
  const toLink = useToLink();
  const auth = useAuth();

  return (
    <Cont>
      {/*<FanupLogo src={fanupLogoSrc} />*/}
      {/*<ButtonsCont>*/}
      {/*  <HeaderButton*/}
      {/*    src={multilingualIconSrc}*/}
      {/*    onClick={props.logic.onMultilingualPress}*/}
      {/*  />*/}
      {/*  <HeaderButton*/}
      {/*    src={auth.isLoggedIn ? logoutIconSrc : loginIconSrc}*/}
      {/*    onClick={*/}
      {/*      auth.isLoggedIn*/}
      {/*        ? props.logic.onLogoutPress*/}
      {/*        : props.logic.onLoginPress*/}
      {/*    }*/}
      {/*  />*/}
      {/*</ButtonsCont>*/}
      <Swiper>
        {props.logic.banners.map((banner: BannerData, bannerIndex: number) => {
          return (
            <SwiperSlide key={banner.id}>
              <SlideCont>
                <BannerImage src={banner.imageUrl} />
                <Dark />

                <Index>
                  {bannerIndex + 1}/{props.logic.banners.length}
                </Index>
                <Contents>
                  <BannerTitle>{toCurrentLocale(banner.title)}</BannerTitle>
                  <ActionButton onClick={() => toLink(banner.link)}>
                    {toCurrentLocale(banner.actionButtonText)}
                    {" >"}
                  </ActionButton>
                </Contents>
              </SlideCont>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Cont>
  );
};

export default BannerHeader;

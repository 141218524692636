interface Logic {
  status: 'LOADED';
}

const useLogic = (): Logic => {
  return {status: 'LOADED'};
};

export default useLogic;
export type {Logic};

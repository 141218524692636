import React from 'react';
import styled from 'styled-components';
import {cg100, cg500, cg700, primary} from "../../../styles";

interface Props {
  className?: string | undefined;
  label?: string | undefined;
  value?: string | undefined;
  onChange?: ((value: string) => void) | undefined;
  inputType?: string | undefined;
  disabled?: boolean | undefined;
  placeholder?: string | undefined;
  buttonLabel?: string | undefined;
  onButtonPress?: (() => void) | undefined;
}

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${cg500};
`;

const InputCont = styled.div<{ disabled: boolean }>`
  align-self: stretch;
  margin-top: 4px;
  display: flex;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid ${cg100};
  border-radius: 4px;
  background-color: ${({ disabled }) => disabled ? cg100 : 'white'};
  align-items: center;
`;

const Input = styled.input<{ disabled: boolean }>`
  box-sizing: border-box;

  display: block;
  height: 100%;

  padding-left: 12px;
  padding-right: 12px;

  flex: 1;
  border: 0px;
  background-color: transparent;

  font-size: 16px;
  line-height: 24px;
  color: ${({ disabled }) => disabled ? cg500 : cg700};
  
  overflow-x: auto;
`;

const Button = styled.span`
  box-sizing: border-box;

  height: 40px;
  
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 4px;

  border-radius: 4px;

  color: white;
  background-color: ${primary};

  font-size: 14px;
  font-weight: bold;
  line-height: 40px;

  white-space: nowrap;

  cursor: pointer;
`;

const TextEditWithButton: React.FC<Props> = (props) => {
  const disabled = props.disabled ?? false;

  const onChange = (e: any) => {
    props.onChange != null && props.onChange(e.target.value);
  };

  return (
    <Cont className={props.className}>
      <Label>
        {props.label}
      </Label>
      <InputCont disabled={disabled}>
        <Input
          type={props.inputType}
          value={props.value}
          onChange={onChange}
          disabled={disabled}
          placeholder={props.placeholder}
        />
        <Button onClick={props.onButtonPress}>
          {props.buttonLabel}
        </Button>
      </InputCont>
    </Cont>
  );
};

export default TextEditWithButton;

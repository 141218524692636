import useHandleAppError from "./handle-app-error";
import {useEffect} from "react";
import useAuth from "./auth";
import {apiAddr} from "../config";
import {useTranslation} from "react-i18next";

const useDailyRewards = () => {
  const handleAppError = useHandleAppError();
  const auth = useAuth();
  const {t} = useTranslation('serviceDailyRewards');

  const tryAskDailyRewards = async () => {
    if (!auth.isLoggedIn) {
      return;
    }

    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/v1/ask-daily-rewards`,
      data: {user: auth.userId},
    });

    if (axiosResult.status === 200) {
      alert(t('attendanceRewardHasArrivedXVoteTickets', {ticketCount: axiosResult.data.rewardingTicketCount}));
      return;
    }

    if (axiosResult.status === 208) {
      return;
    }
  };

  const askDailyRewards = async () => {
    try {
      await tryAskDailyRewards();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => { askDailyRewards(); }, []);
};

export default useDailyRewards;

import {strict as assert} from 'assert';
import DemandingVote from "../data-types/demanding-vote";
import useToEventVoteStatisticsPage from "./to-event-vote-statistics-page";
import useToTournamentVoteStatisticsPage from "./to-tournament-vote-statistics-page";

const useToVoteStatistics = () => {
  const toTournamentVoteStatistics = useToTournamentVoteStatisticsPage();
  const toEventVoteStatistics = useToEventVoteStatisticsPage();

  return (vote: DemandingVote) => {
    if (vote.voteKind === 'TOURNAMENT') {
      toTournamentVoteStatistics(vote.id);
      return;
    }

    if (vote.voteKind === 'EVENT') {
      toEventVoteStatistics(vote.id);
      return;
    }

    assert.fail();
  };
};

export default useToVoteStatistics;

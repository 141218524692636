import {AxiosResponse} from 'axios';

const statusFromResponse = (response: AxiosResponse) => {
  const status: number | undefined = response?.status ?? undefined;
  return status;
};

const errorMessageFromResponse = (response: AxiosResponse) => {
  const errorMessage: string | undefined = response?.data?.message ?? undefined;
  return errorMessage;
};

const startsWithOrFalse = (str: string | undefined | null, startsWith: string): boolean => {
  if (str == null) {
    return false;
  }

  return str.startsWith(startsWith);
};

const isResponseErrorWhichErrorMessageStartsWith = (errorMessageStartsWith: string) => (response: AxiosResponse) => {
  const status = statusFromResponse(response);
  const errorMessage = errorMessageFromResponse(response);

  return status === 400 && startsWithOrFalse(errorMessage, errorMessageStartsWith);
};

export default isResponseErrorWhichErrorMessageStartsWith;

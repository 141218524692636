import Link from "../data-types/link";
import useToVote from "./to-vote";
import useReadVoteById from "./read-vote-by-id";

const useToLink = () => {
  const toVote = useToVote();
  const readVoteById = useReadVoteById();

  return async (link: Link | null | undefined) => {
    if (link == null) {
      return;
    }

    if (link.kind === "URL") {
    } else {
    }
    switch (link.kind) {
      case "URL":
        window.open(link.url);
        break;

      case "VOTE":
        toVote(await readVoteById(link.vote));
        break;
    }
  };
};

export default useToLink;

import React from 'react';
import styled from 'styled-components';
import { blueGradation, white } from '../styles';

const StyledGradientButton = styled.button<{
  color: string;
  fontColor: string;
}>`
  /* button / A */

  height: 48px;

  color: ${(props) => props.fontColor};
  background: ${(props) => props.color};
  border: none;
  border-radius: 4px;
`;

export type GradientButtonProps = {
  className?: string | undefined;
  value: string;
  onClick: any;
};

const PrimaryButton = ({ value, className, onClick }: GradientButtonProps) => {
  return (
    <StyledGradientButton
      onClick={onClick}
      className={className}
      color={blueGradation}
      fontColor={white}
    >
      {value}
    </StyledGradientButton>
  );
};

PrimaryButton.defaultProps = {
  value: 'Next',
  className: 'gradient-button',
  onClick: () => {
    alert('Hi');
  },
};
export default PrimaryButton;

import Setting from "../data-types/setting";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAuth from "./auth";
import { apiAddr } from "../config";

interface Result {
  read: (pushToken: string) => Promise<Setting | undefined>;
  updateAllow: (pushToken: string, pushAllow: boolean) => void;
}

const useCurrentSetting = (): Result => {
  const [allow, setAllow] = useState<boolean | undefined>(undefined);
  const auth = useAuth();

  const readAllowFromServer = useCallback(
    async (pushToken: string): Promise<Setting | undefined> => {
      if (pushToken != null) {
        return undefined;
      }

      const result = await auth.callAxios({
        method: "get",
        url: `${apiAddr}/v1/push-info/${pushToken}`,
      });

      const { pushAllow } = result.data;

      setAllow(pushAllow);

      return pushAllow;
    },
    [auth]
  );

  const updateAllow = useCallback(
    async (pushToken: string, pushAllow: boolean) => {
      const result = await auth.callAxios({
        method: "post",
        url: `${apiAddr}/v1/push-info/update`,
        data: { pushToken, pushAllow },
      });

      const { pushAllow: returnAllow } = result.data;

      setAllow(returnAllow);
    },
    [setAllow]
  );

  const prepareAndRead = useCallback(
    async (pushToken: string): Promise<Setting | undefined> => {
      if (auth.isLoggedIn) {
        const allow = await readAllowFromServer(pushToken);
        return allow;
      }

      return undefined;
    },
    [allow, setAllow, auth]
  );

  return useMemo(
    () => ({
      read: prepareAndRead,
      updateAllow,
    }),
    [prepareAndRead, updateAllow]
  );
};

export default useCurrentSetting;

import React from 'react';
import * as CompoundLayout from '../../../data-types/compound-layout';
import CommercialComponent from './commercial-component';
import AnnouncementsComponent from './announcements-component';
import PlainTextComponent from './plain-text-component';
import VotesComponent from './votes-component';
import RandomDemandingVoteComponent from './random-demanding-vote-component';
import RandomIdolVoteComponent from './random-idol-vote-component';
import MagazineComponent from './magazine-component';

interface Props {
  component: CompoundLayout.Component;
  logic: any;
}

const Component: React.FC<Props> = (props) => {
  switch (props.component.kind) {
    case 'COMMERCIAL':
      return <CommercialComponent component={props.component} logic={props.logic} />;

    case 'ANNOUNCEMENTS':
      return <AnnouncementsComponent component={props.component} logic={props.logic} />;

    case 'PLAIN_TEXT':
      return <PlainTextComponent component={props.component} logic={props.logic} />;

    case 'VOTES':
      return <VotesComponent component={props.component} logic={props.logic} />;

    case 'RANDOM_DEMANDING_VOTE':
      return <RandomDemandingVoteComponent component={props.component} logic={props.logic} />

    case 'RANDOM_IDOL_VOTE':
      return <RandomIdolVoteComponent component={props.component} logic={props.logic} />

    case 'MAGAZINE':
      return <MagazineComponent component={props.component} logic={props.logic} />
  }
};

export default Component;

import { strict as assert } from 'assert';
import { useEffect, useState } from 'react';
import useToAnnouncementsPage from '../../../../services/to-announcements-page';
import useToAnnouncementPage from '../../../../services/to-announcement-page';
import useReadAnnouncements from '../../../../services/read-announcements';
import Announcement from '../../../../data-types/announcement';

interface LoadingLogic {
  status: 'LOADING';
  onMoreClick: () => void;
}

interface LoadedLogic {
  status: 'LOADED';
  announcements: Announcement[];
  onAnnouncementOfIndexClick: (index: number) => void;
  onMoreClick: () => void;
}

interface FailedLogic {
  status: 'FAILED';
  onMoreClick: () => void;
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  announcements: Announcement[];
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: 'LOADING' });

  const readAnnouncements = useReadAnnouncements();
  const toAnnouncements = useToAnnouncementsPage();
  const toAnnouncement = useToAnnouncementPage();

  const init = async () => {
    try {
      const announcements = await readAnnouncements({limit: 3});

      setState({
        status: 'LOADED',
        announcements,
      });
    } catch (error) {
      console.error(error);
      setState({ status: 'FAILED' });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const onMoreClick = () => {
    toAnnouncements();
  };

  if (state.status === 'LOADING') {
    return {
      status: 'LOADING',
      onMoreClick,
    };
  }

  if (state.status === 'FAILED') {
    return {
      status: 'FAILED',
      onMoreClick,
    };
  }

  const onAnnouncementOfIndexClick = (index: number) => {
    const announcement = state.announcements[index];
    toAnnouncement(announcement.id);
  };

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      onAnnouncementOfIndexClick,
      onMoreClick,
      announcements: state.announcements,
    };
  }

  assert.fail();
};

export default useLogic;
export type { Logic, LoadingLogic, LoadedLogic, FailedLogic };

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../pages/login";
import JoinInfo from "../pages/login/joinInfo";
import CreateAccount1Page from "../pages/create-account-1";
import CreateAccount2Page from "../pages/create-account-2";
import CreateAccount3Page from "../pages/create-account-3";
import CreateAccount4Page from "../pages/create-account-4";
import CreateAccount5Page from "../pages/create-account-5";
import VerifyAccountPage from "../pages/verify-account";
import ResetPasswordPage from "../pages/reset-password";
import ResetPasswordDonePage from "../pages/reset-password-done";
import RequestResetPasswordPage from "../pages/request-reset-password";
import RequestResetPasswordDonePage from "../pages/request-reset-password-done";
import MainPage from "../pages/main";
import IdolVotesPage from "../pages/idol-votes";
import DemandingVotesPage from "../pages/demanding-votes";
import VoteTournamentPage from "../pages/vote-tournament";
import TournamentVoteResultPage from "../pages/tournament-vote-result";
import TournamentVoteStatisticsPage from "../pages/tournament-vote-statistics";
import VoteEventPage from "../pages/vote-event";
import EventVoteResultPage from "../pages/event-vote-result";
import EventVoteStatisticsPage from "../pages/event-vote-statistics";
import MyAccountPage from "../pages/my-account";
import EditProfilePage from "../pages/edit-profile";
import AnnouncementsPage from "../pages/announcements";
import AnnouncementPage from "../pages/announcement";
import TicketLogsPage from "../pages/ticket-logs";
import SettingsPage from "../pages/settings";
import InquirePage from "../pages/inquire";
import FaqsPage from "../pages/faqs";
import NotFound from "../pages/not-found";
import wrapElementWithDailyRewards from "../utils/wrap-element-with-daily-rewards";
import SNSAuth from "../pages/login/sns/SNSAuth";
import SNSLoginComplete from "../pages/login/sns/SNSLoginComplete";

import QnaNoMember from "../pages/qna/index";
import QnaForm from "../pages/qna/qnaForm";
import QnaFormEnd from "../pages/qna/qnaFormEnd";
import QnaList from "../pages/qna/qnaList";
import QnaView from "../pages/qna/qnaView";

import FanupTour from "../pages/fanup-tour";

import FanUpMapPage from "../pages/fanup-maps";
import FanUpSearchPage from "../pages/fanup-maps/fanup-search";
import FanUpAddPage from "../pages/fanup-maps/fanup-add";
import FanUpDetailPage from "../pages/fanup-maps/fanup-attraction-detail";
import FanUpBucketPage from "../pages/fanup-bucket";
import FanUpDirectAddPage from "../pages/fanup-maps/fanup-direct-add";
import FanUpPickPage from "../pages/fanup-maps/fanup-picks";

interface RouteData {
  index?: boolean | undefined;
  path?: string | undefined;
  element: any;
  enableDailyRewards?: boolean | undefined;
}

const routeData: RouteData[] = [
  {
    path: "fanup-tour",
    element: <FanupTour />,
  },
  {
    path: "qna-view/:id",
    element: <QnaView />,
  },
  {
    path: "qna-list",
    element: <QnaList />,
  },
  {
    path: "qna-form-end",
    element: <QnaFormEnd />,
  },
  {
    path: "qna-form/:category",
    element: <QnaForm />,
  },
  {
    path: "qna",
    element: <QnaNoMember />,
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "join-info",
    element: <JoinInfo />,
  },
  {
    path: "sns-login-complete",
    element: <SNSLoginComplete />,
  },
  {
    path: "callback",
    element: <SNSAuth />,
  },
  {
    path: "create-account-1",
    element: <CreateAccount1Page />,
  },
  {
    path: "create-account-2",
    element: <CreateAccount2Page />,
  },
  {
    path: "create-account-3",
    element: <CreateAccount3Page />,
  },
  {
    path: "create-account-4",
    element: <CreateAccount4Page />,
  },
  {
    path: "create-account-5",
    element: <CreateAccount5Page />,
  },
  // {
  //   path: "create-account-6",
  //   element: <CreateAccount6Page />,
  // },
  {
    path: "verify-account",
    element: <VerifyAccountPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "reset-password-done",
    element: <ResetPasswordDonePage />,
  },
  {
    path: "request-reset-password",
    element: <RequestResetPasswordPage />,
  },
  {
    path: "request-reset-password-done",
    element: <RequestResetPasswordDonePage />,
  },

  {
    index: true,
    element: <MainPage />,
    enableDailyRewards: true,
  },
  {
    path: "idol-votes",
    element: <IdolVotesPage />,
    enableDailyRewards: true,
  },
  {
    path: "demanding-votes",
    element: <DemandingVotesPage />,
    enableDailyRewards: true,
  },
  {
    path: "vote-tournament/:id",
    element: <VoteTournamentPage />,
  },
  {
    path: "tournament-vote-result/:id",
    element: <TournamentVoteResultPage />,
  },
  {
    path: "tournament-vote-statistics/:id",
    element: <TournamentVoteStatisticsPage />,
  },
  {
    path: "vote-event/:id",
    element: <VoteEventPage />,
  },
  {
    path: "event-vote-result/:id",
    element: <EventVoteResultPage />,
  },
  {
    path: "event-vote-statistics/:id",
    element: <EventVoteStatisticsPage />,
  },

  {
    path: "my-account",
    element: <MyAccountPage />,
    enableDailyRewards: true,
  },
  {
    path: "edit-profile",
    element: <EditProfilePage />,
  },
  {
    path: "announcements",
    element: <AnnouncementsPage />,
  },
  {
    path: "announcement/:id",
    element: <AnnouncementPage />,
  },
  {
    path: "ticket-logs",
    element: <TicketLogsPage />,
  },
  {
    path: "settings",
    element: <SettingsPage />,
  },
  {
    path: "inquire",
    element: <InquirePage />,
  },
  {
    path: "faqs",
    element: <FaqsPage />,
  },
  {
    path: "maps/search",
    element: <FanUpSearchPage />,
  },
  {
    path: "maps/read/:id",
    element: <FanUpDetailPage />,
  },
  {
    path: "bucket/:id",
    element: <FanUpBucketPage />,
  },
  {
    path: "maps/add",
    element: <FanUpAddPage />,
  },
  {
    path: "maps/direct",
    element: <FanUpDirectAddPage />,
  },
  {
    path: "maps/picks",
    element: <FanUpPickPage />,
  },
  {
    path: "maps",
    element: <FanUpMapPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routeData.map((item, itemIndex) => {
          const emptyWrapper = (a: any) => a;
          const wrapper =
            item.enableDailyRewards ?? false
              ? wrapElementWithDailyRewards
              : emptyWrapper;

          return (
            <Route
              key={itemIndex}
              path={item.path}
              index={item.index}
              element={wrapper(item.element)}
            />
          );
        })}
        {/*<Route path='login' element={<LoginPage />} />*/}
        {/*<Route path='create-account-1' element={<CreateAccount1Page />} />*/}
        {/*<Route path='create-account-2' element={<CreateAccount2Page />} />*/}
        {/*<Route path='create-account-3' element={<CreateAccount3Page />} />*/}
        {/*<Route path='create-account-5' element={<CreateAccount4Page />} />*/}
        {/*<Route path='create-account-6' element={<CreateAccount5Page />} />*/}
        {/*<Route path='verify-account' element={<VerifyAccountPage />} />*/}
        {/*<Route path='reset-password' element={<ResetPasswordPage />} />*/}
        {/*<Route path='reset-password-done' element={<ResetPasswordDonePage />} />*/}
        {/*<Route path='request-reset-password' element={<RequestResetPasswordPage />} />*/}
        {/*<Route path='request-reset-password-done' element={<RequestResetPasswordDonePage />} />*/}

        {/*<Route index element={<MainPage />} />*/}
        {/*<Route path='idol-votes' element={<IdolVotesPage />} />*/}
        {/*<Route path='demanding-votes' element={<DemandingVotesPage />} />*/}
        {/*<Route path='vote-tournament/:id' element={<VoteTournamentPage />} />*/}
        {/*<Route path='tournament-vote-result/:id' element={<TournamentVoteResultPage />} />*/}
        {/*<Route path='tournament-vote-statistics/:id' element={<TournamentVoteStatisticsPage />} />*/}
        {/*<Route path='vote-event/:id' element={<VoteEventPage />} />*/}
        {/*<Route path='event-vote-result/:id' element={<EventVoteResultPage />} />*/}
        {/*<Route path='event-vote-statistics/:id' element={<EventVoteStatisticsPage />} />*/}

        {/*<Route path='my-account' element={<MyAccountPage />} />*/}
        {/*<Route path='edit-profile' element={<EditProfilePage />} />*/}
        {/*<Route path='announcements' element={<AnnouncementsPage />} />*/}
        {/*<Route path='announcement/:id' element={<AnnouncementPage />} />*/}
        {/*<Route path='ticket-logs' element={<TicketLogsPage />} />*/}
        {/*<Route path='settings' element={<SettingsPage />} />*/}
        {/*<Route path='inquire' element={<InquirePage />} />*/}
        {/*<Route path='faqs' element={<FaqsPage />} />*/}

        {/*<Route path='*' element={<NotFound />} />*/}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;

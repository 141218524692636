import useAuth from "./auth";
import {apiAddr} from "../config";

const useSendVerificationEmail = () => {
  const auth = useAuth();

  return async () => {
    await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/v1/auth/send-verification-email`,
    });
  };
};

export default useSendVerificationEmail;

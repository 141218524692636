import React from "react";
import styled from "styled-components";
import * as CompoundLayout from "../../../../data-types/compound-layout";
import { LoadedLogic } from "./use-logic";
import voteDateLeft from "../../../../utils/vote-date-left";
import {
  black,
  cg300,
  cg500,
  event,
  fadedEvent,
  fadedIdol,
  fadedPrimary,
  fadedWorldCup,
  idol,
  primary,
  worldCup,
} from "../../../../styles";
import useToCurrentLocale from "../../../../services/to-current-locale";
import addPlusIfPositive from "../../../../utils/add-plus-if-positive";
import { ReactComponent as VoteIcon } from "./vote.svg";
import { ReactComponent as ClockIcon } from "./clock.svg";

interface DumbVote {
  id: string;
  kindColor: string;
  kindBackgroundColor: string;
  kind: string;
  title: string;
  period: string;
  thumbnailSrc: string | undefined;
  rewardingTicketCount: number;
  dateLeft: number;
  onPress: () => void;
}

interface DumbProps {
  votes: DumbVote[];
  onMoreVotesPress: () => void;
}

const Cont = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Title = styled.span`
  font-family: "BalooBhaijaan";
  margin-left: 16px;
  margin-right: 16px;
  color: ${black};
  font-size: 18px;
  line-height: 25px;
`;

const Body = styled.div`
  margin-top: 14px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Card = styled.div`
  position: relative;
  cursor: pointer;

  margin-top: 16px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const WhiteBackground = styled.div`
  width: calc(100% - 28px);
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
`;

const CardContents = styled.div`
  display: flex;
`;

const CardMetadata = styled.div`
  flex: 1;
  padding: 12px 16px 19px 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const CardKind = styled.span<{
  color: string;
  backgroundColor: string;
  backgroundImage: string;
}>`
  font-family: "BalooBhaijaan";
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: ${({ backgroundImage }) => backgroundImage};
  line-height: 24px;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  align-self: flex-start;
`;

const EventCardKind = styled.span<{
  color: string;
  backgroundColor: string;
  backgroundImage: string;
}>`
  font-family: "BalooBhaijaan";
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: ${({ backgroundImage }) => backgroundImage};
  line-height: 24px;
  font-size: 16px;
  padding-left: 3px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  align-self: flex-start;
`;

const CardTitle = styled.span`
  color: ${black};
  font-size: 18px;
  font-weight: bold;
  margin-top: 12px;
`;

const CardPeriod = styled.span`
  margin-top: 9px;
  color: ${cg500};
  font-size: 14px;
`;

const CardThumbnailCont = styled.div`
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 8px;
  align-self: center;
  position: relative;
`;

const CardThumbnailDark = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
`;

const CardThumbnail = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VoteCont = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TheVoteIcon = styled(VoteIcon)``;

const VoteText = styled.span`
  margin-top: 3px;
  font-size: 14px;
  color: white;
`;

const DateLeftCont = styled.div`
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TheClockIcon = styled(ClockIcon)``;

const DateLeft = styled.span`
  font-size: 14px;
  color: white;
`;

const MoreVotesCont = styled.div`
  margin-top: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MoreVotes = styled.span`
  font-size: 14px;
  color: ${cg300};
`;

const Cover = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding-left: 2%;
  padding-right: 2%;
  background-color: white;
`;
const Text = styled.span`
  color: transparent;
  background: linear-gradient(110deg, #00eded 20%, #ff0099 80%);
  background-clip: text;
  -webkit-background-clip: text;
`;

const Dumb: React.FC<DumbProps> = (props) => {
  return (
    <Cont>
      <Title>TOTAL VOTES</Title>
      <Body>
        {props.votes.map((vote) => {
          return (
            <Card key={vote.id} onClick={vote.onPress}>
              <WhiteBackground />
              <CardContents>
                <CardMetadata>
                  {vote.kind === "EVENT WORLD CUP" ? (
                    <EventCardKind
                      color={vote.kindColor}
                      backgroundColor={
                        vote.kind === "EVENT WORLD CUP"
                          ? ""
                          : vote.kindBackgroundColor
                      }
                      backgroundImage={
                        vote.kind === "EVENT WORLD CUP"
                          ? "linear-gradient(110deg, #00dede 20%,#ff0099 80%)"
                          : ""
                      }
                    >
                      <Cover>
                        <Text>{vote.kind}</Text>
                      </Cover>
                    </EventCardKind>
                  ) : (
                    <CardKind
                      color={vote.kindColor}
                      backgroundColor={vote.kindBackgroundColor}
                      backgroundImage={""}
                    >
                      {vote.kind}
                    </CardKind>
                  )}

                  <CardTitle>{vote.title}</CardTitle>
                  <CardPeriod>{vote.period}</CardPeriod>
                </CardMetadata>
                <CardThumbnailCont>
                  <CardThumbnail src={vote.thumbnailSrc} />
                  <CardThumbnailDark />
                  <VoteCont>
                    <TheVoteIcon />
                    <VoteText>
                      {addPlusIfPositive(vote.rewardingTicketCount)}
                    </VoteText>
                  </VoteCont>
                  <DateLeftCont>
                    <TheClockIcon />
                    <DateLeft>D-{vote.dateLeft}</DateLeft>
                  </DateLeftCont>
                </CardThumbnailCont>
              </CardContents>
            </Card>
          );
        })}
      </Body>
      <MoreVotesCont onClick={props.onMoreVotesPress}>
        <MoreVotes>More Votes</MoreVotes>
      </MoreVotesCont>
    </Cont>
  );
};

interface Props {
  component: CompoundLayout.VotesComponent;
  logic: LoadedLogic;
}

const VotesComponent: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  return (
    <Dumb
      votes={props.logic.votes.map((vote) => {
        const getKindColor = () => {
          switch (vote.voteKind) {
            case "TOURNAMENT":
              return worldCup;
            case "EVENT":
              return event;
            case "IDOL":
              return idol;
          }
        };

        const getKindBackgroundColor = () => {
          switch (vote.voteKind) {
            case "TOURNAMENT":
              return fadedWorldCup;
            case "EVENT":
              return fadedEvent;
            case "IDOL":
              return fadedIdol;
          }
        };

        const getKindName = () => {
          switch (vote.voteKind) {
            case "TOURNAMENT":
              return "WORLD CUP";
            case "EVENT":
              return "EVENT WORLD CUP";
            case "IDOL":
              return "FAN VOTE";
          }
        };

        const getVoteTitle = () => {
          switch (vote.voteKind) {
            case "TOURNAMENT":
            case "EVENT":
              return vote.title;
            case "IDOL":
              return vote.question;
          }
        };

        const getPeriodString = () => {
          return `${vote.period.from.toLocaleDateString()} ~ ${vote.period.to.toLocaleDateString()}`;
        };

        const getRewardingTicketCount = () => {
          switch (vote.voteKind) {
            case "TOURNAMENT":
              return props.logic.rewardingTicketCount;
            case "EVENT":
              return props.logic.eventRewardingTicketCount;
            case "IDOL":
              return -1;
          }
        };

        const getThumbnail = () => {
          switch (vote.voteKind) {
            case "TOURNAMENT":
            case "EVENT":
              return vote.thumbnailUrl;
            case "IDOL":
              return vote.backgroundImageUrl;
          }
        };

        return {
          id: vote.id,
          kindColor: getKindColor(),
          kindBackgroundColor: getKindBackgroundColor(),
          kind: getKindName(),
          title: toCurrentLocale(getVoteTitle()),
          period: getPeriodString(),
          dateLeft: voteDateLeft(vote),
          rewardingTicketCount: getRewardingTicketCount(),
          thumbnailSrc: getThumbnail(),
          onPress: () => props.logic.toVote(vote),
        };
      })}
      onMoreVotesPress={props.logic.toDemandingVotes}
    />
  );
};

export default VotesComponent;
export { default as useLogic } from "./use-logic";

import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../components/navigation-bar';
import NoticeText from '../../components/notice-text';
import TextInput from '../../components/text-input';
import Button from '../../components/button';
import styled from 'styled-components';
import { maxTabletSizeInPx } from '../../styles/size';
import useLogic from './use-logic';
import PrimaryButton from '../../components/primary-button';

export const Gap72 = styled.div`
  padding-top: 72px;
`;
export const Gap28 = styled.div`
  padding-top: 28px;
`;
export const Gap24 = styled.div`
  padding-top: 24px;
`;
export const Gap16 = styled.div`
  padding-top: 16px;
`;

export const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;
export const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ResetPasswordPage = () => {
  const { t } = useTranslation('pageResetPassword');
  const logic = useLogic();
  return (
    <div>
      <NavigationBar text={t('pageResetPassword:navigation')}></NavigationBar>
      <Gap72 />
      <RootCont>
        <TextCont>
          <NoticeText
            notice={t('pageResetPassword:notice')}
            desciption={t('pageResetPassword:description')}
          ></NoticeText>
        </TextCont>
        <Gap28 />
        <BoxCont>
          <TextInput
            type="password"
            onChange={logic.setPassword}
            placeholder={t('pageResetPassword:input1')}
          ></TextInput>
          <Gap16 />
          <TextInput
            type="password"
            onChange={logic.setRepassword}
            placeholder={t('pageResetPassword:input2')}
          ></TextInput>
          <Gap24 />
          <Button
            onClick={logic.resetPassword}
            value={logic.isProcessing ? t('processing') : t('button')}
            disabled={!logic.isPasswordValid || logic.isProcessing}
          />
        </BoxCont>
      </RootCont>
    </div>
  );
};

export default ResetPasswordPage;

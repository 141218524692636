import React, {useState} from 'react';
import NavigationBar from "../navigation-bar";
import SelectLanguageModalApp from "../../modals/select-language-app";

interface NavigationBarAppProps {
  className?: string | undefined;
  title?: string | undefined;
}

const NavigationBarApp: React.FC<NavigationBarAppProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onOpenModal = () => {
    setIsModalVisible(true);
  };

  const onRequestModalClose = () => {
    setIsModalVisible(false);
  };

  return <>
    <NavigationBar text={props.title} enableGoBack={false} onLanguagePress={onOpenModal}/>
    <SelectLanguageModalApp isVisible={isModalVisible} onRequestClose={onRequestModalClose}/>
  </>;
};

export default NavigationBarApp;
export type {NavigationBarAppProps};

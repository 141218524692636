const createServerError = () => {
  class ServerError extends Error {
    error: any;

    constructor(error: any) {
      super();
      this.error = error;
    }
  }

  return ServerError;
}

type ServerError = ReturnType<typeof createServerError>;

export type {ServerError};
export {createServerError};

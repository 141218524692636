import Association from "../data-types/association";
import {createServerError, ServerError} from "../utils/server-error";
import axios from 'axios';
import {apiAddr} from "../config";
import serializeAssociation from '../from-server-serializers/association';

const ReadAssociationServerError = createServerError();
type ReadAssociationServerErrro = ServerError;

const useReadAssociation = () => {
  return async (id: string): Promise<Association> => {
    try {
      const axiosResult = await axios({
        method: 'get',
        url: `${apiAddr}/v1/associations/${id}`,
      });

      const association: Association = serializeAssociation(axiosResult.data);

      return association;
    } catch (error) {
      throw new ReadAssociationServerError(error);
    }
  };
};

export default useReadAssociation;
export {ReadAssociationServerError};

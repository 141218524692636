import React, {useState} from 'react';
import SelectLanguageModalApp from "../../modals/select-language-app";
import NavigationBarWithGoBack from "../navigation-bar-with-go-back";
import useGoBack from "../../services/go-back";

interface NavigationBarWithGoBackAppProps {
  className?: undefined | string;
  title: string;
  backHandler?:() => void;

}

const NavigationBarWithGoBackApp: React.FC<NavigationBarWithGoBackAppProps> = (props) => {
  const [isLanguageModalVisible, setIsLanguageModalVisible] = useState<boolean>(false);

  const goBack = useGoBack();

  const onLanguageClick = () => {
    setIsLanguageModalVisible(!isLanguageModalVisible);
  };

  const onRequestLanguageModalClose = () => {
    setIsLanguageModalVisible(false);
  };

  return <>
    <NavigationBarWithGoBack
      onGoBackClick={()=>{
        if(props.backHandler){
          props.backHandler();
        }else{
          goBack();
        }
      }}
      onLanguageClick={onLanguageClick}
      title={props.title}
      className={props.className}
    />
    <SelectLanguageModalApp
      isVisible={isLanguageModalVisible}
      onRequestClose={onRequestLanguageModalClose}
    />
  </>;
};

export default NavigationBarWithGoBackApp;
export type {NavigationBarWithGoBackAppProps};

import { useTranslation } from "react-i18next";
import MalformedBirthError from "../errors/malformed-birth";
import MalformedNameError from "../errors/malformed-name";
import useDelegateUnhandledError from "./delegate-unhandled-error";
import MalformedEmailError from "../errors/malformed-email";
import MalformedRegionError from "../errors/malformed-region";
import MalformedPasswordError from "../errors/malformed-password-error";
import RefreshFailedError from "../utils/auth/errors/refresh-failed";
import UnacceptedAuthDataError from "../utils/auth/errors/unaccepted-auth-data";
import useAuth from "./auth";
import UserEmailNotVerifiedError from "../errors/user-email-not-verified-error";
import useSendVerificationEmail from "./send-verification-email";
import useLoginWithAuthData from "./login-with-auth-data";
import NotLoggedInError from "../errors/not-logged-in";
import useToLoginPage from "./to-login-page";
import CreateAccountEmailNotValid from "../errors/create-account-email-not-valid";
import CreateAccountPasswordShorterThan8 from "../errors/create-account-password-shorter-than-8";
import CreateAccountPasswordDoesNotContainAtLeastOneLetterOrDigit from "../errors/create-account-password-does-not-contain-at-least-one-letter-or-digit";
import CreateAccountBirthNotValid from "../errors/create-account-birth-not-valid";
import CreateAccountEmailAlreadyTaken from "../errors/create-account-email-already-taken";
import IdolVoteLimitPerDayExceed from "../errors/idol-vote-limit-per-day-exceed";

const useHandleAppError = () => {
  const { t } = useTranslation("serviceHandleAppError");
  const delegateUnhandledError = useDelegateUnhandledError();
  const auth = useAuth();
  const sendVerificationEmail = useSendVerificationEmail();
  const loginWithAuthData = useLoginWithAuthData();
  const toLoginPage = useToLoginPage();

  return async (error: any) => {
    if (error instanceof MalformedEmailError) {
      alert(t("serviceHandleAppError:malformedEmail"));
      return;
    }

    if (error instanceof MalformedPasswordError) {
      alert(t("serviceHandleAppError:malformedPassword"));
      return;
    }

    if (error instanceof MalformedBirthError) {
      alert(t("serviceHandleAppError:malformedBirth"));
      return;
    }

    if (error instanceof MalformedNameError) {
      alert(t("serviceHandleAppError:malformedName"));
      return;
    }

    if (error instanceof MalformedRegionError) {
      alert(t("serviceHandleAppError:malformedRegion"));
      return;
    }

    if (error instanceof RefreshFailedError) {
      auth.logout();
      return;
    }

    if (error instanceof UnacceptedAuthDataError) {
      auth.logout();
      return;
    }

    if (error instanceof UserEmailNotVerifiedError) {
      alert(
        t("needEmailVerificationToUseTheServiceWeWillSendVerificationEmail")
      );
      await loginWithAuthData(error.authData);
      await sendVerificationEmail();
      return;
    }

    if (error instanceof NotLoggedInError) {
      alert(t("needToLogIn"));
      toLoginPage();
      return;
    }

    if (error instanceof CreateAccountEmailNotValid) {
      alert(t("malformedEmail"));
      return;
    }

    if (error instanceof CreateAccountPasswordShorterThan8) {
      alert(t("passwordIsShorterThan8"));
      return;
    }

    if (
      error instanceof
      CreateAccountPasswordDoesNotContainAtLeastOneLetterOrDigit
    ) {
      alert(t("passwordDoesNotContainAtLeastOneLetterOrDigit"));
      return;
    }

    if (error instanceof CreateAccountBirthNotValid) {
      alert(t("malformedBirth"));
      return;
    }

    if (error instanceof CreateAccountEmailAlreadyTaken) {
      alert(t("emailIsAlreadyTaken"));
      return;
    }

    if (error instanceof IdolVoteLimitPerDayExceed) {
      alert(t("youExceededDailyIdolVoteLimit"));
      return;
    }

    await delegateUnhandledError(error);
  };
};

export default useHandleAppError;

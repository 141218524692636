import i18n from "i18next";
import {
  AddressModel,
  GovApiResultBodyType,
  GovOpenApiModel,
} from "../../data-types/attraction";

const _services: { [key: string]: string } = {
  kr: "/KorService1",
  en: "/EngService1",
  zh: "/ChtService1",
};
const _openApiInfo = {
  domain: "https://apis.data.go.kr/B551011",
  key: "truMc5Qt80wd8SxZ4rymqhtE%2FNsS6YmmK4ADfQOoI5AsljpZxXRWOkbbd0YGuosjnr62qF%2Bq03NwXt81sVTwtg%3D%3D",
};

/**
 * 근처 검색
 * @param lat
 * @param lng
 */
export const nearByAttractionList = (
  lat: number,
  lng: number
): Promise<GovApiResultBodyType> => {
  const data: GovOpenApiModel = {
    radius: 1000,
    ServiceKey: _openApiInfo.key,
    arrange: "S",
    numOfRows: 50,
    pageNo: 1,
    MobileOS: "ETC",
    MobileApp: "FanUp",
    _type: "json",
    mapX: lng,
    mapY: lat,
  };
  const type = "/locationBasedList1";
  const keys = Object.keys(data) as Array<keyof typeof data>;
  const params = keys.map((key) => `${key}=${data[key]}`).join("&");
  return new Promise((res) => {
    fetch(
      `${_openApiInfo.domain}${_services[i18n.language]}${type}?${params}`,
      {
        method: "GET",
      }
    )
      .then((resp) => resp.json())
      .then((result) => {
        if (result.response.header.resultCode === "0000") {
          res(result.response.body as GovApiResultBodyType);
        } else {
          res({ totalCount: 0 } as GovApiResultBodyType);
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
        res({ totalCount: 0 } as GovApiResultBodyType);
      });
  });
};
/**
 * 키워드 검색
 * @param keyword
 */
export const keywordAttractionList = (
  keyword: string
): Promise<GovApiResultBodyType> => {
  const data: GovOpenApiModel = {
    ServiceKey: _openApiInfo.key,
    keyword: keyword,
    arrange: "S",
    numOfRows: 100,
    pageNo: 1,
    MobileOS: "ETC",
    MobileApp: "FanUp",
    _type: "json",
  };
  const type = "/searchKeyword1";
  const keys = Object.keys(data) as Array<keyof typeof data>;
  const params = keys.map((key) => `${key}=${data[key]}`).join("&");
  return new Promise((res) => {
    fetch(
      `${_openApiInfo.domain}${_services[i18n.language]}${type}?${params}`,
      {
        method: "GET",
      }
    )
      .then((resp) => resp.json())
      .then((result) => {
        if (result.response.header.resultCode === "0000") {
          res(result.response.body as GovApiResultBodyType);
        } else {
          res({ totalCount: 0 } as GovApiResultBodyType);
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
        res({ totalCount: 0 } as GovApiResultBodyType);
      });
  });
};

export const findAddressList = (address: string): Promise<AddressModel[]> => {
  return new Promise((res) => {
    fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURI(
        address
      )}`,
      {
        method: "GET",
      }
    )
      .then((resp) => resp.json())
      .then((result) => {
        res(result);
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
        res([]);
      });
  });
};

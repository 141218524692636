import { strict as assert } from "assert";

interface CreateAccountStep {
  overall: number;
  currentStartingWith1: number;
}

const useCurrentCreateAccountStep = () => {
  return (): CreateAccountStep => {
    const path: string = window.location.pathname;

    const createStep = (current: number): CreateAccountStep => ({
      overall: 5,
      currentStartingWith1: current,
    });

    if (path.startsWith("/create-account-1")) {
      return createStep(1);
    }
    if (path.startsWith("/create-account-2")) {
      return createStep(2);
    }
    if (path.startsWith("/create-account-3")) {
      return createStep(3);
    }
    if (path.startsWith("/create-account-4")) {
      return createStep(4);
    }
    if (path.startsWith("/create-account-5")) {
      return createStep(5);
    }

    assert.fail();
  };
};

export default useCurrentCreateAccountStep;

import React from 'react';
import { ReactComponent as BtnImg } from './language.svg';

type Props = {
  className?: string;
  onPress?: (() => void) | undefined;
};

function LanguageButton({ className, onPress }: Props) {
  return (
    <div className={className} onClick={onPress} style={{cursor: 'pointer'}}>
      <BtnImg />
    </div>
  );
}

export default LanguageButton;

type Fn = () => void;

const noProblem = (...fns: Fn[]): boolean => {
  for (let i = 0; i < fns.length; ++i) {
    try {
      fns[i]();
    } catch {
      return false;
    }
  }

  return true;
};

const throwIfFalsy = (a: any) => {
  if (!a) {
    throw 0;
  }
};

export default noProblem;
export {throwIfFalsy};

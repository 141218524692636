import styled from "styled-components";

export const CalendarWrap = styled.div`
    background-color:#fff;
    padding:20px;
    border-radius: 12px;
    border:1px solid #EBEBEB;
    box-shadow: 0px 4px 32px 0px rgba(170, 170, 170, 0.03);
`;
export const Inner = styled.div`
    position:relative;
`;
export const Months = styled.h3`
    margin:0;
    color: #292E33;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
`;
export const ButtonGroups = styled.div`
    position:absolute;
    right:0;
    top:0;
`;
export const CalendarButton = styled.a`
    display:inline-block;
    padding:0 10px;
    margin:0 5px;
`;
export const Body = styled.div`
    margin-top:36px;
`;
export const CalendarRow = styled.div`
    display:flex;
    margin:15px 0;
`;
export const Weeks = styled.span`
    display:block;
    width:100%;
    color:#333;
    text-align:center;
    
`;
export const Days = styled.span`
    display:block;
    width:100%;
    color:#666666;
    text-align:center;
    a:hover{
        background-color:#FF176B;
        color:#fff;
    }
`;

export const TextLabel = styled.a<{active:boolean,display:boolean}>`
    display:inline-block;
    opacity:${props=>props.display?'1':'0'};
    width:33px;
    height:33px;
    line-height:33px;
    border-radius:33px;
    color:${props=>props.active?'#fff':''};
    background-color:${props=>props.active?'#FF176B':''};
`;

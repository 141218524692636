import axios, { Axios, AxiosRequestConfig } from "axios";
import { strict as assert } from "assert";
import AuthEventHandler from "./auth-event-handler";
import AuthEventResolver from "./auth-event-resolver";
import AuthData from "./auth-data";
import { apiAddr } from "../../config";
import { v4 as uuid } from "uuid";
import NotLoggedInError from "../../errors/not-logged-in";
import cookies from "js-cookie";
import RefreshFailedError from "./errors/refresh-failed";
import UnacceptedAuthDataError from "./errors/unaccepted-auth-data";

const normalizeAuthData = (authData: AuthData): AuthData => {
  return {
    user: authData.user,
    tokens: {
      access: {
        ...authData.tokens.access,
        expires:
          typeof authData.tokens.access.expires === "string"
            ? new Date(authData.tokens.access.expires)
            : authData.tokens.access.expires,
      },
      refresh: {
        ...authData.tokens.refresh,
        expires:
          typeof authData.tokens.refresh.expires === "string"
            ? new Date(authData.tokens.refresh.expires)
            : authData.tokens.refresh.expires,
      },
    },
  };
};

class AuthImpl {
  authData: AuthData | undefined;
  authCookieName: string;
  eventHandlers: Record<string, AuthEventHandler>;

  constructor() {
    this.eventHandlers = {};
    this.authCookieName = "auth";
  }

  writeAuthData(authData: AuthData | undefined) {
    if (authData == null) {
      cookies.remove(this.authCookieName);
      this.authData = undefined;
      return;
    }

    cookies.set(this.authCookieName, JSON.stringify(authData));
    this.authData = normalizeAuthData(authData);
  }

  readAuthData(): AuthData | undefined {
    if (this.authData != null) {
      if (!this.authData.user.id && this.authData.user._id) {
        this.authData.user.id = this.authData.user._id;
      }
      return this.authData;
    }

    const storedAuthDataJson = cookies.get(this.authCookieName);

    if (storedAuthDataJson == null) {
      return undefined;
    }

    const storedAuthData = normalizeAuthData(JSON.parse(storedAuthDataJson));
    this.authData = storedAuthData;
    return this.authData;
  }

  login(authData: AuthData) {
    if (this.isLoggedIn) {
      this.logout();
    }

    this.writeAuthData(authData);
    this.emitLoginEvent(this.readAuthData()!);
  }

  logout() {
    if (!this.isLoggedIn) {
      return;
    }

    this.writeAuthData(undefined);
    this.emitLogoutEvent();
  }

  subscribe(eventHandler: AuthEventHandler): AuthEventResolver {
    const handlerId = uuid();
    this.eventHandlers[handlerId] = eventHandler;
    return () => delete this.eventHandlers[handlerId];
  }

  emitLoginEvent(authData: AuthData) {
    Object.entries(this.eventHandlers).forEach(([key, handler]) => {
      handler({ kind: "LOGIN", authData });
    });
  }

  emitLogoutEvent() {
    Object.entries(this.eventHandlers).forEach(([key, handler]) => {
      handler({ kind: "LOGOUT" });
    });
  }

  get isLoggedIn(): boolean {
    return this.readAuthData() != null;
  }

  get accessTokenExpired(): boolean {
    assert(this.isLoggedIn);
    return (
      this.readAuthData()!.tokens.access.expires.valueOf() <
      new Date().valueOf()
    );
  }

  get refreshTokenExpired(): boolean {
    assert(this.isLoggedIn);
    return (
      this.readAuthData()!.tokens.refresh.expires.valueOf() <
      new Date().valueOf()
    );
  }

  clearIfRefreshTokenExpired() {
    if (this.refreshTokenExpired) {
      throw new RefreshFailedError();
    }
  }

  async refresh() {
    this.clearIfRefreshTokenExpired();

    let result;
    try {
      result = await axios({
        method: "post",
        url: `${apiAddr}/v1/auth/refresh-tokens`,
        data: { refreshToken: this.refreshToken },
      });
    } catch (error) {
      console.error(error);
      throw new RefreshFailedError();
    }

    this.writeAuthData(result.data);
  }

  async refreshIfRequired() {
    assert(this.isLoggedIn);

    if (this.accessTokenExpired) {
      await this.refresh();
    }
  }

  get accessToken(): string | undefined {
    return this.readAuthData()?.tokens.access.token;
  }

  get refreshToken(): string | undefined {
    return this.readAuthData()?.tokens.refresh.token;
  }

  async attachAccessTokenToOptions(
    options: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    if (!this.isLoggedIn) {
      return options;
    }

    await this.refreshIfRequired();

    return {
      ...options,
      headers: {
        ...("headers" in options ? options.headers : {}),
        Authorization: `Bearer ${this.accessToken!}`,
      },
    };
  }

  async callAxios(options: AxiosRequestConfig) {
    const optionsWithAccessToken = await this.attachAccessTokenToOptions(
      options
    );
    try {
      const result = await axios(optionsWithAccessToken);
      return result;
    } catch (error: any) {
      const status: number | undefined = error?.response?.status;
      if (this.isLoggedIn && (status === 401 || status === 403)) {
        throw new UnacceptedAuthDataError();
      }

      throw error;
    }
  }

  get userId(): string | undefined {
    return this.readAuthData()?.user.id;
  }

  checkIsLoggedIn() {
    if (!this.isLoggedIn) {
      throw new NotLoggedInError();
    }
  }

  get email(): string | undefined {
    return this.readAuthData()?.user.email;
  }
}

export default AuthImpl;

import {useNavigate} from "react-router-dom";

const useToRequestResetPasswordDonePage = () => {
  const nav = useNavigate();

  return (email: string) => {
    nav(`/request-reset-password-done?email=${encodeURIComponent(email)}`);
  };
};

export default useToRequestResetPasswordDonePage;

import React, { useState } from "react";
import styled from "styled-components";
import SelectLanguageModalApp from "../../modals/select-language-app";
import { black, white } from "../../styles/index";
import BackButton from "./back-button";
import LanguageButton from "./language-button.tsx";

const StyledHeader = styled.header<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.color ? props.color : "gray")};
  position: sticky;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 15px;
  box-sizing: border-box;
  height: 44px;
`;
const StyledNavBar = styled.nav<{ fontColor: string }>`
  color: ${(props) => props.fontColor};
  font-family: "BalooBhaijaan";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  display: flex;
  margin: auto;
`;
type NavigationBarProps = {
  className?: string | undefined;
  text?: string | undefined;
  enableGoBack?: boolean | undefined;
  onLanguagePress?: () => void;
};
const NavigationBar = ({
  text,
  className,
  enableGoBack,
  onLanguagePress,
}: NavigationBarProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <StyledHeader className={className} color={white}>
        {enableGoBack ? <BackButton></BackButton> : undefined}
        <StyledNavBar fontColor={black}>{text}</StyledNavBar>
        <LanguageButton
          onPress={
            onLanguagePress
              ? onLanguagePress
              : () => {
                  setModalOpen(true);
                }
          }
        />
      </StyledHeader>
      <SelectLanguageModalApp
        isVisible={modalOpen}
        onRequestClose={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

NavigationBar.defaultProps = {
  enableGoBack: true,
};
export default NavigationBar;

import axios from 'axios';
import {apiAddr} from "../config";

let rewardingTicketCount: number | undefined = undefined;

const readFromServer = async (): Promise<number> => {
  const axiosResult = await axios({
    method: 'get',
    url: `${apiAddr}/v1/app/rewarding-ticket-count`,
  });

  return axiosResult.data;
};

const useReadRewardingTicketCount = () => {
  return async (): Promise<number> => {
    if (rewardingTicketCount == null) {
      const result = await readFromServer();
      rewardingTicketCount = result;
    }

    return rewardingTicketCount;
  };
};

export default useReadRewardingTicketCount;

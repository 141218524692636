import MalformedBirthError from "./malformed-birth";

const checkBirth = (birth: string | undefined) => {
  if (birth == null) {
    throw new MalformedBirthError();
  }

  const regex = /\d{4}-\d{2}-\d{2}/;
  if (!regex.test(birth)) {
    throw new MalformedBirthError();
  }
};

export default checkBirth;

import useAuth from "../auth";
import { apiAddr } from "../../config";
import {
  FanUpAttractionBucketPayload,
  FanUpAttractionPayload,
  FanUpAttractionPickPayload,
  FanUpAttractionReviewPayload,
} from "../../data-types/attraction";
import serializeAnnouncement from "../../from-server-serializers/announcement";

/**
 * 찜 추가
 */
export const useCreateAttractionBucketList = () => {
  const auth = useAuth();

  return async (bucket: FanUpAttractionBucketPayload): Promise<void> => {
    bucket.userId = auth.userId;
    const result = await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/attraction-bucket/${bucket.attractId}`,
      data: bucket,
    });
  };
};

/**
 * 찜 불러오기
 */
export const useReadAttractionBucketList = () => {
  const auth = useAuth();

  return async (bucket: FanUpAttractionBucketPayload): Promise<void> => {
    bucket.userId = auth.userId;
    const result = await auth.callAxios({
      method: "get",
      url: `${apiAddr}/v1/attraction-bucket/${bucket.userId}`,
    });
  };
};

/**
 * 찜 삭제
 */
export const useDeleteAttractionBucketList = () => {
  const auth = useAuth();

  return async (bucket: FanUpAttractionBucketPayload): Promise<void> => {
    bucket.userId = auth.userId;
    const result = await auth.callAxios({
      method: "delete",
      url: `${apiAddr}/v1/attraction-bucket/${bucket.attractId}`,
      data: bucket,
    });
  };
};

import axios from 'axios';
import {apiAddr} from "../config";
import serializeVote from '../from-server-serializers/vote';
import DemandingVote from "../data-types/demanding-vote";

const useReadActiveDemandingVotesByCategory = () => {
  return async (categoryId: string): Promise<DemandingVote[]> => {
    const axiosResult = await axios({
      method: 'get',
      url: `${apiAddr}/v1/votes`,
      params: {
        limit: 100,
        onlyDemanding: true,
        category: categoryId,
      },
    });

    const demandingVotes = axiosResult.data.map(serializeVote) as DemandingVote[];

    return demandingVotes;
  };
};

export default useReadActiveDemandingVotesByCategory;

import useHandleAppError from "./handle-app-error";
import { useMemo } from "react";
import useAuth from "./auth";
import { apiAddr } from "../config";
import { useTranslation } from "react-i18next";

interface Result {
  askDailyRewards: () => any;
}

const useDailyRewards = (): Result => {
  const handleAppError = useHandleAppError();
  const auth = useAuth();
  const { t } = useTranslation("serviceBucketListDailyRewards");

  const tryAskDailyRewards = async () => {
    if (!auth.isLoggedIn) {
      return;
    }

    const axiosResult = await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/ask-bucketList-daily-rewards`,
      data: { user: auth.userId },
    });

    if (axiosResult.status === 200) {
      alert(
        t("attendanceRewardHasArrivedXVoteTickets", {
          ticketCount: axiosResult.data.rewardingTicketCount,
        })
      );
      return;
    }

    if (axiosResult.status === 208) {
      return;
    }
  };

  const askDailyRewards = async () => {
    try {
      await tryAskDailyRewards();
    } catch (error) {
      await handleAppError(error);
    }
  };

  return useMemo(
    () => ({
      askDailyRewards,
    }),
    [askDailyRewards]
  );
};

export default useDailyRewards;

import React from "react";
import styled from "styled-components";
import FanupLogo from "./lib/fanup-logo-v3";
import JoinBlock from "./JoinBlock";
import { maxTabletSizeInPx } from "../../styles/size";
import useLogic from "./use-logic";

const Cont = styled.div`
  display: flex;
  max-width: ${maxTabletSizeInPx}px;
  flex-direction: column;
  background-color: #f5f5f5;
  margin: auto;
  padding: 0 18px 0 18px;
  text-align: center;
`;
const LogoCont = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
`;
const Gap130 = styled.div`
  margin-top: 110px;
`;
const Gap56 = styled.div`
  margin-top: 56px;
`;
const Gap16 = styled.div`
  margin-top: 106px;
`;

const LoginPage = () => {
  const logic = useLogic();

  return (
    <Cont>
      <Gap130 />
      <LogoCont>
        <FanupLogo />
      </LogoCont>
      <Gap56 />
      <JoinBlock />
      <Gap16 />
    </Cont>
  );
};

export default LoginPage;

import {useNavigate} from "react-router-dom";

const useToTournamentVoteStatisticsPage = () => {
  const navigate = useNavigate();

  return (voteId: string) => {
    navigate(`/tournament-vote-statistics/${voteId}`);
  };
};

export default useToTournamentVoteStatisticsPage;

import React, { useState } from "react";
import styled from "styled-components";
import FullNavigationGoBackBottomTabLayout from "../../components/full-navigation-bottom-tab-layout";
import useLogic from "./use-logic";
import TabFragment from "./fragments/tab";
import BannerFragment from "./fragments/banner";
import LeadingCandidateFragment from "./fragments/leading-candidate";
import LinkBannerFragment from "./fragments/linkBanner";
import VoteInfoFragment from "./fragments/vote-info";
import CandidatesFragment from "./fragments/candidates";
import VoteIdolModal from "./fragments/vote-idol-modal";
import { warmGray } from "../../styles";
import ModalBaseWrapper from "../../modals/modal-base-wrapper";
import ModalContent from "./fragments/vote-idol-non-member-modal-content";
import { t } from "i18next";
import useToLoginPage from "../../services/to-login-page";

const GraySpace = styled.div`
  height: 8px;
  background-color: ${warmGray};
`;

const IdolVotesPage: React.FC = () => {
  const logic = useLogic();
  const goToLogin = useToLoginPage();

  if (logic.status !== "LOADED") {
    return null; // todo
  }

  return (
    <FullNavigationGoBackBottomTabLayout title={"CHART"}>
      <TabFragment logic={logic} />
      <BannerFragment logic={logic} />
      <LeadingCandidateFragment logic={logic} />
      <GraySpace />
      <VoteInfoFragment logic={logic} />
      <GraySpace />
      <LinkBannerFragment />
      <GraySpace />
      <CandidatesFragment logic={logic} />
      <VoteIdolModal logic={logic} />
      <ModalBaseWrapper
        isVisible={logic.nonModalState}
        onRequestClose={() => {
          logic.openNonModal(false);
        }}
        onLeft={() => {
          logic.openNonModal(false)
        }}
        onRight={() => { goToLogin(); }}
        leftText={logic.t("leftText")} rightText={logic.t("rightText")} content={<ModalContent text={logic.t("modalContent")} />} />
    </FullNavigationGoBackBottomTabLayout>
  );
};

export default IdolVotesPage;

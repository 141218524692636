import MultilingualString from '../data-types/multilingual-string';
import i18next from 'i18next';

const useToCurrentLocale = () => {
  return (multiStr: MultilingualString): string => {
    const curLang = i18next.language as keyof MultilingualString;

    if (!(curLang in multiStr)) {
      return multiStr.en;
    }

    return multiStr[curLang]!;
  };
};

export default useToCurrentLocale;

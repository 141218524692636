import React from 'react';
import styled from 'styled-components';
import { gray } from '../styles';

const StyledLinkedText = styled.a<{ color: string }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  text-align: center;
  text-decoration: none;

  /* Coolgray/CG500 */
  cursor: pointer;
  color: ${(props) => props.color};
`;

type LinkedTextProps = {
  className?: string | undefined;
  text: any;
  onClick: any;
};

const LinkedText = ({ className, text, onClick }: LinkedTextProps) => {
  return (
    <StyledLinkedText className={className} onClick={onClick} color={gray}>
      {text}
    </StyledLinkedText>
  );
};

LinkedText.defaultProps = {
  className: 'linked-text',
  text: 'link',
  onClick: () => {
    alert('click');
  },
};
export default LinkedText;
export type { LinkedTextProps };

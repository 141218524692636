import React, { useEffect, useState } from "react";
import NavigationWithGoBackBottomTabLayout from "../../../components/navigation-with-go-back-bottom-tab-layout";
import {
  FilterSortBy,
  FilterTitle,
  FilterWrap,
  HeadText,
  HeadTitle,
  HeartCount,
  HeartCountText,
  Inner,
  ItemAddr,
  ItemPicture,
  ItemTitle,
  ItemWrap,
  ListFilter,
  ListItem,
  ListTitle,
  ListWrap,
  StarPoint,
  StarWrap,
} from "./dom-styled";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconHeart } from "./svg/icon_heart.svg";
import { ReactComponent as IconSortDesc } from "./svg/icon_sort_desc.svg";

import BackgroundCount from "./svg/icon_union.png";
import { ReactComponent as IconStars } from "../svg/icon_starts.svg";
import { useReadAttractionPickList } from "../../../services/fanup-map/fanup-attraction-pick-repository";
import { FanUpAttractionPayload } from "../../../data-types/attraction";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";

/**
 * 평점 박스
 * @param point
 * @param isEditable
 */
const ReviewStar: React.FC<any> = (props) => {
  return (
    <StarWrap>
      <IconStars fill={props.point >= 1 ? "#FF176B" : "none"} />
      <IconStars fill={props.point >= 2 ? "#FF176B" : "none"} />
      <IconStars fill={props.point >= 3 ? "#FF176B" : "none"} />
      <IconStars fill={props.point >= 4 ? "#FF176B" : "none"} />
      <IconStars fill={props.point >= 5 ? "#FF176B" : "none"} />
      <StarPoint>{props.point}</StarPoint>
    </StarWrap>
  );
};
interface PickListProps {
  sort?: "desc" | "asc";
  data: FanUpAttractionPayload[];
}

/**
 * 관광지 리스트
 * @param props
 * @constructor
 */
const PickList: React.FC<PickListProps> = (props) => {
  let list: FanUpAttractionPayload[] = props.data;
  if (list.length > 0) {
    if (props.sort == "desc") {
      list.sort((a, b) =>
        a.requestAt! > b.requestAt! ? -1 : a.requestAt! < b.requestAt! ? 1 : 0
      );
    } else if (props.sort == "asc") {
      list.sort((a, b) =>
        a.requestAt! < b.requestAt! ? -1 : a.requestAt! > b.requestAt! ? 1 : 0
      );
    }
  }
  return (
    <ItemWrap>
      {props.data.map((item, idx) => (
        <PickItem key={`pickItem${idx}`} index={idx + 1} data={item}></PickItem>
      ))}
    </ItemWrap>
  );
};
/**
 * 개별 관광지 아이템
 * @param props
 * @constructor
 */
const PickItem: React.FC<any> = (props) => {
  const item: FanUpAttractionPayload = props.data;
  const navigate = useNavigate();
  let point = 0;

  if (Array.isArray(item.reviews) && item.reviews.length > 0) {
    let sum: number =
      item.reviews
        .map((item) => item.point)
        .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
    point = Math.round(sum / item.reviews.length);
  }
  return (
    <ListItem
      onClick={() => {
        navigate(`/maps/read/${item.id}`, { state: { data: item } });
      }}
    >
      <ItemPicture
        index={props.index}
        src={item.images && item.images.length > 0 ? item.images[0] : ""}
      ></ItemPicture>
      <ItemTitle>{item.name![i18n.language]}</ItemTitle>
      <ItemAddr>{item.address![i18n.language]}</ItemAddr>
      <ReviewStar point={point}></ReviewStar>
    </ListItem>
  );
};
const GetData = async () => {
  return await useReadAttractionPickList()();
};

const FanUpPickPage: React.PropsWithChildren<any> = () => {
  const { t } = useTranslation("pageFanUpMap");
  const [pickList, setPickList] = useState<FanUpAttractionPayload[]>([]);

  useEffect(() => {
    GetData().then((result) => {
      setPickList(result);
    });
  }, []);

  return (
    <NavigationWithGoBackBottomTabLayout
      title={"FanUP MAP"}
      disabledSafePadding={true}
    >
      <Inner>
        <HeadTitle>
          <IconHeart style={{ position: "relative", top: "10px" }} />
          <HeadText>{t("myPickList")}</HeadText>
        </HeadTitle>
        <HeartCount src={BackgroundCount}>
          <HeartCountText>{pickList.length}</HeartCountText>
        </HeartCount>
        <ListWrap>
          <ListFilter>
            <ListTitle>Hotspot list</ListTitle>
            <FilterWrap>
              <FilterTitle>{t("sortType")}</FilterTitle>
              <FilterSortBy>
                {t("sortRecently")}
                <IconSortDesc />
              </FilterSortBy>
            </FilterWrap>
          </ListFilter>
          <ItemWrap>
            <PickList data={pickList} sort={"desc"}></PickList>
          </ItemWrap>
        </ListWrap>
      </Inner>
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default FanUpPickPage;

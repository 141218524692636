import { strict as assert } from "assert";
import DemandingVote from "../data-types/demanding-vote";
import DemandingVoteLog from "../data-types/demanding-vote-log";
import demandingVoteVotableStatus from "../utils/demanding-vote-votable-status";
import useToVote from "./to-vote";
import useToVoteStatistics from "./to-vote-statistics";

const useToAppropriateVoteDetails = () => {
  const toVote = useToVote();
  const toVoteStatistics = useToVoteStatistics();

  return (vote: DemandingVote, log: DemandingVoteLog | undefined) => {
    const votableStatus = demandingVoteVotableStatus(vote, log);

    if (
      votableStatus === "PARTICIPATED" ||
      votableStatus === "CAN_PARTICIPATE"
    ) {
      toVote(vote);
      return;
    }

    if (votableStatus === "ENDED") {
      toVoteStatistics(vote);
      return;
    }

    assert.fail();
  };
};

export default useToAppropriateVoteDetails;

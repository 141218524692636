import React, { useEffect } from "react";
import useLogic from "./use-logic";
import Contents from "./fragments/contents";
import BottomTabLayout from "../../components/bottom-tab-layout";
import SelectLanguageModalApp from "../../modals/select-language-app";
import { debounce } from "lodash";
// @ts-ignore
import MainVoteModal from "../../modals/main-vote";

const MainPage: React.FC = () => {
  const logic = useLogic();

  // const btn = () => {
  //   sendMessage("전송 데이터(Web) : 네이티브로 데이터 전송");
  // };
  //
  const sendMessage = (str: string) => {
    //window.ReactNativeWebView.postMessage(str);
  };

  const receiveMessage = debounce((event: any) => {
    try {
      if (event.data) {
        if (event.data === "isInApp") {
          sessionStorage.setItem("isInApp", "Yes");
        } else {
          const tokenObjData = JSON.parse(event.data);

          sessionStorage.setItem("tokenData", event.data);
          sessionStorage.setItem("fanupTokenData", tokenObjData.pushToken);

          //alert("받은 데이터=-==(Web) : " + event.data);
        }
      }
    } catch (e) {
      alert(e);
    } finally {
      sessionStorage.setItem("fanupInApp", "app");
    }
    //window.postMessage = JSON.stringify(event.data);
  }, 500);

  useEffect(() => {
    const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if (varUA.indexOf("android") > -1) {
      /** android */
      document.addEventListener("message", receiveMessage);

      //this.sendMessage("Android 핸드폰으로 웹 접속");
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1
    ) {
      /** ios */
      window.addEventListener("message", receiveMessage);

      //sendMessage("iOS 핸드폰으로 웹 접속");
    }
    return () => {
      if (varUA.indexOf("android") > -1) {
        /** android */
        document.removeEventListener("message", receiveMessage);
      } else if (
        varUA.indexOf("iphone") > -1 ||
        varUA.indexOf("ipad") > -1 ||
        varUA.indexOf("ipod") > -1
      ) {
        /** ios */
        window.removeEventListener("message", receiveMessage);
      }
    };
  }, []);

  if (logic.status !== "LOADED") {
    return null; // todo change this to appropriate component.
  }

  const setTimeInterval = setTimeout(async () => {
    const tokenData: string | null = sessionStorage.getItem("tokenData");

    if (tokenData) {
      const objTokenData = JSON.parse(tokenData);

      await logic.mainPushData(objTokenData);
      sessionStorage.removeItem("tokenData");
      clearInterval(setTimeInterval);
    }
  }, 500);

  return (
    <BottomTabLayout>
      <Contents logic={logic} />
      <MainVoteModal
        isVisible={logic?.modalState}
        onRequestClose={() => {
          logic.setModalState(false);
        }}
        onGoToEventButton={logic?.goToEventPage}
        eventVoteData={logic?.eventVoteData}
        statistics={logic?.statistics}
        eventRewardingTicketCount={logic?.eventRewardingTicketCount}
      ></MainVoteModal>
      <SelectLanguageModalApp
        isVisible={logic.isSelectLanguageVisible}
        onRequestClose={logic.closeSelectLanguage}
      />
    </BottomTabLayout>
  );
};

export default MainPage;

import axios from 'axios';
import { apiAddr } from '../config';

const useIsEmailTaken = () => {
  return async (email: string): Promise<boolean> => {
    const axiosResult = await axios({
      method: 'post',
      url: `${apiAddr}/v1/is-email-taken`,
      data: { email },
    });

    return axiosResult.data;
  };
};

export default useIsEmailTaken;

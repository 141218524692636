import React from 'react';
import styled from 'styled-components';
import LinkedText from '../../components/text-linked';
import {Logic} from './use-logic';
import {useTranslation} from "react-i18next";
import { ReactComponent as Wall } from './svgs/login/wall.svg';

const StyledLoginFooter = styled.div`
  /* Auto layout */
  display: flex;
  justify-content: center;
  padding : 10px 0px 20px 0px;
  //flex-direction: column;
  :nth-child(1) {
    flex-grow: 1;
  }
  :nth-child(2) {
    flex-grow: 0;
  }
  :nth-child(3) {
    flex-grow: 1;
  }
`;

const StyledLoginTextWall = styled.div`
  color: #DAE0E6;
  padding : 0px 5px 0px 5px;
`

interface Props {
  logic: Logic;
}

const LoginFooter: React.FC<Props> = (props) => {
  const {t} = useTranslation('pageLogin');

  return (
    <div>
      <StyledLoginFooter>
        <div>
          <LinkedText
              text={t('pageLogin:createAccount')}
              onClick={props.logic.onCreateAccount}
          />
        </div>
        <StyledLoginTextWall> | </StyledLoginTextWall>
        <div>
          <LinkedText
              text={t('pageLogin:resetPassword')}
              onClick={props.logic.onResetPassword}
          />
        </div>


      </StyledLoginFooter>

    </div>
  );
};

export default LoginFooter;

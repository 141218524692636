import {useNavigate} from "react-router-dom";
import TournamentVoteRound from "../data-types/tournament-vote-round";
import stringifyTournamentVoteRound from "../utils/stringify-tournament-vote-round";

const useToVoteTournamentPage = () => {
  const navigate = useNavigate();

  return (voteId: string, round: TournamentVoteRound | undefined = undefined) => {
    if (round == null) {
      navigate(`/vote-tournament/${voteId}`);
      return;
    }

    navigate(`/vote-tournament/${voteId}?round=${stringifyTournamentVoteRound(round)}`);
  };
};

export default useToVoteTournamentPage;

import React from "react";
import { LoadedLogic } from "../../../../use-logic";
import DemandingVote from "../../../../../../data-types/demanding-vote";
import styled from "styled-components";
import {
  eventLeft,
  mainColor,
  voteRepresentingColor,
} from "../../../../../../styles";
import DemandingVoteLog from "../../../../../../data-types/demanding-vote-log";
import voteDateLeft from "../../../../../../utils/vote-date-left";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as EventClock } from "./event-clock.svg";
import i18next from "i18next";
const Cont = styled.div`
  position: absolute;
  top: 20px;
  right: 24px;
  display: flex;
`;

const Text = styled.span<{ color: string }>`
  font-size: 12px;
  color: ${({ color }) => color};
`;

interface Props {
  logic: LoadedLogic;
  vote: DemandingVote;
  log: DemandingVoteLog | undefined;
}

const DateLeft: React.FC<Props> = (props) => {
  const getDateLeft = () => {
    return voteDateLeft(props.vote);
  };

  return (
    <Cont>
      {props.vote.voteKind === "EVENT" ? (
        <>
          {" "}
          <EventClock style={{ marginTop: "2px", marginRight: "1px" }} />
          <Text
            color={
              "transparent;  background-clip: text; background:linear-gradient(110deg,#A329FA 20%, #ff0099 80%); -webkit-background-clip: text"
            }
          >
            D-{getDateLeft()}
          </Text>
        </>
      ) : (
        <>
          <Clock style={{ marginTop: "2px", marginRight: "1px" }} />
          <Text color={mainColor}>D-{getDateLeft()}</Text>
        </>
      )}
    </Cont>
  );
};

export default DateLeft;

import React from 'react';
import * as CompoundLayout from "../../../../data-types/compound-layout";
import styled from 'styled-components';
import {black} from "../../../../styles";
import {Logic} from './use-logic';

interface Props {
  component: CompoundLayout.PlainTextComponent;
  logic: Logic;
}

const Cont = styled.span`
  display: block;
  font-size: 14px;
  color: ${black};
`;

const PlainTextComponent: React.FC<Props> = (props) => {
  return (
    <Cont>
      {props.component.text}
    </Cont>
  );
};

export default PlainTextComponent;
export {default as useLogic} from './use-logic';

import React from 'react';
import styled from 'styled-components';
import { cg500, primary } from "../../styles";
import { ReactComponent as VoteIcon } from './vote-icon.svg';
import { useTranslation } from "react-i18next";
import addCommaToNumber from "../../utils/add-comma-to-number";

const Cont = styled.div`
  margin: 24px 28px 0px 28px;

  border: 1px solid #95c5ec;
  border-radius: 8px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-size: 16px;
`;

const Space = styled.div`
  flex: 1;
`;

const TheVoteIcon = styled(VoteIcon)`
  margin-left: 6px;
  color: black
`;

const Count = styled.span`
  font-size: 16px;
  color: black;
  text-decoration: underline;
`;

interface Props {
  ticketCount: number;
}

const MyVoteTicket: React.FC<Props> = (props) => {
  const { t } = useTranslation('componentVoteIdol');

  return (
    <Cont>
      <Label>{t('componentVoteIdol:myVoteTicket')}</Label>
      <TheVoteIcon />
      <Space />
      <Count>{addCommaToNumber(props.ticketCount)}</Count>
    </Cont>
  );
};

export default MyVoteTicket;


const digit2 = (digit: number): string => {
  if (digit < 10) {
    return `0${digit}`;
  }

  return digit.toString();
};

const dateToYYYYMMDD = (date: Date): string => {
  return `${date.getFullYear()}-${digit2(date.getMonth() + 1)}-${digit2(date.getDate())}`;
};

export default dateToYYYYMMDD;

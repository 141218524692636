import TournamentVote from "../data-types/tournament-vote";
import TournamentVoteRound from "../data-types/tournament-vote-round";
import shuffleArray from "./array-shuffle";

const tournamentDefaultRound = (vote: TournamentVote): TournamentVoteRound => {
  return {
    choices: shuffleArray(vote.choices),
    nthRoundOfThisLevel: 0,
    nthLevel: 0,
    originalChoiceCount: vote.choices.length,
    winnersOfThisLevel: [],
  };
};

export default tournamentDefaultRound;

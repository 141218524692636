import IdolVote from '../data-types/idol-vote';
import serializePeriod from './period';

const serialize = (raw: Record<string, any>): IdolVote => {
  return {
    id: raw.id,
    tabName: raw.tabName,
    question: raw.question,
    voteKind: 'IDOL',
    period: serializePeriod(raw.period),
    modification: raw.modification,
    description: raw.description,
    backgroundImageUrl: raw.backgroundImage,
  };
};

export default serialize;

import React from "react";
import styled from "styled-components";
import { cg60, primary } from "../../styles";

const Cont = styled.div`
  margin-top: 10px;
  border-top: 1px solid ${cg60};

  height: 50px;
  display: flex;
  align-items: center;
`;

const BarInMiddle = styled.div`
  width: 1px;
  background-color: ${cg60};
`;

const ButtonPrimary = styled.div`
  cursor: pointer;
  background: #ffffff;
  border-radius: 0px 0px 0px 8px;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonPrimaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #95c5ec;
`;

const ButtonSecondary = styled.div`
  cursor: pointer;
  background: #95c5ec;
  border-radius: 0px 0px 8px 0px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonSecondaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

interface Props {
  cancelText: string;
  onCancel: () => void;
}

const Buttons: React.FC<Props> = (props) => {
  return (
    <Cont>
      <ButtonPrimary onClick={props.onCancel}>
        <ButtonPrimaryText>{props.cancelText}</ButtonPrimaryText>
      </ButtonPrimary>
    </Cont>
  );
};

export default Buttons;

import React from "react";
import styled from "styled-components";

const Button = styled.div<{ backgroundColor: string }>`
  margin-top: 30px;
  height: 40px;
  padding: 0px 27px 0px 27px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
`;

const ButtonText = styled.span<{ color: string }>`
  font-size: 16px;
  font-weight: bold;
  color: ${({ color }) => color};
`;

interface VoteButtonProps {
  className?: string | undefined;
  color: string;
  backgroundColor: string;
  onPress?: (() => void) | undefined;
  title?: string | undefined;
}

const ArrowRight = styled.div`
  ont-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  /* gardiant/white */

  background: linear-gradient(199.91deg, #ffffff 5.14%, #ededed 118.04%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`;

const VoteButton: React.FC<VoteButtonProps> = (props) => {
  return (
    <Button
      backgroundColor={props.backgroundColor}
      onClick={props.onPress}
      className={props.className}
    >
      <ButtonText color={props.color}>{props.title} &nbsp;&nbsp; 〉</ButtonText>
    </Button>
  );
};

export default VoteButton;
export type { VoteButtonProps };

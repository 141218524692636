import React from "react";
import styled, { css } from "styled-components";

const Chip = styled.span<{
  color: string;
  backgroundColor: string;
  backgroundImage: string;
}>`
  display: inline-block;
  height: 28px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 28px;
  border-radius: 4px;

  ${({ color, backgroundColor, backgroundImage }) => css`
    color: ${color};
    background-color: ${backgroundColor};
    background-image: ${backgroundImage};
  `}
`;

interface Props {
  className?: string | undefined;
  color: string;
  backgroundColor: string;
  backgroundImage: string;
  title?: string | undefined;
}

const CommonChip: React.FC<Props> = (props) => {
  return (
    <Chip
      className={props.className}
      color={props.color}
      backgroundColor={props.backgroundColor}
      backgroundImage={props.backgroundImage}
    >
      {props.title}
    </Chip>
  );
};

export default CommonChip;

import { useEffect, useState } from "react";
import useQna from "../../services/qna";
import useAuth from "../../services/auth";
import useHandleAppError from "../../services/handle-app-error";
import { useTranslation } from "react-i18next";
import useGoBack from "../../services/go-back";

interface LoadingLogic {
  status: "LOADING";
  category?: string;
  title?: string;
  content?: string;
}

interface LoadedLogic {
  status: "LOADED";
  category: string;
  title: string;
  content: string;
  setCategory: (value: string) => void;
  setTitle: (value: string) => void;
  setContent: (value: string) => void;
  save: () => void;
}

interface FailedLogic {
  status: "FAILED";
  category?: string;
  title?: string;
  content?: string;
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic | undefined;

interface LoadingState {
  status: "LOADING";
  category?: string;
  title?: string;
  content?: string;
}

interface LoadedState {
  status: "LOADED";
  category: string;
  title: string;
  content: string;
}

interface FailedState {
  status: "FAILED";
  category?: string;
  title?: string;
  content?: string;
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const auth = useAuth();
  const currentQnaService = useQna();
  const handleAppError = useHandleAppError();
  const { t } = useTranslation("pageQna");
  const goBack = useGoBack();

  const tryInit = async () => {
    auth.checkIsLoggedIn();

    //const user = (await currentQnaService.read())!;

    setState({
      status: "LOADED",
      category: "",
      title: "",
      content: "",
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const setCategory = (value: string) => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, category: value };
    });
  };

  const setTitle = (value: string) => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, title: value };
    });
  };

  const setContent = (value: string) => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, content: value };
    });
  };

  const cancel = () => {
    goBack();
  };

  const save = async () => {
    try {
      await currentQnaService.createQna(state);
      alert(t("updated"));
    } catch (error) {
      await handleAppError(error);
    }
  };
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

import Qna from "../data-types/qna";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAuth from "./auth";
import { apiAddr } from "../config";
import serializeQna from "../from-server-serializers/qna";
import { AuthEventHandler, AuthEvent, AuthData } from "../utils/auth";
import axios, { AxiosResponse } from "axios";

interface QnaType {
  category: string;
  title: string;
  content: string;
}

interface Result {
  read: () => Promise<Qna | undefined>;
  createQna: (param: QnaType) => any;
  refresh: () => void;
}

const useQna = (): Result => {
  const [qna, setQna] = useState<Qna | undefined>(undefined);
  const auth = useAuth();

  let axiosResult: AxiosResponse;

  const getAuthDataFromAxiosResult = () => {
    const qnaData = axiosResult.data as Qna;
    return qnaData;
  };

  const createQna = async (param: QnaType): Promise<Qna> => {
    axiosResult = await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/users/${auth.userId}`,
      data: param,
    });

    setQna((oldQna) => {
      if (oldQna == null) {
        return undefined;
      }

      return { ...oldQna, param };
    });
    return getAuthDataFromAxiosResult();
  };

  const readUserFromServer = useCallback(
    async (force = false): Promise<Qna | undefined> => {
      if (!force && qna != null) {
        return qna;
      }

      const result = await auth.callAxios({
        method: "get",
        url: `${apiAddr}/v1/users/${auth.userId}`,
      });

      const newQna = serializeQna(result.data);

      setQna(newQna);

      return newQna;
    },
    [auth]
  );

  const updateUserField = useCallback(
    async <T>(fieldName: string, fieldValue: T) => {
      await readUserFromServer();

      await auth.callAxios({
        method: "patch",
        url: `${apiAddr}/v1/users/${auth.userId}`,
        data: { [fieldName]: fieldValue },
      });

      setQna((oldQna) => {
        if (oldQna == null) {
          return undefined;
        }

        return { ...oldQna, [fieldName]: fieldValue };
      });
    },
    [readUserFromServer, setQna]
  );

  const updateName = useCallback(
    (newName: string) => updateUserField("name", newName),
    [updateUserField]
  );

  const updateBirth = useCallback(
    (newBirth: string) => updateUserField("birth", newBirth),
    [updateUserField]
  );

  const updatePassword = useCallback(
    (newPassword: string) => updateUserField("password", newPassword),
    [updateUserField]
  );

  const authEventHandlerRef = useRef<AuthEventHandler>();
  authEventHandlerRef.current = (event: AuthEvent) => {
    if (event.kind === "LOGOUT") {
      setQna(undefined);
    }
  };
  useEffect(
    () => auth.subscribe(authEventHandlerRef.current!),
    [auth, authEventHandlerRef]
  );

  const prepareAndRead = useCallback(async (): Promise<Qna | undefined> => {
    if (auth.isLoggedIn) {
      if (qna == null) {
        const newQna = await readUserFromServer();
        return newQna;
      }

      return undefined;
    }

    return undefined;
  }, [qna, setQna, auth]);

  const refresh = useCallback(() => {
    readUserFromServer(true);
  }, [readUserFromServer]);

  return <Result>useMemo(
    () => ({
      createQna,
    }),
    [createQna]
  );
};

export default useQna;

import React from 'react';
import styled from "styled-components";
import {maxTabletSizeInPx, safeEndingPaddingSizeInPx} from "../../styles";
import BottomTabApp from "../bottom-tab-app";
import NavigationBarApp from "../navigation-bar-app";

const WideCont = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

const Cont = styled.div`
  height: 100%;
  max-width: ${maxTabletSizeInPx}px;

  display: flex;
  flex-direction: column;

  margin: auto;
`;

const ChildrenCont = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SafePadding = styled.div`
  height: ${safeEndingPaddingSizeInPx}px;
`;

interface NavigationBottomTabLayoutProps {
  title: string;
  children?: any;
}

const NavigationBottomTabLayout: React.FC<NavigationBottomTabLayoutProps> = (props) => {
  return (
    <WideCont>
      <Cont>
        <NavigationBarApp title={props.title}/>
        <ChildrenCont>
          {props.children}
          <SafePadding/>
        </ChildrenCont>
        <BottomTabApp/>
      </Cont>
    </WideCont>
  );
};

export default NavigationBottomTabLayout;
export type {NavigationBottomTabLayoutProps};

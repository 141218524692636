import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Unchecked} from './unchecked.svg';
import {ReactComponent as Checked} from './checked.svg';

const TheChecked = styled(Checked)`
  cursor: pointer;
`;

const TheUnChecked = styled(Unchecked)`
  cursor: pointer;
`;

interface Props {
  className?: string | undefined;
  value: boolean;
  onPress?: (() => void) | undefined;
}

const CheckBox: React.FC<Props> = (props) => {
  return props.value ? <TheChecked onClick={props.onPress}/> : <TheUnChecked onClick={props.onPress}/>;
};

export default CheckBox;

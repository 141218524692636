import TournamentVote from "../data-types/tournament-vote";
import serializePeriod from './period';
import serializeChoice from './tournament-vote-choice';

const serialize = (raw: Record<string, any>): TournamentVote => {
  return {
    id: raw.id,
    title: raw.title,
    categoryId: raw.category,
    period: serializePeriod(raw.period),
    thumbnailUrl: raw.thumbnail,
    description: raw.description,
    choices: raw.choices.map(serializeChoice),
    voteKind: 'TOURNAMENT',
  };
};

export default serialize;

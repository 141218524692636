import React from "react";
import styled from "styled-components";
import { cg60 } from "../../styles";

const Cont = styled.div`
  margin: 15px;
  --border-top: 1px solid ${cg60};

  height: 40px;
  display: flex;
  align-items: stretch;
`;

const BarInMiddle = styled.div`
  width: 1px;
  --background-color: ${cg60};
`;

const ButtonPrimary = styled.div`
  cursor: pointer;
  background-color: gray;
  border-radius: 15px;
  margin: 0px 20px 0px 20px;

  flex: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonPrimaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;

const ButtonBorderDiv = styled.div`
  
  }
`;
const ButtonSecondary = styled.div`
  cursor: pointer;
  background-image: linear-gradient(110deg, #00dede 20%, #ff0099 70%);
  width: 250px; /* 고정된 너비 값 */
  height: 40px; /* 고정된 높이 값 */
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px; /* 양 옆에 8px 패딩 추가 */

  & > ${ButtonBorderDiv} {
    background: white;
    width: 100%; /* ButtonSecondary 내에서 ButtonBorderDiv가 100% 영역 차지 */
    border-radius: 7px;
    padding: 5px; /* 패딩을 고정된 값으로 설정 */
  }
`;

const ButtonSecondaryText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;

interface Props {
  onCancel: () => void;
  onVote: () => void;
  goToVoteText: string;
  goToHomeText: string;
}

const ButtonsCom: React.FC<Props> = (props) => {
  return (
    <Cont>
      <ButtonPrimary onClick={props.onCancel}>
        <ButtonPrimaryText>{props.goToHomeText}</ButtonPrimaryText>
      </ButtonPrimary>
      <BarInMiddle />

      <ButtonSecondary onClick={props.onVote}>
        <ButtonBorderDiv>
          <ButtonSecondaryText>{props.goToVoteText}</ButtonSecondaryText>
        </ButtonBorderDiv>
      </ButtonSecondary>
    </Cont>
  );
};

export default ButtonsCom;

import Magazine from "../data-types/magazine";
import axios from 'axios';
import { fanupMediaAddr } from "../config";

const serialize = async (r: Record<string, any>): Promise<Magazine> => {
  const getThumbnailUrl = async () => {
    try {
      if (r.featured_media == null) {
        return undefined;
      }

      const axiosResult = await axios({
        method: 'get',
        url: `${fanupMediaAddr}/wp-json/wp/v2/media/${r.featured_media}`,
      });

      return axiosResult.data.guid.rendered;
    } catch (error: any) {
      if (error?.response?.status === 404) {
        return undefined;
      }

      throw error;
    }
  };

  return {
    id: r.id.toString(),
    thumbnail: await getThumbnailUrl(),
    html: r.excerpt.rendered,
    detailsLink: r.link,
    title: r.title.rendered,
  };
};

export default serialize;

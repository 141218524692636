import React from 'react';
import {LoadedLogic} from "../../use-logic";
import CompoundPage from "../../../../components/compound-page";
import styled from 'styled-components';
import BannerHeader from '../banner-header';

const Cont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TheCompoundPage = styled(CompoundPage)`
`;

interface Props {
  logic: LoadedLogic;
}

const Contents: React.FC<Props> = (props) => {
  return (
    <Cont>
      <BannerHeader logic={props.logic}/>
      <TheCompoundPage page={props.logic.mainPageContents}/>
    </Cont>
  );
};

export default Contents;

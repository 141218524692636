import { type } from 'os';
import React from 'react';
import styled from 'styled-components';

const StyledRoundButtonWrapper = styled.span`
  color: gray;
  font-size: 48px;
  font-family: serif;
  padding: 0px 12px 0px 12px;
  position: relative;
`;

type RoundButtonWrapperProps = {
  className?: string | undefined;
  children?: React.ReactNode;
};

const RoundButtonWrapper = ({ children }: RoundButtonWrapperProps) => {
  return <StyledRoundButtonWrapper>{children}</StyledRoundButtonWrapper>;
};

RoundButtonWrapper.defaultProps = {};
export default RoundButtonWrapper;
export type { RoundButtonWrapperProps };

import {AxiosResponse} from "axios";

const passResponseFromErrorOrFalse = (error: any, fn: (response: AxiosResponse) => boolean) => {
  if (error?.response == null) {
    return false;
  }

  return fn(error.response);
};

export default passResponseFromErrorOrFalse;

import {useNavigate} from "react-router-dom";

const useToVoteEventPage = () => {
  const navigate = useNavigate();

  return (voteId: string) => {
    navigate(`/vote-event/${voteId}`);
  };
};

export default useToVoteEventPage;

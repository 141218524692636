import React from "react";
import styled from "styled-components";
import FullNavigationGoBackBottomTabLayout from "../../components/full-navigation-bottom-tab-layout";
import { black, cg600, mainColor, primary } from "../../styles";
import { useTranslation } from "react-i18next";
import useLogic from "./use-logic";

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 18px 16px 18px 16px;
`;

const Title = styled.span`
  display: block;
  font-size: 18px;
  line-height: 23px;
  color: ${black};
  text-align: center;
`;

const Table = styled.table`
  margin-top: 24px;
  border-collapse: collapse;
`;

const THead = styled.thead`
  overflow: hidden;
  tr {
    th {
      background-color: ${mainColor};
    }
    th:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    th:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
`;

const Tr = styled.tr`
  border-radius: 4px;
`;

const Th = styled.th`
  height: 40px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-collapse: collapse;
  background-color: ${primary};
`;

const Td = styled.td`
  border-collapse: collapse;
  height: 40px;
  text-align: center;

  &:nth-of-type(1) {
    font-size: 14px;
    color: ${cg600};
  }

  &:nth-of-type(2) {
    font-size: 14px;
    font-weight: bold;
    color: ${cg600};
  }

  &:nth-of-type(3) {
    font-size: 14px;
    color: ${cg600};
  }
`;

const LoadMoreCont = styled.div`
  align-self: center;
  margin-top: 16px;
`;

const LoadingMore = styled.span`
  color: gray;
`;

const LoadMore = styled.span`
  align-self: center;
  cursor: pointer;
  color: gray;
  text-decoration: underline;
`;

interface DumbLog {
  id: string;
  kind: string;
  ticketCountDiff: number;
  issuedDate: Date;
}

interface DumbProps {
  logs: DumbLog[];
  loadMore: () => void;
  isLoadingMore: boolean;
}

const Dumb: React.FC<DumbProps> = (props) => {
  const { t } = useTranslation("pageTicketLogs");

  return (
    <Cont>
      <Title>{t("voteTicketLogs")}</Title>

      <Table>
        <THead>
          <Tr>
            <Th>{t("kind")}</Th>
            <Th>{t("voteTicketDifference")}</Th>
            <Th>{t("issuedDate")}</Th>
          </Tr>
        </THead>
        <tbody>
          {props.logs.map((log: DumbLog) => {
            return (
              <Tr key={log.id}>
                <Td>{log.kind}</Td>
                <Td>
                  {(log.ticketCountDiff > 0 ? "+" : "") + log.ticketCountDiff}
                </Td>
                <Td>{log.issuedDate.toLocaleDateString()}</Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
      <LoadMoreCont>
        {props.isLoadingMore ? (
          <LoadingMore>{t("loading")}</LoadingMore>
        ) : (
          <LoadMore onClick={props.loadMore}>{t("loadMore")}</LoadMore>
        )}
      </LoadMoreCont>
    </Cont>
  );
};

const TicketLogsPage: React.FC = () => {
  const logic = useLogic();
  const { t } = useTranslation("pageTicketLogs");

  if (logic.status !== "LOADED") {
    return null; // todo
  }

  return (
    <FullNavigationGoBackBottomTabLayout title={"MY"}>
      <Dumb
        logs={logic.logs.map((log) => {
          const getKind = () => {
            switch (log.reason.kind) {
              case "IDOL_VOTE":
                return t("chart");
              case "TOURNAMENT_VOTE":
              case "EVENT_VOTE":
                return t("worldCup");
              case "ATTENDANCE":
                return t("attendance");
              case "BUCKETLIST_ATTENDANCE":
                return t("bucketlistAttendance");
            }
          };

          return {
            id: log.id,
            kind: getKind(),
            ticketCountDiff: log.ticketCountChange,
            issuedDate: log.createdAt,
          };
        })}
        loadMore={logic.loadMore}
        isLoadingMore={logic.isLoadingMore}
      />
    </FullNavigationGoBackBottomTabLayout>
  );
};

export default TicketLogsPage;

import * as CompoundLayout from "../../../data-types/compound-layout";

import {useLogic as useAnnouncementsLogic} from "../component/announcements-component";
import {useLogic as useCommercialLogic} from "../component/commercial-component";
import {useLogic as useRandomIdolVoteLogic} from '../component/random-idol-vote-component';
import {useLogic as useRandomDemandingVoteLogic} from '../component/random-demanding-vote-component';
import {useLogic as useVotesLogic} from '../component/votes-component';
import {useLogic as usePlainTextLogic} from '../component/plain-text-component';
import {useLogic as useMagazineLogic} from '../component/magazine-component';

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  componentLogics: any[];
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

const matchingLogicOfComponent = (componentKind: CompoundLayout.Component['kind']): any => {
  switch (componentKind) {
    case 'ANNOUNCEMENTS':
      return useAnnouncementsLogic;

    case 'COMMERCIAL':
      return useCommercialLogic;

    case 'RANDOM_IDOL_VOTE':
      return useRandomIdolVoteLogic;

    case 'RANDOM_DEMANDING_VOTE':
      return useRandomDemandingVoteLogic;

    case 'VOTES':
      return useVotesLogic;

    case 'PLAIN_TEXT':
      return usePlainTextLogic;

    case 'MAGAZINE':
      return useMagazineLogic;
  }
};

const useComponentLogics = (page: CompoundLayout.Page): any[] => {
  return page.components.map(
    (component: CompoundLayout.Component) => {
      const currentLogic = matchingLogicOfComponent(component.kind);
      return currentLogic(component);
    }
  );
};

const useLogic = (page: CompoundLayout.Page): Logic => {
  const componentLogics = useComponentLogics(page);

  return {
    status: 'LOADED',
    componentLogics,
  };
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};

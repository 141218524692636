import React from "react";
import DemandingVote from "../../../../../data-types/demanding-vote";
import DemandingVoteLog from "../../../../../data-types/demanding-vote-log";
import demandingVoteVotableStatus from "../../../../../utils/demanding-vote-votable-status";
import CommonChip from "../../../components/common-chip";
import EventChip from "../../../components/event-chip";
import { strict as assert } from "assert";
import { useTranslation } from "react-i18next";
import {
  eventLeft,
  fadedVoteRepresentingColor,
  mainColor,
  voteRepresentingColor,
} from "../../../../../styles";

interface Props {
  vote: DemandingVote;
  log: DemandingVoteLog | undefined;
}

const Chip: React.FC<Props> = (props) => {
  const { t } = useTranslation("pageDemandingVotes");

  const getTitle = () => {
    const status = demandingVoteVotableStatus(props.vote, props.log);

    if (status === "ENDED") {
      return t("pageDemandingVotes:worldCupEnded");
    }

    // if (status === "PARTICIPATED") {
    //   return t("pageDemandingVotes:participated");
    // }

    if (status === "PARTICIPATED" || status === "CAN_PARTICIPATE") {
      if (props.vote.voteKind === "TOURNAMENT") {
        return t("pageDemandingVotes:worldCupInProgress");
      }

      if (props.vote.voteKind === "EVENT") {
        return t("pageDemandingVotes:eventWorldCup");
      }
    }

    assert.fail();
  };

  const color = voteRepresentingColor(props.vote, props.log);

  return (
    <>
      {props.vote.voteKind === "EVENT" ? (
        <EventChip
          color={"#FFFFFF"}
          backgroundColor={""}
          backgroundImage={"linear-gradient(110deg, #00dede 10%, #FF0099 80%)"}
          title={getTitle()}
        />
      ) : (
        <CommonChip
          color={"#FFFFFF"}
          backgroundColor={mainColor}
          backgroundImage={""}
          title={getTitle()}
        />
      )}
    </>
  );
};

export default Chip;

import React from "react";
import { LoadedLogic } from "./use-logic";
import styled from "styled-components";
import { black, cg500 } from "../../../../styles";

const HeaderCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h5`
  margin: 0px;
  padding: 0px;

  font-family: "BalooBhaijaan";
  font-weight: normal;
  font-size: 18px;
  color: ${black};
`;

const MoreButton = styled.span`
  color: ${cg500};
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
`;

interface Props {
  logic: LoadedLogic;
}

const Header: React.FC<Props> = (props) => {
  return (
    <HeaderCont>
      <Title>Announcements</Title>
      <MoreButton onClick={props.logic.onMoreClick}>MORE</MoreButton>
    </HeaderCont>
  );
};

export default Header;

import React from 'react';
import * as CompoundLayout from "../../../data-types/compound-layout";
import styled from 'styled-components';
import useToLink from '../../../services/to-link';

const TheImage = styled.img`
  height: 80px;
  object-fit: cover;
  cursor: pointer;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Logic {}

const useLogic = (): Logic => {
  return {};
};

interface Props {
  component: CompoundLayout.CommercialComponent;
  logic: Logic;
}

const CommercialComponent: React.FC<Props> = (props) => {
  const toLink = useToLink();

  return <TheImage
    src={props.component.imageUrl}
    onClick={() => toLink(props.component.link)}
  />;
};

export default CommercialComponent;
export {useLogic};

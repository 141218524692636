import React from 'react';
import styled from 'styled-components';

const Placeholder = styled.div`
display: flex;
  align-items: center;
  justify-content: center;
position: fixed;
margin: 0px;
padding: 0px;
border: 0px;
width: 100vw;
height: 100vh;
`;

const PlaceholderText = styled.span`
color: gray;
font-size: 48px;
font-family: serif;
`;

const NotFoundPage = () => {
    return (
        <Placeholder>
            <PlaceholderText>Page: NotFound</PlaceholderText>
        </Placeholder>
    );
};

export default NotFoundPage;

import { useNavigate } from 'react-router-dom';

const useToRequestResetPasswordPage = () => {
  const nav = useNavigate();

  return () => {
    nav(`/request-reset-password`);
  };
};

export default useToRequestResetPasswordPage;

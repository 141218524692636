import axios from 'axios';
import {apiAddr} from "../config";

interface FileUploadInfo {
  key: string;
  uploadUrl: string;
}

const registerTemporaryFile = (requestUploadInfoPath: string) => async (file: File) => {
  const requestUploadInfo = async (file: File): Promise<FileUploadInfo> => {
    const result = await axios({
      method: 'post',
      url: `${apiAddr}/v1/${requestUploadInfoPath}`,
      data: {mime: file.type, size: file.size},
    });

    return {key: result.data.tempKey, uploadUrl: result.data.uploadTo};
  };

  const uploadFile = async (fileUploadInfo: FileUploadInfo, file: File) => {
    await axios({
      method: 'put',
      url: fileUploadInfo.uploadUrl,
      headers: {
        'Content-Type': file.type,
        'Content-Length': file.size,
      },
      data: file,
    });
  };

  const fileUploadInfo = await requestUploadInfo(file);
  await uploadFile(fileUploadInfo, file);
  return fileUploadInfo.key;
};

export default registerTemporaryFile;

import {useSearchParams} from "react-router-dom";
import {useCallback} from "react";
import parseTournamentVoteRound from "../../../utils/parse-tournament-vote-round";
import tournamentDefaultRound from "../../../utils/tournament-default-round";
import TournamentVote from "../../../data-types/tournament-vote";
import TournamentVoteRound from "../../../data-types/tournament-vote-round";

type Getter = (vote: TournamentVote) => TournamentVoteRound;

const useGetRoundFromQueryParams = (): Getter => {
  const [queryParams] = useSearchParams();

  return useCallback(
    (vote: TournamentVote): TournamentVoteRound => {
      const unparsedRound = queryParams.get('round');

      if (unparsedRound == null) {
        return tournamentDefaultRound(vote);
      }

      const round = parseTournamentVoteRound(unparsedRound);

      return round;
    },
    [queryParams]
  );
};

export default useGetRoundFromQueryParams;

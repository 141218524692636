import Country from "../data-types/country";

const serialize = (data: Record<string, any>): Country => {
  return {
    id: data.id,
    name: data.name,
  };
};

export default serialize;

import styled from "styled-components";

export const OuterWrap = styled.div`
  padding: 16px;
`;
export const InnerWrap = styled.div`
  position: relative;
  height: auto;
  min-height: 100vh;
  background: #ededed;
`;
export const SearchBox = styled.div`
  padding: 24px 16px;
  background: #fff;
`;
export const Title = styled.div`
  color: #12161a;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
`;
export const AttractionMapWrap = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  overflow: hidden;
`;
export const AttractionMap = styled.div`
  margin-top: 16px;
  height: calc(100vh - 420px);
  overflow: hidden;
`;

export const InputBoxWrap = styled.div`
  position: relative;
  padding-top: 28px;
  width: 100%;
`;
export const InputBoxItem = styled.input`
  width: calc(100% - 32px);
  position: relative;
  border: 0;
  border-radius: 6px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px 0px;
`;
export const CheckBtn = styled.a`
  display: block;
  text-align: center;
  color: var(--coolgray-cg-900, #12161a);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  margin-top: 24px;
`;

export const RegistryBtn = styled.a`
  position: relative;
  display: block;
  background-color: #ff176b;
  margin: 0 28px;
  border-radius: 23px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 114.286% */
  color: #fff;
  padding: 8px 0;
`;

import React from "react";
import styled, { css } from "styled-components";

const Chip = styled.span<{
  color: string;
  backgroundColor: string;
  backgroundImage: string;
}>`
  display: inline-block;
  height: 28px;
  padding-left: 2px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
  line-height: 28px;
  border-radius: 4px;

  ${({ color, backgroundColor, backgroundImage }) => css`
    color: ${color};
    background-color: ${backgroundColor};
    background-image: ${backgroundImage};
  `}
`;

const Cover = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding-left: 2%;
  padding-right: 2%;
  background-color: white;
`;
const Text = styled.span`
  background: linear-gradient(110deg, #00eded 20%, #ff0099 80%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 650;
`;

interface Props {
  className?: string | undefined;
  color: string;
  backgroundColor: string;
  backgroundImage: string;
  title?: string | undefined;
}

const EventChip: React.FC<Props> = (props) => {
  return (
    <Chip
      className={props.className}
      color={props.color}
      backgroundColor={props.backgroundColor}
      backgroundImage={props.backgroundImage}
    >
      <Cover>
        <Text>{props.title}</Text>
      </Cover>
    </Chip>
  );
};

export default EventChip;

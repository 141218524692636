import React from "react";
import styled from "styled-components";
import { LoadedLogic } from "./use-logic";
import * as CompoundLayout from "../../../../data-types/compound-layout";
import { ReactComponent as ClockIcon } from "./clock.svg";
import { ReactComponent as VoteIcon } from "./vote.svg";
import {
  black,
  cg500,
  event,
  fadedEvent,
  fadedWorldCup,
  worldCup,
} from "../../../../styles";
import { strict as assert } from "assert";
import useToCurrentLocale from "../../../../services/to-current-locale";
import voteDateLeft from "../../../../utils/vote-date-left";

interface DumbProps {
  kind: string;
  kindColor: string;
  title: string;
  dateLeft: number;
  rewardingTicketCount: number;
  imageUrl: string | undefined;
  onMore: () => void;
  onCardPress: () => void;
  eventRewardingTicketCount: number;
}

const Cont = styled.div`
  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  font-family: "BalooBhaijaan";
  font-size: 18px;
  line-height: 25px;
  color: ${black};
`;

const More = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${cg500};
  font-weight: bold;
  cursor: pointer;
`;

const Card = styled.div`
  position: relative;
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  height: 150px;
  cursor: pointer;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Dim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
`;

const CardContents = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  padding-top: 20px;
  padding-left: 18px;
  padding-bottom: 15px;
`;

const Right = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardKind = styled.span<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-left: 7px;
  padding-right: 7px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
`;
const EventCardKind = styled.span<{ backgroundColor: string }>`
  background-image: ${({ backgroundColor }) => backgroundColor};
  padding-left: 3px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
`;

const CardTitle = styled.span`
  font-size: 20px;
  line-height: 25px;
  color: white;
  font-weight: bold;
`;

const CardDateLeftCont = styled.div`
  display: flex;
  align-items: center;
`;

const TheClockIcon = styled(ClockIcon)``;

const CardDateLeft = styled.span`
  color: white;
  font-size: 12px;
  margin-left: 2px;
`;

const TheVoteIcon = styled(VoteIcon)``;

const RewardingMessage = styled.span`
  margin-top: 4px;
  font-size: 14px;
  color: white;
`;

const Cover = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding-left: 2%;
  padding-right: 2%;
  background-color: white;
`;
const Text = styled.span`
  color: transparent;
  background: linear-gradient(110deg, #00eded 20%, #ff0099 80%);
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 650;
`;

const Dumb: React.FC<DumbProps> = (props) => {
  return (
    <Cont>
      <Header>
        <Title>WORLD CUP</Title>
        <More onClick={props.onMore}>MORE</More>
      </Header>
      <Card onClick={props.onCardPress}>
        <Image src={props.imageUrl} />
        <Dim />
        <CardContents>
          <Left>
            {props.kind === "EVENT WORLD CUP" ? (
              <EventCardKind
                backgroundColor={
                  "linear-gradient(110deg, #00eded 20%, #ff0099 80%)"
                }
              >
                <Cover>
                  <Text>{props.kind}</Text>
                </Cover>
              </EventCardKind>
            ) : (
              <CardKind backgroundColor={props.kindColor}>
                {props.kind}
              </CardKind>
            )}

            <CardTitle>{props.title}</CardTitle>
            <CardDateLeftCont>
              <TheClockIcon />
              <CardDateLeft>{props.dateLeft}</CardDateLeft>
            </CardDateLeftCont>
          </Left>
          <Right>
            <TheVoteIcon />
            {props.kind === "EVENT WORLD CUP" ? (
              <RewardingMessage>
                GET {props.eventRewardingTicketCount}
              </RewardingMessage>
            ) : (
              <RewardingMessage>
                GET {props.rewardingTicketCount}
              </RewardingMessage>
            )}
          </Right>
        </CardContents>
      </Card>
    </Cont>
  );
};

interface Props {
  component: CompoundLayout.RandomDemandingVoteComponent;
  logic: LoadedLogic;
}

const RandomDemandingVoteComponent: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  const logic = props.logic;

  const getKind = () => {
    if (logic.vote.voteKind === "TOURNAMENT") {
      return "WORLD_CUP";
    }

    if (logic.vote.voteKind === "EVENT") {
      return "EVENT WORLD CUP";
    }

    assert.fail();
  };

  const getKindColor = () => {
    if (logic.vote.voteKind === "TOURNAMENT") {
      return fadedWorldCup;
    }

    if (logic.vote.voteKind === "EVENT") {
      return fadedEvent;
    }

    assert.fail();
  };

  return (
    <Dumb
      kind={getKind()}
      kindColor={getKindColor()}
      title={toCurrentLocale(logic.vote.title)}
      dateLeft={voteDateLeft(logic.vote)}
      rewardingTicketCount={logic.rewardingTicketCount}
      imageUrl={logic.vote.thumbnailUrl}
      onCardPress={logic.toVote}
      onMore={logic.toDemandingVotes}
      eventRewardingTicketCount={logic.eventRewardingTicketCount}
    />
  );
};

export default RandomDemandingVoteComponent;
export { default as useLogic } from "./use-logic";

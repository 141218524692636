import React from "react";
import useDailyRewards from "../services/daily-rewards";

const Wrapper: React.FC<{children: any}> = ({children}) => {
  useDailyRewards();
  return children;
};

const wrapElementWithDailyRewards = (element: any): any => {
  return <Wrapper>{element}</Wrapper>;
};

export default wrapElementWithDailyRewards;

import React from "react";
import styled from "styled-components";
import { ReactComponent as TrophyIcon } from "./trophy.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as Clock } from "./clock.svg";
import Rate from "./rate";
import { black, cg20, cg500, mainColor } from "../../styles";
import VoteButton from "../vote-button";
import useToMainPage from "../../services/to-main-page";

interface Choice {
  id: string;
  name: string;
  scale: number; // 0~1
}

interface DemandingVoteStatisticsProps {
  className?: string | undefined;
  color: string;
  title?: string | undefined;
  description?: string | undefined;
  dateLeft?: string | number | undefined;
  choices: Choice[];
  openShareModal: () => void;
}

const borderColor = "#DAE0E6";

const Cont = styled.div``;

const Header = styled.div`
  margin: 24px 24px 16px 24px;
`;

const Title = styled.span`
  display: block;
  color: ${black};
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  text-align: center;
`;

const Description = styled.span`
  display: block;
  color: ${cg500};
  font-size: 18px;
  line-height: 23px;
  text-align: center;
`;

const DateLeftCont = styled.div`
  display: flex;
  margin-top: 12px;
  height: 36px;
  align-items: center;
  justify-content: center;
`;

const Ranking = styled.span`
  font-size: 24px;
  line-height: 30px;
  color: ${black};
`;

const DateLeft = styled.span<{ color: string }>`
  margin-left: 12px;
  font-size: 18px;
  line-height: 23px;
  color: ${({ color }) => color};
`;

const GraySpace = styled.div`
  height: 8px;
  background-color: #ededed;
`;

const RankingTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  &:nth-child(odd) {
    background-color: ${cg20};
  }
`;

const Th = styled.th<{ color: string }>`
  background-color: ${({ color }) => color};
  height: 40px;
  border-collapse: collapse;

  border-left: 1px solid ${borderColor};

  text-align: left;

  padding-left: 16px;
  padding-right: 16px;
  color: white;

  &:nth-child(1) {
    border-left: 0px;
    text-align: center;
  }

  &:nth-child(2),
  &:nth-child(3) {
  }
`;

const Td = styled.td`
  border-collapse: collapse;
  padding-left: 16px;
  padding-right: 16px;
  color: ${black};
  height: 64px;

  &:nth-child(1) {
    font-size: 24px;
    text-align: center;
  }

  &:nth-child(2) {
    font-size: 18px;
  }

  &:nth-child(3) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;
const Buttons = styled.div`
  text-align: center;
`;
const ShareButton = styled(VoteButton)`
  align-self: center;
  margin-top: 24px;
`;

const RankingButton = styled(VoteButton)`
  align-self: center;
  margin-top: 24px;
`;
const TheTrophyIcon = styled(TrophyIcon)``;

const DemandingVoteStatistics: React.FC<DemandingVoteStatisticsProps> = (
  props
) => {
  const { t } = useTranslation("componentDemandingVoteStatistics");
  const onSharePress = () => {
    props.openShareModal();
    //alert(t("pageTournamentVoteResult:featureIsInDevelopment"));
  };
  const goToMainPage = useToMainPage();
  return (
    <Cont className={props.className}>
      <Header>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        <DateLeftCont>
          <Ranking>{t("ranking")}</Ranking>
          <DateLeft color={mainColor}>
            <Clock style={{ transform: "scale(1.5)", marginRight: "5px" }} />
            D-{props.dateLeft}
          </DateLeft>
        </DateLeftCont>
      </Header>
      <GraySpace />
      <RankingTable>
        <thead>
          <Tr>
            <Th color={mainColor}>
              <TheTrophyIcon />
            </Th>
            <Th color={mainColor}>
              {t("componentDemandingVoteStatistics:candidate")}
            </Th>
            <Th color={mainColor}>
              {t("componentDemandingVoteStatistics:rate")}
            </Th>
          </Tr>
        </thead>
        <tbody>
          {props.choices.map((choice: Choice, choiceIndex: number) => {
            return (
              <Tr key={choice.id}>
                <Td>{choiceIndex + 1}</Td>
                <Td>{choice.name}</Td>
                <Td>
                  <Rate color={mainColor} scale={choice.scale} />
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </RankingTable>
      <Buttons>
        <RankingButton
          color={mainColor}
          backgroundColor={"#EFFBFF"}
          title={t("componentDemandingVoteStatistics:home")}
          onPress={goToMainPage}
        />
        <div style={{ display: "inline-block", margin: "15px" }} />
        <ShareButton
          color={"#ffffff"}
          backgroundColor={mainColor}
          title={t("componentDemandingVoteStatistics:share")}
          onPress={onSharePress}
        />
      </Buttons>
    </Cont>
  );
};

export default DemandingVoteStatistics;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavigationWithGoBackLayout from "../../components/navigation-bar-only-go-back";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as ContactEmail } from "./svg/contact_mail.svg";

interface QnaFormEndProps {
  qnaEndFormTitle1: string;
  qnaEndFormTitle2: string;
  qnaEndFormDescription: string;
  qnaEndFormConfirmText: string;
}

const Cont = styled.div`
  padding: 150px 16px 100px 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  align-self: center;
  align-items: center;
`;

const LabelTextArea = styled.div`
  color: #292e33;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 0px 20px 0px;
`;

const LabelTextDescArea = styled.div`
  align-self: center;
  font-size: 14px;
  color: #6c7680;
`;

const ButtonsCont = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Bottomlayout = styled.div`
  position: fixed;
  bottom: 20px;
  width: 95%;
  border: 1px solid #dae0e6;
`;

const StyledButton = styled.button<{ bgColor: string; textColor: string }>`
  width: 100%;
  height: 56px;
  font-weight: bold;
  font-size: 16px;

  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};

  border: none;
  border-radius: 8px;
`;

const QnaFormEnd: React.FC<QnaFormEndProps> = (props) => {
  const navigator = useNavigate();

  const handleSubmit = () => {
    navigator("/");
  };

  return (
    <>
      <Cont>
        <div>
          <ContactEmail />
        </div>

        <LabelTextArea>
          {props.qnaEndFormTitle1}
          <br />
          {props.qnaEndFormTitle2}
        </LabelTextArea>
        <LabelTextDescArea>{props.qnaEndFormDescription}</LabelTextDescArea>

        <ButtonsCont></ButtonsCont>
        <Bottomlayout>
          <StyledButton
            bgColor={`#FFFFFF`}
            textColor={`#3D454D`}
            onClick={handleSubmit}
          >
            확인
          </StyledButton>
        </Bottomlayout>
      </Cont>
    </>
  );
};

const EditProfilePage: React.FC = () => {
  const { t } = useTranslation("pageQna");

  const navigator = useNavigate();

  return (
    <>
      <NavigationWithGoBackLayout
        title={t("qnaInCapital")}
        onGoBackClick={() => navigator("/qna")}
      />
      <QnaFormEnd
        qnaEndFormTitle1={t("qnaEndFormTitle1")}
        qnaEndFormTitle2={t("qnaEndFormTitle2")}
        qnaEndFormDescription={t("qnaEndFormDescription")}
        qnaEndFormConfirmText={t("qnaEndFormConfirmText")}
      />
    </>
  );
};

export default EditProfilePage;

import Banner from "../data-types/banner";

const serialize = (item: Record<string, any>): Banner => {
  return {
    id: item.id,
    title: item.title,
    link: item.link,
    imageUrl: item.imageUrl,
    actionButtonText: item.actionButtonText,
  };
};

export default serialize;

import React from 'react';
import styled from 'styled-components';
import TextInput from '../../components/text-input';
import Button from '../../components/button';
import {Logic} from './use-logic';
import {useTranslation} from "react-i18next";

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Gap16 = styled.div`
  padding-top: 16px;
`;

interface Props {
  logic: Logic;
}

const LoginBlock: React.FC<Props> = (props) => {
  const {t} = useTranslation('pageLogin');

  return (
    <div>
      <Cont>
        <TextInput
          type={'email'}
          placeholder={t('pageLogin:email')}
          onChange={props.logic.setEmail}
        />
        <Gap16></Gap16>
        <TextInput
          type={'password'}
          placeholder={t('pageLogin:password')}
          onChange={props.logic.setPassword}
        />
        <Gap16></Gap16>
        <Button
          value={t('pageLogin:login')}
          onClick={props.logic.onLogin}
          disabled={props.logic.isLoggingIn || !props.logic.isFormValid}
        />
      </Cont>
    </div>
  );
};
export default LoginBlock;

import { useEffect, useState } from "react";
import Announcement from "../../data-types/announcement";
import { strict as assert } from "assert";
import useHandleAppError from "../../services/handle-app-error";
import { useParams } from "react-router-dom";
import useReadAnnouncement from "../../services/read-announcement";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  announcement: Announcement;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  announcement: Announcement;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const urlParams = useParams();
  const handleAppError = useHandleAppError();
  const readAnnouncement = useReadAnnouncement();

  const announcementId = urlParams.id!;

  const tryInit = async () => {
    const announcement = await readAnnouncement(announcementId);

    setState({
      status: "LOADED",
      announcement,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      announcement: state.announcement,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

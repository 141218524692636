import Period from "../data-types/period";

const serialize = (raw: Record<string, any>): Period => {
  return {
    from: new Date(raw.from),
    to: new Date(raw.to),
  };
};

export default serialize;

import React from "react";
import { LoadedLogic } from "../../use-logic";
import styled from "styled-components";
import { cg100, cg500, mainEventColor, primary } from "../../../../styles";
import IdolVote from "../../../../data-types/idol-vote";
import useToCurrentLocale from "../../../../services/to-current-locale";

const Cont = styled.div`
  height: 48px;
  position: relative;
`;

const GrayBar = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 2px;
  background-color: ${cg100};
`;

const ItemsCont = styled.div`
  display: flex;
  align-items: stretch;
  //overflow-x: auto;
`;

const ItemCont = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  font-family: "BalooBhaijaan";
`;

const TopLine = styled.div<{ active: boolean }>`
  height: 2px;
  background-color: ${({ active }) => (active ? mainEventColor : cg100)};
`;

const ItemText = styled.div<{ active: boolean }>`
  padding-left: 16px;
  padding-right: 16px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 18px;
  font-family: "BalooBhaijaan";
  color: ${({ active }) => (active ? mainEventColor : cg500)};
`;

interface Props {
  logic: LoadedLogic;
}

const TabFragment: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  return (
    <Cont>
      <ItemsCont>
        {props.logic.idolVotes.map(
          (idolVote: IdolVote, idolVoteIndex: number) => {
            const active = props.logic.currentTabIndex === idolVoteIndex;

            return (
              <ItemCont
                key={idolVoteIndex}
                onClick={() => props.logic.onTabOfIndexClick(idolVoteIndex)}
              >
                <TopLine active={active} />
                <ItemText active={active}>
                  {toCurrentLocale(idolVote.tabName)}
                </ItemText>
              </ItemCont>
            );
          }
        )}
      </ItemsCont>
    </Cont>
  );
};

export default TabFragment;

import IdolVote from "../../../data-types/idol-vote";
import IdolVoteCandidate from "../../../data-types/idol-vote-candidate";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { strict as assert } from "assert";
import useReadIdolVotes from "../../../services/read-idol-votes";
import useReadIdolVoteCandidates from "../../../services/read-idol-vote-candidates";
import useReadIdol from "../../../services/read-idol";
import Idol from "../../../data-types/idol";
import Association from "../../../data-types/association";
import useReadAssociation from "../../../services/read-association";
import Region from "../../../data-types/region";
import Country from "../../../data-types/country";
import useReadRegion from "../../../services/read-region";
import useReadCountry from "../../../services/read-country";
import useAuth from "../../../services/auth";
import { useTranslation } from "react-i18next";
import useReadMaxIdolVoteCountPerDay from "../../../services/read-max-idol-vote-count-per-day";
import { guestObjectId } from "../../../config";
import { TFunction, TFunctionResult } from "i18next";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  idolVotes: IdolVote[];
  idolVoteCandidates: Record<string, IdolVoteCandidate[]>;
  idols: Record<string, Idol>;
  associations: Record<string, Association>;
  regions: Record<string, Region>;
  countries: Record<string, Country>;
  currentTabIndex: number;
  onTabOfIndexClick: (index: number) => void;
  isDescriptionExpanded: boolean;
  setIsDescriptionExpanded: (newState: boolean) => void;
  openVoteModal: (p: { idol: Idol; vote: IdolVote }) => void;
  openNonModal: Dispatch<SetStateAction<boolean>>;
  nonModalState: boolean;
  closeVoteModal: () => void;
  idolToVote: Idol | undefined;
  voteToVote: IdolVote | undefined;
  onVoteDone: (p: { idol: Idol; vote: IdolVote; voteCount: number }) => void;
  maxIdolVoteCountPerDay: number;
  t: any;

}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  idolVotes: IdolVote[];
  idolVoteCandidates: Record<string, IdolVoteCandidate[]>;
  idols: Record<string, Idol>;
  associations: Record<string, Association>;
  regions: Record<string, Region>;
  countries: Record<string, Country>;
  currentTabIndex: number;
  isDescriptionExpanded: boolean;
  idolToVote: Idol | undefined;
  voteToVote: IdolVote | undefined;
  maxIdolVoteCountPerDay: number;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });
  const readIdolVotes = useReadIdolVotes();
  const readIdolVoteCandidates = useReadIdolVoteCandidates();
  const readIdol = useReadIdol();
  const readAssociation = useReadAssociation();
  const readRegion = useReadRegion();
  const readCountry = useReadCountry();
  const auth = useAuth();
  const { t } = useTranslation("pageIdolVotes");
  const readMaxIdolVoteCountPerDay = useReadMaxIdolVoteCountPerDay();

  const [nonModal, setNonModal] = useState(false);

  const init = async () => {
    const idolVotes = await readIdolVotes();
    const idolVoteCandidates: Record<string, IdolVoteCandidate[]> = {};
    await Promise.all(
      idolVotes.map(async (idolVote: IdolVote) => {
        const result = await readIdolVoteCandidates(idolVote.id);
        idolVoteCandidates[idolVote.id] = result;
      })
    );

    const idols: Record<string, Idol> = {};
    await Promise.all(
      Object.values(idolVoteCandidates)
        .flat()
        .map(async (candidate: IdolVoteCandidate) => {
          if (candidate.idol in idols) {
            return;
          }

          idols[candidate.idol] = await readIdol(candidate.idol);
        })
    );

    const associations: Record<string, Association> = {};
    await Promise.all(
      Object.values(idols).map(async (idol: Idol) => {
        if (idol.association == null) {
          return;
        }

        if (idol.association in associations) {
          return;
        }

        associations[idol.association] = await readAssociation(
          idol.association
        );
      })
    );

    const regions: Record<string, Region> = {};
    await Promise.all(
      Object.values(idols).map(async (idol: Idol) => {
        if (idol.region == null) {
          return;
        }

        if (idol.region in regions) {
          return;
        }

        regions[idol.region] = await readRegion(idol.region);
      })
    );

    const countries: Record<string, Country> = {};
    await Promise.all(
      Object.values(regions).map(async (region: Region) => {
        if (region.country in countries) {
          return;
        }

        countries[region.country] = await readCountry(region.country);
      })
    );

    const maxIdolVoteCountPerDay = await readMaxIdolVoteCountPerDay();

    setState({
      status: "LOADED",
      idolVotes,
      idolVoteCandidates,
      idols,
      associations,
      regions,
      countries,
      currentTabIndex: 0,
      isDescriptionExpanded: false,
      idolToVote: undefined,
      voteToVote: undefined,
      maxIdolVoteCountPerDay,
    });
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return {
      status: "LOADING",
    };
  }

  if (state.status === "FAILED") {
    return {
      status: "FAILED",
    };
  }

  const onTabOfIndexClick = (index: number) => {
    setState((oldState) => ({ ...oldState, currentTabIndex: index }));
  };

  const setIsDescriptionExpanded = (newState: boolean) => {
    setState((oldState) => ({ ...oldState, isDescriptionExpanded: newState }));
  };

  const openVoteModal = ({ idol, vote }: { idol: Idol; vote: IdolVote }) => {
    if (auth.userId) {
      if (!auth.isLoggedIn) {
        alert(t("pageIdolVotes:needToLoginToVote"));
        return;
      }
    } else {
      setNonModal(true);
    }

    setState((oldState) => ({
      ...oldState,
      idolToVote: idol,
      voteToVote: vote,
    }));
  };

  const closeVoteModal = () => {
    setState((oldState) => ({
      ...oldState,
      idolToVote: undefined,
      voteToVote: undefined,
    }));
  };

  const onVoteDone = (params: {
    idol: Idol;
    vote: IdolVote;
    voteCount: number;
  }) => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      const newIdolVoteCandidates = { ...oldState.idolVoteCandidates };
      newIdolVoteCandidates[params.vote.id] = newIdolVoteCandidates[
        params.vote.id
      ].map((candidate: IdolVoteCandidate) => {
        if (candidate.idol !== params.idol.id) {
          return candidate;
        }

        return {
          ...candidate,
          voteCount: candidate.voteCount + params.voteCount,
        };
      });

      return { ...oldState, idolVoteCandidates: newIdolVoteCandidates };
    });
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      idolVotes: state.idolVotes,
      idolVoteCandidates: state.idolVoteCandidates,
      idols: state.idols,
      associations: state.associations,
      regions: state.regions,
      countries: state.countries,
      currentTabIndex: state.currentTabIndex,
      onTabOfIndexClick,
      isDescriptionExpanded: state.isDescriptionExpanded,
      setIsDescriptionExpanded,
      idolToVote: state.idolToVote,
      voteToVote: state.voteToVote,
      openVoteModal,
      closeVoteModal,
      onVoteDone,
      maxIdolVoteCountPerDay: state.maxIdolVoteCountPerDay,
      t,
      nonModalState: nonModal,
      openNonModal: setNonModal,
    };
  }

  assert.fail();
};

export default useLogic;
export type { Logic, LoadingLogic, LoadedLogic, FailedLogic };

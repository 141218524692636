import React, { useEffect } from "react";
import queryString from "query-string";
import useOAuthLogin, { LoginServerError } from "../../../services/oauth-login";
import { useLocation, useSearchParams } from "react-router-dom";
import useAuth from "../../../services/auth";
import useToMainPage from "../../../services/to-main-page";

const SNSAuth = () => {
  const serviceOAuthLogin = useOAuthLogin();
  const [searchParams] = useSearchParams();
  const toMainPage = useToMainPage();

  const query = queryString.parse(searchParams.toString());
  const auth = useAuth();

  const fnLoginCheck = async () => {
    try {
      await serviceOAuthLogin(query?.key);
    } catch (error) {
      console.error(error);
    }
    toMainPage();
  };

  useEffect(() => {
    (async function anyNameFunction() {
      await fnLoginCheck();
    })();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h3>loding...</h3>
    </div>
  );
};

export default SNSAuth;

import {strict as assert} from 'assert';
import Vote from '../data-types/vote';
import useToVoteTournamentPage from "./to-vote-tournament-page";
import useToVoteEventPage from "./to-vote-event-page";

const useToVote = () => {
  const toVoteTournament = useToVoteTournamentPage();
  const toVoteEvent = useToVoteEventPage();

  return (vote: Vote) => {
    if (vote.voteKind === 'TOURNAMENT') {
      toVoteTournament(vote.id);
      return;
    }

    if (vote.voteKind === 'EVENT') {
      toVoteEvent(vote.id);
      return;
    }

    assert.fail(`Unexpected voteKind: ${vote.voteKind}.`);
  };
};

export default useToVote;

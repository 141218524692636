import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavigationWithGoBackLayout from "../../components/navigation-bar-with-go-back";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../services/auth";
import { apiAddr } from "../../config";

interface QnaViewComponentProps {
  qnaCancleText: string;
  qnaDeleteSuccess: string;
  qnaAnswerStatus: string;
  qnaAnswerY: string;
  qanAnswerN: string;
}

const Cont = styled.div`
  padding: 20px 16px 100px 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  align-self: center;
  align-items: center;
`;

const HrGap = styled.hr`
  border: 1px solid #edf1f5;
`;

const Bottomlayout = styled.div`
  position: fixed;
  bottom: 20px;
  width: 95%;
`;

const StyledButton = styled.button<{ bgColor: string; textColor: string }>`
  width: 100%;
  height: 56px;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #dae0e6;

  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};

  border-radius: 8px;
`;

const QnaView = styled.div`
  width: 100%;
  color: #292e33;
  font-size: 16px;
  list-style-type: none;
  padding: 0;
  background: #ffffff;
  overflow: hidden;
  span {
    display: inline-block;
    padding: 10px 0px 10px 0px;
  }
  .status-label {
    color: #6c7680;
    padding: 10px 30px 10px 0px;
  }
  .status {
    padding: 10px 0px 10px 0px;
  }
`;

const QnaViewItem = styled.div`
  width: 100%;
  font-size: 16px;
  display: flex;

  div {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-self: center;
    flex-grow: 1;
    padding-bottom: 20px;
  }
`;

const QnaViewComponent: React.FC<QnaViewComponentProps> = (props) => {
  const navigator = useNavigate();
  const param = useParams();
  const auth = useAuth();
  const [isAnswered, setIsAnswered] = useState(false);

  const handleSubmit = async () => {
    try {
      const result = await auth.callAxios({
        method: "DELETE",
        url: `${apiAddr}/v1/qna/${param.id}`,
      });

      if (result) {
        window.alert(props.qnaDeleteSuccess);
        navigator("/");
      }
    } catch (e) {
      window.alert(e);
      navigator("/qna-list");
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        const result = await auth.callAxios({
          method: "GET",
          url: `${apiAddr}/v1/qna/${param.id}`,
        });
        const { data } = result;

        if (result) {
          document.querySelector("#title")!.textContent = data.title;
          document.querySelector("#text")!.textContent = data.text;
          document.querySelector("#answer")!.innerHTML = data.isAnswered
            ? data.answer
            : "";
          setIsAnswered(data.isAnswered);
        }
      } catch (e) {
        window.alert(e);
        navigator("/qna-list");
      }
    }

    getData();
  }, [param]);

  return (
    <>
      <Cont>
        <QnaView>
          <span id="title"></span>
          <HrGap />
          <QnaViewItem>
            <div id="text"></div>
          </QnaViewItem>
          <HrGap />
          <div>
            <span className={`status-label`}>{props.qnaAnswerStatus}</span>
            <span className={`status`} style={{ color: "#3D454D" }}>
              {isAnswered ? props.qnaAnswerY : props.qanAnswerN}
            </span>
          </div>

          <QnaViewItem>
            <div id="answer"></div>
          </QnaViewItem>
        </QnaView>

        <Bottomlayout>
          <StyledButton
            bgColor={`#ffffff`}
            textColor={`#3D454D`}
            onClick={handleSubmit}
          >
            {props.qnaCancleText}
          </StyledButton>
        </Bottomlayout>
      </Cont>
    </>
  );
};

const QnaViewPage: React.FC = () => {
  const { t } = useTranslation("pageQna");

  return (
    <>
      <NavigationWithGoBackLayout title={t("qnaInCapital")} />
      <QnaViewComponent
        qnaCancleText={t("qnaCancleText")}
        qnaDeleteSuccess={t("qnaDeleteSuccess")}
        qnaAnswerStatus={t("qnaAnswerStatus")}
        qnaAnswerY={t("qnaAnswerY")}
        qanAnswerN={t("qanAnswerN")}
      />
    </>
  );
};

export default QnaViewPage;

import { useEffect, useState } from "react";
import { strict as assert } from "assert";
import TournamentVote from "../../../data-types/tournament-vote";
import useHandleAppError from "../../../services/handle-app-error";
import { useLocation, useParams } from "react-router-dom";
import useReadVoteById from "../../../services/read-vote-by-id";
import useReadVoteCategoryById from "../../../services/read-vote-category-by-id";
import VoteCategory from "../../../data-types/vote-category";
import useGetRoundFromQueryParams from "./use-get-round-from-query-params";
import TournamentVoteRound from "../../../data-types/tournament-vote-round";
import TournamentVoteChoice from "../../../data-types/tournament-vote-choice";
import tournamentCurrentChoices from "../../../utils/tournament-current-choices";
import { useTranslation } from "react-i18next";
import tournamentChoose from "../../../utils/tournament-choose";
import useToTournamentVoteResultPage from "../../../services/to-tournament-vote-result-page";
import useVoteTournament from "../../../services/vote-tournament";
import useToVoteTournamentPage from "../../../services/to-vote-tournament-page";
import useAuth from "../../../services/auth";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  vote: TournamentVote;
  voteCategory: VoteCategory;
  round: TournamentVoteRound;
  currentChoices: TournamentVoteChoice[];
  onChoose: (index: number) => void;
  onNext: () => void;
  isNextInProgress: boolean;
  selectedChoiceIndex: number | undefined;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  vote: TournamentVote;
  voteCategory: VoteCategory;
  round: TournamentVoteRound;
  isNextInProgress: boolean;
  selectedChoiceIndex: number | undefined;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const params = useParams();
  const voteId = params.id!;

  //const auth = useAuth();
  const handleAppError = useHandleAppError();
  const readVote = useReadVoteById();
  const readVoteCategory = useReadVoteCategoryById();
  const getRound = useGetRoundFromQueryParams();
  const voteTournament = useVoteTournament();
  const toTournamentResult = useToTournamentVoteResultPage();
  const toVoteTournament = useToVoteTournamentPage();
  const { t } = useTranslation("pageVoteTournament");
  const location = useLocation();

  const tryInit = async () => {
    setState({ status: "LOADING" });

    //auth.checkIsLoggedIn();

    const vote = (await readVote(voteId)) as TournamentVote;
    const voteCategory = await readVoteCategory(vote.categoryId);
    const round = getRound(vote);
    setState({
      status: "LOADED",
      vote,
      voteCategory,
      round,
      isNextInProgress: false,
      selectedChoiceIndex: undefined,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, [location]);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const currentChoices = tournamentCurrentChoices(state.round);

  const tryOnNext = async () => {
    try {
      setState((oldState) => ({ ...oldState, isNextInProgress: true }));

      // Check whether selectedChoice is not null.
      if (state.selectedChoiceIndex == null) {
        alert(t("pageVoteTournament:pleaseSelectYourChoice"));
        return;
      }

      // Do choose.
      const choosingResult = tournamentChoose(
        state.round,
        state.selectedChoiceIndex
      );

      // If it's the final, submit to the server, and move to the result page.
      if (choosingResult.isEnd) {
        await voteTournament({
          vote: state.vote.id,
          choice: choosingResult.winningChoice.id,
        });

        sessionStorage.setItem(
          "winningChoice.id",
          choosingResult.winningChoice.id
        );

        toTournamentResult(state.vote.id);
        return;
      }

      // If it's not the final, just move to the next round.
      toVoteTournament(state.vote.id, choosingResult.nextRound);
    } finally {
      setState((oldState) => ({ ...oldState, isNextInProgress: false }));
    }
  };

  const onChoose = (index: number) => {
    setState((oldState) => ({ ...oldState, selectedChoiceIndex: index }));
  };

  const onNext = async () => {
    try {
      await tryOnNext();
    } catch (error) {
      await handleAppError(error);
    }
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      vote: state.vote,
      voteCategory: state.voteCategory,
      round: state.round,
      currentChoices,
      onNext,
      isNextInProgress: state.isNextInProgress,
      selectedChoiceIndex: state.selectedChoiceIndex,
      onChoose,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

import DemandingVoteLog from "../data-types/demanding-vote-log";
import { apiAddr, guestObjectId } from "../config";
import useAuth from "./auth";
import NotLoggedInError from "../errors/not-logged-in";
import serializeDemandingVoteLog from "../from-server-serializers/demanding-vote-log";

const useReadDemandingVoteLog = () => {
  const auth = useAuth();

  return async (voteId: string): Promise<DemandingVoteLog | undefined> => {
    try {
      if (auth.userId) {
        if (!auth.isLoggedIn) {
          throw new NotLoggedInError();
        }
      }

      const axiosResult = await auth.callAxios({
        method: "get",
        url: `${apiAddr}/v1/votes/${voteId}/demanding-vote-log`,
        params: { user: auth.userId ?? guestObjectId },
      });

      return serializeDemandingVoteLog(axiosResult.data);
    } catch (error: any) {
      const status = error?.response?.status;
      if (status === 404) {
        return undefined;
      }

      throw error;
    }
  };
};

export default useReadDemandingVoteLog;

import React from "react";
import styled,{css} from "styled-components";
import { cg100, mainEventColor, pointColor, primary } from "../../styles";
import addCommaToNumber from "../../utils/add-comma-to-number";

const Cont = styled.div`
  height: 16px;
  background-color: ${cg100};
  display: flex;
  border-radius: 8px;
  position: relative;
`;

const Filled = styled.div<{ scale: number; first: boolean }>`
  height: 16px;
  width: ${({ scale }) => scale * 100 + "%"};
  border-radius: 8px;
  ${({ first }) =>
  first
    ? css`
        background-image: linear-gradient(90deg, #A329FA 20%, #FF176B 80%);
      `
    : css`
        background-color: ${mainEventColor};
      `};
`;

const TextCont = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  margin-left: 8px;
  color: white;
  font-size: 10px;
`;

interface FatProgressBarProps {
  className?: string | undefined;
  scale: number;
  label: string | number;
  first: boolean;
}

const FatProgressBar: React.FC<FatProgressBarProps> = (props) => {
  const labelStr: string =
    typeof props.label === "number"
      ? addCommaToNumber(props.label)
      : props.label;

  return (
    <Cont className={props.className}>
      <Filled scale={props.scale} first={props.first} />
      <TextCont>
        <Text>{labelStr}</Text>
      </TextCont>
    </Cont>
  );
};

export default FatProgressBar;
export type { FatProgressBarProps };

import {useNavigate} from "react-router-dom";

const useToTicketLogsPage = () => {
  const navigate = useNavigate();

  return () => {
    navigate('/ticket-logs');
  };
};

export default useToTicketLogsPage;

import { strict as assert } from "assert";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DemandingVoteStatistics from "../../../data-types/demanding-vote-statistics";
import useReadVoteById from "../../../services/read-vote-by-id";
import useReadDemandingVoteStatistics from "../../../services/read-demanding-vote-statistics";
import useHandleAppError from "../../../services/handle-app-error";
import EventVote from "../../../data-types/event-vote";
import useReadEventRewardingTicketCount from "../../../services/read-event-rewarding-ticket-count";

interface LoadingLogic {
  status: "LOADING";
  show?: boolean;
}

interface LoadedLogic {
  status: "LOADED";
  vote: EventVote;
  statistics: DemandingVoteStatistics;
  show: boolean;
  openVoteModal: () => void;
  closeVoteModal: () => void;
  gotoJoinMember: () => void;
  voteStart: () => void;
  eventRewardingTicketCount: number;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  vote: EventVote;
  statistics: DemandingVoteStatistics;
  show: boolean;
  eventRewardingTicketCount: number;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const urlParams = useParams();
  const readVote = useReadVoteById();
  const readStatistics = useReadDemandingVoteStatistics();
  const handleAppError = useHandleAppError();
  const readEventRewardingTicketCount = useReadEventRewardingTicketCount();

  const nav = useNavigate();

  const voteId = urlParams.id!;

  const tryInit = async () => {
    const vote = (await readVote(voteId)) as EventVote;
    const statistics = await readStatistics(voteId);
    const eventRewardingTicketCount = await readEventRewardingTicketCount();

    setState({
      status: "LOADED",
      vote,
      statistics,
      show: false,
      eventRewardingTicketCount: eventRewardingTicketCount,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const openVoteModal = () => {
    setState((oldState) => ({ ...oldState, show: true }));
  };

  const closeVoteModal = () => {
    setState((oldState) => ({ ...oldState, show: false }));
  };

  const voteStart = () => {
    nav("/demanding-votes");
  };

  const gotoJoinMember = () => {
    nav("/join-info");
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      vote: state.vote,
      statistics: state.statistics,
      show: state.show,
      openVoteModal,
      closeVoteModal,
      gotoJoinMember,
      voteStart,
      eventRewardingTicketCount: state.eventRewardingTicketCount,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

import React, { useState } from "react";
import FullNavigationBar from "../full-navigation-bar";
import useToMainPage from "../../services/to-main-page";
import SelectLanguageModalApp from "../../modals/select-language-app";
import useToLoginPage from "../../services/to-login-page";
import useAuth from "../../services/auth";
import useLogoutIfLoggedIn from "../../services/logout-if-logged-in";
import useToMyAccountPage from "../../services/to-my-account-page";

interface FullNavigationBarAppProps {
  className?: undefined | string;
  title: string;
}

const FullNavigationBarApp: React.FC<FullNavigationBarAppProps> = (props) => {
  const [isLanguageModalVisible, setIsLanguageModalVisible] =
    useState<boolean>(false);

  const toMainPage = useToMainPage();
  const toLoginPage = useToLoginPage();
  const logoutService = useLogoutIfLoggedIn();
  const auth = useAuth();
  const toMyAccount = useToMyAccountPage();

  const onLogoClick = toMainPage;

  const onLanguageClick = () => {
    setIsLanguageModalVisible(!isLanguageModalVisible);
  };

  const onRequestLanguageModalClose = () => {
    setIsLanguageModalVisible(false);
  };

  const onLoginClick = toLoginPage;
  const onLogoutClick = async () => {
    // logoutService
    /*
    await logoutService();
    toMainPage();
    window.location.reload();
    */

    toMyAccount();
  };

  return (
    <>
      <FullNavigationBar
        onLogoClick={onLogoClick}
        onLanguageClick={onLanguageClick}
        onLoginClick={auth.isLoggedIn ? onLogoutClick : onLoginClick}
        title={props.title}
        className={props.className}
        isLoggedIn={auth.isLoggedIn}
      />
      <SelectLanguageModalApp
        isVisible={isLanguageModalVisible}
        onRequestClose={onRequestLanguageModalClose}
      />
    </>
  );
};

export default FullNavigationBarApp;
export type { FullNavigationBarAppProps };

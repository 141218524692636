import isResponseErrorWhichErrorMessageStartsWith from "../../utils/is-response-error-which-error-message-starts-with";

const isEmailNotValid = isResponseErrorWhichErrorMessageStartsWith('"email" must be a valid email');
const isPasswordShorterThan8 = isResponseErrorWhichErrorMessageStartsWith('password must be at least 8 characters');
const doesPasswordNotContainAtLeastOneLetterOrDigit = isResponseErrorWhichErrorMessageStartsWith('password must contain at least 1 letter and 1 number');
const isBirthNotValid = isResponseErrorWhichErrorMessageStartsWith('"birth" with value');
const isEmailAlreadyTaken = isResponseErrorWhichErrorMessageStartsWith('Email already taken');

export {
  isEmailNotValid,
  isPasswordShorterThan8,
  doesPasswordNotContainAtLeastOneLetterOrDigit,
  isBirthNotValid,
  isEmailAlreadyTaken,
};

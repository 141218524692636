import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavigationWithGoBackLayout from "../../components/navigation-bar-with-go-back";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../services/auth";

import { ReactComponent as Icon } from "./svg/Icon.svg";
import { apiAddr } from "../../config";
import { t } from "i18next";

interface QnaFormEndProps {
  qnaCategorys: {
    id: "login" | "account" | "join" | "etc" | "vote";
    value: string;
  }[];
  goToQna: string;
  yesAnswer: string;
  noAnswer: string;
}

const Cont = styled.div`
  background-color: #ffffff;
  padding: 20px 16px 100px 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  align-self: center;
  align-items: center;
`;

const HrGap = styled.hr`
  border: 1px solid #edf1f5;
`;

const Bottomlayout = styled.div`
  position: fixed;
  bottom: 20px;
  width: 95%;
`;

const StyledButton = styled.button<{ bgColor: string; textColor: string }>`
  width: 100%;
  height: 56px;
  font-weight: bold;
  font-size: 16px;

  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};

  border: none;
  border-radius: 8px;
`;

const QnaList = styled.div`
  width: 100%;
  color: #292e33;
  font-size: 16px;
  list-style-type: none;
  padding: 0;
  background: #ffffff;
  overflow: hidden;
`;

const QnaListItem = styled.div`
  width: 100%;
  background-color: #ffffff;
  height: 44px;
  font-size: 16px;
  display: flex;
  div {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    :nth-child(1) {
      flex-grow: 8;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    :nth-child(2) {
      flex-grow: 2;
      min-width: 85px;
      text-align: right;
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
`;

const NotExistQnaNoti = styled.div`
  background-color: #ffffff;
  color: #98a5b3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const QnaFormEnd: React.FC<QnaFormEndProps> = (props) => {
  const navigator = useNavigate();

  const auth = useAuth();

  const handleWrite = () => {
    navigator("/qna-form/default");
  };

  const [list, setList] = useState<any>([]);

  const callListData: any = async () => {
    try {
      const result = await auth.callAxios({
        method: "get",
        url: `${apiAddr}/v1/qna`,
      });

      const { data } = result;

      if (result) {
        return data.results;
      } else {
        return null;
      }
    } catch (e) {
      window.alert(e);
    }
  };

  const handleViewLink = (id: string) => {
    navigator(`/qna-view/${id}`);
  };

  useEffect(() => {
    async function fetchAndSetUser() {
      const listData = await callListData();
      setList(listData);
    }
    fetchAndSetUser();
  }, []);

  return (
    <>
      <Cont>
        <QnaList>
          {list?.map((item: any, index: any) => (
            <>
              <QnaListItem key={index}>
                <div onClick={() => handleViewLink(item._id)}>
                  <span style={{ fontWeight: "bold", color: "#95c5eb" }}>
                    [
                    {
                      props.qnaCategorys.find((i) => i.id === item.category)
                        ?.value
                    }
                    ]
                  </span>
                  {item.title}
                </div>
                <div>
                  <span style={{ textAlign: "right", marginLeft: "auto" }}>
                    {item.isAnswered ? props.yesAnswer : props.noAnswer}
                  </span>{" "}
                  <Icon />
                </div>
              </QnaListItem>
              <HrGap />
            </>
          ))}
          {list?.length < 1 && (
            <NotExistQnaNoti key={`1`}>{t("qnaListNoList")}</NotExistQnaNoti>
          )}
        </QnaList>

        <Bottomlayout>
          <StyledButton
            bgColor={`#95C5EC`}
            textColor={`#FFFFFF`}
            onClick={handleWrite}
          >
            {props.goToQna}
          </StyledButton>
        </Bottomlayout>
      </Cont>
    </>
  );
};

const QnaListPage: React.FC = () => {
  const { t } = useTranslation("pageQna");

  return (
    <>
      <NavigationWithGoBackLayout title={t("qnaInCapital")} />
      <QnaFormEnd
        qnaCategorys={[
          { id: "login", value: t("login") },
          { id: "account", value: t("account") },
          { id: "join", value: t("join") },
          { id: "vote", value: t("vote") },
          { id: "etc", value: t("etc") },
        ]}
        goToQna={t("goToQna")}
        yesAnswer={t("yesAnswer")}
        noAnswer={t("noAnswer")}
      />
    </>
  );
};

export default QnaListPage;

import TicketReason from "../data-types/ticket-reason";
import { strict as assert } from "assert";

const serializeTicketReason = (data: Record<string, any>): TicketReason => {
  if (
    data.kind === "IDOL_VOTE" ||
    data.kind === "TOURNAMENT_VOTE" ||
    data.kind === "EVENT_VOTE"
  ) {
    return {
      kind: data.kind,
      vote: data.vote,
      voteLog: data.voteLog,
    };
  }

  if (data.kind === "ATTENDANCE") {
    return { kind: "ATTENDANCE" };
  }

  if (data.kind === "BUCKETLIST_ATTENDANCE") {
    return { kind: "BUCKETLIST_ATTENDANCE" };
  }

  assert.fail();
};

export default serializeTicketReason;

import DemandingVoteLog from "../data-types/demanding-vote-log";

const serialize = (data: Record<string, any>): DemandingVoteLog => {
  return {
    id: data.id,
    user: data.user,
    vote: data.vote,
    votedAt: new Date(data.votedAt),
    choice: data.choice,
  };
};

export default serialize;

import Banner from '../data-types/banner';
import useAuth from "./auth";
import {apiAddr} from "../config";
import serializeBanner from '../from-server-serializers/banner';

const useReadMainBanners = () => {
  const auth = useAuth();

  return async (): Promise<Banner[]> => {
    const result = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/v1/app/main-banners`,
    });

    return result.data.map(serializeBanner);
  };
};

export default useReadMainBanners;

import MyUser from "../data-types/my-user";

const serialize = (data: Record<string, any>): MyUser => {
  return {
    id: data.id,
    birth: data.birth,
    email: data.email,
    name: data.name,
    ticketCount: data.ticketCount,
    isEmailVerified: data.isEmailVerified,
  };
};

export default serialize;

import React from "react";
import styled from "styled-components";

const StyledRoundButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledRoundButton = styled.button`
  color: white;
  border: 0;
  border-radius: 16px;
  font-size: 16px;
  font-family: Spoqa Han Sans Neo;
  font-weight: 500;
  padding: 0px 12px 0px 12px;
  position: relative;
  background-color: #FF176B;
  width: 200px;
  height: 52px;
  text-align: center;
`;

type RoundButtonWrapperProps = {
  className?: string | undefined;
  children?: React.ReactNode;
  onClick?: () => void;
};

const RoundButtonWrapper = ({ children, onClick }: RoundButtonWrapperProps) => {
  return (
    <StyledRoundButtonWrapper>
      <StyledRoundButton onClick={onClick}>{children}</StyledRoundButton>
    </StyledRoundButtonWrapper>
  );
};

RoundButtonWrapper.defaultProps = {};
export default RoundButtonWrapper;
export type { RoundButtonWrapperProps };

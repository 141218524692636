import React from 'react';
import styled from "styled-components";
import {maxTabletSizeInPx, safeEndingPaddingSizeInPx} from "../../styles";
import FullNavigationBarApp from "../full-navigation-bar-app";
import BottomTabApp from "../bottom-tab-app";

const WideCont = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

const Cont = styled.div`
  height: 100%;
  max-width: ${maxTabletSizeInPx}px;

  display: flex;
  flex-direction: column;

  margin: auto;
`;

const ChildrenCont = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SafePadding = styled.div`
  height: ${safeEndingPaddingSizeInPx}px;
`;

interface FullNavigationBottomTabLayoutProps {
  title: string;
  children?: any;
}

const FullNavigationGoBackBottomTabLayout: React.FC<FullNavigationBottomTabLayoutProps> = (props) => {
  return (
    <WideCont>
      <Cont>
        <FullNavigationBarApp title={props.title}/>
        <ChildrenCont>
          {props.children}
          <SafePadding style={{display:`${props.children&& props.children.props && props.children.props.className==='map-inner'?'none':'block'}`}}/>
        </ChildrenCont>
        <BottomTabApp/>
      </Cont>
    </WideCont>
  );
};

export default FullNavigationGoBackBottomTabLayout;
export type {FullNavigationBottomTabLayoutProps};

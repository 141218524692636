import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Buttons from "./buttons";
import useToCurrentLocale from "../../services/to-current-locale";
import useToMainPage from "../../services/to-main-page";
import EventVote from "../../data-types/event-vote-main";
import { mainEventColor } from "../../styles";
import i18next from "i18next";
import Draggable from "react-draggable";
import MultilingualString from "../../data-types/multilingual-string";
import { ReactComponent as HandleIcon } from "./Handle.svg";

const Cont = styled.div`
  background-color: white;
  border-radius: 15px 15px 0 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
`;

const Header = styled.div`
  margin: 0px 24px 16px 24px;
`;

const Title = styled.span`
  display: block;
  margin: 0px 28px 0px 28px;

  font-family: "BalooBhaijaan";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #051331;
`;

const Description = styled.span`
  display: block;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: -0.24px;
  text-align: center;

  /* Disabled/end vote */

  color: #7e7e7e;
`;

const ChoiceCont = styled.div`
  padding: 10px 16px 10px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ScoreCont = styled.div`
  display: flex;
  position: relative;
  width: 315px;
  text-align: center;
  align-self: center;
  padding: 10px 6px 10px 6px;

  background-color: white;
  display: flex;
  align-items: stretch;
`;

const ScoreLeft = styled.div<{ per: number }>`
  position: absolute;
  background-color: ${mainEventColor};
  height: 22px;
  left: 1px;
  right: ${({ per }) => (per >= 0 ? `${100 - per}%` : `0%`)};
  top: 1%;
  bottom: 1%;
  border-radius: 10px 0px 0px 10px;

  span {
    position: absolute;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    /* identical to box height */

    left: 8px;
    top: 5px;

    color: #ffffff;
  }
`;

const ScoreRight = styled.div<{ per: number }>`
  position: absolute;
  background-color: #09a7a7;
  height: 22px;
  left: ${({ per }) => (per >= 0 ? `${100 - per}%` : `0%`)};
  right: 1px;
  top: 1%;
  bottom: 1%;

  border-radius: 0px 10px 10px 0px;
  span {
    position: absolute;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    /* identical to box height */

    right: 8px;
    top: 5px;

    color: #ffffff;
  }
`;

const Choices = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  align-self: center;
  padding: 0px 18px 0px 18px;
`;

const ChoicesScore = styled.div`
  flex: 1;
  display: flex;
  padding: 0px 8px 0px 8px;
  div {
    &:nth-child(1) {
      text-align: left;
      flex-grow: 1;
    }
    &:nth-child(2) {
      text-align: right;
      flex-grow: 1;
    }
  }
`;

const ChoicesBlock = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  align-self: center;
  align-items: stretch;
`;

const ChoicesGap = styled.div`
  width: 10px;
  display: flex;
  justify-content: center;
`;

const ChoiceComp = styled.div<{ active: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  min-width: 152px;
  max-width: 162px;
  border: 2px solid ${({ active }) => (!active ? "#FF176B" : "#09A7A7")};
  border-radius: 5px;
`;

const SelectBoxChoice = styled.div<{ active: boolean }>`
  position: relative;
  opacity: ${({ active }) => (active ? "0.5" : "1")};
  background-position: center 50px;
  background-repeat: no-repeat;
`;

const ChoiceRect = styled.div<{ active: boolean }>`
  .choice-img {
    position: absolute;
    left: 37%;
    right: 19.65%;
    top: 17.83%;
    bottom: 72.88%;
  }

  .choice-img-a {
    position: absolute;
    left: 95px;
    top: 22%;
  }
  .choice-img-b {
    position: absolute;
    left: 0px;
    top: 22%;
  }
`;

const ChoiceImage = styled.img`
  display: flex;
  width: 152px;
  height: 122px;
  object-fit: cover;
  z-index: 0;
`;

const ChoiceTitleCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;
`;

const ChoiceTitle = styled.span<{ active: boolean; colorWhenActive: string }>`
  display: block;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
`;

const TextDescription = styled.div`
  padding: 25px 16px 10px 16px;
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;
  div {
    display: flex;
    width: 100%;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #7e7e7e;

    img {
      width: 14px;
      height: 14px !important;
    }
  }
`;
const StyledHandleIcon = styled(HandleIcon)`
  margin: 0 auto; /* 수평 중앙 정렬 */
  align-self: center; /* 수직 중앙 정렬 */
`;

interface Choice {
  id: string;
  name: string;
  scale: number; // 0~1
}

interface MainVoteProps {
  className?: string | undefined;
  onCancel: () => void;
  onGoToEventPageButton: () => void;
  eventVoteData: EventVote;
  choicesArray: Choice[];
  eventRewardingTicketCount: number;
}

const MainVote: React.FC<MainVoteProps> = (props) => {
  const [position, setPosition] = useState({ x: -195, y: 0 });
  const [hideModalToday, setHideModalToday] = useState(
    localStorage.getItem("hideModalToday") === "true"
  );
  const { t } = useTranslation("componentMainVote");
  const { t: monthT } = useTranslation("month");
  const toCurrentLocale = useToCurrentLocale();
  const choiceData = props.eventVoteData?.choices;
  const choiceBool =
    choiceData && choiceData[0]?.voteCount > choiceData[1].voteCount;
  const choiceArray = props.choicesArray;
  const monthText =
    i18next.language === "kr"
      ? `${(new Date().getMonth() + 1).toString()}월 ${
          props.eventVoteData.voteKind
        }`
      : `${monthT(`${(new Date().getMonth() + 1).toString()}`).substring(
          0,
          3
        )}'s ${props.eventVoteData.voteKind}`;

  const handleHideTodayChange = () => {
    setHideModalToday(!hideModalToday);
    localStorage.setItem("hideModalToday", (!hideModalToday).toString());

    if (hideModalToday) {
      props.onCancel();
    }
  };

  const getTodayDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    return `${year}-${month}-${day}`;
  };
  // 페이지가 로드될 때 오늘의 날짜를 확인하여 옵션을 초기화
  const checkTodayAndResetOption = () => {
    const savedDate = localStorage.getItem("lastSeenDate");
    const todayDate = getTodayDate();

    if (savedDate !== todayDate) {
      // 오늘을 처음으로 방문한 경우
      localStorage.removeItem("hideModalToday"); // 옵션 초기화
      localStorage.setItem("lastSeenDate", todayDate); // 날짜 업데이트
      setHideModalToday(false);
    }
  };
  checkTodayAndResetOption();
  // 페이지가 로드될 때 호출
  useEffect(() => {
    if (hideModalToday) {
      props.onCancel();
    }
  });
  return (
    <Draggable
      axis="y"
      handle=".draggable-handle"
      defaultPosition={position}
      onStop={() => {
        // 드래그가 끝날 때 실행됨
        props.onCancel();
      }}
    >
      <Cont>
        {choiceData && (
          <>
            <Header className="draggable-handle">
              <StyledHandleIcon className="StyledHandleIcon" />
              <Title>{monthText} </Title>
              <Description>
                {toCurrentLocale(props.eventVoteData.title)}
              </Description>
            </Header>

            <ChoiceCont>
              <Choices>
                <ChoicesBlock>
                  <ChoiceComp active={false}>
                    <SelectBoxChoice active={false}>
                      <ChoiceRect active={false}>
                        <ChoiceImage src={choiceData[0]?.content.image} />
                        {choiceBool && (
                          <img
                            src={`/asset/image/choice-img.png`}
                            className={`choice-img`}
                          />
                        )}

                        <img
                          src={`/asset/image/vote-a.png`}
                          className={`choice-img-a`}
                        />
                      </ChoiceRect>

                      <ChoiceTitleCont>
                        <ChoiceTitle colorWhenActive={`#000`} active={false}>
                          {choiceArray[0].name}
                        </ChoiceTitle>
                      </ChoiceTitleCont>
                    </SelectBoxChoice>
                  </ChoiceComp>
                </ChoicesBlock>
                <ChoicesGap></ChoicesGap>
                <ChoicesBlock>
                  <ChoiceComp active={true}>
                    <SelectBoxChoice active={false}>
                      <ChoiceRect active={false}>
                        <ChoiceImage src={choiceData[1].content.image} />
                        {!choiceBool && (
                          <img
                            src={`/asset/image/choice-img.png`}
                            className={`choice-img`}
                          />
                        )}
                        <img
                          src={`/asset/image/vote-b.png`}
                          className={`choice-img-b`}
                        />
                      </ChoiceRect>

                      <ChoiceTitleCont>
                        <ChoiceTitle colorWhenActive={`#000`} active={false}>
                          {choiceArray[1].name}
                        </ChoiceTitle>
                      </ChoiceTitleCont>
                    </SelectBoxChoice>
                  </ChoiceComp>
                </ChoicesBlock>
              </Choices>
            </ChoiceCont>

            <ScoreCont>
              <ScoreLeft
                per={
                  choiceArray[0]?.scale > 0
                    ? 100 - Math.round((1 - choiceArray[0]?.scale) * 100)
                    : 0
                }
              >
                <span className={``}>
                  {choiceData[0]?.voteCount}
                  {t("componentMainVote:vote")}
                </span>
              </ScoreLeft>
              <ScoreRight
                per={
                  choiceArray[1]?.scale > 0
                    ? 100 - Math.round((1 - choiceArray[1]?.scale) * 100)
                    : 0
                }
              >
                <span className={``}>
                  {choiceData[1]?.voteCount}
                  {t("componentMainVote:vote")}
                </span>
              </ScoreRight>
            </ScoreCont>

            <TextDescription>
              <div>
                &nbsp; {t("componentMainVote:voteDesc")}&nbsp;&nbsp;
                <img
                  src={`/asset/image/Vector.png`}
                  className={`img-row`}
                />{" "}
                &nbsp;+{props.eventRewardingTicketCount}&nbsp;
                {t("componentMainVote:voteDescTail")}
              </div>
            </TextDescription>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <input
                type="checkbox"
                id="check"
                checked={hideModalToday}
                onChange={handleHideTodayChange}
              />
              <label htmlFor="check">{t("componentMainVote:popupModal")}</label>
            </div>
            <Buttons
              onCancel={props.onCancel}
              onVote={props.onGoToEventPageButton}
              goToHomeText={t("modalButtonHome")}
              goToVoteText={t("modalButtonVote")}
            />
          </>
        )}
      </Cont>
    </Draggable>
  );
};

export default MainVote;

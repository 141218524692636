import React, { useEffect, useState } from "react";
import {
  BtnBox,
  BtnBoxWrap,
  BtnCircle,
  BtnCirclePan,
  BtnCircleWrap,
  BtnHearCircle,
  BtnRefresh,
  InnerWrap,
  ListWrap,
} from "./dom-styled";
import { ReactComponent as IconCenter } from "./svg/icon_center.svg";
import { ReactComponent as IconQuestion } from "./svg/icon_question.svg";
import { ReactComponent as IconMapAct } from "./svg/icon_map_act.svg";
import { ReactComponent as IconMap } from "./svg/icon_map.svg";
import { ReactComponent as IconMail } from "./svg/icon_mail.svg";
import { ReactComponent as IconRefresh } from "./svg/icon_ref.svg";
import { ReactComponent as IconPan } from "./svg/icon_pan.svg";
import { ReactComponent as IconHeart } from "./svg/icon_heart.svg";
import FanUpRead from "./fanup-read";
import {
  FanUpAttractionPayload,
  FanUpAttractionType,
} from "../../data-types/attraction";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AttractionReadParams,
  useReadAttractions,
} from "../../services/fanup-map/fanup-attraction-repository";
import {
  findAddressList,
  keywordAttractionList,
} from "../../services/fanup-map/fanup-openapi-repository";
import L, { Map as LeafletMap, marker } from "leaflet";
import IconMarker from "./svg/icon_marker_default.svg";
import IconMarkerSelected from "./svg/icon_marker_selected.svg";
import IconPickMarker from "./svg/icon_pick.svg";
import IconPickMarkerSelected from "./svg/icon_pick_selected.svg";
import useGeoLocation, { UserLocationType } from "../../utils/user-geoLocation";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { ReactComponent as IconSearch } from "./svg/icon_search.svg";
import FullNavigationGoBackBottomTabLayout from "../../components/full-navigation-bottom-tab-layout";
import useAuth from "../../services/auth";
import { Auth } from "../../utils/auth";
import MessageModal, { MessageModalHook } from "../../modals/message-modal";
import { map } from "lodash";
const defaultMarker = new L.Icon({
  iconUrl: IconMarker,
  iconSize: [40, 40],
});
const selectedMarker = new L.Icon({
  iconUrl: IconMarkerSelected,
  iconSize: [40, 52],
});
const pickDefaultMarker = new L.Icon({
  iconUrl: IconPickMarker,
  iconSize: [40, 40],
});
const pickSelectedMarker = new L.Icon({
  iconUrl: IconPickMarkerSelected,
  iconSize: [40, 52],
});
let _isLoadedMap: boolean = false;
let _keyword: string = "";
let _userLocation: UserLocationType;
let _setKeyword: React.Dispatch<React.SetStateAction<string>>;
let _setAtMarkers: React.Dispatch<
  React.SetStateAction<FanUpAttractionPayload[]>
>;
let _map: LeafletMap;
let _listType: string = "";
let _modifyPic: FanUpAttractionPayload | undefined;
let _auth: Auth;
let _center: { lat: number | undefined; lng: number | undefined } = {
  lat: undefined,
  lng: undefined,
};
let _useReadAttractions: (
  params: AttractionReadParams
) => Promise<FanUpAttractionPayload[]>;
let _setModifyPic: React.Dispatch<
  React.SetStateAction<FanUpAttractionPayload | undefined>
>;
let _setShowModal: React.Dispatch<
  React.SetStateAction<MessageModalHook | undefined>
>;
const _searchLocation = () => {
  if (_keyword.length > 1) {
    findAddressList(_keyword).then((result) => {
      if (result.length > 0) {
        if (_map) {
          if (result[0].lat !== undefined && result[0].lon !== undefined) {
            _center.lat = result[0].lat;
            _center.lng = result[0].lon;
          }
          _map.setView([result[0].lat, result[0].lon]);
          _map.setZoom(16);
        }
      } else {
      }
    });
  }
};
/**
 * 검색 이벤트
 */
const _searchAttraction = (userId?: string) => {
  if (_center.lat === undefined || _center.lng === undefined) {
    _center.lat = _userLocation.lat;
    _center.lng = _userLocation.lng;
  }

  if (_listType === "fanUp" || _listType === "myPic") {
    _useReadAttractions({
      lat: _center.lat,
      lng: _center.lng,
      keyword: _keyword,
      picks: _listType === "myPic" && userId ? userId : "",
    }).then((result) => {
      if (result.length > 0) {
        _setModifyPic(undefined);
        _setAtMarkers(result);

        _map.flyTo([result[0].location[1], result[0].location[0]]);
      } else {
        _searchLocation();
        _searchAttractionNoKeyword();
      }
    });
  } else {
    _searchLocation();
    _searchAttractionNoKeyword();
  }
};
const _searchAttractionNoKeyword = (userId?: string) => {
  if (_listType === "fanUp" || _listType === "myPic") {
    _useReadAttractions({
      lat: _center.lat,
      lng: _center.lng,
      keyword: "",
      picks: _listType === "myPic" && userId ? userId : "",
    }).then((result) => {
      _setModifyPic(undefined);
      _setAtMarkers(result);
    });
  }
};

/**
 * 지도뷰
 * @constructor
 */
const MapWrap: React.FC<any> = (props) => {
  _userLocation = useGeoLocation();
  const [map, setMap] = useState<LeafletMap | undefined>(undefined);
  const [atMarkers, setAtMarkers] = useState<FanUpAttractionPayload[]>([]);
  let defaultIcon: L.Icon;
  let selectIcon: L.Icon;
  _setAtMarkers = setAtMarkers;

  useEffect(() => {
    if (!map) return;
    _map = map;
    if (!_userLocation.isLoaded) return;

    _center.lat = _userLocation.lat;
    _center.lng = _userLocation.lng;

    if (map && !_isLoadedMap && _userLocation.isLoaded) {
      _isLoadedMap = true;
      _center.lat = _userLocation.lat;
      _center.lng = _userLocation.lng;
      map.setView({ lat: _userLocation.lat, lng: _userLocation.lng });
    }
  }, [map]);
  atMarkers.filter((item) => item.id == _modifyPic?.id).length == 0 &&
    _setModifyPic(undefined);
  if (_listType === "myPic") {
    defaultIcon = pickDefaultMarker;
    selectIcon = pickSelectedMarker;
  } else {
    defaultIcon = defaultMarker;
    selectIcon = selectedMarker;
  }
  return (
    <MapContainer
      style={{ width: "100%", height: "100vh" }}
      center={[_userLocation.lat, _userLocation.lng]}
      zoom={15}
      zoomControl={false}
      whenCreated={(map) => {
        map.on("dragend", () => {
          _center.lat = map.getCenter().lat;
          _center.lng = map.getCenter().lng;
          _searchAttractionNoKeyword(_auth.isLoggedIn ? _auth.userId : "");
        });
        setMap(map);
      }}
    >
      <TileLayer
        attribution={
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {["fanUp", "myPic"].indexOf(_listType) > -1 && atMarkers.length > 0
        ? atMarkers.map((item, idx) => (
            <Marker
              position={[item.location[1]!, item.location[0]!]}
              icon={
                _modifyPic && _modifyPic.id == item.id
                  ? selectIcon
                  : defaultIcon
              }
              eventHandlers={{
                click: (e) => {
                  if (_modifyPic && _modifyPic.id == item.id) {
                    _setModifyPic(undefined);
                  } else {
                    _map.flyTo([item.location[1], item.location[0]]);
                    _setModifyPic(item);
                  }
                },
              }}
            ></Marker>
          ))
        : null}
    </MapContainer>
  );
};
/**
 * 상단 검색바
 * @constructor
 */
interface InputBoxParam {
  value?: string;
}
const InputBox: React.FC<InputBoxParam> = (props) => {
  const { t } = useTranslation("pageFanUpMap");

  return (
    <div
      id={"srchBox"}
      style={{
        paddingTop: "28px",
        position: "absolute",
        width: "100%",
        zIndex: "1000",
      }}
    >
      <input
        type={"text"}
        style={{
          width: "calc(100% - 64px)",
          position: "relative",
          margin: "0 14px",
          height: "calc(48px - 32px)",
          border: "0",
          borderRadius: "6px",
          padding: "16px",
          boxShadow:
            "0px 4px 8px 0px rgba(108, 118, 128, 0.16), 0px 2px 4px 0px rgba(108, 118, 128, 0.16)",
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            _keyword = e.currentTarget.value;
            _searchAttraction(_auth.isLoggedIn ? _auth.userId : "");
          } else {
            _keyword = e.currentTarget.value;
            _setKeyword(e.currentTarget.value);
          }
        }}
        placeholder={t("srchField")}
      />
      <button
        onClick={() => {
          _searchAttraction(_auth.isLoggedIn ? _auth.userId : "");
        }}
        style={{
          background: "transparent",
          border: 0,
          position: "absolute",
          right: "30px",
          top: "40px",
        }}
      >
        <IconSearch className="svgIcon" />
      </button>
    </div>
  );
};

const FanMapPage: React.FC<any> = (props) => {
  const [listType, setListType] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");
  const [modifyPic, setModifyPic] = useState<
    FanUpAttractionPayload | undefined
  >(undefined);

  const { t, i18n } = useTranslation("pageFanUpMap");
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const auth = useAuth();
  _useReadAttractions = useReadAttractions();
  _setKeyword = setKeyword;
  _setModifyPic = setModifyPic;
  _listType = listType;
  _modifyPic = modifyPic;
  _auth = auth;

  return (
    <FullNavigationGoBackBottomTabLayout title={"FanUP MAP"}>
      <InnerWrap className={"map-inner"}>
        <InputBox value={keyword} />
        <BtnCircleWrap>
          <BtnCircle
            onClick={() => {
              if (!_userLocation.isLoaded) {
                let appData = localStorage.getItem("recentlyLocation");
                if (appData !== null) {
                  let { lat, lng, isLoaded } = JSON.parse(appData);
                  _userLocation.isLoaded = isLoaded;
                  _userLocation.lat = lat;
                  _userLocation.lng = lng;
                }

                if (_userLocation.isLoaded) {
                  _center.lat = _userLocation.lat;
                  _center.lng = _userLocation.lng;
                  _map &&
                    _map.setView({
                      lat: _userLocation.lat,
                      lng: _userLocation.lng,
                    });
                } else {
                  _setShowModal({
                    messageContent: t("message.findingLocation"),
                  });
                }
              } else {
                _center.lat = _userLocation.lat;
                _center.lng = _userLocation.lng;
                _map &&
                  _map.setView({
                    lat: _userLocation.lat,
                    lng: _userLocation.lng,
                  });
              }
            }}
          >
            <IconCenter />
          </BtnCircle>
          <BtnCircle
            onClick={() => {
              if (currentLanguage == "zh") {
                navigate("/announcement/654ca6d066bfa55bedeb92c0");
              } else if (currentLanguage == "kr") {
                navigate("/announcement/653f13809b525067725d0916");
              } else {
                navigate("/announcement/654cb34866bfa55bedeb930c");
              }
            }}
          >
            <IconQuestion />
          </BtnCircle>
        </BtnCircleWrap>
        <BtnBoxWrap>
          <BtnBox
            active={listType === "fanUp"}
            id={"fanUp"}
            onClick={() => {
              setModifyPic(undefined);
              setListType(listType === "fanUp" ? "" : "fanUp");
            }}
          >
            {listType === "fanUp" ? (
              <IconMapAct style={{ display: "block", margin: "0 auto 4px" }} />
            ) : (
              <IconMap style={{ display: "block", margin: "0 auto 4px" }} />
            )}
            FanUP MAP
          </BtnBox>
          <BtnBox
            active={listType === "myPic"}
            id={"myPic"}
            onClick={() => {
              setModifyPic(undefined);
              setListType(listType === "myPic" ? "" : "myPic");
            }}
            style={{
              display: auth.isLoggedIn ? "block" : "none",
            }}
          >
            {listType === "myPic" ? (
              <IconMapAct style={{ display: "block", margin: "0 auto 4px" }} />
            ) : (
              <IconMap style={{ display: "block", margin: "0 auto 4px" }} />
            )}
            MY Pick
          </BtnBox>
        </BtnBoxWrap>
        <MapWrap />
        <ListWrap active={listType !== ""}>
          <BtnCircle
            style={{ position: "absolute", left: "18px" }}
            onClick={() => {
              navigate("/qna");
            }}
          >
            <IconMail />
          </BtnCircle>
          <BtnRefresh
            onClick={() => {
              if (!_center.lat || !_center.lng) {
                _center.lat = _map.getCenter().lat;
                _center.lng = _map.getCenter().lng;
              }
              _searchAttractionNoKeyword(_auth.isLoggedIn ? _auth.userId : "");
            }}
          >
            <IconRefresh
              style={{ position: "absolute", top: "10px", left: "10px" }}
            />
            {t("findThisLocation")}
          </BtnRefresh>
          {listType === "fanUp" ? (
            <BtnCirclePan
              style={{
                position: "absolute",
                right: "18px",
                display: auth.isLoggedIn ? "block" : "none",
                border: "2px solid #000",
              }}
              onClick={() =>
                navigate(listType === "fanUp" ? "/maps/search" : "/maps/likes")
              }
            >
              <IconPan />
            </BtnCirclePan>
          ) : (
            <BtnHearCircle
              onClick={() => navigate("/maps/picks")}
              style={{
                display: auth.isLoggedIn ? "block" : "none",
              }}
            >
              <IconHeart />
            </BtnHearCircle>
          )}
          {["myPic", "fanUp"].indexOf(listType) > -1 && _modifyPic
            ? FanUpRead({
                picData: _modifyPic,
                trnas: t,
                onClick: () => {
                  if (auth.isLoggedIn) {
                    navigate(`/maps/read/${_modifyPic?.id}`, {
                      state: { data: _modifyPic },
                    });
                  } else {
                    alert("Please Login");
                    navigate("/login");
                  }
                },
              })
            : ""}
        </ListWrap>
      </InnerWrap>
      <MessageModal
        onHook={(fn) => {
          _setShowModal = fn;
        }}
      />
    </FullNavigationGoBackBottomTabLayout>
  );
};
export default FanMapPage;

import useAuth from "../auth";
import { apiAddr } from "../../config";
import {
  FanUpAttractionPayload,
  FanUpAttractionReviewPayload,
} from "../../data-types/attraction";
import serializeAnnouncement from "../../from-server-serializers/announcement";

/**
 * 리뷰 등록
 */
export const useCreateAttractionReview = () => {
  const auth = useAuth();

  return async (review: FanUpAttractionReviewPayload): Promise<void> => {
    const result = await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/attraction-review`,
      data: review,
    });
  };
};
/**
 * 리뷰 수정
 */
export const useUpdateAttractionReview = () => {
  const auth = useAuth();

  return async (review: FanUpAttractionReviewPayload): Promise<void> => {
    const result = await auth.callAxios({
      method: "patch",
      url: `${apiAddr}/v1/attraction-review`,
      data: review,
    });
  };
};
export interface AttractionReviewReadParams {
  attractId: string;
}

/**
 * 관광지 목록 조회
 */
export const useReadAttractionReviews = () => {
  const auth = useAuth();
  return async (
    params: AttractionReviewReadParams
  ): Promise<FanUpAttractionReviewPayload[]> => {
    const result = await auth.callAxios({
      method: "get",
      url: `${apiAddr}/v1/attraction-review`,
      params: {
        attractId: params.attractId,
      },
    });
    if (Array.isArray(result.data) && result.data.length > 0) {
      return result.data;
    } else {
      return [];
    }
  };
};

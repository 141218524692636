import React from "react";
import styled from "styled-components";
import { LoadedLogic } from "../../../use-logic";
import { black, cg300, pointColor, primary } from "../../../../../styles";
import { ReactComponent as VoteIcon } from "./vote-icon.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Cont = styled.div`
  padding: 13px 16px 13px 16px;
`;

const Title = styled.span`
  display: block;
  font-size: 18px;
  line-height: 25px;
  color: ${black};
`;

const Description = styled.div`
  display: flex;
  margin-left: 2px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
`;

const DescriptionNormal = styled.span`
  font-weight: normal;
  color: ${cg300};
  white-space: pre;
`;

const DescriptionBold = styled.span`
  font-weight: bold;
  color: ${black};
`;

const DescriptionPrimaryBold = styled.span`
  font-weight: bold;
  color: ${pointColor};
  white-space: pre;
`;

const TheVoteIcon = styled(VoteIcon)`
  margin-left: 4px;
`;

interface Props {
  logic: LoadedLogic;
}

const Header: React.FC<Props> = (props) => {
  const { t } = useTranslation("pageIdolVotes");

  return (
    <Cont>
      <Title>CHART</Title>
      <Description>
        <DescriptionBold>1</DescriptionBold>
        <DescriptionNormal>{t("day")} </DescriptionNormal>
        <DescriptionPrimaryBold>
          {props.logic.maxIdolVoteCountPerDay}
          {t("tickets")}{" "}
        </DescriptionPrimaryBold>
        <DescriptionNormal>
          {t("votable")} |{" "}
          {i18next.language === "kr" ? (
            <>
              <DescriptionBold>1</DescriptionBold> 회 당
            </>
          ) : (
            <>
              Use <DescriptionBold>1</DescriptionBold> tickets for a vote
            </>
          )}
        </DescriptionNormal>
        <TheVoteIcon />
      </Description>
    </Cont>
  );
};

export default Header;

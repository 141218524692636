import Magazine from "../data-types/magazine";
import axios from 'axios';
import {fanupMediaAddr} from "../config";
import serializeMagazine from '../from-server-serializers/magazine';

const useReadMagazines = () => {
  return async (): Promise<Magazine[]> => {
    const axiosResult = await axios({
      method: 'get',
      url: `${fanupMediaAddr}/wp-json/wp/v2/posts`,
    });

    return await Promise.all(axiosResult.data.map(serializeMagazine));
  };
};

export default useReadMagazines;

import React from 'react';
import styled from 'styled-components';
import {black, cg40} from "../../styles";

interface RateProps {
  className?: string | undefined;
  color: string;
  scale: number;
}

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Text = styled.span`
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: ${black};
`;

const BarCont = styled.div`
  margin-top: 3px;
  height: 16px;
  border-radius: 8px;
  background-color: ${cg40};
  display: flex;
`;

const Bar = styled.div<{ color: string; scale: number }>`
  height: 16px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  width: ${({ scale }) => scale * 100}%;
`;

const Rate: React.FC<RateProps> = (props) => {
  return (
    <Cont>
      <Text>
        {Math.round(props.scale * 100)}%
      </Text>
      <BarCont>
        <Bar color={props.color} scale={props.scale}/>
      </BarCont>
    </Cont>
  );
};

export default Rate;

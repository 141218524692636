import { useEffect, useState } from "react";
import DemandingVote from "../../../../data-types/demanding-vote";
import useReadRandomActiveDemandingVote from "../../../../services/read-random-active-demanding-vote";
import useHandleAppError from "../../../../services/handle-app-error";
import { strict as assert } from "assert";
import useToVote from "../../../../services/to-vote";
import useToDemandingVotesPage from "../../../../services/to-demanding-votes-page";
import useReadRewardingTicketCount from "../../../../services/read-rewarding-ticket-count";
import useReadEventRewardingTicketCount from "../../../../services/read-event-rewarding-ticket-count";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  vote: DemandingVote;
  toVote: () => void;
  toDemandingVotes: () => void;
  rewardingTicketCount: number;
  eventRewardingTicketCount: number;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  vote: DemandingVote;
  rewardingTicketCount: number;
  eventRewardingTicketCount: number;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "FAILED" });

  const handleAppError = useHandleAppError();
  const readRandomActiveDemandingVote = useReadRandomActiveDemandingVote();
  const toVoteService = useToVote();
  const toDemandingVotes = useToDemandingVotesPage();
  const readRewardingTicketCount = useReadRewardingTicketCount();
  const readEventRewardingTicketCount = useReadEventRewardingTicketCount();

  const tryInit = async () => {
    const vote = await readRandomActiveDemandingVote();
    const rewardingTicketCount = await readRewardingTicketCount();
    const eventRewardingTicketCount = await readEventRewardingTicketCount();

    setState({
      status: "LOADED",
      vote,
      rewardingTicketCount,
      eventRewardingTicketCount,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const toVote = () => {
    toVoteService(state.vote);
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      vote: state.vote,
      toVote,
      toDemandingVotes,
      rewardingTicketCount: state.rewardingTicketCount,
      eventRewardingTicketCount: state.eventRewardingTicketCount,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

import React from "react";
import { LoadedLogic } from "../../use-logic";
import styled from "styled-components";
import { black, cg300, mainColor, pointColor, shadow2 } from "../../../../styles";
import useToCurrentLocale from "../../../../services/to-current-locale";
import ProgressBar from "../../../../components/progress-bar";
import { ReactComponent as VoteIcon } from "./vote-icon.svg";
import leadingCandidateScale from "../../utils/leading-candidate-scale";

const Cont = styled.div`
  padding: 16px;
`;

const Title = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: ${black};
`;

const IdolCard = styled.div`
  margin-top: 12px;
  box-shadow: ${shadow2};
  height: 100px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const Ranking = styled.span`
  margin-left: 12px;
  font-size: 48px;
  line-height: 60px;
  color: ${mainColor};
  font-weight: bold;
`;

const IdolThumbnail = styled.img`
  margin-left: 14px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

const IdolInfo = styled.div`
  margin-left: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  flex: 1;
  align-self: start;
`;

const IdolInfoTitle = styled.span`
  display: block;
  font-size: 18px;
  line-height: 23px;
  color: ${black};
`;

const IdolInfoDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: ${cg300};
`;

const IdolInfoVoteCount = styled.span`
  display: block;
  margin-top: 6px;
  color: ${black};
  font-size: 12px;
  line-height: 15px;
`;

const TheProgressBar = styled(ProgressBar)`
  margin-top: 4px;
`;

const TheVoteIcon = styled(VoteIcon)`
  margin-left: 18px;
  margin-top: 31px;
  align-self: start;
  margin-right: 20px;
  cursor: pointer;
`;

interface Props {
  logic: LoadedLogic;
}

const LeadingCandidateFragment: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  const currentVote = props.logic.idolVotes[props.logic.currentTabIndex];
  const firstCandidate = props.logic.idolVoteCandidates[currentVote.id][0];

  if (firstCandidate == null) {
    return null;
  }

  const firstCandidateIdol = props.logic.idols[firstCandidate.idol];
  const firstCandidateIdolAssociation =
    firstCandidateIdol.association != null
      ? props.logic.associations[firstCandidateIdol.association]
      : firstCandidateIdol.association;

  return (
    <Cont>
      <Title>Real-Time Chart</Title>
      <IdolCard>
        <Ranking>1</Ranking>
        <IdolThumbnail src={firstCandidateIdol.thumbnailUrl} />
        <IdolInfo>
          <IdolInfoTitle>
            {toCurrentLocale(firstCandidateIdol.name)}
          </IdolInfoTitle>
          <IdolInfoDescription>
            {firstCandidateIdolAssociation != null
              ? toCurrentLocale(firstCandidateIdolAssociation.name)
              : null}
          </IdolInfoDescription>
          <IdolInfoVoteCount>{firstCandidate.voteCount}</IdolInfoVoteCount>
          <TheProgressBar
            scale={firstCandidate.voteCount !== 0 ? leadingCandidateScale : 0}
          />
        </IdolInfo>
        <TheVoteIcon
          onClick={() =>
            props.logic.openVoteModal({
              vote: currentVote,
              idol: firstCandidateIdol,
            })
          }
        />
      </IdolCard>
    </Cont>
  );
};

export default LeadingCandidateFragment;

import React from "react";
import Modal from "react-modal";
import ModalBase from "../../components/modal-base";
import useToCurrentLocale from "../../services/to-current-locale";
import { maxTabletSizeInPx } from "../../styles";

interface IProps {
  isVisible: boolean;
  onRequestClose: () => void;
  onLeft: () => void;
  onRight: () => void;
  leftText: string;
  rightText: string;
  disableRight?: boolean | undefined;
  disableLeft?: boolean | undefined;
  content: JSX.Element;
}

const ModalBaseWrapper: React.FC<IProps> = (props: IProps) => {
  //const toCurrentLocale = useToCurrentLocale();
  return (
    <Modal
      isOpen={props.isVisible}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          padding: 0,
          margin: 0,
          textAlign: "center",
          alignSelf: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          inset: "150px -5px",
          margin: "auto",
          textAlign: "center",
          alignSelf: "center",
          borderRadius: 0,
          background: "transparent",
          border: 0,
          minWidth: "330px",
          height: "500px",
          maxWidth: maxTabletSizeInPx,
          whiteSpace: "pre-line",
        },
      }}
    >
      <ModalBase
        onLeft={props.onLeft}
        onRight={props.onRight}
        rightText={props.rightText}
        leftText={props.leftText}
        disableRight={props.disableRight}
        disableLeft={props.disableLeft}
        content={props.content}
      />
    </Modal>
  );
};

export default ModalBaseWrapper;

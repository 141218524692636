import DemandingVote from "../data-types/demanding-vote";
import DemandingVoteVotableStatus from "../data-types/demanding-vote-votable-status";
import DemandingVoteLog from "../data-types/demanding-vote-log";
import isDateInPeriod from "./is-date-in-period";

const demandingVoteVotableStatus = (demandingVote: DemandingVote, log: DemandingVoteLog | undefined): DemandingVoteVotableStatus => {
  const now = new Date();

  if (log != null) {
    return 'PARTICIPATED';
  }

  if (!isDateInPeriod(now, demandingVote.period)) {
    return 'ENDED';
  }

  return 'CAN_PARTICIPATE';
};

export default demandingVoteVotableStatus;


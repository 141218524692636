import { useNavigate } from 'react-router-dom';

const useToCreateAccount = () => {
  const nav = useNavigate();

  return () => {
    nav(`/create-account-1`);
  };
};

export default useToCreateAccount;

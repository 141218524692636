import React from 'react'
import styled from 'styled-components';
import Idol from "../../../../../../data-types/idol";
import {LoadedLogic} from "../../../../use-logic";
import {black, cg100} from "../../../../../../styles";
import {ReactComponent as VoteIcon} from './vote-icon.svg';
import IdolVote from "../../../../../../data-types/idol-vote";

const Cont = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${cg100};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Text = styled.span`
  margin-top: 1px;
  font-size: 14px;
  line-height: 18px;
  color: ${black};
`;

interface Props {
  idol: Idol;
  vote: IdolVote;
  logic: LoadedLogic;
}

const VoteButton: React.FC<Props> = (props) => {
  const onClick = () => {
    props.logic.openVoteModal({idol: props.idol, vote: props.vote});
  };

  return (
    <Cont onClick={onClick}>
      <VoteIcon/>
      <Text>Vote</Text>
    </Cont>
  );
};

export default VoteButton;

import NavigationWithGoBackBottomTabLayout from "../../../components/navigation-with-go-back-bottom-tab-layout";
import {
  AttractionMap,
  AttractionMapWrap,
  CheckBtn,
  InnerWrap,
  InputBoxItem,
  InputBoxWrap,
  OuterWrap,
  RegistryBtn,
  SearchBox,
  Title,
} from "./dom-styled";
import React, { useState } from "react";
import IconMarkerSelected from "../svg/icon_marker_selected.svg";
import {
  findAddressList,
  keywordAttractionList,
} from "../../../services/fanup-map/fanup-openapi-repository";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { NavigateFunction, useNavigate } from "react-router-dom";
import useGeoLocation, {
  UserLocationType,
} from "../../../utils/user-geoLocation";
import {
  AddressModel,
  FanUpAttractionPayload,
  FanUpAttractionType,
  GovAttractionDataType,
} from "../../../data-types/attraction";
import { TFunction, useTranslation } from "react-i18next";
import { ReactComponent as IconMapAct } from "../svg/icon_map_act.svg";
import UserGeoLocation from "../../../utils/user-geoLocation";
import MessageModal, { MessageModalHook } from "../../../modals/message-modal";
import { at } from "lodash";

let _setMapInfo: React.Dispatch<React.SetStateAction<AddressModel | undefined>>;
let _setDataList: React.Dispatch<React.SetStateAction<GovAttractionDataType[]>>;
let _setModifyFlag: React.Dispatch<React.SetStateAction<string>>;
let _navigate: NavigateFunction;
let _isLoading: boolean = false;
let _userLocation: UserLocationType;
let _setShowModal: React.Dispatch<
  React.SetStateAction<MessageModalHook | undefined>
>;
let _trans: TFunction<"pageFanUpMap", undefined>;
let _setCenter: React.Dispatch<React.SetStateAction<number[]>>;
let _map: L.Map;
interface MapViewProps {
  lat: number;
  lng: number;
  hasMarker: boolean;
  markerInfo?: {
    title: string;
    address: string;
    lat: number;
    lng: number;
  };
}

const _createAttraction = () => {};
/**
 * 맵뷰
 * @param props
 * @constructor
 */
const MapView: React.FC<MapViewProps> = (props) => {
  const mapIcon = new L.Icon({
    iconUrl: IconMarkerSelected,
    iconSize: [25, 40],
  });

  return (
    <AttractionMapWrap>
      <AttractionMap>
        <MapContainer
          style={{ width: "100%", height: "calc(100vh - 420px)" }}
          center={[props.lat, props.lng]}
          zoom={16}
          whenCreated={(map) => {
            console.log("crated!!");
            _map = map;
          }}
          zoomControl={false}
        >
          <TileLayer
            attribution={
              '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props.hasMarker ? (
            <Marker
              position={[props.markerInfo!.lat, props.markerInfo!.lng]}
              icon={mapIcon}
            >
              <Popup>
                {props.markerInfo!.title}
                <br />
                {props.markerInfo!.address}
              </Popup>
            </Marker>
          ) : null}
        </MapContainer>
      </AttractionMap>
    </AttractionMapWrap>
  );
};
/**
 * 등록페이지로 이동
 * @param item
 */
const _movePage = (item: FanUpAttractionType) => {
  let isValid: boolean = true;
  let field: string = "";
  if (!item.longitude || !item.longitude) {
    field = "locationInfo";
    isValid = false;
  }
  if (!item.address) {
    field = "address";
    isValid = false;
  }
  if (!item.name) {
    field = "attractionName";
    isValid = false;
  }

  if (!isValid) {
    _setShowModal({
      messageContent: (
        <div>
          {_trans(field)} {_trans("message.emptyField")}
        </div>
      ),
    });
    return false;
  }
  _navigate("/maps/add", { state: { data: item } });
};
/**
 * 위치 확인
 * @param addr
 */
const _checkLocation = (addr: string) => {
  if (!addr) {
    _setShowModal({
      messageContent: (
        <div>
          {_trans("address")} {_trans("message.emptyField")}
        </div>
      ),
    });
    return false;
  }
  findAddressList(addr).then((result) => {
    if (result.length > 0) {
      console.log(_map);
      if (_map) {
        _map.setView([result[0].lat, result[0].lon]);
      }
      _setMapInfo(result[0]);
    }
  });
};

/**
 * 검색박스
 * @constructor
 */
interface InputBoxProperties {
  isClearValue?: boolean | undefined;
  onChange?: (value?: string) => void;
}

/**
 * 상호입력 박스
 * @param props
 * @constructor
 */
const AttractionName: React.FC<InputBoxProperties> = (props) => {
  const { t } = useTranslation("pageFanUpMap");
  return (
    <InputBoxWrap id={"srchBox"}>
      <InputBoxItem
        type={"text"}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value);
        }}
        placeholder={t("message.name")}
      />
    </InputBoxWrap>
  );
};
/**
 * 주소 입력 박스
 * @param props
 * @constructor
 */
const AttractionAddress: React.FC<InputBoxProperties> = (props) => {
  const { t } = useTranslation("pageFanUpMap");
  return (
    <InputBoxWrap id={"srchBox"}>
      <InputBoxItem
        type={"text"}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value);
        }}
        placeholder={t("message.address")}
      />
    </InputBoxWrap>
  );
};

/**
 * 직접등록 페이지 메인
 * @constructor
 */
const FanUpDirectAddPage: React.PropsWithChildren<any> = () => {
  const [modifyFlag, setModifyFlag] = useState("");
  const [attractData, setAttractData] = useState<FanUpAttractionType>({});
  const [mapInfo, setMapInfo] = useState<AddressModel | undefined>(undefined);
  const { t } = useTranslation("pageFanUpMap");
  const userLocation = UserGeoLocation();
  _userLocation = useGeoLocation();
  _navigate = useNavigate();
  _setMapInfo = setMapInfo;
  _setModifyFlag = setModifyFlag;
  _trans = t;

  const isShowMarker: boolean = typeof mapInfo !== "undefined";
  return (
    <NavigationWithGoBackBottomTabLayout
      title={"FanUP MAP"}
      disabledSafePadding={true}
    >
      <InnerWrap id={"content"}>
        <SearchBox>
          <Title>{t("attractionName")}</Title>
          <AttractionName
            onChange={(value) => {
              attractData.name = value;
              setAttractData(attractData);
            }}
          />
        </SearchBox>
        <OuterWrap>
          <SearchBox>
            <Title>
              {t("address")} {t("enterDirectly")}
            </Title>
            <AttractionAddress
              onChange={(value) => {
                attractData.address = value;
                setAttractData(attractData);
              }}
            />
            <CheckBtn
              onClick={() => {
                _checkLocation(attractData.address || "");
              }}
            >
              {t("button.checkLocation")}
            </CheckBtn>
          </SearchBox>
        </OuterWrap>
        <RegistryBtn
          onClick={(e) => {
            if (isShowMarker) {
              attractData.latitude = mapInfo!.lat;
              attractData.longitude = mapInfo!.lon;
            }
            _movePage(attractData);
          }}
        >
          <IconMapAct
            style={{ position: "relative", top: "5px", right: "5px" }}
          />
          {t("button.enterLocation")}{" "}
        </RegistryBtn>
        <MapView
          lat={userLocation.lat}
          lng={userLocation.lng}
          hasMarker={isShowMarker}
          markerInfo={
            isShowMarker
              ? {
                  title: mapInfo!.name,
                  address: mapInfo!.display_name,
                  lat: mapInfo!.lat,
                  lng: mapInfo!.lon,
                }
              : undefined
          }
        ></MapView>
      </InnerWrap>
      <MessageModal
        onHook={(fn) => {
          _setShowModal = fn;
        }}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default FanUpDirectAddPage;

import React from "react";
import styled from "styled-components";
import { black, coolGray, mainEventColor } from "../styles";

const enabledStyle = `
  color: white;
  background: ${mainEventColor};
`;

const disabledStyle = `
  color: ${black};
  background-color: ${coolGray};
`;

const StyledButton = styled.button<{ disabled: boolean }>`
  /* button / A */

  height: 48px;

  ${(props) => (props.disabled ? disabledStyle : enabledStyle)}
  border: none;
  border-radius: 4px;
`;

type ButtonProps = {
  className?: string | undefined;
  value?: string | undefined;
  onClick?: (() => void) | undefined;
  disabled?: boolean | undefined;
};

const Button = ({
  className,
  value,
  onClick,
  disabled = false,
}: ButtonProps) => {
  return (
    <StyledButton onClick={onClick} className={className} disabled={disabled}>
      {value}
    </StyledButton>
  );
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
export type { ButtonProps };

import React, { useState } from "react";
import styled from "styled-components";
import LoginBlock from "./LoginBlock";
import SNSBlock from "./SNSBlock";
import LoginFooter from "./LoginFooter";
import { maxTabletSizeInPx } from "../../styles/size";
import useLogic from "./use-logic";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FanupLogoV2 from "./lib/fanup-logo-v3";
import { pointColor } from "../../styles";
import NavigationBar from "../../components/navigation-bar";
import SelectLanguageModalApp from "../../modals/select-language-app";

const Cont = styled.div`
  display: flex;
  max-width: ${maxTabletSizeInPx}px;
  flex-direction: column;
  margin: auto;
  padding: 0 18px 0 18px;
`;
const LogoCont = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
`;
const Gap130 = styled.div`
  margin-top: 10px;
`;
const Gap56 = styled.div`
  margin-top: 36px;
`;
const Gap16 = styled.div`
  margin-top: 16px;
`;
const StyledUnderLineFaq = styled.div`
  display: flex;
  justify-content: center;
  padding: 28px 0px 20px 0px;
  font-size: 14px;
  font-weight: bold;
  color: ${pointColor};
`;

const LoginPage = () => {
  const { t } = useTranslation("pageLogin");
  const logic = useLogic();

  const navigator = useNavigate();

  const handleClick = () => {
    navigator(`/qna`);
  };

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onOpenModal = () => {
    setIsModalVisible(true);
  };

  const onRequestModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Cont>
      <NavigationBar
        enableGoBack={true}
        text={""}
        onLanguagePress={onOpenModal}
      />
      <SelectLanguageModalApp
        isVisible={isModalVisible}
        onRequestClose={onRequestModalClose}
      />
      <Gap130 />
      <LogoCont>
        <FanupLogoV2 />
      </LogoCont>
      <Gap56 />
      <LoginBlock logic={logic} />
      <SNSBlock />
      <Gap16 />
      <LoginFooter logic={logic} />

      <StyledUnderLineFaq
        onClick={() => {
          handleClick();
        }}
      >
        {t("pageLogin:joinFaqWord")}
      </StyledUnderLineFaq>
    </Cont>
  );
};

export default LoginPage;

import React from "react";
import Modal from "react-modal";
import VoteEventComp from "../../components/vote-event";

import { maxTabletSizeInPx } from "../../styles";
import { useTranslation } from "react-i18next";

interface Props {
  isVisible: boolean;
  gotoJoin: () => void;
  onRequestClose: () => void;
}

const VoteEventModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation("modalVoteIdol");

  return (
    <Modal
      isOpen={props.isVisible}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          padding: 0,
          margin: 0,
          textAlign: "center",
          alignSelf: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          inset: "150px -5px",
          margin: "auto",
          textAlign: "center",
          alignSelf: "center",
          borderRadius: 0,
          background: "transparent",
          border: 0,
          minWidth: "330px",
          height: "500px",
          maxWidth: maxTabletSizeInPx,
        },
      }}
    >
      <VoteEventComp
        gotoJoin={props.gotoJoin}
        onCancel={props.onRequestClose}
      />
    </Modal>
  );
};

export default VoteEventModal;

import React from "react";
import * as CompoundLayout from "../../../../data-types/compound-layout";
import { LoadedLogic } from "./use-logic";
import styled from "styled-components";
import { black, cg100, shadow2 } from "../../../../styles";

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
  padding-top: 18px;
  padding-bottom: 22px;
`;

const Title = styled.span`
  display: block;
  font-family: "BalooBhaijaan";
  margin-left: 16px;
  margin-right: 16px;
  font-size: 18px;
  color: ${black};
  line-height: 25px;
`;

const Body = styled.div`
  margin-top: 8px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  overflow-x: auto;
`;

const Item = styled.div`
  min-width: 240px;
  border-radius: 8px;
  border: 1px solid ${cg100};
  overflow: hidden;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  box-shadow: ${shadow2};
`;

const ItemImage = styled.img`
  height: 138px;
  object-fit: cover;
`;

const ItemTitle = styled.span`
  display: block;
  color: ${black};
  font-size: 14px;
  padding: 14px 12px 14px 12px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface Props {
  component: CompoundLayout.MagazineComponent;
  logic: LoadedLogic;
}

const MagazineComponent: React.FC<Props> = (props) => {
  return (
    <Cont>
      <Title>MAGAZINE</Title>
      <Body>
        {props.logic.magazines.map((magazine) => {
          return (
            <Item key={magazine.id} onClick={magazine.onClick}>
              <ItemImage src={magazine.thumbnail} />

              <ItemTitle>
                {magazine.title
                  .replaceAll("&#8216;", "'")
                  .replaceAll("&#8217;", "'")
                  .replaceAll("&#8220;", '"')
                  .replaceAll("&#8221;", '"')
                  .replaceAll("<strong>", "")}
              </ItemTitle>
            </Item>
          );
        })}
      </Body>
    </Cont>
  );
};

export default MagazineComponent;
export { default as useLogic } from "./use-logic";

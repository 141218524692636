import axios, { AxiosResponse } from "axios";
import { apiAddr } from "../config";
import { AuthData } from "../utils/auth";
import { createServerError, ServerError } from "../utils/server-error";
import useLoginWithAuthData from "./login-with-auth-data";
import checkEmail from "../errors/check-email";
import checkPassword from "../errors/check-password";
import UserEmailNotVerifiedError from "../errors/user-email-not-verified-error";

const LoginServerError = createServerError();
type LoginServerError = ServerError;

interface LoginParams {
  email: string;
  password: string;
}

const useLogin = () => {
  const loginWithAuthData = useLoginWithAuthData();

  const validateInput = (params: LoginParams) => {
    checkEmail(params.email);
    checkPassword(params.password);
  };

  return async (params: LoginParams) => {
    validateInput(params);

    let axiosResult: AxiosResponse;

    const getAuthDataFromAxiosResult = () => {
      const authData = axiosResult.data as AuthData;
      return authData;
    };

    const checkEmailVerified = () => {
      //이메일 인증 제거 2022-12-07
      // if (axiosResult.data?.user?.isEmailVerified !== true) {
      //   throw new UserEmailNotVerifiedError(getAuthDataFromAxiosResult());
      // }
    };

    try {
      axiosResult = await axios({
        method: "post",
        url: `${apiAddr}/v1/auth/login`,
        data: {
          email: params.email,
          password: params.password,
        },
      });

      checkEmailVerified();
    } catch (error) {
      if (error instanceof UserEmailNotVerifiedError) {
        throw error;
      }

      throw new LoginServerError(error);
    }

    await loginWithAuthData(getAuthDataFromAxiosResult());
  };
};

export default useLogin;
export { LoginServerError };

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../components/navigation-bar";
import ProgressText from "../../components/progress-text";
import NoticeText from "../../components/notice-text";
import Button from "../../components/button";
import "react-datepicker/dist/react-datepicker.css";
import { cg60, maxTabletSizeInPx } from "../../styles";
import useToNextCreateAccountPage from "../../services/to-next-create-account-page";
import useHandleAppError from "../../services/handle-app-error";
import { CreateAccountServerError } from "../../services/create-account";
import TextInput from "../../components/text-input";

const Gap72 = styled.div`
  padding-top: 72px;
`;
const Gap48 = styled.div`
  padding-top: 48px;
`;
const Gap16 = styled.div`
  padding-top: 16px;
`;
const Gap8 = styled.div`
  padding-top: 8px;
`;

const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;

const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .skipColor {
    color: #fff;
    background-color: #fd7bb2;
  }
`;

const CalCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const CustomSelectBox = styled.div`
  padding: 0px 0px 10px 0px;
  .active {
    ul {
      overflow-y: scroll;
    }
  }
`;

const getIsFormValid = (country: string): boolean => {
  if (country.trim().length !== 0) {
    return true;
  }

  return false;
};

const CreateAccount4Page = () => {
  const { t } = useTranslation("pageCreateAccount3-1");

  const [country, setCountry] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const isFormValid = getIsFormValid(country);
  const toNext = useToNextCreateAccountPage();
  const handleAppError = useHandleAppError();
  const [isCreateAccountInProgress, setIsCreateAccountInProgress] =
    useState<boolean>(false);

  const onSubmit = async () => {
    try {
      if (isCreateAccountInProgress) {
        return;
      }

      setIsCreateAccountInProgress(true);

      await toNext({ country: country, region: region });
    } catch (error) {
      if (error instanceof CreateAccountServerError) {
        alert(t("pageCreateAccount3-1:failedToCreateAccount"));
        return;
      }

      await handleAppError(error);
    } finally {
      setIsCreateAccountInProgress(false);
    }
  };

  const onSkip = async () => {
    try {
      if (isCreateAccountInProgress) {
        return;
      }

      setIsCreateAccountInProgress(true);

      await toNext({ country: "선택안함", region: "없음" });
    } catch (error) {
      if (error instanceof CreateAccountServerError) {
        alert(t("pageCreateAccount3-1:failedToCreateAccount"));
        return;
      }

      await handleAppError(error);
    } finally {
      setIsCreateAccountInProgress(false);
    }
  };

  const countryList: { id: number; key: string; value: string }[] = [
    {
      id: 1,
      key: "KR",
      value: "KR",
    },
    {
      id: 2,
      key: "JP",
      value: "JP",
    },
    {
      id: 3,
      key: "US",
      value: "US",
    },
    {
      id: 4,
      key: "MN",
      value: "MN",
    },
    {
      id: 5,
      key: "CN",
      value: "CN",
    },
    {
      id: 6,
      key: "TW",
      value: "TW",
    },
    {
      id: 7,
      key: "HK",
      value: "HK",
    },
    {
      id: 8,
      key: "RU",
      value: "RU",
    },
    {
      id: 9,
      key: "ID",
      value: "ID",
    },
    {
      id: 10,
      key: "IN",
      value: "IN",
    },
    {
      id: 11,
      key: "MM",
      value: "MM",
    },
    {
      id: 12,
      key: "TH",
      value: "TH",
    },
    {
      id: 13,
      key: "CH",
      value: "CH",
    },
    {
      id: 14,
      key: "PH",
      value: "PH",
    },
    {
      id: 15,
      key: "NO",
      value: "NO",
    },
    {
      id: 16,
      key: "NL",
      value: "NL",
    },
    {
      id: 17,
      key: "MX",
      value: "MX",
    },
    {
      id: 16,
      key: "MY",
      value: "MY",
    },
    {
      id: 17,
      key: "KZ",
      value: "KZ",
    },
    {
      id: 18,
      key: "FR",
      value: "FR",
    },
    {
      id: 19,
      key: "DK",
      value: "DK",
    },
    {
      id: 20,
      key: "CA",
      value: "CA",
    },
    {
      id: 21,
      key: "BR",
      value: "BR",
    },
    {
      id: 22,
      key: "AU",
      value: "AU",
    },
    {
      id: 23,
      key: "AR",
      value: "AR",
    },
    {
      id: 24,
      key: "GB",
      value: "GB",
    },
    {
      id: 25,
      key: "TR",
      value: "TR",
    },
    {
      id: 26,
      key: "ETC",
      value: "ETC",
    },
  ];

  const selectCountry = () => {
    return (
      <ul className="optionList">
        {countryList.map((item, index) => (
          <li
            onClick={(e) => {
              setCountry(t(`pageCreateAccount3-1:${item.key}`));
            }}
            key={index}
            className="optionItem"
          >
            {t(`pageCreateAccount3-1:${item.value}`)}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    /* 화살표 함수 */
    const label: any = document.querySelector(".labelCustom");
    const options: any = document.querySelectorAll(".optionItem");

    // 클릭한 옵션의 텍스트를 라벨 안에 넣음
    const handleSelect = (item: any) => {
      label.parentNode.classList.remove("active");
      label.innerHTML = item.textContent;
    };
    // 옵션 클릭시 클릭한 옵션을 넘김
    options.forEach((option: any) => {
      option.addEventListener("click", () => handleSelect(option));
    });

    // 라벨을 클릭시 옵션 목록이 열림/닫힘
    label.addEventListener("click", () => {
      if (label.parentNode.classList.contains("active")) {
        label.parentNode.classList.remove("active");
      } else {
        label.parentNode.classList.add("active");
      }
    });
  });

  return (
    <>
      <NavigationBar
        text={t("pageCreateAccount3-1:navigation")}
      ></NavigationBar>
      <RootCont>
        <Gap72 />
        <TextCont>
          <ProgressText></ProgressText>
          <NoticeText
            notice={t("pageCreateAccount3-1:notice")}
            desciption={t("pageCreateAccount3-1:description")}
          ></NoticeText>
        </TextCont>
        <Gap16 />
        <BoxCont>
          <CalCont>
            <CustomSelectBox>
              <div className="selectBoxCustom ">
                <button className="labelCustom">
                  {t("pageCreateAccount3-1:countrySelect")}
                </button>
                {selectCountry()}
              </div>
            </CustomSelectBox>
            <TextInput
              onChange={setRegion}
              value={region}
              placeholder={t("pageCreateAccount3-1:region")}
            ></TextInput>
          </CalCont>

          <Gap16 />
          {isCreateAccountInProgress ? (
            <Button
              onClick={onSubmit}
              value={t("pageCreateAccount301:inProgress")}
              disabled={true}
            />
          ) : (
            <Button
              onClick={onSubmit}
              value={t("pageCreateAccount3-1:button")}
              disabled={!isFormValid}
            />
          )}
          <Gap16 />
          <Button
            onClick={onSkip}
            value={t("pageCreateAccount3-1:skip")}
            disabled={false}
            className={`skipColor`}
          />
        </BoxCont>
      </RootCont>
    </>
  );
};

export default CreateAccount4Page;

import Vote from '../data-types/vote';
import useAuth from "./auth";
import {apiAddr} from "../config";
import serializeVote from '../from-server-serializers/vote';

const useReadRandomActiveVotes3 = () => {
  const auth = useAuth();

  return async (): Promise<Vote[]> => {
    const result = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/v1/random-active-votes`,
      params: {count: 3},
    });

    return result.data.map(serializeVote);
  };
};

export default useReadRandomActiveVotes3;

import React from 'react';
import VoteIdolModal from '../../../../modals/vote-idol';
import {LoadedLogic} from "../../use-logic";

interface Props {
  logic: LoadedLogic;
}

const TheVoteIdolModal: React.FC<Props> = (props) => {
  return <VoteIdolModal
    isVisible={props.logic.idolToVote != null}
    onRequestClose={props.logic.closeVoteModal}
    idol={props.logic.idolToVote}
    vote={props.logic.voteToVote}
    onVoteDone={props.logic.onVoteDone}
  />;
};

export default TheVoteIdolModal;

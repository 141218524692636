import React from "react";
import {
    AttractionItemAddr,
    AttractionItemClass2,
    AttractionItemClass3,
    AttractionItemInner,
    AttractionItemMemo,
    AttractionItemPic,
    AttractionItemTitle,
    AttractionItemWrap,
    AttractionItemYear,
    FanUpWrap,
    Header,
    HeaderBar,
    HeaderTitle,
    Inner
} from "./dom-styled";
import {ReactComponent as IconMarker} from "../svg/icon_marker.svg";
import {FanUpAttractionPayload} from "../../../data-types/attraction";
import i18n from "i18next";
import {TFunction} from "react-i18next";

interface MyPicReadProps{
    picData : FanUpAttractionPayload;
    trnas: TFunction<"pageFanUpMap", undefined>;
    onClick?: (value: string|undefined) => void;
}
const FanUpRead :React.FC<MyPicReadProps> =(props)=>{
    const picData = props.picData;
    return(
        <FanUpWrap onClick={()=>{
            props.onClick&& props.onClick(picData.id||undefined);
        }}>
            <Inner>
                <Header>
                    <HeaderBar/>
                    <HeaderTitle>
                        <IconMarker style={{position:"absolute",left:0}}/>
                        <span style={{color:'#FF176B'}}>{picData.name![i18n.language]}</span> {props.trnas('nearAttraction')}
                    </HeaderTitle>
                </Header>
                <AttractionItemWrap>
                    <div style={{minHeight:"82px"}}>
                        <AttractionItemPic src={picData.images![0]}/>
                        <AttractionItemTitle>{picData.name![i18n.language]}</AttractionItemTitle>
                        <AttractionItemMemo>{picData.memo&& picData.memo[i18n.language]}</AttractionItemMemo>
                        <AttractionItemInner>
                            <AttractionItemClass2>{picData.class2}</AttractionItemClass2>
                            <AttractionItemClass3>{picData.class3&& picData.class3[i18n.language]}</AttractionItemClass3>
                            <AttractionItemYear>/ 2023</AttractionItemYear>
                        </AttractionItemInner>
                    </div>
                    <AttractionItemAddr>{picData.address&& picData.address[i18n.language]}</AttractionItemAddr>
                </AttractionItemWrap>
            </Inner>
        </FanUpWrap>
    );
}

export default FanUpRead;

import styled from "styled-components";

export const BucketWrap = styled.div`
    width:100%;
    height:100vh;
    background-color:#EDEDED;
`;

export const AttractItem = styled.div`
    padding:16px;
`;
export const Inner = styled.div`
    background: #fff;
    padding:16px;
    border-radius: 12px;
`;

export const Header = styled.div`
    padding:16px;
`;
export const HeaderBar = styled.div`
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 4px;
    background: #DAE0E6;
`;
export const HeaderTitle = styled.div`
    font-size: 14px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    height: 24px;
    padding-left: 26px;
    margin-top:16px;
`;

export const AttractionItemWrap = styled.div`
    position: relative;
    padding-left: 100px;
    min-height: 100px;
    padding-top:0;
`;
export const AttractionItemPic = styled.div<{src?:string}>`
    position: absolute;
    top: 0;
    left: 0;
    background-color: #D9D9D9;
    background-image: url(${props=>props.src});
    background-size: cover;
    background-position: center;
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 6px;
    border: 1px solid #E4EAF0;
`;
export const AttractionItemTitle = styled.h3`
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
`;
export const AttractionItemMemo = styled.p`
margin: 5px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #6C7680;
`;
export const AttractionItemInner = styled.div`
    display:flex;
`;
export const AttractionItemClass2 = styled.strong`
color: var(--change-color, #FF176B);
font-family: Spoqa Han Sans Neo;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
margin-right:10px
`;
export const AttractionItemClass3 = styled.span`
color: var(--gray-700, #3D454D);
font-family: Spoqa Han Sans Neo;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
margin-right:10px

`;
export const AttractionItemYear= styled.span`
color: var(--gray-500, #6C7680);
font-family: Spoqa Han Sans Neo;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
`;
export const AttractButtonGroup = styled.div`
    display:flex;
`;
export const BucketButton = styled.a<{active:boolean}>`
    position:relative;
    display:block;
    width:100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    border-radius:23px;
    background-color:${props=>props.active?'#FF176B':'#D9D9D9'};
    color:#fff;
    padding:10px 12px;
    :nth-child(1){margin-right:10px}
`;

export const CalendarWrap = styled.div`
    padding:16px;
`;



import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ModalBaseWrapper from "../modal-base-wrapper";
/**
 * 메시지 모달
 */
export interface MessageModalHook{
    disabledDone? :string|undefined;
    doneHandler? : ()=>void|undefined;
    closeHandler?:()=>void|undefined;
    messageContent:JSX.Element
}
interface MessageModalProps{
    onHook?:(fn: React.Dispatch<React.SetStateAction<MessageModalHook | undefined>>)=>void;
}

/**
 * 메시지 모달
 * @param props
 * @constructor
 */
const MessageModal: React.FC<MessageModalProps> =(props)=>{
    const {t} = useTranslation("pageFanUpMap");
    const [modalMessage,setModalMessage] = useState<MessageModalHook|undefined>();

    let isVisible = typeof modalMessage !=='undefined';
    let isDisableDone = true;
    let doneHandler = ()=>{};
    let closeHandler = ()=>{};
    if(props.onHook){
        props.onHook(setModalMessage);
    }
    if(modalMessage){
        isDisableDone = modalMessage.disabledDone !=='N';
        doneHandler = ()=>{
            setModalMessage(undefined);
            modalMessage.doneHandler&& modalMessage.doneHandler();
        };
        closeHandler = ()=>{
            setModalMessage(undefined);
            modalMessage.closeHandler&& modalMessage.closeHandler();
        }
    }
    return (
        <ModalBaseWrapper
            isVisible={isVisible}
            onRequestClose={()=>{closeHandler();}}
            onLeft={()=>{
                closeHandler();
            }}
            leftText={isDisableDone?t('button.check'):t('button.cancel')}
            disableRight={isDisableDone}
            onRight={()=>{
                doneHandler()
            }}
            rightText={t('button.check')}
            content={modalMessage?.messageContent!}>

        </ModalBaseWrapper>
    )
}
export default MessageModal;

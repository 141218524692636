import React from 'react';
import styled from 'styled-components';
import {LoadedLogic} from "../../../use-logic";
import {ReactComponent as ClockIcon} from './clock-icon.svg';
import {black} from "../../../../../styles";
import moment from 'moment';
import i18next from 'i18next';
import MultilingualString from "../../../../../data-types/multilingual-string";

interface Props {
  logic: LoadedLogic;
}

const Cont = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${black};
`;

const Text = styled.span`
  margin-left: 14px;
  color: white;
  font-size: 14px;
`;

const getCurrentLocale = () => i18next.language as keyof MultilingualString;

const toMomentLocale = (locale: keyof MultilingualString): string => {
  switch (locale) {
    case 'en': return 'en';
    case 'kr': return 'ko';
    case 'zh': return 'zh-cn';
  }
};

const TimeLeft: React.FC<Props> = (props) => {
  return (
    <Cont>
      <ClockIcon/>
      <Text>
        {moment(props.logic.idolVotes[props.logic.currentTabIndex].period.to).locale(toMomentLocale(getCurrentLocale())).format('LLLL')}
      </Text>
    </Cont>
  );
};

export default TimeLeft;

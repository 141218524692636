import React from 'react';
import {LoadedLogic} from "../../use-logic";
import styled from 'styled-components';
import IdolVoteCandidate from "../../../../data-types/idol-vote-candidate";
import IdolVote from "../../../../data-types/idol-vote";
import Idol from "../../../../data-types/idol";
import Candidate from './candidate';

const Cont = styled.div`
  padding: 18px 16px 18px 16px;
`;

interface Props {
  logic: LoadedLogic;
}

const CandidatesFragment: React.FC<Props> = (props) => {
  const currentVote: IdolVote = props.logic.idolVotes[props.logic.currentTabIndex];
  const candidates: IdolVoteCandidate[] = props.logic.idolVoteCandidates[currentVote.id];
  const candidateIdols: Idol[] = candidates.map((candidate: IdolVoteCandidate) => props.logic.idols[candidate.idol]);

  return (
    <Cont>
      {candidateIdols.map(
        (idol: Idol, idolIndex: number) => {
          return <Candidate
            key={idol.id}
            vote={currentVote}
            idol={idol}
            idolRanking={idolIndex}
            logic={props.logic}
          />;
        }
      )}
    </Cont>
  );
};

export default CandidatesFragment;

import React from "react";
import styled from "styled-components";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import { useTranslation } from "react-i18next";

import useLogic from "./use-logic";
import { black } from "../../styles";
import { ReactComponent as Right } from "../my-account/svg/arrow-icon.svg";

interface DumbProps {
  allow: boolean;
  onSave: (pushToken: string, pushAllow: boolean) => void;
  pushTitle: string;
}

const Cont = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #ededed;
  padding: 16px;
`;

const Section = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const LightButton = styled.div`
  background-color: white;
  height: 64px;
  padding: 0px 16px 0px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  border-top: 1px solid #dae0e6;

  &:first-of-type {
    border-top: 0px;
  }
`;

const LightButtonText = styled.span`
  flex: 1;
  color: ${black};
  font-size: 16px;
`;

const TableCont = styled.div`
  display: flex;
  div {
    &:nth-child(1) {
      text-align: right;
      flex-grow: 1;
    }
    &:nth-child(2) {
      text-align: right;
      flex-grow: 4;

      toggleSwitch {
        width: 100px;
        height: 50px;
        display: block;
        position: relative;
        border-radius: 30px;
        background-color: #fff;
        box-shadow: 0 0 16px 3px rgba(0 0 0 / 15%);
        cursor: pointer;
      }

      /* 토글 버튼 */
      .toggleSwitch .toggleButton {
        /* 버튼은 토글보다 작아야함  */
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        border-radius: 50%;
        background: #f03d3d;
      }
    }
  }
`;

const LabelCont = styled.label`
  .toggleButton {
    /* 버튼은 토글보다 작아야함  */
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #f03d3d;
  }
`;

const GrayGap = styled.div`
  height: 1px;
  border: 1px solid gray;
`;

const Dumb: React.FC<DumbProps> = (props) => {
  const pushToken = sessionStorage.getItem("fanupTokenData") ?? "";
  const handleSave = (allow: boolean) => {
    props.onSave(pushToken, allow);
  };

  return (
    <Cont>
      <Section>
        <LightButton>
          <LightButtonText>{props.pushTitle}</LightButtonText>
          <div>
            <label>
              <input
                type="radio"
                className={`toggleButton`}
                name={`allow`}
                checked={props?.allow}
                onChange={() => handleSave(true)}
              />
              ON&nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className={`toggleButton`}
                name={`allow`}
                onChange={() => handleSave(false)}
                checked={!props?.allow}
              />
              OFF
            </label>
          </div>
        </LightButton>
      </Section>
    </Cont>
  );
};

const SettingsPage: React.FC = () => {
  const { t } = useTranslation("pageMyAccount");
  const pushToken = sessionStorage.getItem("fanupTokenData") ?? "";

  const logic = useLogic(pushToken);

  if (logic.status !== "LOADED") {
    return null; // todo
  }

  return (
    <NavigationWithGoBackBottomTabLayout title={t("pageMyAccount:settings")}>
      <Dumb
        allow={logic.allow}
        onSave={logic.save}
        pushTitle={t("pageMyAccount:alarm")}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default SettingsPage;

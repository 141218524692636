import DemandingVoteStatistics from '../data-types/demanding-vote-statistics';

const serialize = (data: Record<string, any>): DemandingVoteStatistics => {
  return {
    totalVoteCount: data.totalVoteCount,
    choices: data.choices,
  };
};

export default serialize;

import axios from "axios";
import { apiAddr } from "../config";

let eventRewardingTicketCount: number | undefined = undefined;

const readFromServer = async (): Promise<number> => {
  const axiosResult = await axios({
    method: "get",
    url: `${apiAddr}/v1/app/event-rewarding-ticket-count`,
  });

  return axiosResult.data;
};

const useReadEventRewardingTicketCount = () => {
  return async (): Promise<number> => {
    if (eventRewardingTicketCount == null) {
      const result = await readFromServer();
      eventRewardingTicketCount = result;
    }

    return eventRewardingTicketCount;
  };
};

export default useReadEventRewardingTicketCount;

import React from 'react';
import styled, {css} from 'styled-components';
import {LoadedLogic} from "../../use-logic";
import Vote from './vote';
import DemandingVote from "../../../../data-types/demanding-vote";

const backgroundColor = '#EDEDED'; // Forgive me.

const Cont = styled.div`
  background-color: ${backgroundColor};
  padding-top: 8px;
`;

interface Props {
  logic: LoadedLogic;
}

const Votes: React.FC<Props> = (props) => {
  return (
    <Cont>
      {props.logic.currentVotes.map((vote: DemandingVote, voteIndex: number) => {
        const voteLog = props.logic.currentVoteLogs[voteIndex];

        return (
          <Vote
            key={vote.id}
            logic={props.logic}
            category={props.logic.currentVoteCategory}
            vote={vote}
            voteLog={voteLog}
            voteIndex={voteIndex}
          />
        );
      })}
    </Cont>
  );
};

export default Votes;

import { createServerError, ServerError } from "../utils/server-error";
import useAuth from "./auth";
import { apiAddr, guestObjectId } from "../config";
import isResponseErrorWhichErrorMessageStartsWith from "../utils/is-response-error-which-error-message-starts-with";
import passResponseFromErrorOrFalse from "../utils/pass-response-from-error-or-false";
import IdolVoteLimitPerDayExceed from "../errors/idol-vote-limit-per-day-exceed";

interface Params {
  vote: string;
  idol: string;
  voteCount: number;
}

const VoteIdolServerError = createServerError();
type VoteIdolServerError = ServerError;

const isIdolVoteLimitPerDayExceedError =
  isResponseErrorWhichErrorMessageStartsWith(
    "User does not have enough tickets to vote"
  );

const useVoteIdol = () => {
  const auth = useAuth();

  return async (params: Params) => {
    try {
      await auth.callAxios({
        method: "post",
        url: `${apiAddr}/v1/votes/${params.vote}/vote-idol`,
        data: {
          user: auth.userId ?? guestObjectId,
          idol: params.idol,
          voteCount: params.voteCount,
        },
      });
    } catch (error) {
      if (
        passResponseFromErrorOrFalse(error, isIdolVoteLimitPerDayExceedError)
      ) {
        throw new IdolVoteLimitPerDayExceed();
      }

      throw new VoteIdolServerError(error);
    }
  };
};

export default useVoteIdol;
export { VoteIdolServerError };

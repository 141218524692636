import { useState } from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import useToResetPasswordDonePage from '../../../services/to-reset-password-done-page';
import noProblem from "../../../utils/no-problem";
import useResetPassword from "../../../services/reset-password";
import useHandleAppError from "../../../services/handle-app-error";

interface State {
  password: string;
  repassword: string;
  isProcessing: boolean;
}

interface Logic {
  goBack: () => void;
  password: string;
  repassword: string;
  setPassword: (newValue: string) => void;
  setRepassword: (newValue: string) => void;
  isProcessing: boolean;
  resetPassword: () => void;
  isPasswordValid: boolean;
}

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({
    password: '',
    repassword: '',
    isProcessing: false,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordService = useResetPassword();
  const handleAppError = useHandleAppError();
  const toNext = useToResetPasswordDonePage();

  const goBack = () => navigate(-1);
  const setPassword = (newPassword: string) => {
    setState((oldState) => ({ ...oldState, password: newPassword }));
  };
  const setRepassword = (newRepassword: string) => {
    setState((oldState) => ({ ...oldState, repassword: newRepassword }));
  };
  const checkIsProcessing = () => {
    if (state.isProcessing) {
      throw 'ALREADY_PROCESSING_ERROR';
    }
  };
  const checkIsPasswordValid = () => {
    if (state.password.length === 0) {
      throw 'EMPTY_PASSWORD';
    }
    if (state.repassword !== state.password) {
      throw 'REPASSWORD_MISMATCH';
    }
  };
  const startResetPasswordProcess = async (fn: () => Promise<void>) => {
    try {
      setState((oldState) => ({ ...oldState, isProcessing: true }));
      await fn();
    } finally {
      setState((oldState) => ({ ...oldState, isProcessing: false }));
    }
  };
  const readResetPasswordToken = () => {
    return searchParams.get('token')!;
  };
  const requestResetPasswordToServerAndUpdateLocalAuth = async () => {
    const resetPasswordToken = readResetPasswordToken();
    await resetPasswordService({
      resetPasswordToken,
      newPassword: state.password,
    });
  };
  const navigateToResetPasswordDone = () => {
    toNext();
  };
  const resetPassword = async () => {
    try {
      checkIsProcessing();
      checkIsPasswordValid();
      await startResetPasswordProcess(async () => {
        await requestResetPasswordToServerAndUpdateLocalAuth();
      });
      navigateToResetPasswordDone();
    } catch (error) {
      if (error === 'ALREADY_PROCESSING_ERROR') {
        return;
      }

      if (error === 'EMPTY_PASSWORD') {
        alert('비밀번호를 입력해주세요');
        return;
      }

      if (error === 'REPASSWORD_MISMATCH') {
        alert('비밀번호와 비밀번호 확인이 일치하지 않습니다');
        return;
      }

      await handleAppError(error);
    }
  };

  return {
    goBack,
    password: state.password,
    repassword: state.repassword,
    setPassword,
    setRepassword,
    isProcessing: state.isProcessing,
    resetPassword,
    isPasswordValid: noProblem(checkIsPasswordValid),
  };
};

export type { Logic };
export default useLogic;

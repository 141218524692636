import axios from 'axios';
import {apiAddr} from "../config";

const readFromServer = async (): Promise<number> => {
  const axiosResult = await axios({
    method: 'get',
    url: `${apiAddr}/v1/app/max-idol-vote-count-per-day`,
  });

  return axiosResult.data;
};

const maxIdolVoteCountPerDay: Promise<number> = readFromServer();

const useReadMaxIdolVoteCountPerDay = () => {
  return () => maxIdolVoteCountPerDay;
};

export default useReadMaxIdolVoteCountPerDay;

import React, { useState } from "react";
import styled from "styled-components";
import { cg900 } from "../../styles";
import { ReactComponent as GoBackIcon } from "./go-back.svg";
import { ReactComponent as LanguageIcon } from "./language-icon.svg";
import SelectLanguageModalApp from "../../modals/select-language-app";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cont = styled.div`
  top: 4px;
  height: 44px;
  position: relative;
`;

const TitleCont = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  font-family: "BalooBhaijaan";
  color: ${cg900};
  font-weight: bold;
  font-size: 16px;
`;

const TheGoBackIcon = styled(GoBackIcon)`
  position: absolute;
  top: 0px;
  left: 4px;
  cursor: pointer;
`;

const TheLanguageIcon = styled(LanguageIcon)`
  position: absolute;
  top: -5px;
  right: 8px;
  cursor: pointer;
`;

interface NavigationBarWithGoBackProps {
  className?: string | undefined;
  onGoBackClick?: (() => void) | undefined;
  onLanguageClick?: (() => void) | undefined;
  title: string;
}

const NavigationBarWithGoBack: React.FC<NavigationBarWithGoBackProps> = (
  props
) => {
  const [isLanguageModalVisible, setIsLanguageModalVisible] =
    useState<boolean>(false);

  const navigator = useNavigate();

  const onGoBackClick = () => {
    navigator(-1);
  };

  const onLanguageClick = () => {
    setIsLanguageModalVisible(!isLanguageModalVisible);
  };

  const onRequestLanguageModalClose = () => {
    setIsLanguageModalVisible(false);
  };

  return (
    <>
      <Cont>
        <TitleCont>
          <Title>{props.title}</Title>
        </TitleCont>
        <TheGoBackIcon onClick={props.onGoBackClick ?? onGoBackClick} />
        <TheLanguageIcon onClick={props.onLanguageClick ?? onLanguageClick} />
      </Cont>
      <SelectLanguageModalApp
        isVisible={isLanguageModalVisible}
        onRequestClose={onRequestLanguageModalClose}
      />
    </>
  );
};

export default NavigationBarWithGoBack;
export type { NavigationBarWithGoBackProps };

import React from "react";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "./svg/home.svg";
import { ReactComponent as ChartIcon } from "./svg/chart.svg";
import { ReactComponent as AirportIcon } from "./svg/airport.svg";
import { ReactComponent as MyIcon } from "./svg/my.svg";
import { ReactComponent as CupIcon } from "./svg/cup.svg";
import { ReactComponent as MapIcon } from "./svg/map.svg";
import { useTranslation } from "react-i18next";
import { white } from "../../styles";
import { mainColor, cg300 } from "../../styles";
import AppTabKind from "../../utils/app-tab-kind";
const Cont = styled.div<{ color: string }>`
  background: ${(props) => props.color};
  position: sticky;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  height: 66px;
  width: 100%;
  z-index: 9999;
`;

const cssWhenActive = `
  svg {
    fill: ${mainColor};
  }
  color: ${mainColor};
`;

const Btn = styled.button<{ active: boolean }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  white-space: nowrap;

  //text-transform: uppercase;
  display: flex;
  flex-direction: column;
  padding: 12px 0px 0px 0px;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  color: ${cg300};
  svg {
    fill: ${cg300};
    width: 24px;
    height: 24px;
  }
  :hover svg {
    fill: ${mainColor};
  }
  :hover {
    color: ${mainColor};
  }

  ${(props) => props.active && cssWhenActive}
`;

interface BottomBarProps {
  className?: string | undefined;
  activeTab?: AppTabKind | undefined;
  onTabPress?: (pressedTab: AppTabKind) => void;
}

function BottomTab(props: BottomBarProps) {
  const { t } = useTranslation("componentBottomMenu");

  const onChartPress = () => {
    props.onTabPress != null && props.onTabPress("CHART");
  };

  const onFanupPress = () => {
    props.onTabPress != null && props.onTabPress("FANUP");
  };

  const onMainPress = () => {
    props.onTabPress != null && props.onTabPress("MAIN");
  };
  const onMapPress = () => {
    props.onTabPress != null && props.onTabPress("MAP");
  };

  const onDemandingPress = () => {
    props.onTabPress != null && props.onTabPress("DEMANDING");
  };

  const onMyAccountPress = () => {
    props.onTabPress != null && props.onTabPress("MY_ACCOUNT");
  };

  return (
    <Cont color={white} className={props.className}>
      <Btn active={props.activeTab === "CHART"} onClick={onChartPress}>
        <ChartIcon className="svgIcon" />
        {t("componentBottomMenu:chart")}
      </Btn>
      <Btn active={props.activeTab === "FANUP"} onClick={onFanupPress}>
        <AirportIcon className="svgIcon" />
        {t("componentBottomMenu:pick")}
      </Btn>
      <Btn active={props.activeTab === "MAIN"} onClick={onMainPress}>
        <HomeIcon className="svgIcon" />
        {t("componentBottomMenu:home")}
      </Btn>
      <Btn active={props.activeTab === "MAP"} onClick={onMapPress}>
        <MapIcon className="svgIcon" />
        {t("componentBottomMenu:map")}
      </Btn>
      <Btn active={props.activeTab === "DEMANDING"} onClick={onDemandingPress}>
        <CupIcon className="svgIcon" />
        {t("componentBottomMenu:cup")}
      </Btn>
    </Cont>
  );
}

export default BottomTab;

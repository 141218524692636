import {useNavigate} from "react-router-dom";

const useToTournamentVoteResultPage = () => {
  const navigate = useNavigate();

  return (voteId: string) => {
    navigate(`/tournament-vote-result/${voteId}`);
  };
};

export default useToTournamentVoteResultPage;

import React from "react";
import styled from "styled-components";
import Buttons from "./buttons";

const Cont = styled.div`
  background-color: white;
  border-radius: 8px;
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  overflow: hidden;
`;

const Content = styled.div`
  display: block;
  margin: 0px 28px 0px 28px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  font-weight: bold;
  text-align: center;
`;

interface ModalBaseProps {
  content: JSX.Element;
  leftText: string;
  rightText: string;
  disableRight?: boolean | undefined;
  disableLeft?: boolean | undefined;
  onLeft: () => void;
  onRight: () => void;
}

const ModalBase: React.FC<ModalBaseProps> = (props) => {
  return (
    <Cont>
      <Content>{props.content}</Content>
      <Buttons
        onLeft={props.onLeft}
        onRight={props.onRight}
        disableLeft={props.disableLeft}
        disableRight={props.disableRight}
        leftText={props.leftText}
        rightText={props.rightText}
      />
    </Cont>
  );
};

export default ModalBase;

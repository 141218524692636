import useAuth from "./auth";
import { apiAddr, guestObjectId } from "../config";

interface Params {
  vote: string;
  choice: string;
}

const useVoteTournament = () => {
  const auth = useAuth();

  return async (params: Params) => {
    //auth.checkIsLoggedIn();

    await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/votes/${params.vote}/vote-tournament`,
      data: {
        choice: params.choice,
        user: auth.userId ?? guestObjectId,
      },
    });
  };
};

export default useVoteTournament;

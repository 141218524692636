import React from "react";
import Modal from "react-modal";
import MainVoteComp from "../../components/main-vote";
import EventVote from "../../data-types/event-vote-main";
import useToCurrentLocale from "../../services/to-current-locale";
import { maxTabletSizeInPx } from "../../styles";
import TournamentVoteChoice from "../../data-types/tournament-vote-choice-main";
import DemandingVoteStatistics from "../../data-types/demanding-vote-statistics";

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  onGoToEventButton: () => void;
  eventVoteData: EventVote;
  statistics: DemandingVoteStatistics;
  eventRewardingTicketCount: any;
}

const MainVoteModal: React.FC<Props> = (props: Props) => {
  const toCurrentLocale = useToCurrentLocale();
  return (
    <Modal
      isOpen={props.isVisible}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          padding: 0,
          margin: 0,
          textAlign: "center",
          alignSelf: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          inset: "150px -5px",
          margin: "auto",
          textAlign: "center",
          alignSelf: "center",
          borderRadius: 0,
          padding: "0px",
          background: "transparent",
          border: 0,
          minWidth: "330px",
          height: "500px",
          maxWidth: maxTabletSizeInPx,
        },
      }}
    >
      <MainVoteComp
        onCancel={props.onRequestClose}
        onGoToEventPageButton={props.onGoToEventButton}
        eventVoteData={props?.eventVoteData}
        choicesArray={props.eventVoteData.choices.map((data) => {
          const choice = props.statistics.choices.find(
            (i) => i.choice === data.id
          )!;

          return {
            id: choice?.choice,
            name: toCurrentLocale(data.content.title),
            scale: choice?.voteCount / props.statistics.totalVoteCount,
          };
        })}
        eventRewardingTicketCount={props.eventRewardingTicketCount}
      />
    </Modal>
  );
};

export default MainVoteModal;
// choicesArray={props.statistics?.choices.map((choiceStat) => {
//   const findById = (id: string) => (choice: TournamentVoteChoice) =>
//     choice.id === id;
//   const choice: TournamentVoteChoice =
//     props.eventVoteData?.choices.find(findById(choiceStat.choice))!;

//   return {
//     id: choiceStat.choice,
//     name: toCurrentLocale(choice.content.title),
//     scale: choiceStat.voteCount / props.statistics.totalVoteCount,
//   };
// })}

import { useEffect, useState } from "react";

export interface UserLocationType {
  isLoaded: boolean;
  lat: number;
  lng: number;
}

const key = "recentlyLocation";
const _recentlyLocation = (): UserLocationType | undefined => {
  try {
    let data = window.localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }

    const appData = localStorage.getItem(key);
    if (appData !== null) {
      const parseData = JSON.parse(appData);
      if (parseData) {
        const { lat, lng, isLoaded } = parseData;

        return {
          lat: lat,
          lng: lng,
          isLoaded: isLoaded,
        };
      }
    } else {
      return {
        lat: 37.56538242698944,
        lng: 126.97766793130997,
        isLoaded: false,
      };
    }
  } catch (e) {
    console.log(e);
  }
};
const useGeoLocation = (): UserLocationType => {
  let recentlyLocation = _recentlyLocation();
  const [location, setLocation] = useState<UserLocationType>({
    isLoaded: false,
    lat: recentlyLocation!.lat,
    lng: recentlyLocation!.lng,
  });

  useEffect(() => {
    if (!location.isLoaded) {
      try {
        navigator.geolocation.getCurrentPosition(
          (loc: { coords: { latitude: number; longitude: number } }) => {
            const userLocation = {
              isLoaded: true,
              lat: loc.coords.latitude,
              lng: loc.coords.longitude,
            };
            window.localStorage.setItem(key, JSON.stringify(userLocation));
            setLocation({
              isLoaded: true,
              lat: loc.coords.latitude,
              lng: loc.coords.longitude,
            });
          },
          (e) => {
            console.log(e);
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  });
  return location;
};
export default useGeoLocation;

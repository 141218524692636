import { apiAddr } from '../config';
import Announcement from '../data-types/announcement';
import useAuth from "./auth";
import serializeAnnouncement from '../from-server-serializers/announcement';

interface ReadAnnouncementsParams {
  from?: string | undefined;
  limit: number;
}

const useReadAnnouncements = () => {
  const auth = useAuth();

  return async (params: ReadAnnouncementsParams): Promise<Announcement[]> => {
    const result = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/v1/announcements`,
      params: {from: params.from, limit: params.limit},
    });

    return result.data.map(serializeAnnouncement);
  };
};

export default useReadAnnouncements;

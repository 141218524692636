import React, { useState } from "react";
import queryString from "query-string";
import qs from "qs";
import { useLocation, useSearchParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import useLoginWithAuthData from "../../../services/login-with-auth-data";
import { apiAddr } from "../../../config";
import { AuthData } from "../../../utils/auth";

const SNSAuth = () => {
  let axiosResult: AxiosResponse;

  const getAuthDataFromAxiosResult = () => {
    return axiosResult.data as AuthData;
  };

  const loginWithAuthData = useLoginWithAuthData();

  const fnAuthRequest = async (
    e: React.MouseEvent | null,
    code: string | null | (string | null)[],
    state: string | null | (string | null)[]
  ) => {
    if (code) {
      try {
        // result = await axios({
        //     method: "post",
        //     url: `https://api.line.me/oauth2/v2.1/token`,
        //     headers:{"content-type": "application/x-www-form-urlencoded"},
        //     data: {grant_type:"authorization_code",code:code,redirect_uri:encodeURI("http://127.0.0.1:3000/callback"),client_id:"1657531469",client_secret:"143eb89a40a47427ab120fffa6a4b7ee"},
        // });

        let data = qs.stringify({
          code: code,
          client_id: "1657531469",
          client_secret: "143eb89a40a47427ab120fffa6a4b7ee",
          grant_type: "authorization_code",
          redirect_uri: "https://www.fanupvote.com/callback",
        });

        axios({
          method: "post",
          url: "https://api.line.me/oauth2/v2.1/token",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        })
          .then(async function (response) {
            console.log(JSON.stringify(response.data));

            axiosResult = await axios({
              method: "post",
              url: `${apiAddr}/v1/auth/sns-auth`,
              data: response.data,
            });

            console.log(axiosResult);

            await loginWithAuthData(getAuthDataFromAxiosResult());
          })
          .catch(function (error) {
            console.log(error);
          });

        // result = await axios.post(
        //   "https://api.line.me/oauth2/v2.1/token",
        //   JSON.stringify({
        //     grant_type: "authorization_code",
        //     code: code,
        //     redirect_uri: encodeURI("http://127.0.0.1:3000/callback"),
        //     client_id: "1657531469",
        //     client_secret: "143eb89a40a47427ab120fffa6a4b7ee",
        //   }),
        //   {
        //     params: {
        //       grant_type: "authorization_code",
        //       code: code,
        //       redirect_uri: encodeURI("http://127.0.0.1:3000/callback"),
        //       client_id: "1657531469",
        //       client_secret: "143eb89a40a47427ab120fffa6a4b7ee",
        //     },
        //     headers: { "content-type": "application/x-www-form-urlencoded" },
        //   }
        // );
        //
        // result = await axios({
        //     method: 'post',
        //     url: `${apiAddr}/v1/auth/sns-tokens`,
        //     data: {code, state},
        // });
      } catch (error) {
        console.error(error);
      }
    }
  };

  // useLocation()
  const { search } = useLocation();
  const values = queryString.parse(search);
  console.log(values);

  // useSearchParams()
  const [searchParams] = useSearchParams();
  const query = queryString.parse(searchParams.toString());
  console.log(query);

  let requestCode: string | null | (string | null)[] = query?.code;
  let requestState: string | null | (string | null)[] = query?.state;

  fnAuthRequest(null, requestCode, requestState);

  return (
    <div>
      <h1>oAuth 결과 페이지 입니다.</h1>
      <h2>{requestCode}</h2>
      <h2>{query?.code}</h2>
      <h1>
        <button onClick={(e) => fnAuthRequest(e, requestCode, requestState)}>
          서버에 전달하기
        </button>
      </h1>
    </div>
  );
};

export default SNSAuth;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import Modal from 'react-modal';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
import LogRocket from 'logrocket';

const rootElementId = 'root';

Modal.setAppElement(`#${rootElementId}`);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById(rootElementId)
);

LogRocket.init('hcfr9r/fanup');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


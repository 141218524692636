import React from "react";
import useToCurrentLocale from "../../services/to-current-locale";
import { useTranslation } from "react-i18next";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
// @ts-ignore
import EventVoteResult from "../../components/event-vote-result";
import { event, fadedEvent } from "../../styles";
import voteDateLeft from "../../utils/vote-date-left";
// @ts-ignore
import VoteEventModal from "./vote-modal";
import useLogic from "./use-logic";
import TournamentVoteChoice from "../../data-types/tournament-vote-choice";

const EventVoteResultPage: React.FC = () => {
  const logic = useLogic();
  const toCurrentLocale = useToCurrentLocale();
  const { t } = useTranslation("pageEventVoteResult");

  if (logic.status !== "LOADED") {
    return null; // todo fix this.
  }

  return (
    <NavigationWithGoBackBottomTabLayout title={"WORLD CUP"}>
      <EventVoteResult
        color={event}
        title={toCurrentLocale(logic.vote.title)}
        choices={logic.statistics.choices.map((choiceStat) => {
          const findById = (id: string) => (choice: TournamentVoteChoice) =>
            choice.id === id;
          const choice: TournamentVoteChoice = logic.vote.choices.find(
            findById(choiceStat.choice)
          )!;

          return {
            id: choiceStat.choice,
            name: toCurrentLocale(choice.content.title),
            scale: choiceStat.voteCount / logic.statistics.totalVoteCount,
          };
        })}
        vote={logic.vote}
        voteStart={logic.voteStart}
        openModal={logic.openVoteModal}
        dateLeft={voteDateLeft(logic.vote)}
        description={`${logic.vote.period.from.toLocaleDateString()} ~ ${logic.vote.period.to.toLocaleDateString()}`}
        eventRewardingTicketCount={logic.eventRewardingTicketCount}
      />

      <VoteEventModal logic={logic} />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default EventVoteResultPage;

import Region from "../data-types/region";

const serialize = (data: Record<string, any>): Region => {
  return {
    id: data.id,
    country: data.country,
    name: data.name,
  };
};

export default serialize;

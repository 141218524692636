import VoteChoiceContent from "../data-types/vote-choice-content";

const serialize = (raw: Record<string, any>): VoteChoiceContent => {
  return {
    title: raw.title,
    description: raw.description,
    link: raw.link,
    imageUrl: raw.image,
  };
};

export default serialize;

import NavigationWithGoBackBottomTabLayout from "../../../components/navigation-with-go-back-bottom-tab-layout";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  AgreeCheck,
  AgreeLabel,
  ButtonGroup,
  FormBox,
  FormButton,
  FormInput,
  FormLabel,
  Inner,
  InputSection,
  PictureBox,
  PictureBoxTips,
  PictureButton,
  PictureCount,
  PictureInner,
  PictureItem,
  ProfileAddr,
  ProfileDate,
  ProfileImage,
  ProfileTitle,
  ProfileType,
  ProfileWrap,
  RadioItem,
  RadioWrap,
  SectionSubTile,
  SectionTitle,
} from "./dom-styled";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  AttractionLargeCategory,
  AttractionMidCategory,
  FanUpAttractionPayload,
  FanUpAttractionType,
} from "../../../data-types/attraction";
import InternalFile from "../../../data-types/internal-file";
import i18n from "i18next";
import { uploadAndCreateInternalFile } from "../../../utils/image-utils";
import { useCreateAttraction } from "../../../services/fanup-map/fanup-attraction-repository";
import ModalBaseWrapper from "../../../modals/modal-base-wrapper";
import useAuth from "../../../services/auth";
import { TFunction, useTranslation } from "react-i18next";
import { Auth } from "../../../utils/auth";

let _attractData: FanUpAttractionType;
let _setAttractData: Dispatch<SetStateAction<FanUpAttractionType>>;
let _setModifyFlag: Dispatch<SetStateAction<string>>;
let _trans: TFunction<"pageIdolVotes", undefined>;
let _navigate: NavigateFunction;
let _auth: Auth;
/**
 * 벨리데이션 검사
 * @param data
 */
const _checkValidation = (data: FanUpAttractionType): boolean => {
  return Object.values(data).filter((item) => item).length >= 8;
};
/**
 * 첨부파일 변경시
 * @param e
 */
const _onFileChange = async (e: any) => {
  let rtnFile: InternalFile[] = [];
  const hasFile = (e: any) => {
    return e.target.files.length !== 0;
  };
  if (e.target.files.length > 8) {
    alert("최대 업로드 허용이미지 수는 8개 입니다.");
    return false;
  }
  if (hasFile(e)) {
    let files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      rtnFile.push(await uploadAndCreateInternalFile(files[i]));
    }
    _attractData.images = rtnFile;
    _setModifyFlag(JSON.stringify(_attractData));
  }
};
/**
 * 관광지 등록
 */
const _createAttraction = () => {
  const data: FanUpAttractionPayload = {
    name: {
      kr: "",
      en: "",
      zh: "",
    },
    address: {
      kr: "",
      en: "",
      zh: "",
    },
    class1: _attractData.class1,
    class2: _attractData.class2,
    class3: {
      kr: "",
      en: "",
      zh: "",
    },
    requestAt: new Date(),
    requestId: _auth.userId,
    latitude: _attractData.latitude,
    longitude: _attractData.longitude,
    location: [],
    isAgree: "Y",
    images: [],
  };
  data.name![i18n.language] = _attractData.name!;
  data.address![i18n.language] = _attractData.address!;
  data.class3![i18n.language] = _attractData.class3!;
  data.location.push(Number(_attractData.longitude || 0));
  data.location.push(Number(_attractData.latitude || 0));
  if (_attractData.images && _attractData.images.length > 0) {
    _attractData.images.forEach((item) => data.images!.push(item.storeKey!));
  }

  useCreateAttraction()(data).then(() => {
    _setModifyFlag("F:CREATED");
  });
};
/**
 * 로그인모달
 */
const _loginModal: React.PropsWithChildren<any> = () => {
  return (
    <ModalBaseWrapper
      isVisible={true}
      onRequestClose={() => {
        _setModifyFlag("authClose");
      }}
      onLeft={() => {
        _setModifyFlag("authClose");
      }}
      onRight={() => _navigate("/login")}
      leftText={_trans("button.cancel")}
      rightText={_trans("button.login")}
      content={_trans("message.notAuth")}
    ></ModalBaseWrapper>
  );
};
/**
 * 확인모달
 */
const _confirmModal: React.PropsWithChildren<any> = () => {
  return (
    <ModalBaseWrapper
      isVisible={true}
      onRequestClose={() => {
        _setModifyFlag("confirmClose");
      }}
      onLeft={() => {
        _setModifyFlag("confirmClose");
      }}
      onRight={() => _createAttraction()}
      leftText={_trans("button.cancel")}
      rightText={_trans("button.confirm")}
      content={_trans("message.confirm")}
    ></ModalBaseWrapper>
  );
};
/**
 * 완료모달
 */
const _successModal: React.PropsWithChildren<any> = () => {
  return (
    <ModalBaseWrapper
      isVisible={true}
      onRequestClose={() => {
        _navigate("/maps");
      }}
      onLeft={() => {
        _navigate("/maps");
      }}
      leftText={_trans("button.check")}
      content={_trans("message.success")}
      disableRight={true}
      rightText={""}
      onRight={() => {}}
    ></ModalBaseWrapper>
  );
};
/**
 * 메인 바디
 * @constructor
 */
const FanUpAddPage: React.PropsWithChildren<any> = () => {
  const location = useLocation();
  const [modifyFlag, setModifyFlag] = useState("");
  const [attractData, setAttractData] = useState<FanUpAttractionType>(
    location.state.data
  );
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation("pageFanUpMap");
  _trans = t;
  _auth = auth;
  _attractData = attractData;
  _attractData.isAgree = true;
  _setAttractData = setAttractData;
  _setModifyFlag = setModifyFlag;
  _navigate = navigate;
  if (!location.state) {
    navigate("/maps/search");
  }
  return (
    <NavigationWithGoBackBottomTabLayout
      title={"FanUP MAP"}
      disabledSafePadding={true}
    >
      <Inner>
        <ProfileWrap>
          <ProfileImage
            image={_attractData.images && _attractData.images[0].url}
          />
          <ProfileTitle>{_attractData.name}</ProfileTitle>
          <ProfileAddr>{_attractData.address}</ProfileAddr>
          <div>
            <ProfileType>
              {_attractData.class1 || _trans("category")}{" "}
            </ProfileType>
            <ProfileDate>/{new Date().getFullYear() + "년"}</ProfileDate>
          </div>
        </ProfileWrap>
        <InputSection>
          <SectionTitle>
            {_trans("fanupAdd.inforTextTop")}
            <br />
            {_trans("fanupAdd.inforTextBottom")}
          </SectionTitle>
          <FormBox>
            <FormLabel>{_trans("fanupAdd.name")}</FormLabel>
            <FormInput
              placeholder={_trans("fanupAdd.nameText")}
              value={_attractData.name}
              onChange={(event) => {
                _attractData.name = event.target.value;
                setModifyFlag(event.target.value);
              }}
            />
          </FormBox>
          <FormBox>
            <FormLabel>{_trans("fanupAdd.address")}</FormLabel>
            <FormInput
              placeholder={_trans("fanupAdd.addressText")}
              value={_attractData.address}
              onChange={(event) => {
                _attractData.address = event.target.value;
                setModifyFlag(event.target.value);
              }}
            />
          </FormBox>
          <SectionSubTile>{_trans("fanupAdd.keyword")}</SectionSubTile>
          <RadioWrap>
            {Object.values(AttractionLargeCategory).map((item, idx) => (
              <RadioItem
                key={idx}
                active={item === _attractData.class1}
                onClick={() => {
                  _attractData.class1 = item;
                  setModifyFlag(item);
                }}
              >
                {item}
              </RadioItem>
            ))}
          </RadioWrap>
          <FormLabel type={"normal"}>{_trans("fanupAdd.category1")}</FormLabel>
          <RadioWrap style={{ marginBottom: "4px" }}>
            {Object.values(AttractionMidCategory).map((item, idx) =>
              idx < 3 ? (
                <RadioItem
                  key={idx}
                  active={item === _attractData.class2}
                  onClick={() => {
                    _attractData.class2 = item;
                    setModifyFlag(item);
                  }}
                >
                  {item}
                </RadioItem>
              ) : null
            )}
          </RadioWrap>
          <RadioWrap style={{ marginTop: "4px" }}>
            {Object.values(AttractionMidCategory).map((item, idx) =>
              idx >= 3 ? (
                <RadioItem
                  key={idx}
                  active={item === _attractData.class2}
                  onClick={() => {
                    _attractData.class2 = item;
                    setModifyFlag(item);
                  }}
                >
                  {item}
                </RadioItem>
              ) : null
            )}
          </RadioWrap>
          <FormBox>
            <FormLabel type={"normal"}>
              {_trans("fanupAdd.category2")}
            </FormLabel>
            <FormInput
              placeholder={_trans("fanupAdd.categoryText")}
              onChange={(event) => {
                _attractData.class3 = event.target.value;
                setModifyFlag(event.target.value);
              }}
            />
          </FormBox>
          <FormBox>
            <FormLabel>{_trans("fanupAdd.memo")}</FormLabel>
            <FormInput
              placeholder={_trans("fanupAdd.memoText")}
              onChange={(event) => {
                _attractData.memo = event.target.value;
                setModifyFlag(event.target.value);
              }}
            />
          </FormBox>
          <PictureBox>
            <FormLabel>
              {_trans("fanupAdd.images")}{" "}
              <PictureBoxTips>{_trans("fanupAdd.imagesText")}</PictureBoxTips>
            </FormLabel>
            <input
              id={"picture"}
              type={"file"}
              style={{ display: "none" }}
              onChange={_onFileChange}
              multiple={true}
              accept="image/*"
            ></input>
            <PictureCount>{(_attractData.images || []).length}/8</PictureCount>
            <PictureInner>
              {_attractData.images &&
                _attractData.images!.map((item, idx) => (
                  <PictureItem src={item.url} key={`img${idx}`}></PictureItem>
                ))}
              <PictureButton
                onClick={() => {
                  document.getElementById("picture")!.click();
                }}
              >
                {_trans("button.photo")}
              </PictureButton>
            </PictureInner>
          </PictureBox>
          <AgreeLabel>
            {_trans("fanupAdd.imagesAgree")}
            <AgreeCheck
              type={"checkbox"}
              checked={_attractData.isAgree}
              onClick={(event) => {
                _attractData.isAgree = (
                  event.target as HTMLInputElement
                ).checked;
                setModifyFlag(`${_attractData.isAgree}`);
              }}
            />
          </AgreeLabel>
          <ButtonGroup>
            <FormButton
              active={_checkValidation(_attractData)}
              onClick={() => {
                if (_checkValidation(_attractData)) {
                  setModifyFlag("F:DONE");
                } else {
                  alert(t("pleaseAllWrite"));
                  return;
                }
              }}
            >
              {_trans("fanupAdd.register")}
            </FormButton>
            <FormButton active={false} onClick={() => navigate(-1)}>
              {_trans("fanupAdd.cancel")}
            </FormButton>
          </ButtonGroup>
        </InputSection>
      </Inner>
      {modifyFlag === "F:DONE"
        ? auth && auth.isLoggedIn
          ? _confirmModal()
          : _loginModal()
        : null}
      {modifyFlag === "F:CREATED" ? _successModal() : null}
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default FanUpAddPage;

import VoteCategory from "../../../data-types/vote-category";
import DemandingVote from "../../../data-types/demanding-vote";
import { useEffect, useState } from "react";
import useHandleAppError from "../../../services/handle-app-error";
import { strict as assert } from "assert";
import DemandingVoteLog from "../../../data-types/demanding-vote-log";
import useReadVoteCategories from "../../../services/read-vote-categories";
import useReadActiveDemandingVotesByCategory from "../../../services/read-active-demanding-votes-by-category";
import useReadDemandingVoteLog from "../../../services/read-demanding-vote-log";
import useToAppropriateVoteDetails from "../../../services/to-appropriate-vote-details";
import useAuth from "../../../services/auth";
import { guestObjectId } from "../../../config";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  voteCategories: VoteCategory[];
  currentTabIndex: number;
  currentVoteCategory: VoteCategory;
  votes: Record<string, DemandingVote[]>;
  voteLogs: Record<string, (DemandingVoteLog | undefined)[]>;
  currentVotes: DemandingVote[];
  currentVoteLogs: (DemandingVoteLog | undefined)[];
  onTabOfIndexClick: (index: number) => void;
  onVoteClick: (vote: DemandingVote) => void;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  voteCategories: VoteCategory[];
  currentTabIndex: number;
  votes: Record<string, DemandingVote[]>;
  voteLogs: Record<string, (DemandingVoteLog | undefined)[]>;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const auth = useAuth();
  const handleAppError = useHandleAppError();
  const readVoteCategories = useReadVoteCategories();
  const readActiveDemandingVotesByCategory =
    useReadActiveDemandingVotesByCategory();
  const readDemandingVoteLog = useReadDemandingVoteLog();
  const toAppropriateVote = useToAppropriateVoteDetails();

  const [state, setState] = useState<State>({ status: "LOADING" });

  const tryInit = async () => {
    const voteCategories: VoteCategory[] = await readVoteCategories();

    const votes: Record<string, DemandingVote[]> = {};
    await Promise.all(
      voteCategories.map(async (voteCategory: VoteCategory) => {
        votes[voteCategory.id] = await readActiveDemandingVotesByCategory(
          voteCategory.id
        );
      })
    );

    const voteLogs: Record<string, (DemandingVoteLog | undefined)[]> = {};
    await Promise.all(
      voteCategories.map(async (voteCategory: VoteCategory) => {
        voteLogs[voteCategory.id] = await Promise.all(
          votes[voteCategory.id].map(async (vote: DemandingVote) => {
            if (auth.userId) {
              if (!auth.isLoggedIn) {
                return undefined;
              }
            }

            const result = await readDemandingVoteLog(vote.id);
            return result;
          })
        );
      })
    );

    setState({
      status: "LOADED",
      voteCategories,
      votes,
      voteLogs,
      currentTabIndex: 0,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      console.log(error);
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const currentVoteCategory = state.voteCategories[state.currentTabIndex];
  const currentVotes = state.votes[currentVoteCategory.id];
  const currentVoteLogs = state.voteLogs[currentVoteCategory.id];

  const onTabOfIndexClick = (index: number) => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, currentTabIndex: index };
    });
  };

  const tryOnVoteClick = (vote: DemandingVote) => {
    const getVoteLogOfVote = (): DemandingVoteLog | undefined => {
      const voteOfId = (curVote: DemandingVote) => curVote.id === vote.id;

      const voteIndex = currentVotes.findIndex(voteOfId);
      assert(voteIndex !== -1);

      return currentVoteLogs[voteIndex];
    };

    toAppropriateVote(vote, getVoteLogOfVote());
  };

  const onVoteClick = async (vote: DemandingVote) => {
    try {
      return tryOnVoteClick(vote);
    } catch (error) {
      await handleAppError(error);
    }
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      voteCategories: state.voteCategories,
      currentTabIndex: state.currentTabIndex,
      votes: state.votes,
      voteLogs: state.voteLogs,
      currentVoteCategory,
      currentVotes,
      currentVoteLogs,
      onVoteClick,
      onTabOfIndexClick,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

import React from "react";
import FullNavigationGoBackBottomTabLayout from "../../components/full-navigation-bottom-tab-layout";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { black, cg300, cg60, mainColor, primary } from "../../styles";
import useLogic from "./use-logic";
import useToCurrentLocale from "../../services/to-current-locale";

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 18px 16px 18px 16px;
`;

const Title = styled.span`
  display: block;
  text-align: center;
  font-size: 18px;
  color: ${black};
`;

const Table = styled.table`
  margin-top: 18px;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  cursor: pointer;
`;

const Td = styled.td`
  border-collapse: collapse;
  height: 48px;
  border-top: 1px solid ${cg60};

  &:nth-child(1) {
    text-align: center;
    color: ${mainColor};
    font-weight: bold;
    font-size: 12px;
  }

  &:nth-child(2) {
    text-align: left;
    color: ${black};
    font-size: 14px;
  }

  &:nth-child(3) {
    text-align: center;
    color: ${cg300};
    font-size: 12px;
  }
`;

const LoadMoreCont = styled.div`
  align-self: center;
  margin-top: 16px;
`;

const LoadingMore = styled.span`
  color: gray;
`;

const LoadMore = styled.span`
  align-self: center;
  cursor: pointer;
  color: gray;
  text-decoration: underline;
`;

interface DumbAnnouncement {
  id: string;
  kind: string;
  title: string;
  issuedDate: Date;
  onPress: () => void;
}

interface DumbProps {
  announcements: DumbAnnouncement[];
  loadMore: () => void;
  isLoadingMore: boolean;
}

const Dumb: React.FC<DumbProps> = (props) => {
  const { t } = useTranslation("pageAnnouncements");

  // todo announcement title ellipsis was not applied. Do this later.
  return (
    <Cont>
      <Title>{t("title")}</Title>
      <Table>
        <tbody>
          {props.announcements.map((announcement: DumbAnnouncement) => {
            return (
              <Tr key={announcement.id} onClick={announcement.onPress}>
                <Td>{announcement.kind}</Td>
                <Td>{announcement.title}</Td>
                <Td>{announcement.issuedDate.toLocaleDateString()}</Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
      <LoadMoreCont>
        {props.isLoadingMore ? (
          <LoadingMore>{t("loading")}</LoadingMore>
        ) : (
          <LoadMore onClick={props.loadMore}>{t("loadMore")}</LoadMore>
        )}
      </LoadMoreCont>
    </Cont>
  );
};

const AnnouncementsPage: React.FC = () => {
  const logic = useLogic();

  const toCurrentLocale = useToCurrentLocale();

  if (logic.status !== "LOADED") {
    return null; // todo
  }

  return (
    <FullNavigationGoBackBottomTabLayout title={"MY"}>
      <Dumb
        announcements={logic.announcements.map(
          (announcement, announcementIndex) => {
            return {
              id: announcement.id,
              kind: announcement.kind,
              title: toCurrentLocale(announcement.title),
              issuedDate: announcement.issuedDate,
              onPress: () =>
                logic.onAnnouncementOfIndexPress(announcementIndex),
            };
          }
        )}
        loadMore={logic.loadMore}
        isLoadingMore={logic.isLoadingMore}
      />
    </FullNavigationGoBackBottomTabLayout>
  );
};

export default AnnouncementsPage;

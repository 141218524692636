import axios from 'axios';
import DemandingVoteStatistics from "../data-types/demanding-vote-statistics";
import {apiAddr} from "../config";
import serializeStatistics from '../from-server-serializers/demanding-vote-statistics';

const useReadDemandingVoteStatistics = () => {
  return async (voteId: string): Promise<DemandingVoteStatistics> => {
    const axiosResult = await axios({
      method: 'get',
      url: `${apiAddr}/v1/votes/${voteId}/demanding-vote-statistics`,
    });

    const statistics = serializeStatistics(axiosResult.data);

    return statistics;
  };
};

export default useReadDemandingVoteStatistics;

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Buttons from "./buttons";
import MultilingualString from "../../data-types/multilingual-string";
import i18next from "i18next";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";

const Cont = styled.div`
  background-color: white;
  border-radius: 4px;
  padding-top: 5px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  min-width: 330px;

  overflow: hidden;
`;

const ShareList = styled.div`
  padding: 15px 15px 15px 15px;
  text-align: center;
  align-self: center;
`;

const ShareItem = styled.div`
  display: flex;
  text-align: center;
  padding: 10px 0px 10px 0px;
`;

interface VoteIdolProps {
  className?: string | undefined;
  title: string;
  url: string;
  image: string;
  onCancel: () => void;
}

const VoteIdol: React.FC<VoteIdolProps> = (props) => {
  const { t } = useTranslation("componentVoteEvent");
  const curLang = i18next.language as keyof MultilingualString;

  return (
    <Cont>
      <ShareList>
        <ShareItem>
          <FacebookShareButton
            style={{ marginRight: "20px" }}
            url={props.url}
            title={props.title}
          >
            <FacebookIcon
              size={48}
              round={true}
              borderRadius={24}
            ></FacebookIcon>
          </FacebookShareButton>
          <InstapaperShareButton
            style={{ marginRight: "20px" }}
            url={props.url}
            title={props.title}
          >
            <InstapaperIcon
              size={48}
              round={true}
              borderRadius={24}
            ></InstapaperIcon>
          </InstapaperShareButton>
          <TwitterShareButton
            style={{ marginRight: "20px" }}
            url={props.url}
            title={props.title}
          >
            <TwitterIcon size={48} round={true} borderRadius={24}></TwitterIcon>
          </TwitterShareButton>
          <LineShareButton url={props.url} title={props.title}>
            <LineIcon size={48} round={true} borderRadius={24}></LineIcon>
          </LineShareButton>
        </ShareItem>
        <ShareItem>
          <LinkedinShareButton url={props.url} title={props.title}>
            <LinkedinIcon
              size={48}
              round={true}
              borderRadius={24}
            ></LinkedinIcon>
          </LinkedinShareButton>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <PinterestShareButton
            media={props.image}
            url={props.url}
            title={props.title}
          >
            <PinterestIcon
              size={48}
              round={true}
              borderRadius={24}
            ></PinterestIcon>
          </PinterestShareButton>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <TelegramShareButton url={props.url} title={props.title}>
            <TelegramIcon
              size={48}
              round={true}
              borderRadius={24}
            ></TelegramIcon>
          </TelegramShareButton>
        </ShareItem>
      </ShareList>
      <Buttons
        onCancel={props.onCancel}
        cancelText={t("componentVoteEvent:close")}
      />
    </Cont>
  );
};

export default VoteIdol;

import Region from "../data-types/region";
import axios from 'axios';
import {apiAddr} from "../config";
import serializeRegion from '../from-server-serializers/region';
import RegionNotFoundError from "../errors/region-not-found";

const readRegions = async (): Promise<Record<string, Region>> => {
  const axiosResult = await axios({
    method: 'get',
    url: `${apiAddr}/v1/regions`,
    params: { limit: 500 },
  });

  const regions: Region[] = axiosResult.data.map(serializeRegion);

  const regionRecord = Object.fromEntries(regions.map((region) => [region.id, region]));

  return regionRecord;
};

const regionsPromise: Promise<Record<string, Region>> = readRegions();

const useReadRegion = () => {
  return async (id: string): Promise<Region> => {
    const region = (await regionsPromise)[id];

    if (region == null) {
      throw new RegionNotFoundError(id);
    }

    return region;

    // try {
    //   const axiosResult = await axios({
    //     method: 'get',
    //     url: `${apiAddr}/v1/regions/${id}`,
    //   });
    //
    //   const region: Region = serializeRegion(axiosResult.data);
    //
    //   return region;
    // } catch (error) {
    //   throw new ReadRegionServerError(error);
    // }
  };
};

export default useReadRegion;

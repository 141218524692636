import useAuth from "../auth";
import { apiAddr } from "../../config";
import {
  FanUpAttractionPayload,
  FanUpAttractionPickPayload,
  FanUpAttractionReviewPayload,
} from "../../data-types/attraction";
import serializeAnnouncement from "../../from-server-serializers/announcement";

/**
 * 찜 추가
 */
export const useCreateAttractionPick = () => {
  const auth = useAuth();

  return async (pick: FanUpAttractionPickPayload): Promise<void> => {
    pick.userId = auth.userId;
    const result = await auth.callAxios({
      method: "post",
      url: `${apiAddr}/v1/attraction-pick/${pick.attractId}`,
      data: pick,
    });
  };
};
/**
 * 찜 삭제
 */
export const useDeleteAttractionPick = () => {
  const auth = useAuth();

  return async (pick: FanUpAttractionPickPayload): Promise<void> => {
    pick.userId = auth.userId;
    const result = await auth.callAxios({
      method: "delete",
      url: `${apiAddr}/v1/attraction-pick/${pick.attractId}`,
      data: pick,
    });
  };
};

/**
 * 관광지 찜 목록 조회
 */
export const useReadAttractionPickList = () => {
  const auth = useAuth();
  return async (): Promise<FanUpAttractionPayload[]> => {
    const result = await auth.callAxios({
      method: "get",
      url: `${apiAddr}/v1/attraction-pick`,
      params: {
        userId: auth.userId,
      },
    });

    if (Array.isArray(result.data.data) && result.data.data.length > 0) {
      return result.data.data;
    } else {
      return [];
    }
  };
};

import React from "react";
import Modal from "react-modal";
import VoteShareComp from "../../components/vote-share";

import { maxTabletSizeInPx } from "../../styles";
import { useTranslation } from "react-i18next";

interface Props {
  isVisible: boolean;
  title: string;
  url: string;
  image: string;
  onRequestClose: () => void;
  onCancel: () => void;
}

const VoteEventModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation("modalVoteIdol");

  return (
    <Modal
      isOpen={props.isVisible}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          padding: 0,
          margin: 0,
          textAlign: "center",
          alignSelf: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          inset: "150px -5px",
          margin: "auto",
          textAlign: "center",
          alignSelf: "center",
          borderRadius: 0,
          background: "transparent",
          border: 0,
          minWidth: "330px",
          height: "500px",
          maxWidth: maxTabletSizeInPx,
        },
      }}
    >
      <VoteShareComp
        title={props.title}
        url={props.url}
        image={props.image}
        onCancel={props.onCancel}
      />
    </Modal>
  );
};

export default VoteEventModal;

import TournamentVoteRound from "../data-types/tournament-vote-round";

const tournamentCurrentRoundIndex = (round: TournamentVoteRound): number => {
  const getBase = (nthLevel: number): number => {
    if (nthLevel === 0) {
      return 0;
    }

    return round.originalChoiceCount / (nthLevel * 2) + getBase(nthLevel - 1);
  };

  const base = getBase(round.nthLevel);

  return base + round.nthRoundOfThisLevel;
};

export default tournamentCurrentRoundIndex;

import React from "react";
import styled from "styled-components";
import { coolGray, textBox, placeHolder } from "../styles";
import { text } from "stream/consumers";

const StyledInputBox = styled.textarea`
  /* text field */
  height: 180px;
  /* Coolgray/CG00 */
  background: #ffffff;
  color: ${textBox};
  font-size: 16px;
  /* Coolgray/CG100 */
  border: 1px solid ${coolGray};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 10px 10px 10px;

  &::placeholder {
    color: ${placeHolder};
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`;

type TextAreaProps = {
  className?: string | undefined;
  placeholder?: string | undefined;
  onChange?: ((newValue: string) => void) | undefined;
  value?: string | undefined;
  preventAutoComplete?: boolean | undefined;
};

const TextArea = (props: TextAreaProps) => {
  const onChange = (e: any) => {
    props.onChange && props.onChange(e.target.value);
  };

  return (
    <StyledInputBox
      onChange={onChange}
      className={props.className}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
};

export default TextArea;
export type { TextAreaProps };

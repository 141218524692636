import React from "react";
import styled, { css } from "styled-components";
import {
  black,
  cg300,
  mainEventColor,
  pointColor,
  primary,
  shadow2,
} from "../../../../../styles";
import VoteButton from "./vote-button";
import FatProgressBar from "../../../../../components/fat-progress-bar";
import Idol from "../../../../../data-types/idol";
import { LoadedLogic } from "../../../use-logic";
import { ReactComponent as LocationIcon } from "./location-icon.svg";
import { ReactComponent as LocationIconFirst } from "./location-icon_first.svg";
import useToCurrentLocale from "../../../../../services/to-current-locale";
import leadingCandidateScale from "../../../utils/leading-candidate-scale";
import IdolVote from "../../../../../data-types/idol-vote";

const Cont = styled.div`
  margin-top: 12px;

  &:first-of-type {
    margin-top: 0px;
  }

  //height: 96px;
  border-radius: 4px;
  box-shadow: ${shadow2};
  padding: 10px;
  display: flex;
`;

const ThumbnailCont = styled.div<{ first: boolean }>`
  width: 76px;
  height: 76px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  ${({ first }) =>
    first &&
    css`
      border: 3px solid ${mainEventColor};
    `}
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
`;

const ThumbnailTextCont = styled.div`
  top: 0px;
  left: 0px;
  position: absolute;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 2px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.88);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThumbnailText = styled.span<{ first: boolean }>`
  font-size: 14px;
  color: ${({ first }) =>
    first ? `${mainEventColor}` : `${mainEventColor}`} !important;
  font-weight: bold;
`;

const Contents = styled.div`
  margin-left: 8px;
  flex: 1;
`;

const NonVoteCount = styled.div`
  display: flex;
`;

const TheFatProgressBar = styled(FatProgressBar)`
  margin-top: 8px;
`;

const Metadata = styled.div`
  flex: 1;
`;

const IdolName = styled.span`
  display: block;
  color: ${black};
  font-size: 14px;
  line-height: 18px;
`;

const IdolAssociation = styled.span`
  display: block;
  color: ${cg300};
  font-size: 12px;
  line-height: 15px;
  margin-top: 2px;
`;

const IdolRegionCont = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
`;

const IdolRegionText = styled.span`
  margin-left: 4px;
  font-size: 12px;
  line-height: 15px;
  color: #a0a0a0; /* I accepted a devil. */
`;

interface Props {
  idol: Idol;
  vote: IdolVote;
  idolRanking: number;
  logic: LoadedLogic;
}

const Candidate: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();
  const idolAssociation =
    props.idol.association != null
      ? props.logic.associations[props.idol.association]
      : props.idol.association;
  const idolRegion =
    props.idol.region != null
      ? props.logic.regions[props.idol.region]
      : props.idol.region;
  const idolCountry =
    idolRegion != null ? props.logic.countries[idolRegion.country] : idolRegion;
  const currentVote = props.logic.idolVotes[props.logic.currentTabIndex];
  const currentCandidates = props.logic.idolVoteCandidates[currentVote.id];
  const firstIdolVoteCount = currentCandidates[0].voteCount;
  const currentIdolVoteCount = currentCandidates[props.idolRanking].voteCount;

  const scale =
    firstIdolVoteCount !== 0
      ? (currentIdolVoteCount / firstIdolVoteCount) * leadingCandidateScale
      : 0;

  return (
    <Cont>
      <ThumbnailCont first={props.idolRanking === 0}>
        <Thumbnail src={props.idol.thumbnailUrl} />
        <ThumbnailTextCont>
          <ThumbnailText first={props.idolRanking === 0}>
            {props.idolRanking + 1}
          </ThumbnailText>
        </ThumbnailTextCont>
      </ThumbnailCont>

      <Contents>
        <NonVoteCount>
          <Metadata>
            <IdolName>{toCurrentLocale(props.idol.name)}</IdolName>
            <IdolAssociation>
              {
                idolAssociation != null
                  ? toCurrentLocale(idolAssociation.name)
                  : " " // This keeps text height.
              }
            </IdolAssociation>
            <IdolRegionCont>
              {props.idolRanking === 0 ? (
                <LocationIconFirst />
              ) : (
                <LocationIcon />
              )}
              <IdolRegionText>
                {
                  idolRegion != null
                    ? toCurrentLocale(idolRegion.name) +
                      ", " +
                      toCurrentLocale(idolCountry!.name)
                    : " " // This keeps text height.
                }
              </IdolRegionText>
            </IdolRegionCont>
          </Metadata>
          <VoteButton idol={props.idol} vote={props.vote} logic={props.logic} />
        </NonVoteCount>
        <TheFatProgressBar
          scale={scale}
          label={currentIdolVoteCount}
          first={props.idolRanking === 0}
        />
      </Contents>
    </Cont>
  );
};

export default Candidate;

import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Buttons from "./buttons";
import MultilingualString from "../../data-types/multilingual-string";
import i18next from "i18next";

const Cont = styled.div`
  background-color: white;
  border-radius: 4px;
  padding-top: 5px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  min-width: 330px;

  overflow: hidden;
`;

interface VoteIdolProps {
  className?: string | undefined;
  gotoJoin: () => void;
  onCancel: () => void;
}

const VoteIdol: React.FC<VoteIdolProps> = (props) => {
  const { t } = useTranslation("componentVoteEvent");
  const curLang = i18next.language as keyof MultilingualString;

  return (
    <Cont>
      {curLang === "en" && <img src={`/asset/image/event-EN.png`} />}
      {curLang === "kr" && <img src={`/asset/image/event-KR.png`} />}
      {curLang === "zh" && <img src={`/asset/image/event-ZH.png`} />}

      <Buttons
        onSubmit={props.gotoJoin}
        onCancel={props.onCancel}
        joinText={t("componentVoteEvent:join")}
        cancelText={t("componentVoteEvent:close")}
      />
    </Cont>
  );
};

export default VoteIdol;

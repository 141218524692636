import VoteCategory from "../data-types/vote-category";

const serialize = (data: Record<string, any>): VoteCategory => {
  return {
    id: data.id,
    name: data.name,
  };
};

export default serialize;

import InternalFile from "../data-types/internal-file";
import registerTemporaryFile from "./register-temporary-file";

export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
};

export const uploadAndCreateInternalFile = async (file: File): Promise<InternalFile> => {
    return {
        url: await blobToBase64(file),
        isNew: true,
        storeKey: await uploadAndTakeStoreKey(file,'upload-attraction-image'),
    };
};
const uploadAndTakeStoreKey = async (file: File,requestUploadInfoPath:string): Promise<string> => {
    const registerIdolThumbnail = registerTemporaryFile(requestUploadInfoPath);
    const key = await registerIdolThumbnail(file);
    return key;
};

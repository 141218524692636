import React from "react";
import styled from "styled-components";
import { maxTabletSizeInPx, safeEndingPaddingSizeInPx } from "../../styles";
import BottomTabApp from "../bottom-tab-app";
import NavigationBarWithApp from "../full-navigation-bar-app";

interface BottomTabLayoutProps {
  children?: any;
}

const WideCont = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`;

const Cont = styled.div`
  height: 100%;
  max-width: ${maxTabletSizeInPx}px;

  display: flex;
  flex-direction: column;

  margin: auto;
`;

const ChildrenCont = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SafePadding = styled.div`
  height: ${safeEndingPaddingSizeInPx}px;
`;

const BottomTabLayout: React.FC<BottomTabLayoutProps> = (props) => {
  return (
    <WideCont>
      <Cont>
        <NavigationBarWithApp title={``} />
        <ChildrenCont>
          {props.children}
          <SafePadding />
        </ChildrenCont>
        <BottomTabApp />
      </Cont>
    </WideCont>
  );
};

export default BottomTabLayout;
export type { BottomTabLayoutProps };

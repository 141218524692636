import Idol from "../../data-types/idol";
import { useEffect, useState } from "react";
import useCurrentUser from "../../services/current-user";
import { strict as assert } from "assert";
import useHandleAppError from "../../services/handle-app-error";
import { useTranslation } from "react-i18next";
import IdolVote from "../../data-types/idol-vote";
import useVoteIdol, { VoteIdolServerError } from "../../services/vote-idol";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  myTicketCount: number;
  voteCount: string;
  setVoteCount: (newValue: string) => void;
  vote: () => void;
  isVoteInProgress: boolean;
}

interface FailedLogic {
  status: "FAILED";
  becauseNotLoggedIn?: boolean | undefined;
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  myTicketCount: number;
  voteCount: string;
  isVoteInProgress: boolean;
}

interface FailedState {
  status: "FAILED";
  becauseNotLoggedIn?: boolean | undefined;
}

type State = LoadingState | LoadedState | FailedState;

class MalformedVoteCountError extends Error {
  voteCount: string;

  constructor(voteCount: string) {
    super(`voteCount cannot be parsed into string: "${voteCount}"`);
    this.voteCount = voteCount;
  }
}

class LackOfTicketError extends Error {
  required: number;
  current: number;

  constructor(params: { required: number; current: number }) {
    super(
      `Trying to vote more than possessing ticket count, REQUIRED: ${params.required}, CURRENT: ${params.current}`
    );
    this.required = params.required;
    this.current = params.current;
  }
}

interface Props {
  vote: IdolVote | undefined;
  idol: Idol | undefined;
  close: () => void;
  onVoteDone: (p: { idol: Idol; vote: IdolVote; voteCount: number }) => void;
}

const useLogic = (props: Props): Logic => {
  const [state, setState] = useState<State>({
    status: "LOADING",
  });

  const voteIdol = useVoteIdol();
  const currentUserService = useCurrentUser();
  const handleAppError = useHandleAppError();
  const { t } = useTranslation("modalVoteIdol");

  const tryInit = async () => {
    if (props.idol == null) {
      setState({ status: "LOADING" });
      return;
    }

    const currentUser = await currentUserService.read();

    if (currentUser == null) {
      setState({ status: "FAILED", becauseNotLoggedIn: true });
      return;
    }

    setState({
      status: "LOADED",
      myTicketCount: currentUser.ticketCount,
      voteCount: "",
      isVoteInProgress: false,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, [props.idol?.id]);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    if (state.becauseNotLoggedIn) {
      return {
        status: "FAILED",
        becauseNotLoggedIn: true,
      };
    }

    return { status: "FAILED" };
  }

  const setVoteCount = (newValue: string) => {
    setState((oldState) => ({ ...oldState, voteCount: newValue }));
  };

  const checkInput = () => {
    const voteCountInNumber = Number(state.voteCount);

    if (Number.isNaN(voteCountInNumber)) {
      throw new MalformedVoteCountError(state.voteCount);
    }

    if (voteCountInNumber < 1) {
      throw new MalformedVoteCountError(state.voteCount);
    }

    if (voteCountInNumber > state.myTicketCount) {
      throw new LackOfTicketError({
        required: voteCountInNumber,
        current: state.myTicketCount,
      });
    }
  };

  const requestVote = async () => {
    assert(props.idol != null);
    assert(props.vote != null);

    const voteCountInNumber = Number(state.voteCount);

    await voteIdol({
      vote: props.vote.id,
      idol: props.idol.id,
      voteCount: voteCountInNumber,
    });
  };

  const tryVote = async () => {
    checkInput();
    await requestVote();
    props.onVoteDone({
      idol: props.idol!,
      vote: props.vote!,
      voteCount: Number(state.voteCount),
    });
    props.close();
  };

  const logicVote = async () => {
    try {
      await tryVote();
    } catch (error) {
      if (error instanceof MalformedVoteCountError) {
        alert(t("modalVoteIdol:pleaseEnterCorrectVoteCount"));
        return;
      }

      if (error instanceof LackOfTicketError) {
        alert(t("modalVoteIdol:cannotVoteMoreThanYourTicketCount"));
        return;
      }

      if (error instanceof VoteIdolServerError) {
        alert(t("modalVoteIdol:unknownErrorOccurred"));
        return;
      }

      await handleAppError(error);
    }
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      myTicketCount: state.myTicketCount,
      isVoteInProgress: state.isVoteInProgress,
      voteCount: state.voteCount,
      setVoteCount,
      vote: logicVote,
    };
  }

  assert.fail();
};

export default useLogic;

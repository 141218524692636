import Idol from "../data-types/idol";

const serialize = (data: Record<string, any>): Idol => {
  return {
    id: data.id,
    association: data.association,
    name: data.name,
    region: data.region,
    thumbnailUrl: data.thumbnail,
  };
};

export default serialize;

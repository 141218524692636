import { useNavigate } from 'react-router-dom';

const useToLoginPage = () => {
  const nav = useNavigate();

  return () => {
    nav('/login');
  };
};

export default useToLoginPage;

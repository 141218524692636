import React from 'react';
import styled from 'styled-components';
import {LoadedLogic} from "../../use-logic";
import Header from "./header";
import TimeLeft from "./time-left";
import Description from "./description";

const Cont = styled.div`
`;

interface Props {
  logic: LoadedLogic;
}

const VoteInfoFragment: React.FC<Props> = (props) => {
  return (
    <Cont>
      <Header logic={props.logic}/>
      <TimeLeft logic={props.logic}/>
      <Description logic={props.logic}/>
    </Cont>
  );
};

export default VoteInfoFragment;

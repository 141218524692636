import axios from 'axios';
import {apiAddr} from "../config";
import {createServerError, ServerError} from "../utils/server-error";

interface Params {
  verifyAccountToken: string;
}

const VerifyAccountServerError = createServerError();
type VerifyAccountServerError = ServerError;

const useVerifyAccount = () => {
  return async (params: Params) => {
    try {
      await axios({
        method: 'post',
        url: `${apiAddr}/v1/auth/verify-email`,
        params: {token: params.verifyAccountToken},
      });
    } catch (error) {
      throw new VerifyAccountServerError(error);
    }
  };
};

export default useVerifyAccount;
export {VerifyAccountServerError};

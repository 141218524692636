import React from "react";
import styled, { css } from "styled-components";
import {
  cg100,
  cg500,
  eventLeft,
  eventRight,
  mainEventColor,
  pointColor,
  fadedEvent,
  mainColor,
} from "../../styles";
import { ReactComponent as VsIcon } from "./vs-icon.svg";
import EventVoteBrief from "../event-vote-brief";
import VoteButton from "../vote-button";
import { ReactComponent as Clock } from "./clock.svg";

const Cont = styled.div``;

const BottomCont = styled.div`
  padding: 18px 16px 18px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const GraySpace = styled.div`
  height: 8px;
  background-color: #ededed; // I'm feeling bad.
`;

const Content = styled.div`
  padding: 18px 0px 18px 0px;
  background-color: white;
  display: flex;
  justify-content: space-between;
`;

// const ContentHeader = styled.div`
//   height: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   position: relative;
// `;

const DateLeft = styled.span<{ color: string }>`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  padding-left: 10px;
  color: ${({ color }) => color};
`;

const Round = styled.span<{ color: string }>`
  font-size: 18px;
  color: ${({ color }) => color};
`;

const ContentTitleCont = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;

  padding: 18px 0px 0px 0px;
  background-color: white;
`;

const ContentTitle = styled.span`
  isplay: flex;
  text-align: center;
  justify-content: center;

  background: #FF176B;
  border-radius: 8px;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;

  width: 327px;
  height: 40px;

  letter-spacing: -0.24px;

  color: #ffffff;
`;

const Choices = styled.div`
  display: flex;
  position: relative;
  align-self: center;
`;

const ChoicesGap = styled.div`
  width: 8px;
  display: flex;
  justify-content: center;
`;

const ChoiceCompA = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  max-width: 165px;
  border: 2px solid ${eventLeft};
  border-radius: 5px;
`;

const ChoiceCompB = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  max-width: 165px;
  border: 2px solid ${eventRight};
  border-radius: 5px;
`;

const ChoiceImage = styled.img<{ active: boolean }>`
  display: flex;
  min-width: 165px;
  max-width: 165px;
  height: 122px;
  object-fit: cover;
  opacity: ${({ active }) => (active ? "0.7" : "1")};
  align-items: center;
  justify-content: center;
`;

const ChoiceTitleCont = styled.div<{ left: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 88px;
  border-top: 1px solid ${({ left }) => (left ? mainEventColor : "#09A7A7")};
  border-bottom: 1px solid ${({ left }) => (left ? mainEventColor : "#09A7A7")};
`;

const ChoiceTitle = styled.span<{ active: boolean; colorWhenActive: string }>`
  display: block;
  text-align: center;

  display: block;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
`;

const ChoiceDescription = styled.span`
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: ${cg500};
  margin-top: 4px;
  padding: 5px;
`;

const ChoiceAction = styled.span<{
  active: boolean;
  left: boolean;
  color: string;
  backgroundColor: string;
}>`
  display: block;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0px;
  color: ${({ color }) => color};
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ active, left }) =>
    active ? (left ? "#FFF5F5" : "#EFFBFF") : ""};
  //background-color: ${({ active, backgroundColor }) =>
    active ? backgroundColor : "white"};
`;

const TheVoteButton = styled.div`
  display: flex;
  align-self: center;
  text-align: center;
  width: 100%;
  border-radius: 0px;
`;

const SelectBoxChoice = styled.div<{ active: boolean }>`
  opacity: ${({ active }) => (active ? "0.6" : "1")};
  background-position: center 50px;
  background-repeat: no-repeat;
  //display: ${({ active }) => (active ? "block" : "none")};
`;

const ChoiceRect = styled.div<{ active: boolean }>`
  display: flex;
  .choice-img-a {
    position: absolute;
    left: 110px;
    top: 22%;
  }
  .choice-img-b {
    position: absolute;
    left: 179px;
    top: 22%;
  }
`;

const Button = styled.div<{ backgroundColor: string }>`
  height: 40px;
  padding: 5px 27px 0px 27px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  align-self: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonText = styled.span<{ color: string }>`
  font-family: "BalooBhaijaan";
  font-size: 24px;
  font-weight: bold;
  color: ${({ color }) => color};
`;

interface Choice {
  imageSrc?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  actionTitle?: string | undefined;
  onPress?: (() => void) | undefined;
  onActionPress?: (() => void) | undefined;
  selectedChoiceIndex?: number | undefined;
  isSelected?: boolean | undefined;
}

interface DemandingVoteRoundProps {
  className?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  dateLeft?: number | string | undefined;
  totalRoundCount?: number | string | undefined;
  currentRoundIndex?: number | string | undefined;
  choices: Choice[];
  onNextPress?: (() => void) | undefined;
  cateTitle?: string | undefined;

  color: string;
  backgroundColor: string;
  selectedChoiceIndex?: number | undefined;
}

const EventVoteRound: React.FC<DemandingVoteRoundProps> = (props) => {
  const getChoiceOfIndex = (index: number) => {
    const choiceData = props.choices[index];

    return (
      <>
        {index === 0 ? (
          <ChoiceCompA onClick={choiceData.onPress}>
            <SelectBoxChoice active={choiceData.isSelected ?? false}>
              <ChoiceRect active={choiceData.isSelected ?? false}>
                <ChoiceImage
                  src={choiceData.imageSrc}
                  active={choiceData.isSelected ?? false}
                />
                {index === 0 ? (
                  <img
                    src={`/asset/image/vote-a.png`}
                    className={`choice-img-a`}
                  />
                ) : (
                  <img
                    src={`/asset/image/vote-b.png`}
                    className={`choice-img-b`}
                  />
                )}
              </ChoiceRect>
              <ChoiceTitleCont left={true}>
                <ChoiceTitle
                  colorWhenActive={props.color}
                  active={choiceData.isSelected ?? false}
                >
                  {choiceData.title}
                </ChoiceTitle>
                <ChoiceDescription>{choiceData.description}</ChoiceDescription>
              </ChoiceTitleCont>
            </SelectBoxChoice>
            <ChoiceAction
              color={index === 0 ? eventLeft : eventRight}
              backgroundColor={`#edf1f5`}
              active={choiceData.isSelected ?? false}
              left={true}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                choiceData.onActionPress && choiceData.onActionPress();
              }}
            >
              {choiceData.actionTitle}
            </ChoiceAction>
          </ChoiceCompA>
        ) : (
          <ChoiceCompB onClick={choiceData.onPress}>
            <SelectBoxChoice active={choiceData.isSelected ?? false}>
              <ChoiceRect active={choiceData.isSelected ?? false}>
                <ChoiceImage
                  src={choiceData.imageSrc}
                  active={choiceData.isSelected ?? false}
                />
                {index === 0 ? (
                  <img
                    src={`/asset/image/vote-a.png`}
                    className={`choice-img-a`}
                  />
                ) : (
                  <img
                    src={`/asset/image/vote-b.png`}
                    className={`choice-img-b`}
                  />
                )}
              </ChoiceRect>
              <ChoiceTitleCont left={false}>
                <ChoiceTitle
                  colorWhenActive={props.color}
                  active={choiceData.isSelected ?? false}
                >
                  {choiceData.title}
                </ChoiceTitle>
                <ChoiceDescription>{choiceData.description}</ChoiceDescription>
              </ChoiceTitleCont>
            </SelectBoxChoice>
            <ChoiceAction
              color={index === 0 ? eventLeft : eventRight}
              backgroundColor={`#edf1f5`}
              active={choiceData.isSelected ?? false}
              left={false}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                choiceData.onActionPress && choiceData.onActionPress();
              }}
            >
              {choiceData.actionTitle}
            </ChoiceAction>
          </ChoiceCompB>
        )}
      </>
    );
  };

  return (
    <Cont className={props.className}>
      <ContentTitleCont>
        <ContentTitle>{props.cateTitle}</ContentTitle>
      </ContentTitleCont>
      <EventVoteBrief title={props.title} description={props.description} />
      <TheVoteButton>
        <Button
          backgroundColor={
            props.selectedChoiceIndex !== undefined
              ? props.backgroundColor
              : `#EDF1F5`
          }
          onClick={props.onNextPress}
        >
          <ButtonText color={`#ffffff`}>NEXT &nbsp;&nbsp; 〉</ButtonText>
        </Button>
      </TheVoteButton>
      <BottomCont>
        <Content>
          <DateLeft color={props.color}>
            <Clock style={{ marginRight: "5px", transform: "scale(1.2)" }} />
            D-{props.dateLeft}
          </DateLeft>
          {props.totalRoundCount != null && props.currentRoundIndex != null ? (
            <Round color={props.color}>
              <strong>{props.currentRoundIndex}</strong>/{props.totalRoundCount}
            </Round>
          ) : null}
        </Content>
        <Choices>
          {getChoiceOfIndex(0)}
          <ChoicesGap></ChoicesGap>
          {getChoiceOfIndex(1)}
        </Choices>
      </BottomCont>
    </Cont>
  );
};

export default EventVoteRound;
export type { DemandingVoteRoundProps };

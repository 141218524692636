import React from "react";
import styled, { css } from "styled-components";
import { black, cg100, cg500 } from "../../styles";
import { ReactComponent as VsIcon } from "./vs-icon.svg";
import DemandingVoteBrief from "../demanding-vote-brief";
import VoteButton from "../vote-button";
import { ReactComponent as Clock } from "./clock.svg";

const Cont = styled.div``;

const BottomCont = styled.div`
  padding: 5px 16px 18px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const GraySpace = styled.div`
  height: 8px;
  background-color: #ededed; // I'm feeling bad.
`;

const SelectBoxChoice = styled.div<{ active: boolean }>`
  opacity: ${({ active }) => (active ? "0.6" : "1")};
  background-image: url(${({ active }) =>
    active ? "/choice-img.png" : "none"});
  background-position: center 50px;
  background-repeat: no-repeat;
  //display: ${({ active }) => (active ? "block" : "none")};
`;

const ChoiceBox = styled.div`
  position: absolute;
  left: 67.44%;
  right: 19.65%;
  top: 17.83%;
  bottom: 72.88%;
`;

const Content = styled.div`
  padding: 18px 0px 18px 0px;
  background-color: white;
  display: flex;
  justify-content: space-between;
`;

// const ContentHeader = styled.div`
//   height: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   position: relative;
// `;

const DateLeft = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;

const Round = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;

// const ContentTitleCont = styled.div`
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 100%;
// `;
//
// const ContentTitle = styled.span`
//   font-size: 24px;
//   font-weight: bold;
//   color: ${black};
// `;

const Choices = styled.div`
  display: flex;
  position: relative;
`;

const ChoicesGap = styled.div`
  width: 8px;
  display: flex;
  justify-content: center;
`;

const TheVsIconCont = styled.div`
  position: absolute;
  margin-top: 60px;
  justify-content: center;
  width: 100%;
  display: flex;
`;

const TheVsIcon = styled(VsIcon)``;

const ChoiceComp = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  text-align: center;
  border: 1px solid ${cg100};
`;

const ChoiceRect = styled.div<{ active: boolean }>`
  .choice-img {
    display: ${({ active }) => (active ? "block" : "none")};
    position: absolute;
    left: 67.44%;
    right: 19.65%;
    top: 17.83%;
    bottom: 72.88%;
  }
`;

const ChoiceImage = styled.img`
  // max-width: 175px;
  // height: 168px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

const ChoiceTitleCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 158px;
  justify-content: center;
  border-top: 1px solid ${cg100};
  padding: 16px;
`;

const ChoiceTitle = styled.span<{ active: boolean; colorWhenActive: string }>`
  display: block;
  text-align: center;

  display: block;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  ${({ active, colorWhenActive }) => {
    if (active) {
      return css`
        color: ${colorWhenActive};
      `;
    }

    return css`
      color: ${black};
    `;
  }}
`;

const ChoiceDescription = styled.span`
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: ${cg500};
  margin-top: 4px;
`;

const ChoiceAction = styled.span<{
  active: boolean;
  color: string;
  backgroundColor: string;
}>`
  display: block;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-top: 1px solid ${cg100} !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0px;
  color: ${({ active, color }) => (active ? "#ffffff" : color)};
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ active, backgroundColor }) =>
    active ? backgroundColor : "white"};
`;

const TheVoteButton = styled.div`
  display: flex;
  align-self: center;
  text-align: center;
  width: 100%;
  border-radius: 0px;
`;

const ContentTitleCont = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;

  padding: 18px 0px 0px 0px;
  background-color: white;
`;

const ContentTitle = styled.span`
  isplay: flex;
  text-align: center;
  justify-content: center;

  background: #FF176B;
  border-radius: 8px;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;

  width: 327px;
  height: 40px;

  letter-spacing: -0.24px;

  color: #ffffff;
`;

const StepText = styled.div`
  margin-left: -20px;
  color: #292e33;
  font-weight: bold;
`;

const Button = styled.div<{ backgroundColor: string }>`
  height: 40px;
  padding: 5px 27px 0px 27px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  align-self: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonText = styled.span<{ color: string }>`
  font-family: "BalooBhaijaan";
  font-size: 24px;
  font-weight: bold;
  color: ${({ color }) => color};
`;

interface Choice {
  imageSrc?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  actionTitle?: string | undefined;
  onPress?: (() => void) | undefined;
  onActionPress?: (() => void) | undefined;
  selectedChoiceIndex?: number | undefined;
  isSelected?: boolean | undefined;
}

interface DemandingVoteRoundProps {
  className?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  dateLeft?: number | string | undefined;
  totalRoundCount?: number | string | undefined;
  currentRoundIndex?: number | string | undefined;
  choices: Choice[];
  onNextPress?: (() => void) | undefined;
  selectedChoiceIndex?: number | undefined;

  cateTitle?: string | undefined;
  stepTitle?: string | undefined;

  color: string;
  backgroundColor: string;
}

const DemandingVoteRound: React.FC<DemandingVoteRoundProps> = (props) => {
  const getChoiceOfIndex = (index: number) => {
    const choiceData = props.choices[index];

    return (
      <ChoiceComp onClick={choiceData.onPress}>
        <SelectBoxChoice active={choiceData.isSelected ?? false}>
          <div>
            <ChoiceImage src={choiceData.imageSrc} />
          </div>
          <ChoiceTitleCont>
            <ChoiceTitle
              colorWhenActive={props.color}
              active={choiceData.isSelected ?? false}
            >
              {choiceData.title}
            </ChoiceTitle>
            <ChoiceDescription>{choiceData.description}</ChoiceDescription>
          </ChoiceTitleCont>
          <ChoiceAction
            color={props.color}
            backgroundColor={props.backgroundColor}
            active={choiceData.isSelected ?? false}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              choiceData.onActionPress && choiceData.onActionPress();
            }}
          >
            {choiceData.actionTitle}
          </ChoiceAction>
        </SelectBoxChoice>
      </ChoiceComp>
    );
  };

  return (
    <Cont className={props.className}>
      <ContentTitleCont>
        <ContentTitle>{props.cateTitle}</ContentTitle>
      </ContentTitleCont>
      <DemandingVoteBrief title={props.title} description={props.description} />
      <TheVoteButton>
        <Button
          backgroundColor={
            props.selectedChoiceIndex !== undefined
              ? props.backgroundColor
              : `#EDF1F5`
          }
          onClick={props.onNextPress}
        >
          <ButtonText color={`#ffffff`}>NEXT &nbsp;&nbsp; 〉</ButtonText>
        </Button>
      </TheVoteButton>
      <BottomCont>
        <Content>
          {/*<ContentTitleCont>*/}
          {/*  <ContentTitle>{}</ContentTitle>*/}
          {/*</ContentTitleCont>*/}
          <DateLeft color={props.color}>
            <Clock style={{ marginRight: "5px", transform: "scale(1.5)" }} />
            D-{props.dateLeft}
          </DateLeft>
          <StepText>{props.stepTitle}</StepText>
          {props.totalRoundCount != null && props.currentRoundIndex != null ? (
            <Round color={props.color}>
              <strong>{props.currentRoundIndex}</strong>/{props.totalRoundCount}
            </Round>
          ) : null}
        </Content>

        <Choices>
          <TheVsIconCont>
            <TheVsIcon />
          </TheVsIconCont>
          {getChoiceOfIndex(0)}
          <ChoicesGap></ChoicesGap>
          {getChoiceOfIndex(1)}
        </Choices>
      </BottomCont>
    </Cont>
  );
};

export default DemandingVoteRound;
export type { DemandingVoteRoundProps };

import React from "react";
import styled from "styled-components";
import { darkBlue, darkGray, mainEventColor } from "../styles/index";

const StyledTitle = styled.div<{ fontColor: string }>`
  // color: ${(props) => (props.fontColor ? props.fontColor : "gray")};
  color: ${mainEventColor};
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
`;
const StyledDiscription = styled.div<{ fontColor: string }>`
  color: ${(props) => (props.fontColor ? props.fontColor : "gray")};
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
`;

type NoticeTextProps = {
  className?: string | undefined;
  notice: string;
  desciption: string;
};

const NoticeText = ({ className, notice, desciption }: NoticeTextProps) => {
  return (
    <>
      <StyledTitle className={className} fontColor={darkBlue}>
        {notice}
      </StyledTitle>
      <StyledDiscription fontColor={darkGray}>{desciption}</StyledDiscription>
    </>
  );
};

NoticeText.defaultProps = {
  className: "notice-text",
  notice: "Notice",
  desciption: "description",
};
export default NoticeText;

import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../svgs/logo_v3.svg";

const StyledFanupLogo = styled.span`
  /* Group 53 */
  width: 209.95px;
  height: 187px;
  text-align: center;
`;

const FanupLogoV2 = () => {
  return (
    <StyledFanupLogo>
      <Logo />
    </StyledFanupLogo>
  );
};

export default FanupLogoV2;

import React from 'react';
import styled from 'styled-components';
import { black, gray } from '../styles/index';
import CreateAccountStep from '../services/current-create-account-step';
import useCurrentCreateAccountStep from '../services/current-create-account-step';
const Cont = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
`;
const StyledStepText = styled.span<{ fontColor: string }>`
  color: ${(props) => (props.fontColor ? props.fontColor : 'gray')};
`;
const StyledMaxStepText = styled.span<{ fontColor: string }>`
  color: ${(props) => (props.fontColor ? props.fontColor : 'gray')};
`;

export type ProgressTextProps = {
  className?: string | undefined;
};
export const ProgressText = ({ className }: ProgressTextProps) => {
  const s = useCurrentCreateAccountStep();
  return (
    <Cont>
      <StyledStepText className={className} fontColor={black}>
        Step {s().currentStartingWith1}
      </StyledStepText>
      <StyledMaxStepText className={className} fontColor={gray}>
        /{s().overall}
      </StyledMaxStepText>
    </Cont>
  );
};

ProgressText.defaultProps = {
  className: 'progress-text',
};
export default ProgressText;

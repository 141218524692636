import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../components/navigation-bar";
import ProgressText from "../../components/progress-text";
import NoticeText from "../../components/notice-text";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import styled from "styled-components";
import { maxTabletSizeInPx } from "../../styles/size";
import useToNextCreateAccountPage from "../../services/to-next-create-account-page";
import noProblem, { throwIfFalsy } from "../../utils/no-problem";
import checkPassword from "../../errors/check-password";
import useHandleAppError from "../../services/handle-app-error";
import CreateAccountPasswordDoesNotContainAtLeastOneLetterOrDigit from "../../errors/create-account-password-does-not-contain-at-least-one-letter-or-digit";

const Gap72 = styled.div`
  padding-top: 72px;
`;
const Gap28 = styled.div`
  padding-top: 28px;
`;
const Gap24 = styled.div`
  padding-top: 24px;
`;
const Gap16 = styled.div`
  padding-top: 16px;
`;

const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;
export const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CreateAccount2Page = () => {
  const { t } = useTranslation("pageCreateAccount2");
  const toNext = useToNextCreateAccountPage();
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const isFormValid = noProblem(
    () => checkPassword(password),
    () => throwIfFalsy(password === repassword)
  );
  const handleAppError = useHandleAppError();

  const onSubmit = async () => {
    try {
      if (password !== repassword) {
        alert(t("pageCreateAccount2:passwordAndRepasswordAreNotSame"));
        return;
      }

      if (!password.match(/\d/) || !password.match(/[a-zA-Z]/)) {
        throw new CreateAccountPasswordDoesNotContainAtLeastOneLetterOrDigit();
      }

      if (password.length < 8) {
        alert(t("theLengthOfThePasswordMustBeSameOrLargerThan8"));
        return;
      }

      await toNext({ password });
    } catch (error) {
      await handleAppError(error);
    }
  };

  return (
    <>
      <NavigationBar text={t("pageCreateAccount2:navigation")}></NavigationBar>
      <RootCont>
        <Gap72 />
        <TextCont>
          <ProgressText></ProgressText>
          <NoticeText
            notice={t("pageCreateAccount2:notice")}
            desciption={t("pageCreateAccount2:description")}
          ></NoticeText>
        </TextCont>
        <Gap28 />
        <BoxCont>
          <TextInput
            type="password"
            onChange={setPassword}
            value={password}
            placeholder={t("pageCreateAccount2:input1")}
            preventAutoComplete
          />
          <Gap16 />
          <TextInput
            type="password"
            onChange={setRepassword}
            value={repassword}
            placeholder={t("pageCreateAccount2:input2")}
            preventAutoComplete
          />
          <Gap24 />
          <Button
            onClick={onSubmit}
            value={t("pageCreateAccount2:button")}
            disabled={!isFormValid}
          />
        </BoxCont>
      </RootCont>
    </>
  );
};

export default CreateAccount2Page;

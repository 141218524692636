import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../components/navigation-bar";
import ProgressText from "../../components/progress-text";
import NoticeText from "../../components/notice-text";
import Button from "../../components/button";
import "react-datepicker/dist/react-datepicker.css";
import { maxTabletSizeInPx } from "../../styles";
import useToNextCreateAccountPage from "../../services/to-next-create-account-page";
import useHandleAppError from "../../services/handle-app-error";
import { CreateAccountServerError } from "../../services/create-account";
import TextInput from "../../components/text-input";

const Gap72 = styled.div`
  padding-top: 72px;
`;
const Gap48 = styled.div`
  padding-top: 48px;
`;
const Gap16 = styled.div`
  padding-top: 16px;
`;
const Gap8 = styled.div`
  padding-top: 8px;
`;

const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;

const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .skipColor {
    color: #fff;
    background-color: #fd7bb2;
  }
`;

const CalCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const getIsFormValid = (year: string, month: string, date: string): boolean => {
  if (
    year.trim().length !== 0 &&
    month.trim().length !== 0 &&
    date.trim().length !== 0
  ) {
    return true;
  }

  return false;
};

const getFullBirth = (year: string, month: string, date: string): string => {
  return `${year}-${month}-${date}`;
};

const CreateAccount4Page = () => {
  const { t } = useTranslation("pageCreateAccount4");
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const toNext = useToNextCreateAccountPage();
  const handleAppError = useHandleAppError();
  const isFormValid = getIsFormValid(year, month, date);
  const [isCreateAccountInProgress, setIsCreateAccountInProgress] =
    useState<boolean>(false);

  const onSubmit = async () => {
    try {
      if (isCreateAccountInProgress) {
        return;
      }

      setIsCreateAccountInProgress(true);

      await toNext({ birth: getFullBirth(year, month, date) });
    } catch (error) {
      if (error instanceof CreateAccountServerError) {
        alert(t("pageCreateAccount4:failedToCreateAccount"));
        return;
      }

      await handleAppError(error);
    } finally {
      setIsCreateAccountInProgress(false);
    }
  };

  const onSkip = async () => {
    try {
      if (isCreateAccountInProgress) {
        return;
      }

      setIsCreateAccountInProgress(true);

      await toNext({ birth: `9999-12-12` });
    } catch (error) {
      if (error instanceof CreateAccountServerError) {
        alert(t("pageCreateAccount4:failedToCreateAccount"));
        return;
      }

      await handleAppError(error);
    } finally {
      setIsCreateAccountInProgress(false);
    }
  };

  return (
    <>
      <NavigationBar text={t("pageCreateAccount4:navigation")}></NavigationBar>
      <RootCont>
        <Gap72 />
        <TextCont>
          <ProgressText></ProgressText>
          <NoticeText
            notice={t("pageCreateAccount4:notice")}
            desciption={t("pageCreateAccount4:description")}
          ></NoticeText>
        </TextCont>
        <Gap16 />
        <BoxCont>
          <CalCont>
            <TextInput
              placeholder={t("year")}
              value={year}
              onChange={setYear}
            />
            <Gap8 />
            <TextInput
              placeholder={t("month")}
              value={month}
              onChange={setMonth}
            />
            <Gap8 />
            <TextInput
              placeholder={t("date")}
              value={date}
              onChange={setDate}
            />
          </CalCont>
          <Gap48 />
          {isCreateAccountInProgress ? (
            <Button
              onClick={onSubmit}
              value={t("pageCreateAccount4:inProgress")}
              disabled={true}
            />
          ) : (
            <Button
              onClick={onSubmit}
              value={t("pageCreateAccount4:button")}
              disabled={!isFormValid}
            />
          )}
          <Gap16 />
          <Button
            onClick={onSkip}
            value={t("pageCreateAccount3-1:skip")}
            disabled={false}
            className={`skipColor`}
          />
        </BoxCont>
      </RootCont>
    </>
  );
};

export default CreateAccount4Page;

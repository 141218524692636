import styled from "styled-components";

export const Inner = styled.div`
    padding:16px;
    height: calc(100% - 32px);

`;
export const HeadTitle = styled.div`
    position:relative;
    margin-top:20px;
    width:100%;
    text-align:center;
    margin-bottom:26px;
    
`;
export const HeadText = styled.h3`
    display:inline-block;
    color: var(--coolgray-cg-900, #12161A);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.15px;
    margin:0;
`;

export const HeartCount = styled.div<{src:string}>`
    height: 104px;
    width: 200px;
    background-image: url(${props=>props.src});
    background-repeat: no-repeat;
    margin: 0 auto;
    text-align: center;
    line-height:80px
`;
export const HeartCountText = styled.span`
    color: var(--coolgray-cg-900, var(--coolgray-cg-900, #12161A));
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
`;

export const ListWrap = styled.div`
    margin-top:16px;
`;
export const ListFilter = styled.div`display:flex;margin-bottom:10px;`;
export const ListTitle = styled.div`
    width:100%;
    text-align:left;
    color: var(--gray-700, #3D454D);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
`;

export const FilterWrap = styled.div`
    width:100%;
    text-align:right;
`;
export const FilterTitle = styled.span`
    color: var(--coolgray-cg-300, #98A5B3);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
`;
export const FilterSortBy = styled.span`
    border-radius: 9px;
    background: var(--coolgray-cg-900, #12161A);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
    padding: 3px 15px;
    margin-left: 10px;
`;
export const ItemWrap = styled.div`
   
`;
export const ListItem = styled.div`
    position: relative;
    margin-bottom:16px;
    padding:16px;
    padding-left: 110px;
    border-radius: 8px;
    background: var(--coolgary-cg-00, #FFF);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
`;
export const ItemPicture = styled.div<{src:string,index:string}>`
    position:absolute;
    left:16px;
    background-image:url(${props=>props.src});
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 6px;
    border: 2px solid var(--change-color, #FF176B);
    backdrop-filter: blur(2px);
    :after{
        content :'${props=>props.index}';
        display: block;
        position: absolute;
        top:-5px;
        left:-5px;
        color:#FD7BB2;
        width: 21px;
        height: 21px;
        background: rgba(255, 255, 255, 0.90);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;

export const ItemTitle = styled.h6`
    color: var(--gray-800, #292E33);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin:0;
    margin-bottom:8px;
`;
export const ItemAddr = styled.p`
    color: var(--gray-700, #3D454D);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin:0;
    margin-bottom:8px;
`;


export const StarWrap = styled.div`
    position: relative;
    padding-right: 20px;
    display: inline-block;
    height: 21px;
`;
export const StarPoint = styled.span`
    position: absolute;
    right: 2px;
    top: 1px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
     svg.active{fill:#FF176B}
`;


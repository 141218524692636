import {AuthData} from "../utils/auth";

class UserEmailNotVerifiedError extends Error {
  authData: AuthData;

  constructor(authData: AuthData) {
    super('UserEmailNotVerifiedError');
    this.authData = authData;
  }
}

export default UserEmailNotVerifiedError;

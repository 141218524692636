import React from "react";
import styled, { css } from "styled-components";
import useAuth from "../../services/auth";
import { useTranslation } from "react-i18next";
import { mainEventColor } from "../../styles";
import useToMainPage from "../../services/to-main-page";
import useToCurrentLocale from "../../services/to-current-locale";

interface Choice {
  id: string;
  name: string;
  scale: number; // 0~1
}

interface DemandingVoteStatisticsProps {
  className?: string | undefined;
  vote?: any;
  color: string;
  title?: string | undefined;
  description?: string | undefined;
  dateLeft?: string | number | undefined;
  choices: Choice[];
  openModal: () => void;
  voteStart: () => void;
  eventRewardingTicketCount: number;
}

const borderColor = "#DAE0E6";

const Cont = styled.div`
  background-color: white;
`;

const Header = styled.div`
  margin: 24px 24px 16px 24px;
`;

const RowGap = styled.div`
  height: 5px;
`;

const Title = styled.span`
  display: block;
  height: 36px;

  font-family: "BalooBhaijaan";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  /* black */

  color: #051331;
`;

const Description = styled.span`
  display: block;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: -0.24px;
  text-align: center;

  /* Disabled/end vote */

  color: #7e7e7e;
`;

const GrayHr = styled.div`
  margin: 20px 21px 0px 21px;
  text-align: center;
  align-self: center;
  height: 2px;
  background-color: #7e7e7e;
`;

const ChoiceCont = styled.div`
  padding: 10px 16px 10px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ScoreCont = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  width: 93%;
  align-self: center;
  padding: 10px 16px 10px 16px;
  background-color: white;
  display: flex;
  align-items: stretch;
`;

const ScoreLeft = styled.div<{ per: number }>`
  position: absolute;
  background-color: ${mainEventColor};
  height: 22px;
  left: 20px;
  right: ${({ per }) => (per > 5 ? `${100 - per}%` : `95%`)};
  top: 1%;
  bottom: 1%;

  span {
    position: absolute;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    /* identical to box height */

    left: 8px;
    top: 5px;

    color: #ffffff;
  }
`;

const ScoreRight = styled.div<{ per: number }>`
  position: absolute;
  background-color: #09a7a7;
  height: 22px;
  left: ${({ per }) => (per < 95 ? `${100 - per}%` : `5%`)};
  right: 25px;
  top: 1%;
  bottom: 1%;
  span {
    position: absolute;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    /* identical to box height */

    right: 8px;
    top: 5px;

    color: #ffffff;
  }
`;

const Choices = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  align-self: center;
`;

const ChoicesScore = styled.div`
  flex: 1;
  display: flex;
  padding: 0px 8px 0px 8px;
  div {
    &:nth-child(1) {
      flex-grow: 1;
    }
  }
`;

const ChoicesBlock = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  align-self: center;
  align-items: stretch;
`;

const ChoicesGap = styled.div`
  width: 12px;
  display: flex;
  justify-content: center;
`;

const ChoiceComp = styled.div<{ active: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  max-width: 162px;
  border: 2px solid ${({ active }) => (!active ? "#FF176B" : "#09A7A7")};
  border-radius: 5px;
`;

const SelectBoxChoice = styled.div<{ active: boolean }>`
  opacity: ${({ active }) => (active ? "0.5" : "1")};
  background-position: center 50px;
  background-repeat: no-repeat;
  //display: ${({ active }) => (active ? "block" : "none")};
`;

const ChoiceRect = styled.div<{ active: boolean }>`
  .choice-img {
    position: absolute;
    left: 37%;
    right: 19.65%;
    top: 17.83%;
    bottom: 72.88%;
  }

  .choice-img-a {
    position: absolute;
    left: 105px;
    top: 22%;
  }
  .choice-img-b {
    position: absolute;
    left: 1px;
    top: 22%;
  }
`;

const ChoiceImage = styled.img`
  width: 162px;
  height: 122px;
  object-fit: cover;
  z-index: 0;
`;

const ChoiceTitleCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 64px;
`;

const ChoiceTitle = styled.span<{ active: boolean; colorWhenActive: string }>`
  display: block;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #000000;
`;

const TableCont = styled.div`
  padding: 10px 16px 10px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TitleCont = styled.div`
  padding-bottom: 5px;
  display: flex;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #000000;
  div {
    &:nth-child(1) {
      flex-grow: 1;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
    &:nth-child(3) {
      flex-grow: 1;
    }
    &:nth-child(4) {
      flex-grow: 1;
    }
  }
`;

const RowCont = styled.div`
  display: flex;
  height: 30px;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #000000;

  padding: 5px 0px 5px 0px;
  background: rgba(208, 208, 208, 0.17);
  border-radius: 8px;
  div {
    &:nth-child(1) {
      flex-grow: 1;
      padding-top: 7px;
      font-family: "Spoqa Han Sans Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;

      color: #000000;
    }
    &:nth-child(2) {
      flex-grow: 1;
      padding-top: 5px;
    }
    &:nth-child(3) {
      flex-grow: 1;
      position: relative;
      padding-top: 5px;
      font-family: "Spoqa Han Sans Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;

      color: #000000;

      .img-line {
        position: absolute;
        left: 5px;
        right: 1%;
        top: -1px;
        bottom: 1%;
      }

      .line-text {
        position: absolute;
        left: -3px;
        right: 1%;
        top: -8px;
        bottom: 1%;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #09a7a7;
      }
    }
    &:nth-child(4) {
      flex-grow: 1;
      padding: -15px 0px 15px 15px;
      display: flex;

      div {
        &:nth-child(1) {
          flex-grow: 1;
        }

        &:nth-child(2) {
          flex-grow: 2;
          text-align: center;
          font-family: "Spoqa Han Sans Neo";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;

          height: 25px;
          /* identical to box height */

          background: rgba(254, 115, 115, 0.17);
          border-radius: 8px;

          text-align: center;

          color: #fe7373;
        }

        &:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
  }
`;

const RowContMember = styled.div`
  display: flex;
  height: 30px;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #000000;

  padding: 5px 0px 5px 0px;
  background: rgba(208, 208, 208, 0.17);
  border-radius: 8px;
  div {
    &:nth-child(1) {
      flex-grow: 1;
      padding-top: 7px;
      font-family: "Spoqa Han Sans Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;

      color: #000000;
    }
    &:nth-child(2) {
      flex-grow: 1;
      padding-top: 5px;
    }
    &:nth-child(3) {
      flex-grow: 1;
      position: relative;
      padding-top: 5px;
      font-family: "Spoqa Han Sans Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;

      color: #000000;

      .img-line {
        position: absolute;
        left: 5px;
        right: 1%;
        top: -1px;
        bottom: 1%;
      }

      .line-text {
        position: absolute;
        left: -3px;
        right: 1%;
        top: -8px;
        bottom: 1%;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: #09a7a7;
      }
    }
    &:nth-child(4) {
      flex-grow: 1;
      padding: -15px 0px 15px 15px;
      display: flex;

      div {
        &:nth-child(1) {
          flex-grow: 1;
        }

        &:nth-child(2) {
          flex-grow: 2;
          text-align: center;
          font-family: "Spoqa Han Sans Neo";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;

          height: 25px;
          /* identical to box height */

          background: rgba(144, 137, 217, 0.17);
          border-radius: 8px;

          text-align: center;

          color: #9089d9;
        }

        &:nth-child(3) {
          flex-grow: 1;
        }
      }
    }
  }
`;

const BottomRect = styled.div`
  padding: 25px 5px 5px 5px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const ButtonRect = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;
  width: 100%;

  div {
    width: 100%;
    &:nth-child(1) {
      flex-grow: 1;
      text-align: -webkit-right;
      div {
        width: 154px;
        height: 34px;
        margin-left: 20px;
        border: 2px solid black;
        border-radius: 12px;
        padding-top: 20px;

        font-family: "Spoqa Han Sans Neo";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        text-align: center;

        color: #black;
      }
    }
    &:nth-child(2) {
      flex-grow: 1;
      text-align: -webkit-left;
      div {
        position: relative;
        width: 154px;
        height: 34px;
        border: 2px solid #09a7a7;
        border-radius: 12px;
        padding-top: 20px;

        background-color: #09a7a7;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;

        text-align: center;

        color: white;

        .alert-img {
          position: absolute;
          left: 40px;
          right: 1%;
          top: -15px;
          bottom: 1%;
        }

        .alert-img-txt {
          position: absolute;
          left: 57px;
          top: -9px;
        }
      }
    }
  }
`;

const TextDescription = styled.div`
  padding: 40px 16px 10px 30px;
  display: flex;
  align-items: center;
  text-align: center;
  div {
    display: flex;
    width: 100%;
    font-family: "Spoqa Han Sans Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #7e7e7e;

    img {
      width: 14px;
      height: 14px !important;
    }
  }
`;

const RowContSec = styled.div`
  display: flex;
  height: 30px;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  padding: 5px 0px 5px 0px;

  border-radius: 8px;
  div {
    &:nth-child(1) {
      flex-grow: 2;
      padding-top: 7px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      letter-spacing: -0.24px;
      color: #12161a;
    }

    &:nth-child(2) {
      flex-grow: 1;
      padding-top: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: left;

      color: #000000;
    }
    &:nth-child(3) {
      flex-grow: 1;
      padding: -15px 0px 15px 0px;
      display: flex;
      text-align: center;

      div {
        &:nth-child(1) {
          flex-grow: 2;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;

          height: 25px;
          background: rgba(144, 137, 217, 0.17);
          border-radius: 8px;

          text-align: center;

          color: #9089d9;
        }

        &:nth-child(2) {
          flex-grow: 1;
        }
      }
    }
  }
`;

const EventVoteResult: React.FC<DemandingVoteStatisticsProps> = (props) => {
  const auth = useAuth();
  const { t } = useTranslation("componentDemandingVoteStatistics");
  const toCurrentLocale = useToCurrentLocale();
  const choiceData = props.vote?.choices;
  const choiceBool = choiceData[0]?.voteCount > choiceData[1].voteCount;
  const choiceArray = props.choices;

  const goBackHome = useToMainPage();
  return (
    <Cont className={props.className}>
      <Header>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </Header>
      <ChoiceCont>
        <Choices>
          <ChoicesBlock>
            <ChoiceComp active={choiceBool}>
              <SelectBoxChoice active={choiceBool}>
                <ChoiceRect active={choiceBool}>
                  <ChoiceImage src={choiceData[0]?.content.imageUrl} />
                  {choiceBool && (
                    <img
                      src={`/asset/image/choice-img.png`}
                      className={`choice-img`}
                    />
                  )}

                  <img
                    src={`/asset/image/vote-a.png`}
                    className={`choice-img-a`}
                  />
                </ChoiceRect>

                <ChoiceTitleCont>
                  <ChoiceTitle colorWhenActive={props.color} active={false}>
                    {toCurrentLocale(choiceData[0].content.title)}
                  </ChoiceTitle>
                </ChoiceTitleCont>
              </SelectBoxChoice>
            </ChoiceComp>
          </ChoicesBlock>
          <ChoicesGap></ChoicesGap>
          <ChoicesBlock>
            <ChoiceComp active={!choiceBool}>
              <SelectBoxChoice active={!choiceBool}>
                <ChoiceRect active={!choiceBool}>
                  <ChoiceImage src={choiceData[1].content.imageUrl} />
                  {!choiceBool && (
                    <img
                      src={`/asset/image/choice-img.png`}
                      className={`choice-img`}
                    />
                  )}

                  <img
                    src={`/asset/image/vote-b.png`}
                    className={`choice-img-b`}
                  />
                </ChoiceRect>

                <ChoiceTitleCont>
                  <ChoiceTitle colorWhenActive={props.color} active={false}>
                    {toCurrentLocale(choiceData[1].content.title)}
                  </ChoiceTitle>
                </ChoiceTitleCont>
              </SelectBoxChoice>
            </ChoiceComp>
          </ChoicesBlock>
        </Choices>
      </ChoiceCont>
      <ChoiceCont>
        <ChoicesScore>
          <div>
            <img src={`/asset/image/A.png`} />
          </div>
          <div>
            <img src={`/asset/image/B.png`} />
          </div>
        </ChoicesScore>
      </ChoiceCont>
      <ScoreCont>
        <ScoreLeft per={Math.round((1 - choiceArray[0]?.scale) * 100)}>
          <span className={``}>
            {choiceData[0]?.voteCount}
            {t("componentDemandingVoteStatistics:vote")}
          </span>
        </ScoreLeft>
        <ScoreRight per={Math.round((1 - choiceArray[1]?.scale) * 100)}>
          <span className={``}>
            {choiceData[1]?.voteCount}
            {t("componentDemandingVoteStatistics:vote")}
          </span>
        </ScoreRight>
      </ScoreCont>
      <GrayHr />
      <TableCont>
        <TitleCont>
          <div>{t("componentDemandingVoteStatistics:joinStatus")}</div>
          <div>{t("componentDemandingVoteStatistics:voteResult")}</div>
          <div>{t("componentDemandingVoteStatistics:voteGain")}</div>
          <div>{t("componentDemandingVoteStatistics:participation")}</div>
        </TitleCont>
        <RowGap />
        {!auth.isLoggedIn ? (
          <>
            <RowCont>
              <div>{t("componentDemandingVoteStatistics:nonMembers")}</div>
              <div>
                <img src={`/asset/image/CoinMark.png`} className={`img-row`} />
              </div>
              <div>
                <img src={`/asset/image/Vector.png`} className={`img-row`} />
                &nbsp;+0
              </div>
              <div>
                <div></div>
                <div>{t("componentDemandingVoteStatistics:impossible")}</div>
                <div></div>
              </div>
            </RowCont>
            <RowGap />
            <RowContSec>
              <div>{t("componentDemandingVoteStatistics:signingUp")}</div>
              <div>
                <img src={`/asset/image/Vector.png`} className={`img-row`} />
                &nbsp;+{props.eventRewardingTicketCount}
              </div>
              <div>
                <div>{t("componentDemandingVoteStatistics:possible")}</div>
                <div></div>
              </div>
            </RowContSec>
          </>
        ) : (
          <RowContMember>
            <div>
              &nbsp;&nbsp;&nbsp;{t("componentDemandingVoteStatistics:members")}
            </div>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img src={`/asset/image/CoinMark.png`} className={`img-row`} />
            </div>
            <div>
              <img src={`/asset/image/Vector.png`} className={`img-row`} />
              &nbsp;+{props.eventRewardingTicketCount}
              <img src={`/asset/image/RectLine.png`} className={`img-line`} />
              <span className={`line-text`}>
                {t("componentDemandingVoteStatistics:complete")}
              </span>
            </div>
            <div>
              <div></div>
              <div>{t("componentDemandingVoteStatistics:possible")}</div>
              <div></div>
            </div>
          </RowContMember>
        )}
      </TableCont>

      <TextDescription>
        <div>
          <img src={`/asset/image/Vector.png`} className={`img-row`} />
          {`? ${"\u00A0"} ${t("componentDemandingVoteStatistics:voteDesc")}`}
        </div>
      </TextDescription>

      <BottomRect>
        <ButtonRect>
          <div onClick={goBackHome}>
            <div>{t("componentDemandingVoteStatistics:backHome")}</div>
          </div>
          &nbsp;&nbsp;
          {!auth.isLoggedIn ? (
            <div
              onClick={() => {
                props.openModal();
              }}
            >
              <div>
                {t("componentDemandingVoteStatistics:join")}
                <img
                  src={`/asset/image/Rectangle.png`}
                  className={`alert-img`}
                />
                <img
                  src={`/asset/image/Group.png`}
                  className={`alert-img-txt`}
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                props.voteStart();
              }}
            >
              <div>{t("componentDemandingVoteStatistics:voteStart")}</div>
            </div>
          )}
        </ButtonRect>
      </BottomRect>
    </Cont>
  );
};

export default EventVoteResult;

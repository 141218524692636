import React from 'react';
import Modal from 'react-modal';
import Idol from "../../data-types/idol";
import VoteIdolComp from '../../components/vote-idol';
import useLogic from './use-logic';
import IdolVote from "../../data-types/idol-vote";
import {maxTabletSizeInPx} from "../../styles";
import {useTranslation} from "react-i18next";

interface Props {
  isVisible: boolean;
  onRequestClose: () => void;
  idol: Idol | undefined;
  vote: IdolVote | undefined;
  onVoteDone: (p: {
    idol: Idol;
    vote: IdolVote;
    voteCount: number;
  }) => void;
}

const VoteIdolModal: React.FC<Props> = (props: Props) => {
  const logic = useLogic({
    vote: props.vote,
    idol: props.idol,
    close: props.onRequestClose,
    onVoteDone: props.onVoteDone,
  });
  const {t} = useTranslation('modalVoteIdol');

  if (logic.status !== 'LOADED') {
    return null;
  }

  return (
    <Modal
      isOpen={props.isVisible}
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          borderRadius: 0,
          background: "transparent",
          border: 0,
          maxWidth: maxTabletSizeInPx,
          margin: 'auto',
        }
      }}
    >
      {logic.isVoteInProgress ?
        t('voteIsInProgress') :
        <VoteIdolComp
          myTicketCount={logic.myTicketCount}
          voteCount={logic.voteCount}
          onVoteCountChange={logic.setVoteCount}
          onSubmit={logic.vote}
          onCancel={props.onRequestClose}
        />
      }
    </Modal>
  );
};

export default VoteIdolModal;

import TicketLog from "../data-types/ticket-log";
import serializeTicketReason from './ticket-reason';

const serialize = (data: Record<string, any>): TicketLog => {
  return {
    id: data.id,
    user: data.user,
    reason: serializeTicketReason(data.reason),
    ticketCountChange: data.ticketCountChange,
    createdAt: new Date(data.createdAt),
  };
};

export default serialize;

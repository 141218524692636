import styled from "styled-components";

export const AttractHeadPicture = styled.div<{ src: string }>`
  position: relative;
  background-image: url(${(props) => props.src});
  height: calc(100% * 180 / 500);
  width: 100%;
  background-position: center;
  background-size: contain;
  :before {
    content: "";
    display: block;
    background: linear-gradient(2deg, #000 10.08%, rgba(5, 19, 49, 0) 95.15%);
    width: 100%;
    height: 100%;
  }
`;
export const AttractHeadWrap = styled.div`
  position: absolute;
  top: 40%;
  text-align: left;
  width: calc(100% - 50px);
  padding: 0 25px;
`;
export const AttractHeadTitle = styled.h3`
  color: var(--coolgary-cg-00, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  letter-spacing: -0.24px;
  margin: 0;
`;
export const AttractHeadAddr = styled.p`
  color: var(--coolgary-cg-00, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const AttractBodyWrap = styled.div`
  padding: 15px;
`;
export const AttractBodyHeartWrap = styled.div`
  text-align: right;
`;
export const AttractBodyHeartCount = styled.span`
  position: relative;
  padding-right: 25px;
  display: inline-block;
  color: var(--coolgray-cg-500, #6c7680);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.15px;
`;
export const AttractBodyDate = styled.span`
  display: block;
  text-align: center;
  color: var(--coolgray-cg-500, #6c7680);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.15px;
`;
export const AttractBodyWhoAdded = styled.span`
  display: block;
  text-align: center;
  color: var(--coolgray-cg-500, #6c7680);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.15px;
`;
export const AttractBodyTitle = styled.h3`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Spoqa Han Sans Neo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.24px;
  margin-top: 8px;
  margin-bottom: 33px;
`;
export const DataRow = styled.dl`
  padding: 8px 0;
  display: flex;
  margin: 0;
  border-top: 1px solid #12161a;
`;
export const DataItemTitle = styled.dt`
  width: 170px;
  color: var(--coolgray-cg-900, #12161a);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.15px;
`;
export const DataItemMapOpenBtn = styled.a`
  color: #6c7680;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  border: 1px solid #6c7680;
  border-radius: 30px;
  padding: 3px 25px;
  position: relative;
`;
export const DataItemCont = styled.dd<{ picked?: boolean }>`
  position: relative;
  width: 100%;
  text-align: right;
  color: var(--coolgray-cg-500, #6c7680);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.15px;
  svg path {
    fill: ${(props) => (props.picked ? "#FF176B" : "")};
  }
`;
export const DataItemText = styled.span`
  color: var(--coolgray-cg-500, #6c7680);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.15px;
`;
export const DataBucketBtn = styled.a<{ active: boolean }>`
  position: relative;
  border-radius: 23px;
  display: block;
  background: ${(props) => (props.active ? "#FF176B" : "#D9D9D9")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  padding: 8px 16px;
  margin-bottom: 16px;
`;
export const ClassBtn = styled.a<{ active: boolean }>`
  border-radius: 23px;
  border: 1px solid ${(props) => (props.active ? "#FF176B" : "#D9D9D9")};
  padding: 8px 63px;
  color: ${(props) => (props.active ? "#FF176B" : "#D9D9D9")};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  width: 100%;
`;

export const AttractBodySubTitle = styled.h6`
  color: #12161a;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 15px;
`;
export const AttractBodyCategory = styled.div`
  margin-top: 10px;
  display: flex;
`;
export const AttractBodyCategoryItem = styled.div`
  width: 100%;
`;
export const AttractBodyCategoryTitle = styled.strong`
  display: block;
  width: 64px;
  text-align: center;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 6px;
`;
export const AttractClassIcon = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  width: 64px;
  display: block;
  height: 64px;
  background-position: center;
  background-size: contain;
  border-radius: 64px;
  border: 4px solid var(--change-color, #ff176b);
`;
export const BodyImageWrap = styled.div`
  height: 180px;
`;
export const BodyImageItem = styled.div<{ src: string }>`
  display: block;
  float: left;
  background-image: url(${(props) => props.src});
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: #ededed;
  margin-right: 8px;
  margin-bottom: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const ReviewWrap = styled.div`
  margin-bottom: 28px;
`;
export const ReviewInner = styled.div`
  display: flex;
  position: relative;
  padding-left: 50px;
`;
export const ReviewIcon = styled.div``;
export const ReviewName = styled.span`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;
`;
export const ReviewPicCnt = styled.span`
  color: #bdbdbd;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;
`;
export const ReviewDate = styled.span``;
export const ReviewStart = styled.div``;
export const ReviewButton = styled.button`
  color: #0078da;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: #e6f1fa;
  padding: 5px 10px;
  border: 0;
  height: 40px;
`;
export const ReviewImageWrap = styled.ul`
  padding: 0;
`;
export const ReviewImageItem = styled.li<{ src: string }>`
  display: inline-block;
  width: 80px;
  height: 80px;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  margin-right: 8px;
`;
export const ReviewContentWrap = styled.div`
  position: relative;
  padding: 16px;
  background-color: #ededed;
  border-radius: 8px;
`;
export const ReviewContentBox = styled.div`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Spoqa Han Sans Neo;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;
`;
export const ReviewContentEditWrap = styled.div`
  min-height: 116px;
`;
export const ReviewContentEditDate = styled.span`
  color: var(--600gray, #6c7680);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;
`;
export const ReviewContentModBtn = styled.a`
  color: var(--600gray, #6c7680);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.15px;
`;
export const ReviewDivide = styled.hr`
  margin: 24px 0;
`;

export const ReviewInputWrap = styled.div`
  position: relative;
  display: flex;
  padding-left: 50px;
`;
export const ReviewInputBox = styled.input`
  display: block;
  border: 0;
  border-radius: 6px;
  padding: 16px;
  box-shadow: 0px 4px 8px 0px rgba(108, 118, 128, 0.16),
    0px 2px 4px 0px rgba(108, 118, 128, 0.16);
  width: calc(100% - 45px);
`;
export const ReviewBtn = styled.a`
  display: block;
  width: 36px;
  height: 96px;
  border-radius: 6px;
  background: #e6f1fa;
  line-height: 96px;
  color: #0078da;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`;
export const ReviewAddPhotoBtn = styled.a`
  position: relative;
  display: inline-block;
  height: 26px;
  padding-right: 30px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 150% */
  letter-spacing: 0.15px;
`;
export const ReviewPhotoPreview = styled.div<{ src: string | undefined }>`
  display: inline-block;
  position: relative;
  border-radius: 12px;
  text-align: center;
  line-height: 37px;
  width: 37px;
  height: 37px;
  font-size: 14px;
  background-color: #ededed;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-shadow: 4px 4px #00000030;
`;

export const StartWrap = styled.div`
  position: relative;
  padding-right: 20px;
  display: inline-block;
  height: 21px;
  margin: 0 10px;
`;
export const StartPoint = styled.span`
  position: absolute;
  right: 2px;
  top: 1px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  svg.active {
    fill: #ff176b;
  }
`;

export const AttractionWrap = styled.div`
  padding: 16px;
`;

export const AttractionMapWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(173, 173, 173, 0.54);
  backdrop-filter: blur(2px);
  overflow: hidden;
`;
export const AttractionMap = styled.div`
  margin-top: 16px;
  padding: 34px 16px;
  height: calc(100vh - 300px);
  overflow: hidden;
`;
export const AttractionButton = styled.button`
  margin: 16px;
  padding: 16px;
  width: calc(100% - 32px);
  border: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: #ff176b;
  color: #fff;
  border-radius: 6px 6px 0px 0px;
`;

import React from 'react';
import styled from 'styled-components';
import { primary } from "../../styles";
import { useTranslation } from "react-i18next";
import MyVoteTicket from "./my-vote-ticket";
import CountInput from "./count-input";
import Buttons from './buttons';

const Cont = styled.div`
  background-color: white;
  border-radius: 8px;
  padding-top: 40px;
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  overflow: hidden;
`;

const Title = styled.span`
  display: block;
  margin: 0px 28px 0px 28px;
  font-size: 18px;
  line-height: 16px;
  color: black;
  font-weight: bold;
  text-align: center;
`;

interface VoteIdolProps {
  className?: string | undefined;
  myTicketCount: number;
  voteCount: string;
  onVoteCountChange: (newValue: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const VoteIdol: React.FC<VoteIdolProps> = (props) => {
  const { t } = useTranslation('componentVoteIdol');

  return (
    <Cont>
      <Title>{t('componentVoteIdol:voteIdol')}</Title>
      <MyVoteTicket ticketCount={props.myTicketCount} />
      <CountInput
        value={props.voteCount}
        onChange={props.onVoteCountChange}
        placeholder={t('componentVoteIdol:enterYourVoteCount')}
      />
      <Buttons
        onSubmit={props.onSubmit}
        onCancel={props.onCancel}
      />
    </Cont>
  );
};

export default VoteIdol;

import IdolVoteCandidate from "../data-types/idol-vote-candidate";
import {createServerError, ServerError} from "../utils/server-error";
import axios from 'axios';
import {apiAddr} from "../config";
import serializeIdolVoteCandidate from '../from-server-serializers/idol-vote-candidate';

const ReadIdolVoteCandidatesServerError = createServerError();
type ReadIdolVoteCandidatesServerError = ServerError;

const useReadIdolVoteCandidates = () => {
  return async (voteId: string): Promise<IdolVoteCandidate[]> => {
    try {
      const axiosResult = await axios({
        method: 'get',
        url: `${apiAddr}/v1/votes/${voteId}/idol-vote-candidates`,
        params: {
          limit: 100,
        },
      });

      const result: IdolVoteCandidate[] = axiosResult.data.map(serializeIdolVoteCandidate);

      return result;
    } catch (error) {
      throw new ReadIdolVoteCandidatesServerError(error);
    }
  };
};

export default useReadIdolVoteCandidates;
export {ReadIdolVoteCandidatesServerError};

class CountryNotFoundError extends Error {
  tryingToFindId: string;

  constructor(tryingToFindId: string) {
    super(`CountryNotFoundError: Cannot find Country of id ${tryingToFindId}.`);
    this.tryingToFindId = tryingToFindId;
  }
}

export default CountryNotFoundError;

import IdolVoteCandidate from "../data-types/idol-vote-candidate";

const serialize = (data: Record<string, any>): IdolVoteCandidate => {
  return {
    id: data.id,
    idol: data.idol,
    vote: data.vote,
    voteCount: data.voteCount,
  };
};

export default serialize;

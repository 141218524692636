import InternalFile from "./internal-file";

export interface FanUpAttractionType {
  name?: string;
  address?: string;
  class1?: string;
  class2?: string;
  class3?: string;
  memo?: string;
  latitude?: number;
  longitude?: number;
  heartCnt?: number;
  images?: InternalFile[];
  isAgree?: boolean;
}
export interface FanUpAttractionPayload {
  id?: string;
  name?: { [key: string]: string };
  address?: { [key: string]: string };
  class1?: string;
  class2?: string;
  class3?: { [key: string]: string };
  class3Image?: string;
  tel?: string;
  time?: string;
  memo?: { [key: string]: string };
  latitude?: number;
  longitude?: number;
  location: number[];
  images?: string[];
  isAgree?: string;
  requestAt?: Date;
  requestId?: string;
  requestNm?: string;
  heartCnt?: number;
  reviews?: FanUpAttractionReviewPayload[];
  picks?: string[];
}
export interface FanUpAttractionReviewPayload {
  id?: string;
  attractId: string;
  content?: string;
  images?: string[];
  point?: number;
  createdAt?: Date;
  createdId?: string;
  modifiedAt?: Date;
  createdNm?: string;
}
export interface FanUpAttractionPickPayload {
  attractId?: string;
  userId?: string;
}
export interface FanUpAttractionBucketPayload {
  attractId?: string;
  userId?: string;
  date?: string;
  dailyRewards?: any;
}

export const AttractionLargeCategory = {
  Artist: "Artist",
  Content: "CONTENT",
};
export const AttractionMidCategory = {
  Drama: "DRAMA",
  Pop: "K-POP",
  Culture: "K-Culture",
  Movie: "MOVIE",
  Comic: "Web Comic",
  Variety: "K-Variety",
};

export interface GovApiResultBodyType {
  items?: {
    item: GovAttractionDataType[];
  };
  numOfRows?: number;
  pageNo?: number;
  totalCount: number;
}
export interface GovAttractionDataType {
  addr1?: string;
  addr2?: string;
  areacode?: number;
  booktour?: number;
  cat1?: string;
  cat2?: string;
  cat3?: string;
  contentid?: number;
  contenttypeid?: number;
  createdtime?: number;
  dist?: number;
  firstimage?: string;
  firstimage2?: string;
  cpyrhtDivCd?: string;
  mapx?: number;
  mapy?: number;
  mlevel?: number;
  modifiedtime?: number;
  sigungucode?: number;
  tel?: string;
  title?: string;
}

/**
 * 오픈api모델
 */
export interface GovOpenApiModel {
  MobileOS: string; //기본 Etc
  MobileApp: string; //
  ServiceKey: string;
  pageNo: number;
  numOfRows: number;
  _type: string;
  mapX?: number | undefined;
  mapY?: number | undefined;
  keyword?: string | undefined;
  radius?: number | undefined;
  arrange?: string | undefined;
}

export interface AddressModel {
  place_id: string;
  lat: number;
  lon: number;
  name: string;
  display_name: string;
}

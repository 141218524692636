import styled from "styled-components";

const InnerWrap = styled.div`
  position: relative;
  height: calc(100vh - 160px);
  overflow: hidden;
`;
const BtnCircle = styled.a`
  display: block;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.36));
  fill: #fff;
  stroke-width: 2px;
  stroke: #dee3e8;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.36));
  background: #fff;
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 45px;
  line-height: 55px;
  margin: 8px 0;
  border: 2px solid #dee3e8;
  :hover svg path {
    stroke: #ff176b;
  }
`;
const BtnCirclePan = styled.a`
    position:absolute
    right:18px
    display: block;
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.36));
    fill:#fff;
    background: #fff;
    width: 45px;
    height: 45px;
    text-align: center;
    border-radius: 45px;
    line-height: 55px;
    margin:8px 0;
    border: 2px solid #DEE3E8;
    border:'2px solid #000'
    :hover svg path{
        stroke: #FF176B
    }
`;
const BtnHearCircle = styled.a`
  position: absolute;
  top: 10px;
  right: 18px;
  width: 48px;
  height: 48px;
  background: #6c7680;
  fill: var(--600gray, #6c7680);
  stroke-width: 2px;
  stroke: var(--600gray, #6c7680);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.36));
  border-radius: 48px;
  text-align: center;
  line-height: 62px;
  :hover {
    background: #ff176b;
  }
  :hover path.inner {
    fill: #ff176b;
  }
`;
const BtnCircleWrap = styled.div`
  position: absolute;
  top: 90px;
  left: 14px;
  z-index: 1000;
`;
const BtnBox = styled.a<{ active: boolean }>`
  display: block;
  background: #fff;
  width: 80px;
  height: 40px;
  padding: 6px 0;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  color: ${(props) => (props.active ? "#fff" : "#98A5B3")};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 0 2px;
  background: ${(props) => (props.active ? "#FF176B" : "#fff")};
`;
const BtnBoxWrap = styled.div`
  position: absolute;
  display: flex;
  right: 18px;
  top: 90px;
  z-index: 1000;
`;
const ListWrap = styled.div<{ active: boolean }>`
  position: absolute;
  width: 100%;
  bottom: 0;
  max-height: 30vh;
  min-height: 70px;
  z-index: 1000;
  display: ${(props) => (props.active ? "block" : "none")};

  @media (max-height: 700px) {
    bottom: 50px; /* 원하는 높이로 설정하세요 */
  }
`;
const BtnRefresh = styled.a`
  position: absolute;
  display: block;
  background: #fff;
  top: 9px;
  font-size: 14px;
  padding: 12px 20px 12px 40px;
  left: calc((100% - 141px) / 2);
  border-radius: 38px;
  box-shadow: 0px 4px 8px 0px rgba(108, 118, 128, 0.16),
    0px 2px 4px 0px rgba(108, 118, 128, 0.16);
`;

export {
  InnerWrap,
  BtnCircle,
  BtnCircleWrap,
  BtnBox,
  BtnBoxWrap,
  ListWrap,
  BtnRefresh,
  BtnHearCircle,
  BtnCirclePan,
};

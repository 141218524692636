import Idol from "../data-types/idol";
import axios from 'axios';
import {createServerError, ServerError} from "../utils/server-error";
import {apiAddr} from "../config";
import serializeIdol from '../from-server-serializers/idol';

const ReadIdolServerError = createServerError();
type ReadIdolServerError = ServerError;

const useReadIdol = () => {
  return async (idolId: string): Promise<Idol> => {
    try {
      const axiosResult = await axios({
        method: 'get',
        url: `${apiAddr}/v1/idols/${idolId}`,
      });

      const idol: Idol = serializeIdol(axiosResult.data);

      return idol;
    } catch (error) {
      throw new ReadIdolServerError(error);
    }
  };
};

export default useReadIdol;

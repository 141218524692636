import React from 'react';
import {LoadedLogic} from "../../use-logic";
import styled from 'styled-components';
import useToCurrentLocale from "../../../../services/to-current-locale";

interface Props {
  logic: LoadedLogic;
}

const Cont = styled.div`
  height: 180px;
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Dark = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
`;

const InfoCont = styled.div`
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 24px;
`;

const Title = styled.span`
  display: block;
  font-size: 32px;
  color: white;
  line-height: 130%;
  font-weight: bold;
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: white;
`;

const BannerFragment: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();

  const currentVote = props.logic.idolVotes[props.logic.currentTabIndex];
  const currentImageSrc: string | undefined = currentVote.backgroundImageUrl;

  return (
    <Cont>
      <Image src={currentImageSrc}/>
      <Dark/>
      <InfoCont>
        <Title>
          {toCurrentLocale(currentVote.question)}
        </Title>
        <Description>
          {toCurrentLocale(currentVote.modification)}
        </Description>
      </InfoCont>
    </Cont>
  );
};

export default BannerFragment;

import {useNavigate} from 'react-router-dom';

const useToAnnouncementsPage = () => {
  const navigate = useNavigate();

  return () => {
    navigate('/announcements');
  };
};

export default useToAnnouncementsPage;

import {strict as assert} from 'assert';
import Vote from '../data-types/vote';
import idolSerializer from './idol-vote';
import tournamentSerializer from './tournament-vote';
import eventSerializer from './event-vote';

const voteFromServerSerializer = (vote: Record<string, any>): Vote => {
    switch (vote.voteKind) {
        case 'IDOL':
            return idolSerializer(vote);

        case 'TOURNAMENT':
            return tournamentSerializer(vote);

        case 'EVENT':
            return eventSerializer(vote);

        default:
            assert.fail(`Unexpected vote kind: ${vote.voteKind}.`);
    }
};

export default voteFromServerSerializer;

import {
  AttractBodyCategory,
  AttractBodyCategoryItem,
  AttractBodyCategoryTitle,
  AttractBodyDate,
  AttractBodyHeartCount,
  AttractBodyHeartWrap,
  AttractBodySubTitle,
  AttractBodyTitle,
  AttractBodyWhoAdded,
  AttractBodyWrap,
  AttractClassIcon,
  AttractHeadAddr,
  AttractHeadPicture,
  AttractHeadTitle,
  AttractHeadWrap,
  BodyImageItem,
  BodyImageWrap,
  ClassBtn,
  DataBucketBtn,
  DataItemCont,
  DataItemMapOpenBtn,
  DataItemText,
  DataItemTitle,
  DataRow,
  ReviewAddPhotoBtn,
  ReviewBtn,
  ReviewButton,
  ReviewContentBox,
  ReviewContentEditDate,
  ReviewContentEditWrap,
  ReviewContentModBtn,
  ReviewContentWrap,
  ReviewDivide,
  ReviewImageItem,
  ReviewImageWrap,
  ReviewInner,
  ReviewInputBox,
  ReviewInputWrap,
  ReviewName,
  ReviewPhotoPreview,
  ReviewPicCnt,
  ReviewWrap,
  StartPoint,
  StartWrap,
  AttractionMap,
  AttractionMapWrap,
  AttractionButton,
} from "./dom-styled";
import React, { ReactComponentElement, useEffect, useState } from "react";
import {
  AttractionLargeCategory,
  AttractionMidCategory,
  FanUpAttractionPayload,
  FanUpAttractionReviewPayload,
  GovAttractionDataType,
} from "../../../data-types/attraction";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconNormalHeart } from "../svg/icon_normal_heart.svg";
import { ReactComponent as IconEmptyHeart } from "../svg/icon_empty_heart.svg";
import { ReactComponent as IconGoogle } from "../svg/icon_google.svg";
import { ReactComponent as IconUnfold } from "../svg/icon_unfold.svg";
import { ReactComponent as IconRight } from "../svg/icon_right.svg";
import { ReactComponent as IconDrama } from "../svg/icon_drama.svg";
import { ReactComponent as IconMusic } from "../svg/icon_music.svg";
import { ReactComponent as IconCulture } from "../svg/icon_culture.svg";
import { ReactComponent as IconMovie } from "../svg/icon_movie.svg";
import { ReactComponent as IconComic } from "../svg/icon_comic.svg";
import { ReactComponent as IConBeauty } from "../svg/icon_beauty.svg";
import { ReactComponent as IconEmpty } from "../svg/icon_empty_ctgr.svg";
import { ReactComponent as IconEmptyPic } from "../svg/icon_pic_empty.svg";
import { ReactComponent as IconProfile } from "../svg/icon_profile.svg";
import { ReactComponent as IconPlusBtn } from "../svg/icon_plus_circle.svg";
import { ReactComponent as IconStars } from "../svg/icon_starts.svg";
import NavigationWithGoBackBottomTabLayout from "../../../components/navigation-with-go-back-bottom-tab-layout";
import i18n from "i18next";
import { TFunction, useTranslation } from "react-i18next";
import InternalFile from "../../../data-types/internal-file";
import { uploadAndCreateInternalFile } from "../../../utils/image-utils";
import {
  useCreateAttractionReview,
  useReadAttractionReviews,
} from "../../../services/fanup-map/fanup-attraction-review-repository";
import { Auth } from "../../../utils/auth";
import useAuth from "../../../services/auth";
import {
  useCreateAttractionPick,
  useDeleteAttractionPick,
} from "../../../services/fanup-map/fanup-attraction-pick-repository";
import dateToYyyyMmDd from "../../../utils/date-to-yyyy-mm-dd";
import MessageModal, { MessageModalHook } from "../../../modals/message-modal";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import IconMarkerSelected from "../svg/icon_marker_selected.svg";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

let _setShowMap: React.Dispatch<React.SetStateAction<GovAttractionDataType>>;
let _setImages: React.Dispatch<React.SetStateAction<InternalFile[]>>;
let _setShowModal: React.Dispatch<
  React.SetStateAction<MessageModalHook | undefined>
>;
let _setReviews: React.Dispatch<
  React.SetStateAction<FanUpAttractionReviewPayload[]>
>;
let _setPicked: React.Dispatch<React.SetStateAction<boolean>>;
let _auth: Auth;
let _attractData: FanUpAttractionPayload;
let _reviewPoint: number;
let _navigate: NavigateFunction;
let _trans: TFunction<"pageFanUpMap", undefined>;
let _isOpen: boolean = false;

const MapView = (
  data: GovAttractionDataType,
  isShowMapClose: any
): React.PropsWithChildren<any> => {
  if (data.mapx == 0 || data.mapy == 0) return null;
  const mapIcon = new L.Icon({
    iconUrl: IconMarkerSelected,
    iconSize: [25, 40],
  });

  return (
    <AttractionMapWrap>
      <AttractionMap>
        <MapContainer
          style={{ width: "100%", height: "100vh" }}
          center={[data.mapy!, data.mapx!]}
          zoom={16}
          zoomControl={false}
        >
          <TileLayer
            attribution={
              '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[data.mapy!, data.mapx!]} icon={mapIcon}>
            <Popup>
              {data.title}
              <br />
              {data.addr1} {data.addr2}
            </Popup>
          </Marker>
        </MapContainer>
      </AttractionMap>
      <AttractionButton
        onClick={() => {
          _setShowMap({ mapx: 0, mapy: 0 });
          isShowMapClose();
        }}
      >
        {"닫기"}
      </AttractionButton>
    </AttractionMapWrap>
  );
};

const _classIcon: React.PropsWithChildren<any> = (type: string) => {
  let icon = undefined;
  switch (type) {
    case AttractionMidCategory.Drama:
      icon = <IconDrama></IconDrama>;
      break;
    case AttractionMidCategory.Pop:
      icon = <IconMusic></IconMusic>;
      break;
    case AttractionMidCategory.Comic:
      icon = <IconComic></IconComic>;
      break;
    case AttractionMidCategory.Culture:
      icon = <IconCulture></IconCulture>;
      break;
    case AttractionMidCategory.Movie:
      icon = <IconMovie></IconMovie>;
      break;
    case AttractionMidCategory.Variety:
      icon = <IConBeauty></IConBeauty>;
      break;
    default:
      icon = <IconEmpty></IconEmpty>;
      break;
  }
  return icon;
};
const _attractImages = (images: string[]) => {
  const doms: ReactComponentElement<any>[] = [];
  for (let i = 0; i < 8; i++) {
    if (i >= images.length) {
      doms.push(
        <BodyImageItem src={""}>
          <IconEmptyPic></IconEmptyPic>
        </BodyImageItem>
      );
    } else {
      doms.push(<BodyImageItem src={images[i]}></BodyImageItem>);
    }
  }
  return doms;
};
/**
 * 평점 박스
 * @param point
 * @param isEditable
 */
const _reviewStars: React.FC<any> = (point: number, isEditable = false) => {
  const [star, setStart] = useState(point);
  _reviewPoint = star;
  return (
    <StartWrap>
      <IconStars
        fill={star >= 1 ? "#FF176B" : "none"}
        onClick={(e) => {
          isEditable && setStart(1);
        }}
      />
      <IconStars
        fill={star >= 2 ? "#FF176B" : "none"}
        onClick={(e) => {
          isEditable && setStart(2);
        }}
      />
      <IconStars
        fill={star >= 3 ? "#FF176B" : "none"}
        onClick={(e) => {
          isEditable && setStart(3);
        }}
      />
      <IconStars
        fill={star >= 4 ? "#FF176B" : "none"}
        onClick={(e) => {
          isEditable && setStart(4);
        }}
      />
      <IconStars
        fill={star >= 5 ? "#FF176B" : "none"}
        onClick={(e) => {
          isEditable && setStart(5);
        }}
      />
      <StartPoint>{star}</StartPoint>
    </StartWrap>
  );
};
/**
 * 첨부파일 변경시
 * @param e
 */
const _onFileChange = async (e: any) => {
  let rtnFile: InternalFile[] = [];
  const hasFile = (e: any) => {
    return e.target.files.length !== 0;
  };
  if (e.target.files.length > 4) {
    _setShowModal({
      messageContent: _trans("message.uploadMax"),
    });
    return false;
  }
  if (hasFile(e)) {
    let files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      rtnFile.push(await uploadAndCreateInternalFile(files[i]));
    }
    _setImages(rtnFile);
  }
};
//
// /**
//  * 메시지 모달
//  * @constructor
//  */
// const MessageModal: React.FC<any> =()=>{
//     const {t} = useTranslation("pageFanUpMap");
//     const [modalMessage,setModalMessage] = useState<MessageModalProps|undefined>();
//     _setShowModal = setModalMessage;
//     let isVisible = typeof modalMessage !=='undefined';
//     let isDisableDone = true;
//     let doneHandler = ()=>{};
//     if(modalMessage){
//         isDisableDone = modalMessage.disabledDone !=='N';
//         doneHandler = ()=>{
//             _setShowModal(undefined);
//             modalMessage.doneHandler&& modalMessage.doneHandler();
//         };
//     }
//     return (
//         <ModalBaseWrapper
//             isVisible={isVisible}
//             onRequestClose={()=>{_setShowModal(undefined)}}
//             onLeft={()=>{_setShowModal(undefined)}}
//             leftText={isDisableDone?t('button.check'):t('button.cancel')}
//             disableRight={isDisableDone}
//             onRight={()=>{
//                 doneHandler()
//             }}
//             rightText={t('button.check')}
//             content={t(modalMessage?.messageCode||'')}>
//
//         </ModalBaseWrapper>
//     )
// }
/**
 * 리뷰 아이템
 * @param props
 * @constructor
 */
const ReviewItem: React.FC<any> = (props) => {
  const reviewItem: FanUpAttractionReviewPayload = props.data!;
  return (
    <ReviewWrap>
      <ReviewInner>
        <IconProfile style={{ position: "absolute", left: "0" }} />
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <ReviewName style={{ width: "100%" }}>
              {reviewItem.createdNm}
            </ReviewName>
            <div style={{ width: "100%", textAlign: "right" }}>
              {_reviewStars(reviewItem.point, false)}
            </div>
          </div>
          <ReviewPicCnt>Pick list 30</ReviewPicCnt>
        </div>
        <ReviewButton>Follow</ReviewButton>
      </ReviewInner>
      <ReviewImageWrap>
        {reviewItem.images &&
          reviewItem.images!.map((item) => <ReviewImageItem src={item} />)}
      </ReviewImageWrap>
      <ReviewContentWrap>
        <ReviewContentEditWrap>
          <ReviewContentBox>{reviewItem.content}</ReviewContentBox>
          <div style={{ position: "absolute", right: "16px", bottom: "16px" }}>
            <ReviewContentEditDate>
              {reviewItem.createdAt}
            </ReviewContentEditDate>
            <ReviewContentModBtn>수정</ReviewContentModBtn>
            <ReviewContentModBtn>삭제</ReviewContentModBtn>
          </div>
        </ReviewContentEditWrap>
      </ReviewContentWrap>
    </ReviewWrap>
  );
};
/**
 * 리뷰 입력 박스
 * @constructor
 */
const ReviewInputSection: React.FC<any> = (props) => {
  const { t } = useTranslation("pageFanUpMap");
  const [images, setImages] = useState<InternalFile[]>([]);
  _setImages = setImages;
  return (
    <ReviewInputWrap style={{ display: props.display }}>
      <IconProfile style={{ position: "absolute", left: "0" }} />
      <div style={{ width: "100%" }}>
        <ReviewInputBox placeholder={t("writeComment")} id={"reviewContent"} />
        <div style={{ marginTop: "13px", textAlign: "right" }}>
          <ReviewAddPhotoBtn
            onClick={() => {
              document.getElementById("reviewFile")!.click();
            }}
          >
            {t("button.pictureAdd")}
            <input
              id={"reviewFile"}
              type={"file"}
              style={{ display: "none" }}
              onChange={_onFileChange}
              multiple={true}
            ></input>
            <IconPlusBtn style={{ position: "absolute", right: "0" }} />
          </ReviewAddPhotoBtn>
          <ReviewPhotoPreview
            src={images && images.length > 0 ? images[0].url : undefined}
          >
            {images.length}
          </ReviewPhotoPreview>
          {_reviewStars(0, true)}
        </div>
      </div>
      <ReviewBtn
        onClick={() => {
          _setShowModal({
            disabledDone: "N",
            doneHandler: () => {
              const imgs: string[] = [];
              images.forEach((item) => imgs.push(item.storeKey!));

              _createAttractionReview({
                images: imgs,
                attractId: _attractData.id || "",
                content: (
                  document.getElementById("reviewContent") as HTMLInputElement
                ).value,
                point: _reviewPoint,
              });
            },
            messageContent: t("message.confirm"),
          });
        }}
      >
        {t("button.enter")}
      </ReviewBtn>
    </ReviewInputWrap>
  );
};
/**
 * 리뷰 등록
 */
const _createAttractionReview = (data: FanUpAttractionReviewPayload) => {
  const createReview = useCreateAttractionReview();
  data.createdAt = new Date();
  data.createdId = _auth.userId;
  if (!data.content) {
    _setShowModal({
      messageContent: _trans("message.emptyContent"),
    });
    return false;
  }
  createReview(data).then(() => {
    window.location.reload();
  });
};
/**
 * 찜 수정
 * @param isPicked
 */
const _modifyAttractionPic = (isPicked: boolean) => {
  const createPick = useCreateAttractionPick();
  const deletePick = useDeleteAttractionPick();
  let result = undefined;

  if (!_auth.isLoggedIn) {
    _unAuthModal();
    return false;
  }

  if (isPicked) {
    result = createPick({ attractId: _attractData.id });
    _attractData.heartCnt = (_attractData.heartCnt || 0) + 1;
  } else {
    result = deletePick({ attractId: _attractData.id });
    _attractData.heartCnt = (_attractData.heartCnt || 1) - 1;
  }

  result.then(() => {
    _setPicked(isPicked);
  });
};
/**
 * 비로그인시 로그인모달
 */
const _unAuthModal = () => {
  _setShowModal({
    disabledDone: "N",
    doneHandler: () => {
      _navigate("/login");
    },
    messageContent: _trans("message.notAuth"),
  });
};
/**
 * 데이터 조회
 * @param id
 * @constructor
 */
const GetData = async (id: string) => {
  return await useReadAttractionReviews()({ attractId: id });
};

/**
 * 관광지 상세페이지
 * @constructor
 */
const FanUpDetailPage: React.FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation("pageFanUpMap");
  const [attractData, setAttractData] = useState<FanUpAttractionPayload>(
    location.state.data
  );
  const [reviews, setReviews] = useState<FanUpAttractionReviewPayload[]>([]);
  const [isPicked, setPicked] = useState<boolean>(
    auth.isLoggedIn &&
      (attractData.picks || []).filter((item) => item == auth.userId).length > 0
  );
  const [isShowMap, setIsShowMap] = useState<boolean>(false);
  const [mapInfo, setShowMap] = useState<GovAttractionDataType>({});
  _auth = auth;
  _attractData = attractData;
  _setReviews = setReviews;
  _setPicked = setPicked;
  _navigate = navigate;
  _setShowMap = setShowMap;
  attractData.heartCnt = (attractData.picks || []).length;
  _trans = t;
  let point = 0;
  if (!attractData) {
    navigate("/maps");
  }
  useEffect(() => {
    GetData(attractData.id!).then((result) => {
      setReviews(result);
    });
  }, [attractData.id]);

  useEffect(() => {
    mapInfo.mapx = attractData.location[0];
    mapInfo.mapy = attractData.location[1];
  }, [isShowMap]);

  function isShowMapClose() {
    setIsShowMap(!isShowMap);
  }

  if (Array.isArray(reviews) && reviews.length > 0) {
    let sum: number =
      reviews
        .map((item) => item.point)
        .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
    point = Math.round(sum / reviews.length);
  }

  /*
  if (isShowMap) {
    document
      .getElementById("content")!
      .parentElement!.scrollTo({ top: 0, left: 0 });
  }
*/
  return (
    <NavigationWithGoBackBottomTabLayout title={"FanUP MAP"}>
      <AttractHeadPicture src={attractData!.images![0]}>
        <AttractHeadWrap>
          <AttractHeadAddr>
            {attractData.address![i18n.language]}
          </AttractHeadAddr>
          <AttractHeadTitle>
            {attractData.name![i18n.language]}
          </AttractHeadTitle>
        </AttractHeadWrap>
      </AttractHeadPicture>
      <AttractBodyWrap id={"content"}>
        <div style={{ marginBottom: "33px" }}>
          <AttractBodyHeartWrap>
            <AttractBodyHeartCount>
              {attractData.heartCnt || 0}
              <IconNormalHeart
                style={{ marginLeft: "6px", position: "absolute", top: "3px" }}
              />
            </AttractBodyHeartCount>
          </AttractBodyHeartWrap>
          <AttractBodyDate>
            [{t("whenAdded")}]{dateToYyyyMmDd(new Date(attractData.requestAt!))}
          </AttractBodyDate>
          <AttractBodyWhoAdded>
            [{attractData.requestNm}]{t("whoAdded")}
          </AttractBodyWhoAdded>
          <AttractBodyTitle>
            {attractData.name![i18n.language]}
          </AttractBodyTitle>
        </div>
        <div>
          <DataRow>
            <DataItemTitle>{t("address")}</DataItemTitle>
            <DataItemCont>
              <DataItemText>{attractData.address![i18n.language]}</DataItemText>
            </DataItemCont>
          </DataRow>
          <DataRow>
            <DataItemTitle>{t("tel")}</DataItemTitle>
            <DataItemCont>
              <DataItemText>{attractData.tel}</DataItemText>
            </DataItemCont>
          </DataRow>
          <DataRow>
            <DataItemTitle>{t("average")}</DataItemTitle>
            <DataItemCont>
              <StartWrap>
                <IconStars fill={point >= 1 ? "#FF176B" : "none"} />
                <IconStars fill={point >= 2 ? "#FF176B" : "none"} />
                <IconStars fill={point >= 3 ? "#FF176B" : "none"} />
                <IconStars fill={point >= 4 ? "#FF176B" : "none"} />
                <IconStars fill={point >= 5 ? "#FF176B" : "none"} />
                <StartPoint>{point}</StartPoint>
              </StartWrap>
              <DataItemText>
                {(reviews || []).length}
                {t("count")} {t("average")}
              </DataItemText>
            </DataItemCont>
          </DataRow>
          <DataRow>
            <DataItemTitle>{t("category")}</DataItemTitle>
            <DataItemCont>
              <DataItemText>
                {`${attractData.class1} > ${attractData.class2} > ${
                  attractData.class3![i18n.language]
                }`}
              </DataItemText>
            </DataItemCont>
          </DataRow>
          <DataRow>
            <DataItemTitle>{t("showMap")}</DataItemTitle>
            <DataItemCont>
              <DataItemText>{attractData.address![i18n.language]}</DataItemText>
            </DataItemCont>
          </DataRow>
          <DataRow>
            <DataItemTitle>
              {t("findWay")}
              <IconGoogle style={{ display: "block" }} />
            </DataItemTitle>
            <DataItemCont style={{ paddingTop: "15px" }}>
              <DataItemMapOpenBtn
                onClick={() => {
                  _setShowMap(mapInfo);
                  setIsShowMap(true);
                }}
              >
                {t("pushOpenMap")}{" "}
                <IconUnfold
                  style={{ position: "absolute", right: "10px", top: "10px" }}
                />{" "}
              </DataItemMapOpenBtn>
            </DataItemCont>
          </DataRow>
          <DataRow>
            <DataItemTitle>{t("pickPlace")}</DataItemTitle>
            <DataItemCont picked={isPicked && auth.isLoggedIn}>
              <IconEmptyHeart
                onClick={() => {
                  _modifyAttractionPic(!isPicked);
                }}
              />
            </DataItemCont>
          </DataRow>
          <DataRow>
            <DataItemTitle>{_trans("bucketList")}</DataItemTitle>
          </DataRow>
          <DataBucketBtn
            active={isPicked && auth.isLoggedIn}
            onClick={() => {
              if (isPicked) {
                navigate(`/bucket/${attractData.id}`, {
                  state: {
                    data: attractData,
                  },
                });
              }
            }}
          >
            {_trans("button.bucket")}
            <IconRight
              style={{ position: "absolute", right: "20px", top: "10px" }}
            />
          </DataBucketBtn>
          <DataRow style={{ paddingTop: "24px" }}>
            <ClassBtn
              active={attractData.class1 === AttractionLargeCategory.Artist}
              style={{ marginRight: "10px" }}
            >
              Artist
            </ClassBtn>
            <ClassBtn
              active={attractData.class1 === AttractionLargeCategory.Content}
            >
              Contents
            </ClassBtn>
          </DataRow>
        </div>
        <div style={{ marginTop: "57px" }}>
          <AttractBodySubTitle>{t("subCategory")}</AttractBodySubTitle>
          <AttractBodyCategory>
            <AttractBodyCategoryItem
              style={{ borderRight: "1px solid ##6C7680" }}
            >
              {_classIcon(attractData.class2!)}
              <AttractBodyCategoryTitle>
                {attractData.class2}
              </AttractBodyCategoryTitle>
            </AttractBodyCategoryItem>
            <AttractBodyCategoryItem
              style={{ paddingLeft: "16px", borderLeft: "1px solid #6C7680" }}
            >
              {attractData.class3Image ? (
                <AttractClassIcon
                  src={attractData.class3Image}
                ></AttractClassIcon>
              ) : (
                <IconEmpty></IconEmpty>
              )}
              <AttractBodyCategoryTitle>
                {attractData.class3![i18n.language]}
              </AttractBodyCategoryTitle>
            </AttractBodyCategoryItem>
          </AttractBodyCategory>
        </div>
        <div style={{ marginTop: "41px" }}>
          <AttractBodySubTitle>
            {t("reviewPhoto")} ({(attractData.images || []).length}
            {t("count")})
          </AttractBodySubTitle>
          <BodyImageWrap>
            {_attractImages(attractData.images || [])}
          </BodyImageWrap>
        </div>
        <div style={{ marginTop: "90px" }}>
          <AttractBodySubTitle>{t("review")}</AttractBodySubTitle>
          {reviews.map((item, idx) => (
            <ReviewItem data={item} key={`reviews${idx}`}></ReviewItem>
          ))}
          <ReviewDivide style={{ display: !auth.isLoggedIn ? "none" : "" }} />
          <ReviewInputSection display={!auth.isLoggedIn ? "none" : ""} />
        </div>
        {isShowMap ? MapView(mapInfo, isShowMapClose) : null}
      </AttractBodyWrap>
      <MessageModal
        onHook={(fn) => {
          _setShowModal = fn;
        }}
      ></MessageModal>
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default FanUpDetailPage;

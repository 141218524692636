import React from "react";
import styled from "styled-components";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import { useTranslation } from "react-i18next";
import noThumbnailSrc from "./svg/profile-man.svg";
import { ReactComponent as EditThumbnail } from "./svg/edit-button.svg";
import TextEdit from "./components/text-edit";
import TextEditWithButton from "./components/text-edit-with-button";
import PrimaryButton from "./components/primary-button";
import SecondaryButton from "./components/secondary-button";
import useLogic from "./use-logic";
import { mainColor } from "../../styles";
import Button from "../../components/button";

interface DumbProps {
  userThumbnail: string | undefined;
  onEditUserThumbnail: () => void;
  name: string;
  setName: (value: string) => void;
  email: string;
  password: string;
  setPassword: (value: string) => void;
  editPassword: () => void;
  onCancel: () => void;
  onSave: () => void;
  onDrawal: () => void;
}

const Cont = styled.div`
  padding: 48px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .skipColor {
    align-self: center;
    font-weight: bold;
    color: #6c7680;
    width: 85%;
    background-color: #dae0e6;
  }
`;

const ThumbnailCont = styled.div`
  align-self: center;
  width: 132px;
  height: 132px;
  position: relative;
`;

const Thumbnail = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 60px;
`;

const TheEditThumbnail = styled(EditThumbnail)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
`;

const Gap16 = styled.div`
  padding-top: 16px;
`;

const NameEdit = styled(TextEdit)`
  margin-top: 16px;
`;

const EmailEdit = styled(TextEdit)`
  margin-top: 12px;
`;

const PasswordEdit = styled(TextEditWithButton)`
  margin-top: 12px;

  div {
    span {
      background-color: ${mainColor};
    }
  }
`;

const ButtonsCont = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-around;
`;

const CancelButton = styled(SecondaryButton)`
  border-radius: 8px;
  color: ${mainColor};
  border-color: ${mainColor};
`;
const SaveButton = styled(PrimaryButton)`
  border-radius: 8px;
  color: #ffffff;
  background-color: ${mainColor};
`;

const Dumb: React.FC<DumbProps> = (props) => {
  const { t } = useTranslation("pageEditProfile");
  return (
    <Cont>
      <ThumbnailCont>
        <Thumbnail src={props.userThumbnail ?? noThumbnailSrc} />
        <TheEditThumbnail onClick={props.onEditUserThumbnail} />
      </ThumbnailCont>
      <NameEdit label={"닉네임"} value={props.name} onChange={props.setName} />
      <EmailEdit
        label={"이메일"}
        value={props.email}
        disabled={true}
        inputType={"email"}
        placeholder={"asdf@gmail.com"}
      />
      <PasswordEdit
        label={"비밀번호"}
        value={props.password}
        onChange={props.setPassword}
        buttonLabel={"비밀번호 변경"}
        onButtonPress={props.editPassword}
        inputType={"password"}
        placeholder={"********"}
      />
      <ButtonsCont>
        <CancelButton onPress={props.onCancel}>
          {t("pageEditProfile:cancel")}
        </CancelButton>
        <SaveButton onPress={props.onSave}>
          {t("pageEditProfile:save")}
        </SaveButton>
      </ButtonsCont>
      <Gap16 />
      <Button
        onClick={props.onDrawal}
        value={t("pageEditProfile:drawal")}
        disabled={false}
        className={`skipColor`}
      />
    </Cont>
  );
};

const EditProfilePage: React.FC = () => {
  const { t } = useTranslation("pageEditProfile");

  const logic = useLogic();

  if (logic.status !== "LOADED") {
    return null; // todo
  }

  return (
    <NavigationWithGoBackBottomTabLayout title={t("editProfileInCapital")}>
      <Dumb
        userThumbnail={undefined}
        onEditUserThumbnail={logic.editThumbnail}
        name={logic.name}
        setName={logic.setName}
        email={logic.email}
        password={logic.password}
        setPassword={logic.setPassword}
        editPassword={logic.editPassword}
        onCancel={logic.cancel}
        onSave={logic.save}
        onDrawal={logic.drawal}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default EditProfilePage;

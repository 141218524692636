import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavigationWithGoBackLayout from "../../components/navigation-bar-with-go-back";
import { useTranslation } from "react-i18next";
import TextInput from "../../components/text-input";
import TextAreaInput from "../../components/textarea-input";
import useLogic from "./use-logic";
import { Logic } from "./use-logic";
import { coolGray, placeHolder, textBox } from "../../styles";
import { useNavigate, useParams } from "react-router-dom";
import { apiAddr } from "../../config";
import useAuth from "../../services/auth";

interface FaqFormProps {
  logic: Logic;
  selectText: string;
  titleHolderText: string;
  contentHolderText: string;
  qnaSubmitText: string;
  qnaCategorys: {
    id: "login" | "account" | "join" | "etc" | "vote";
    value: string;
  }[];
}

const Cont = styled.div`
  padding: 30px 16px 100px 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
`;

const TitleCont = styled.div`
  align-self: center;
  font-weight: bold;
  font-size: 24px;
`;

const LabelTextArea = styled.div`
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #292e33;
  font-size: 14px;
  font-weight: bold;
  padding: 0px 0px 5px 0px;
`;

const CustomSelectBox = styled.div`
  padding: 0px 0px 20px 0px;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const SelectBoxArea = styled.div`
  padding: 0px 0px 20px 0px;
  select {
    appearance: none;
    -webkit-appearance: none; /* 사파리, 크롬 하위버전용 */
    -moz-appearance: none; /* 사파리, 크롬 하위버전용 */

    padding: 0 10px;
    box-sizing: border-box;

    font-size: 16px;

    background-image: url(/select_icon.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
  }
  select::-ms-expand {
    display: none;
  } /* 익스플로러용 */
`;

const InputBoxArea = styled.div`
  padding: 0px 0px 5px 0px;
  input {
    width: 100%;
    height: 46px;
    color: ${textBox};
    font-size: 16px;
    caret-color: ${placeHolder};
    padding: 0px 10px 0px 10px;

    &::placeholder {
      color: ${placeHolder};
      font-size: 16px;
      letter-spacing: "-0.5%";
    }

    &:focus {
      outline: none;
    }
  }
`;

const ButtonsCont = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Bottomlayout = styled.div`
  height: 400px;
  display: flex;
  align-items: end;
  bottom: 0;
`;

const StyledButton = styled.button<{ bgColor: string; textColor: string }>`
  width: 100%;
  height: 56px;
  margin: 7px 0px 5px 0px;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};

  border: none;
  border-radius: 8px;
`;

const FaqForm: React.FC<FaqFormProps> = (props) => {
  const param = useParams();
  const navigator = useNavigate();
  const [category, setCategory] = useState<string | null>();
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const auth = useAuth();
  const validation = () => {
    let result: boolean;
    switch (category) {
      case "login":
      case "join":
      case "vote":
      case "account":
      case "etc":
        result = true;
        break;
      default:
        result = false;
    }

    if (title.trim().length === 0) {
      result = false;
    }

    if (text.trim().length === 0) {
      result = false;
    }

    return result;
  };
  const handleSubmit = async () => {
    if (validation()) {
      try {
        const result = await auth.callAxios({
          method: "post",
          url: `${apiAddr}/v1/qna`,
          data: { title, text, category },
        });

        if (result) {
          navigator("/qna-form-end");
        }
      } catch (e) {
        window.alert(e);
      }
    } else {
      window.alert("Fail");
    }
  };
  useEffect(() => {
    /* 화살표 함수 */
    const label: any = document.querySelector(".labelCustom");
    const options: any = document.querySelectorAll(".optionItem");

    // 클릭한 옵션의 텍스트를 라벨 안에 넣음
    const handleSelect = (item: any) => {
      label.parentNode.classList.remove("active");
      label.innerHTML = item.textContent;
    };
    // 옵션 클릭시 클릭한 옵션을 넘김
    options.forEach((option: any) => {
      option.addEventListener("click", () => handleSelect(option));
    });

    // 라벨을 클릭시 옵션 목록이 열림/닫힘
    label.addEventListener("click", () => {
      if (label.parentNode.classList.contains("active")) {
        label.parentNode.classList.remove("active");
      } else {
        label.parentNode.classList.add("active");
      }
    });

    switch (param.category) {
      case "login":
      case "vote":
      case "etc":
      case "join":
      case "account":
        label.textContent = props.qnaCategorys.find(
          (item) => item.id === param.category
        )?.value;
        setCategory(param.category);
        break;
      default:
        label.textContent = props.selectText;
        setCategory(null);
    }

    return () => {};
  }, [param]);

  return (
    <>
      <Cont>
        <CustomSelectBox>
          <div className="selectBoxCustom ">
            <button className="labelCustom">{props.selectText}</button>
            <ul className="optionList">
              {props.qnaCategorys.map((item) => (
                <li
                  onClick={(e) => {
                    setCategory(
                      props.qnaCategorys.find(
                        (item) => item.value === e.currentTarget.textContent
                      )?.id
                    );
                  }}
                  key={item.id}
                  className="optionItem"
                >
                  {item.value}
                </li>
              ))}
            </ul>
          </div>
        </CustomSelectBox>
        <LabelTextArea>Q&A*</LabelTextArea>

        <InputBoxArea>
          <TextInput
            type={"text"}
            placeholder={props.titleHolderText}
            value={title}
            onChange={(e) => {
              setTitle(e);
            }}
          />
        </InputBoxArea>

        <TextAreaInput
          placeholder={props.contentHolderText}
          value={text}
          onChange={(e) => {
            setText(e);
          }}
        />

        <ButtonsCont></ButtonsCont>
        <Bottomlayout>
          <StyledButton
            bgColor={`#EDF1F5`}
            textColor={`#98A5B3`}
            onClick={handleSubmit}
          >
            {props.qnaSubmitText}
          </StyledButton>
        </Bottomlayout>
      </Cont>
    </>
  );
};

const QnaFormPage: React.FC = () => {
  const { t } = useTranslation("pageQna");
  const login = useLogic();

  return (
    <>
      <NavigationWithGoBackLayout title={t("qnaInCapital")} />
      <FaqForm
        logic={login}
        selectText={t("selectText")}
        qnaSubmitText={t("qnaSubmitText")}
        titleHolderText={t("titleHolderText")}
        contentHolderText={t("contentHolderText")}
        qnaCategorys={[
          { id: "login", value: t("login") },
          { id: "account", value: t("account") },
          { id: "join", value: t("join") },
          { id: "vote", value: t("vote") },
          { id: "etc", value: t("etc") },
        ]}
      />
    </>
  );
};

export default QnaFormPage;

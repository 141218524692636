import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NavigationBar from "../../components/navigation-bar";
import ProgressText from "../../components/progress-text";
import NoticeText from "../../components/notice-text";
import TextInput from "../../components/text-input";
import Button from "../../components/button";
import styled from "styled-components";
import { maxTabletSizeInPx } from "../../styles/size";
import useToNextCreateAccountPage from "../../services/to-next-create-account-page";

import noProblem from "../../utils/no-problem";
import checkName from "../../errors/check-name";
import useHandleAppError from "../../services/handle-app-error";

export const Gap72 = styled.div`
  padding-top: 72px;
`;

export const Gap56 = styled.div`
  padding-top: 56px;
`;

export const Gap24 = styled.div`
  padding-top: 24px;
`;

export const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;

export const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CreateAccount3Page = () => {
  const { t } = useTranslation("pageCreateAccount3");
  const toNext = useToNextCreateAccountPage();
  const [name, setName] = useState("");
  const isFormValid = noProblem(() => checkName(name));
  const handleAppError = useHandleAppError();

  const onSubmit = async () => {
    try {
      await toNext({ name });
    } catch (error) {
      await handleAppError(error);
    }
  };

  return (
    <>
      <NavigationBar text={t("pageCreateAccount3:navigation")} />
      <RootCont>
        <Gap72></Gap72>
        <TextCont>
          <ProgressText></ProgressText>
          <NoticeText
            notice={t("pageCreateAccount3:notice")}
            desciption={t("pageCreateAccount3:description")}
          ></NoticeText>
        </TextCont>
        <Gap56 />
        <BoxCont>
          <TextInput
            onChange={setName}
            value={name}
            placeholder={t("pageCreateAccount3:input1")}
          ></TextInput>
          <Gap24 />
          <Button
            disabled={!isFormValid}
            onClick={onSubmit}
            value={t("pageCreateAccount3:button")}
          ></Button>
        </BoxCont>
      </RootCont>
    </>
  );
};

export default CreateAccount3Page;

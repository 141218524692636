import { useEffect, useState } from "react";
import { strict as assert } from "assert";
import useHandleAppError from "../../../services/handle-app-error";
import { useLocation, useParams } from "react-router-dom";
import useReadVoteById from "../../../services/read-vote-by-id";
import useReadVoteCategoryById from "../../../services/read-vote-category-by-id";
import VoteCategory from "../../../data-types/vote-category";
import { useTranslation } from "react-i18next";
import EventVote from "../../../data-types/event-vote";
import useVoteEvent from "../../../services/vote-event";
import useToEventVoteResultPage from "../../../services/to-event-vote-result-page";
import useAuth from "../../../services/auth";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  vote: EventVote;
  voteCategory: VoteCategory;
  onChoose: (index: number) => void;
  onNext: () => void;
  isNextInProgress: boolean;
  selectedChoiceIndex: number | undefined;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  vote: EventVote;
  voteCategory: VoteCategory;
  isNextInProgress: boolean;
  selectedChoiceIndex: number | undefined;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const params = useParams();
  const voteId = params.id!;

  const auth = useAuth();
  const handleAppError = useHandleAppError();
  const readVote = useReadVoteById();
  const readVoteCategory = useReadVoteCategoryById();
  const voteEvent = useVoteEvent();
  const { t } = useTranslation("pageVoteTournament");
  const location = useLocation();
  const toEventResult = useToEventVoteResultPage();

  const tryInit = async () => {
    setState({ status: "LOADING" });

    //auth.checkIsLoggedIn();

    const vote = (await readVote(voteId)) as EventVote;
    const voteCategory = await readVoteCategory(vote.categoryId);

    setState({
      status: "LOADED",
      vote,
      voteCategory,
      isNextInProgress: false,
      selectedChoiceIndex: undefined,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, [location]);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const tryOnNext = async () => {
    try {
      setState((oldState) => ({ ...oldState, isNextInProgress: true }));

      // Check whether selectedChoice is not null.
      if (state.selectedChoiceIndex == null) {
        alert(t("pageVoteTournament:pleaseSelectYourChoice"));
        return;
      }

      const selectedChoice = state.vote.choices[state.selectedChoiceIndex];
      await voteEvent({ vote: state.vote.id, choice: selectedChoice.id });

      toEventResult(state.vote.id);
    } finally {
      setState((oldState) => ({ ...oldState, isNextInProgress: false }));
    }
  };

  const onChoose = (index: number) => {
    setState((oldState) => ({ ...oldState, selectedChoiceIndex: index }));
  };

  const onNext = async () => {
    try {
      await tryOnNext();
    } catch (error) {
      await handleAppError(error);
    }
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      vote: state.vote,
      voteCategory: state.voteCategory,
      onNext,
      isNextInProgress: state.isNextInProgress,
      selectedChoiceIndex: state.selectedChoiceIndex,
      onChoose,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

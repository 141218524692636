import axios from 'axios';
import {apiAddr} from "../config";

interface Params {
  resetPasswordToken: string;
  newPassword: string;
}

const useResetPassword = () => {
  return async (params: Params) => {
    await axios({
      method: 'post',
      url: `${apiAddr}/v1/auth/reset-password`,
      params: {token: params.resetPasswordToken},
      data: {password: params.newPassword},
    });
  };
};

export default useResetPassword;

import React from "react";
import styled from "styled-components";
import { coolGray, mainEventColor, pointColor } from "../styles";

const StyledInputBox = styled.input`
  /* text field */
  height: 48px;
  /* Coolgray/CG00 */
  background: #ffffff;
  /* Coolgray/CG100 */
  border: 1px solid ${coolGray};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0px 10px 0px 10px;

  &::placeholder {
    color: ${coolGray};
    font-size: 14px;
  }

  &:focus {
    outline: 0;
    border-color: ${pointColor};
  }
`;

type TextInputProps = {
  className?: string | undefined;
  placeholder?: string | undefined;
  onChange?: ((newValue: string) => void) | undefined;
  value?: string | undefined;
  type?: "text" | "password" | "email" | undefined;
  preventAutoComplete?: boolean | undefined;
};

const TextInput = (props: TextInputProps) => {
  const onChange = (e: any) => {
    props.onChange && props.onChange(e.target.value);
  };

  return (
    <StyledInputBox
      onChange={onChange}
      className={props.className}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      autoComplete={props.preventAutoComplete ? "new-password" : "on"}
    />
  );
};

TextInput.defaultProps = {
  type: "text",
};

export default TextInput;
export type { TextInputProps };

import TournamentVoteRound from "../data-types/tournament-vote-round";
import TournamentVoteChoice from "../data-types/tournament-vote-choice";
import shuffleArray from "./array-shuffle";

const tournamentNextRoundIsNextLevel = (round: TournamentVoteRound): boolean => {
  return round.choices.length <= (round.nthRoundOfThisLevel * 2);
};

const tournamentNextLevel = (round: TournamentVoteRound): TournamentVoteRound => {
  const newRound:TournamentVoteRound = {
    choices: shuffleArray(round.winnersOfThisLevel),
    originalChoiceCount: round.originalChoiceCount,
    nthLevel: round.nthLevel + 1,
    nthRoundOfThisLevel: 0,
    winnersOfThisLevel: [],
  };

  return newRound;
};

interface IsEnd {
  isEnd: true;
  winningChoice: TournamentVoteChoice;
}

interface IsNotEnd {
  isEnd: false;
  nextRound: TournamentVoteRound;
}

type Result = IsEnd | IsNotEnd;

const tournamentChoose = (round: TournamentVoteRound, winningChoiceIndex: number): Result => {
  const winningChoice = round.choices.slice(round.nthRoundOfThisLevel * 2, round.nthRoundOfThisLevel * 2 + 2)[winningChoiceIndex];

  if (round.choices.length === 2) {
    return {
      isEnd: true,
      winningChoice: winningChoice,
    };
  }

  const nextRound: TournamentVoteRound = {
    choices: round.choices,
    winnersOfThisLevel: [...round.winnersOfThisLevel, winningChoice],
    nthLevel: round.nthLevel,
    nthRoundOfThisLevel: round.nthRoundOfThisLevel + 1,
    originalChoiceCount: round.originalChoiceCount,
  };

  return {
    isEnd: false,
    nextRound: tournamentNextRoundIsNextLevel(nextRound) ?
      tournamentNextLevel(nextRound) :
      nextRound,
  };
};

export default tournamentChoose;

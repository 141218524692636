import React from "react";
import styled from "styled-components";

const Cont = styled.div<{ backgroundColor: string }>`
  display: inline-flex;
  height: 32px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 1px;
  background-image: ${({ backgroundColor }) => backgroundColor};
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Text = styled.span<{ color: string }>`
  background: linear-gradient(110deg, #00eded 20%, #ff0099 80%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 14px;
  font-weight: 650;
`;

const Cover = styled.div`
  display: inline-flex;
  width: 100%;
  height: 90%;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 15px;
  background-color: white;
  justify-content: center;
  align-items: center;
`;

interface Props {
  className?: string | undefined;
  title: string;
  onClick?: (() => void) | undefined;
  color: string;
  backgroundColor: string;
}

const EventButton: React.FC<Props> = (props) => {
  return (
    <Cont
      className={props.className}
      onClick={props.onClick}
      backgroundColor={props.backgroundColor}
    >
      <Cover>
        <Text color={props.color}>{props.title}</Text>
      </Cover>
    </Cont>
  );
};

export default EventButton;

import React from "react";
import VoteShareModalCom from "../../../modals/vote-share";
import { LoadedLogic } from "../use-logic";

interface Props {
  logic: LoadedLogic;
  title: string;
  url: string;
  image: string;
}

const VoteShareModal: React.FC<Props> = (props) => {
  return (
    <VoteShareModalCom
      isVisible={props.logic.show}
      onCancel={props.logic.closeVoteModal}
      onRequestClose={props.logic.closeVoteModal}
      title={props.title}
      url={props.url}
      image={props.image}
    />
  );
};

export default VoteShareModal;

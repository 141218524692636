import React, { useState, useEffect } from "react";
import NavigationBottomTabLayout from "../../components/navigation-bottom-tab-layout";
import styled from "styled-components";
import { ReactComponent as NoUserImage } from "./svg/profile-man.svg";
import { ReactComponent as TicketIcon } from "./svg/vote-icon.svg";
import { ReactComponent as Right } from "./svg/arrow-icon.svg";
import { useTranslation } from "react-i18next";
import { black, cg20, cg500, mainColor } from "../../styles";
import useLogic from "./use-logic";

const theColor = "#3754A3";
const fadedTheColor = "#3754A32b";

const Cont = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #ededed;
  padding: 16px;
`;

const PrimaryMenu = styled.div`
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
`;

const Above = styled.div`
  padding: 36px 24px 28px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserImage = styled.img`
  border-radius: 36px;
  width: 72px;
  height: 72px;
  object-fit: cover;
`;

const UserName = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: ${black};
`;

const UserEmail = styled.span`
  display: block;
  margin-top: 2px;
  font-size: 12px;
  line-height: 18px;
  color: ${cg500};
`;

const Tickets = styled.div`
  margin-top: 16px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #95c5ec;
  padding: 0px 12px 0px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TicketCount = styled.span`
  margin-left: 12px;
  font-size: 12px;
  color: ${mainColor};
  font-weight: bold;
  text-decoration-line: underline;
`;

const TicketCountUnit = styled.span`
  margin-left: 12px;
  font-size: 12px;
  color: #6C7680};
  font-weight: bold;
`;

const AttendanceHistory = styled.div`
  margin-top: 16px;
  min-width: 200px;
  height: 40px;
  background-color: ${mainColor};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AttendanceHistoryText = styled.span`
  font-size: 14px;
  color: #ffffff;
`;

const Border = styled.div`
  height: 1px;
  background-color: #dae0e6;
`;

const Below = styled.div``;

const Section = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const DarkButton = styled.div`
  background-color: ${cg20};
  height: 64px;
  padding: 0px 16px 0px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DarkButtonText = styled.span`
  flex: 1;
  color: ${black};
  font-weight: bold;
  font-size: 16px;
`;

const LightButton = styled.div`
  background-color: white;
  height: 64px;
  padding: 0px 16px 0px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  border-top: 1px solid #dae0e6;

  &:first-of-type {
    border-top: 0px;
  }
`;

const LightButtonText = styled.span`
  flex: 1;
  color: ${black};
  font-size: 16px;
`;

const Space8 = styled.div`
  height: 8px;
`;

interface DumbProps {
  thumbnailUrl: string | undefined;
  userName: string;
  email: string;
  ticketCount: number;
  onQna: () => void;
  onTicketCountPress: () => void;
  onAttendancePress: () => void;
  onEditProfilePress: () => void;
  onTicketLogPress: () => void;
  onSettingsPress: () => void;
  onAnnouncementsPress: () => void;
  onLogoutPress: () => void;
}

const Dumb: React.FC<DumbProps> = (props) => {
  const { t } = useTranslation("pageMyAccount");
  const [useApp, setUseApp] = useState<boolean>(false);

  useEffect(() => {
    setUseApp(
      sessionStorage.getItem("isInApp")
        ? true
        : sessionStorage.getItem("fanupInApp")
        ? true
        : false
    );
  }, []);

  return (
    <Cont>
      <PrimaryMenu>
        <Above>
          {props.thumbnailUrl != null ? (
            <UserImage src={props.thumbnailUrl} />
          ) : (
            <NoUserImage />
          )}
          <UserName>{props.userName}</UserName>
          <UserEmail>{props.email}</UserEmail>
          <Tickets onClick={props.onTicketCountPress}>
            <TicketIcon />
            <TicketCount>{props.ticketCount}</TicketCount>
            <TicketCountUnit>{t("pageMyAccount:possess")}</TicketCountUnit>
          </Tickets>
          <AttendanceHistory onClick={props.onAttendancePress}>
            <AttendanceHistoryText>
              {t("pageMyAccount:attendanceHistory")}
            </AttendanceHistoryText>
          </AttendanceHistory>
        </Above>
        <Border />
        <Below>
          <DarkButton onClick={props.onEditProfilePress}>
            <DarkButtonText>{t("pageMyAccount:editMyInfo")}</DarkButtonText>
            <Right />
          </DarkButton>
        </Below>
      </PrimaryMenu>

      <Space8 />

      <Section>
        <LightButton onClick={props.onTicketLogPress}>
          <LightButtonText>
            {t("pageMyAccount:voteTicketHistory")}
          </LightButtonText>
          <Right />
        </LightButton>
        <LightButton onClick={props.onQna}>
          <LightButtonText>{t("pageMyAccount:qna")}</LightButtonText>
          <Right />
        </LightButton>
        {useApp && (
          <LightButton onClick={props.onSettingsPress}>
            <LightButtonText>{t("pageMyAccount:settings")}</LightButtonText>
            <Right />
          </LightButton>
        )}
      </Section>

      <Space8 />

      <Section>
        <LightButton onClick={props.onAnnouncementsPress}>
          <LightButtonText>{t("pageMyAccount:announcements")}</LightButtonText>
          <Right />
        </LightButton>
      </Section>

      <Space8 />

      <Section>
        <LightButton onClick={props.onLogoutPress}>
          <LightButtonText>{t("pageMyAccount:logout")}</LightButtonText>
        </LightButton>
      </Section>
    </Cont>
  );
};

const MyAccountPage: React.FC = () => {
  const logic = useLogic();

  if (logic.status !== "LOADED") {
    return null; // todo
  }

  return (
    <NavigationBottomTabLayout title={"MY"}>
      <Dumb
        thumbnailUrl={undefined}
        userName={logic.user.name}
        email={logic.user.email}
        onQna={logic.openQna}
        ticketCount={logic.user.ticketCount}
        onTicketCountPress={logic.openTicketLogs}
        onAttendancePress={logic.openAttendance}
        onEditProfilePress={logic.openEditProfile}
        onTicketLogPress={logic.openTicketLogs}
        onSettingsPress={logic.openSettings}
        onAnnouncementsPress={logic.openAnnouncements}
        onLogoutPress={logic.onLogoutPress}
      />
    </NavigationBottomTabLayout>
  );
};

export default MyAccountPage;

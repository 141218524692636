import {useNavigate} from "react-router-dom";

const useToResetPasswordDonePage = () => {
  const nav = useNavigate();

  return () => {
    nav(`/reset-password-done`);
  };
};

export default useToResetPasswordDonePage;

import React from 'react';
import styled from 'styled-components';
import {LoadedLogic} from "../../../use-logic";
import VoteCategory from "../../../../../data-types/vote-category";
import DemandingVote from "../../../../../data-types/demanding-vote";
import Chip from "./chip";
import DateLeft from './date-left';
import DemandingVoteLog from "../../../../../data-types/demanding-vote-log";
import Contents from "./contents";

const Cont = styled.div`
  background-color: white;
  padding: 20px 24px 20px 24px;
  
  margin-top: 8px;
  
  position: relative;
  
  &:first-of-type {
    margin-top: 0px;
  }
`;

interface Props {
  logic: LoadedLogic;
  category: VoteCategory;
  vote: DemandingVote;
  voteLog: DemandingVoteLog | undefined;
  voteIndex: number;
}

const Vote: React.FC<Props> = (props) => {
  return (
    <Cont>
      <Chip vote={props.vote} log={props.voteLog} />
      <DateLeft logic={props.logic} vote={props.vote} log={props.voteLog} />
      <Contents logic={props.logic} vote={props.vote} log={props.voteLog} />
    </Cont>
  );
};

export default Vote;

import React from "react";
import styled from "styled-components";
import { cg900 } from "../../styles";
import { ReactComponent as FanupLogo } from "./fanup-nav-logo.svg";
import { ReactComponent as LanguageIcon } from "./language-icon.svg";
import { ReactComponent as LoginIcon } from "./login-icon.svg";
import { ReactComponent as LogoutIcon } from "./logout-icon.svg";
import { ReactComponent as MyPageIcon } from "./myPage-icon.svg";

const Cont = styled.div`
  top: 0px;
  height: 56px;
  position: relative;
`;

const TitleCont = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  color: ${cg900};
  font-family: "BalooBhaijaan";
  font-weight: bold;
  font-size: 16px;
`;

const TheFanupLogo = styled(FanupLogo)`
  position: absolute;
  top: 22px;
  left: 20px;
  cursor: pointer;
`;

const IconsCont = styled.div`
  position: absolute;
  top: 10px;
  right: 8px;
  display: flex;
  align-items: center;
  padding: 0px;
`;

const TheLanguageIcon = styled(LanguageIcon)`
  cursor: pointer;
`;

const TheLoginIcon = styled(LoginIcon)`
  cursor: pointer;
  margin-left: 16px;
`;

const TheLogoutIcon = styled(LogoutIcon)`
  cursor: pointer;
  margin-left: 16px;
`;

const TheMyPageIcon = styled(MyPageIcon)`
  cursor: pointer;
  margin-left: 16px;
`;

interface FullNavigationBarProps {
  className?: string | undefined;
  onLogoClick?: (() => void) | undefined;
  onLanguageClick?: (() => void) | undefined;
  onLoginClick?: (() => void) | undefined;
  title: string;
  isLoggedIn?: boolean;
}

const FullNavigationBar: React.FC<FullNavigationBarProps> = (props) => {
  const LoginOrLogoutIcon = props.isLoggedIn ? TheMyPageIcon : TheLoginIcon;

  return (
    <Cont>
      <TitleCont>
        <Title>{props.title}</Title>
      </TitleCont>
      <TheFanupLogo onClick={props.onLogoClick} />
      <IconsCont>
        <TheLanguageIcon onClick={props.onLanguageClick} />
        <LoginOrLogoutIcon onClick={props.onLoginClick} />
      </IconsCont>
    </Cont>
  );
};

export default FullNavigationBar;
export type { FullNavigationBarProps };

import {useNavigate} from "react-router-dom";

const useToSettingsPage = () => {
  const navigate = useNavigate();

  return () => {
    navigate('/settings');
  };
};

export default useToSettingsPage;

import React from "react";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import DemandingVoteResult from "../../components/demanding-vote-result";
import { fadedWorldCup, worldCup } from "../../styles";
import useLogic from "./use-logic";
import useToCurrentLocale from "../../services/to-current-locale";
import voteDateLeft from "../../utils/vote-date-left";
import VoteShareModal from "./share-modal/index";
import { useTranslation } from "react-i18next";

const TournamentVoteResultPage: React.FC = () => {
  const logic = useLogic();
  const toCurrentLocale = useToCurrentLocale();
  const { t } = useTranslation("pageTournamentVoteResult");

  if (logic.status !== "LOADED") {
    return null; // todo fix this.
  }

  return (
    <NavigationWithGoBackBottomTabLayout title={"WORLD CUP"}>
      <DemandingVoteResult
        color={worldCup}
        backgroundColor={fadedWorldCup}
        title={toCurrentLocale(logic.vote.title)}
        description={`${logic.vote.period.from.toLocaleDateString()} ~ ${logic.vote.period.to.toLocaleDateString()}`}
        dateLeft={voteDateLeft(logic.vote)}
        roundTitle={t("pageTournamentVoteResult:winner")}
        cardImageSrc={logic.winningChoice.content.imageUrl}
        cardTitle={toCurrentLocale(logic.winningChoice.content.title)}
        cardActionTitle={t("pageTournamentVoteResult:details")}
        onCardDetailsPress={logic.onDetailsPress}
        onSharePress={logic.onSharePress}
        onRankingPress={logic.onRankingPress}
      />
      <VoteShareModal
        logic={logic}
        title={toCurrentLocale(logic.vote.title)}
        image={logic.winningChoice.content.imageUrl ?? ""}
        url={window.location.href}
      />
      
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default TournamentVoteResultPage;

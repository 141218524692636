import IdolVote from "../data-types/idol-vote";
import useAuth from "./auth";
import {apiAddr} from "../config";
import serializeVote from '../from-server-serializers/vote';

const useReadRandomActiveIdolVote = () => {
  const auth = useAuth();

  return async (): Promise<IdolVote> => {
    const result = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/v1/random-active-idol-vote`,
    });

    return serializeVote(result.data) as IdolVote;
  };
};

export default useReadRandomActiveIdolVote;

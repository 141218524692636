import * as CompoundLayout from '../data-types/compound-layout';
import useAuth from "./auth";
import {apiAddr} from "../config";

const useReadMainPageContents = () => {
  const auth = useAuth();

  return async (): Promise<CompoundLayout.Page> => {
    const loadComponents = async (): Promise<CompoundLayout.Component[]> => {
      const result = await auth.callAxios({
        method: 'get',
        url: `${apiAddr}/v1/app/main-page-contents`,
      });

      const transform = (raw: Record<string, any>): CompoundLayout.Component => {
        return raw as CompoundLayout.Component;
      };

      return result.data.map(transform);
    };

    return { components: await loadComponents() };
  };
};

export default useReadMainPageContents;

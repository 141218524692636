import React from "react";
import styled from "styled-components";
import { cg100, pointColor, primary } from "../../styles";

const Cont = styled.div`
  height: 8px;
  background-color: ${cg100};
  display: flex;
  border-radius: 4px;
`;

const Filled = styled.div<{ scale: number }>`
  height: 8px;
  width: ${({ scale }) => scale * 100 + "%"};
  border-radius: 4px;
  background-image: linear-gradient(90deg, #A329FA 20% , #FF176B 80%);
  --background-color: ${pointColor};
`;

interface ProgressBarProps {
  className?: string | undefined;
  scale: number;
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  return (
    <Cont className={props.className}>
      <Filled scale={props.scale} />
    </Cont>
  );
};

export default ProgressBar;
export type { ProgressBarProps };

import React from 'react';
import {LoadedLogic} from "../../../use-logic";
import styled from 'styled-components';
import {black, cg300, warmGray} from "../../../../../styles";
import {ReactComponent as DownIcon} from './down.svg';
import {ReactComponent as UpIcon} from './up.svg';
import useToCurrentLocale from "../../../../../services/to-current-locale";
import {useTranslation} from "react-i18next";

interface Props {
  logic: LoadedLogic;
}

const Cont = styled.div`
`;

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
`;

const Text = styled.span`
  font-size: 14px;
  color: ${cg300};
`;

const GraySpace = styled.div`
  height: 8px;
  background-color: ${warmGray};
`;

const DescriptionText = styled.span`
  display: block;
  padding: 18px;
  color: ${black};
  font-size: 14px;
  line-height: 18px;
`;

const Description: React.FC<Props> = (props) => {
  const toCurrentLocale = useToCurrentLocale();
  const {t} = useTranslation('pageIdolVotes');

  const currentVote = props.logic.idolVotes[props.logic.currentTabIndex];

  if (props.logic.isDescriptionExpanded) {
    return (
      <Cont>
        <ToggleButton onClick={() => props.logic.setIsDescriptionExpanded(false)}>
          <Text>{t('pageIdolVotes:closeInfo')}</Text>
          <UpIcon/>
        </ToggleButton>
        <GraySpace/>
        <DescriptionText>
          {toCurrentLocale(currentVote.description)}
        </DescriptionText>
      </Cont>
    );
  } else {
    return (
      <ToggleButton onClick={() => props.logic.setIsDescriptionExpanded(true)}>
        <Text>{t('pageIdolVotes:voteInfo')}</Text>
        <DownIcon/>
      </ToggleButton>
    );
  }
};

export default Description;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../components/navigation-bar';
import NoticeText from '../../components/notice-text';
import Button from '../../components/button';
import NoticeTextSmall from '../../components/notice-text-small';
import { maxTabletSizeInPx } from '../../styles/size';
import useSendPasswordResetEmail from "../../services/send-password-reset-email";
import useHandleAppError from "../../services/handle-app-error";
import {useSearchParams} from "react-router-dom";
import useToLoginPage from "../../services/to-login-page";

const Gap72 = styled.div`
  padding-top: 72px;
`;
const Gap56 = styled.div`
  padding-top: 56px;
`;
const Gap8 = styled.div`
  padding-top: 8px;
`;

const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;
const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const RequestResetPasswordDonePage = () => {
  const { t } = useTranslation('pageRequestResetPasswordDone');
  const [searchParams] = useSearchParams();
  const toNext = useToLoginPage();
  const sendResetPasswordEmailService = useSendPasswordResetEmail();
  const handleAppError = useHandleAppError();

  const onResendResetPasswordEmail = async () => {
    try {
      const email = searchParams.get('email')!;
      await sendResetPasswordEmailService(email);
      alert(t('resentResetPasswordEmail'));
    } catch (error) {
      await handleAppError(error);
    }
  };

  return (
    <div>
      <NavigationBar
        enableGoBack={false}
        text={t('pageRequestResetPasswordDone:navigation')}
      ></NavigationBar>
      <Gap72 />
      <RootCont>
        <TextCont>
          <NoticeText
            notice={t('pageRequestResetPasswordDone:notice')}
            desciption={t('pageRequestResetPasswordDone:description')}
          ></NoticeText>
        </TextCont>
        <Gap56 />
        <Gap8 />
        <BoxCont>
          <Button
            onClick={toNext}
            value={t('pageRequestResetPasswordDone:button')}
          ></Button>
        </BoxCont>
        <Gap72 />
        <TextCont>
          <NoticeTextSmall
            title={t('pageRequestResetPasswordDone:noticeTitle')}
            description={t('pageRequestResetPasswordDone:noticeDescription')}
            linedText={t('pageRequestResetPasswordDone:noticeLinedText')}
            onActionPress={onResendResetPasswordEmail}
          ></NoticeTextSmall>
        </TextCont>
      </RootCont>
    </div>
  );
};

export default RequestResetPasswordDonePage;

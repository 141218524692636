import VoteCategory from "../data-types/vote-category";
import useAuth from "./auth";
import { apiAddr } from "../config";
import serializeVoteCategory from "../from-server-serializers/vote-category";

const useReadVoteCategoryById = () => {
  const auth = useAuth();

  return async (voteCategoryId: string): Promise<VoteCategory> => {
    const result = await auth.callAxios({
      method: "get",
      url: `${apiAddr}/v1/vote-categories/${voteCategoryId}`,
    });

    return serializeVoteCategory(result?.data);
  };
};

export default useReadVoteCategoryById;

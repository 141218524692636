import React, { useState } from "react";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import {
  AttractionItemClass2,
  AttractionItemClass3,
  AttractionItemInner,
  AttractionItemMemo,
  AttractionItemPic,
  AttractionItemTitle,
  AttractionItemWrap,
  AttractionItemYear,
  BucketWrap,
  AttractItem,
  Inner,
  AttractButtonGroup,
  BucketButton,
  CalendarWrap,
} from "./dom-styled";
import i18n from "i18next";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { TFunction, useTranslation } from "react-i18next";
import { ReactComponent as IconArrow } from "./svg/icon_arrow_white.svg";
import CalendarView from "../../components/ calendar";
import dateToYYYYMMDD from "../../utils/date-to-yyyy-mm-dd";
import useAuth from "../../services/auth";
import { useCreateAttraction } from "../../services/fanup-map/fanup-attraction-repository";
import { useCreateAttractionBucketList } from "../../services/fanup-map/fanup-attraction-bucket-repository";
import useDailyRewards from "../../services/bucketList-daily-rewards";
import { FanUpAttractionBucketPayload } from "../../data-types/attraction";
import ModalBaseWrapper from "../../modals/modal-base-wrapper";
import MessageModal, { MessageModalHook } from "../../modals/message-modal";
import useGoBack from "../../services/go-back";
interface Result {
  askDailyRewards: () => any;
}

let _createAttractionBucketList: (
  bucket: FanUpAttractionBucketPayload
) => Promise<void>;
let _modalHook: (hook: MessageModalHook) => void;
let _trans: TFunction<"pageFanUpMap", undefined>;
let _navigate: NavigateFunction;

const _onCreateAttractionBuketList = (
  bucket: FanUpAttractionBucketPayload,
  dailyRewards: Result
) => {
  if (!bucket.date) {
    _modalHook({
      disabledDone: "Y",
      doneHandler: () => {},
      messageContent: <span>{_trans("message.notChooseDate")}</span>,
    });
    return false;
  }
  _modalHook({
    disabledDone: "N",
    doneHandler: () => {
      //등록
      _createAttractionBucketList(bucket).then(() => {
        _modalHook({
          disabledDone: "Y",
          doneHandler: () => {},
          closeHandler: () => {
            _navigate(-1);
          },
          messageContent: _trans("message.bucketSuccess"),
        });
      });
      dailyRewards.askDailyRewards();
    },
    messageContent: (
      <div>
        <strong
          style={{ color: "#FF176B", display: "block", marginBottom: "8px" }}
        >
          {bucket.date}
        </strong>
        Bucket List {_trans("message.add")}
      </div>
    ),
  });
};

/**
 * 버킷 등록 메인 바디
 * @constructor
 */
const FanUpBucketPage: React.FC<any> = () => {
  const [isCreated, setIsCreated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("pageFanUpMap");
  const askDailyRewards = useDailyRewards();
  const auth = useAuth();
  const picData = location.state.data;
  const date = new Date();
  let selDate: string = "";
  if (!picData || !auth.isLoggedIn) {
    navigate("/maps");
  }
  _trans = t;
  _navigate = navigate;
  _createAttractionBucketList = useCreateAttractionBucketList();

  return (
    <NavigationWithGoBackBottomTabLayout
      title={"FanUP MAP"}
      disabledSafePadding={true}
    >
      <BucketWrap>
        <AttractItem>
          <Inner>
            <AttractionItemWrap>
              <AttractionItemPic src={picData.images![0]} />
              <AttractionItemTitle>
                {picData.name![i18n.language]}
              </AttractionItemTitle>
              <AttractionItemMemo>
                {picData.memo && picData.memo[i18n.language]}
              </AttractionItemMemo>
              <AttractionItemInner>
                <AttractionItemClass2>{picData.class2}</AttractionItemClass2>
                <AttractionItemClass3>
                  {picData.class3 && picData.class3[i18n.language]}
                </AttractionItemClass3>
                <AttractionItemYear>/ 2023</AttractionItemYear>
              </AttractionItemInner>
            </AttractionItemWrap>
            <AttractButtonGroup>
              <BucketButton
                active={true}
                onClick={() =>
                  _onCreateAttractionBuketList(
                    {
                      attractId: picData.id,
                      date: selDate,
                    },
                    askDailyRewards
                  )
                }
              >
                Bucket List Up
                <IconArrow style={{ position: "absolute", right: "10px" }} />
              </BucketButton>
              <BucketButton active={false}>
                Travel Diary
                <IconArrow style={{ position: "absolute", right: "10px" }} />
              </BucketButton>
            </AttractButtonGroup>
          </Inner>
        </AttractItem>
        <CalendarWrap>
          <CalendarView
            year={date.getFullYear()}
            month={date.getMonth() + 1}
            onChangeHandler={(date) => {
              selDate = dateToYYYYMMDD(date);
            }}
          />
        </CalendarWrap>
      </BucketWrap>
      <MessageModal
        onHook={(fn) => {
          _modalHook = fn;
        }}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default FanUpBucketPage;

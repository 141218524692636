import React from "react";
import VoteEventModalCom from "../../../modals/vote-event";
import { LoadedLogic } from "../use-logic";

interface Props {
  logic: LoadedLogic;
}

const VoteEventModal: React.FC<Props> = (props) => {
  return (
    <VoteEventModalCom
      isVisible={props.logic.show}
      gotoJoin={props.logic.gotoJoinMember}
      onRequestClose={props.logic.closeVoteModal}
    />
  );
};

export default VoteEventModal;

import React from 'react';
import styled from 'styled-components';
import NavigationBar from '../../components/navigation-bar';
import NoticeText from '../../components/notice-text';
import { useTranslation } from 'react-i18next';
import TextInput from '../../components/text-input';
import Button from '../../components/button';
import { maxTabletSizeInPx } from '../../styles/size';
import useLogic from './use-logic';

const Gap72 = styled.div`
  padding-top: 72px;
`;
const Gap28 = styled.div`
  padding-top: 28px;
`;
const Gap24 = styled.div`
  padding-top: 24px;
`;

const RootCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${maxTabletSizeInPx}px;
  box-sizing: border-box;
  padding: 0 48px 0 48px;
`;
const TextCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const BoxCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RequestResetPasswordPage: React.FC = () => {
  const { t } = useTranslation('pageRequestResetPassword');
  const logic = useLogic();

  return (
    <div>
      <NavigationBar
        text={t('pageRequestResetPassword:navigation')}
      ></NavigationBar>
      <RootCont>
        <Gap72 />
        <TextCont>
          <NoticeText
            notice={t('pageRequestResetPassword:notice')}
            desciption={t('pageRequestResetPassword:description')}
          ></NoticeText>
        </TextCont>
        <Gap28 />
        <BoxCont>
          <TextInput
            type="email"
            onChange={logic.setEmail}
            placeholder="ex. abcdefg@gmail.com"
          ></TextInput>
          <Gap24 />
          <Button
            onClick={logic.onNext}
            value={logic.inProgress ? t('inProgress') : t('button')}
            disabled={!logic.isEmailValid || logic.inProgress}
          />
        </BoxCont>
      </RootCont>
    </div>
  );
};

export default RequestResetPasswordPage;

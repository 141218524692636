import EventVoteChoice from "../data-types/event-vote-choice";
import serializeVoteContent from "./vote-choice-content";

const serialize = (raw: Record<string, any>): EventVoteChoice => {
  return {
    id: raw.id,
    voteCount: raw.voteCount,
    content: serializeVoteContent(raw.content),
  };
};

export default serialize;

import React from "react";
import useToCurrentLocale from "../../services/to-current-locale";
import { useTranslation } from "react-i18next";
import useToLink from "../../services/to-link";
import NavigationWithGoBackBottomTabLayout from "../../components/navigation-with-go-back-bottom-tab-layout";
import EventVoteRound from "../../components/event-vote-round";
import voteDateLeft from "../../utils/vote-date-left";
import { event, fadedEvent, mainEventColor } from "../../styles";
import useLogic from "./use-logic";
import i18next from "i18next";
import MultilingualString from "../../data-types/multilingual-string";

const VoteEventPage: React.FC = () => {
  const logic = useLogic();
  const toCurrentLocale = useToCurrentLocale();
  const { t } = useTranslation("pageVoteEvent");
  const { t: monthT } = useTranslation("month");
  const toLink = useToLink();

  if (logic.status !== "LOADED") {
    return null; // todo fix this.
  }
  const cateTitle =
    i18next.language === "kr"
      ? `${(new Date().getMonth() + 1).toString()}월 이벤트 투표`
      : `${monthT((new Date().getMonth() + 1).toString()).substring(
          0,
          3
        )}'s EVENT VOTE`;
  return (
    <NavigationWithGoBackBottomTabLayout title={"WORLD CUP"}>
      <EventVoteRound
        title={toCurrentLocale(logic.vote.title)}
        selectedChoiceIndex={logic.selectedChoiceIndex}
        cateTitle={cateTitle}
        description={`${logic.vote.period.from.toLocaleDateString()} ~ ${logic.vote.period.to.toLocaleDateString()}`}
        dateLeft={voteDateLeft(logic.vote)}
        choices={logic.vote.choices.map((choice, choiceIndex) => {
          return {
            imageSrc: choice.content.imageUrl,
            title: toCurrentLocale(choice.content.title),
            description: toCurrentLocale(choice.content.description),
            actionTitle: t("pageVoteEvent:details"),
            onActionPress: () => toLink(choice.content.link),
            onPress: () => logic.onChoose(choiceIndex),
            isSelected: logic.selectedChoiceIndex === choiceIndex,
          };
        })}
        onNextPress={logic.onNext}
        color={mainEventColor}
        backgroundColor={fadedEvent}
      />
    </NavigationWithGoBackBottomTabLayout>
  );
};

export default VoteEventPage;

import styled from "styled-components";

const FanUpWrap = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 70px;
`;
const Inner = styled.div`
    margin: 0 8px;
    background: #fff;
    height: 100%;
    border-radius: 12px 12px 0px 0px;
    box-shadow: 0px 4px 8px 0px rgba(108, 118, 128, 0.16), 0px 2px 4px 0px rgba(108, 118, 128, 0.16);
`;

const Header = styled.div`
    padding:16px;
`;
const HeaderBar = styled.div`
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 4px;
    background: #DAE0E6;
`;
const HeaderTitle = styled.div`
    font-size: 14px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    height: 24px;
    padding-left: 26px;
    margin-top:16px;
`;

const AttractionItemWrap = styled.div`
    position: relative;
    padding: 16px;
    padding-left: 112px;
    min-height: 100px;
    padding-top:0;
`;
const AttractionItemPic = styled.div<{src?:string}>`
    position: absolute;
    top: 0;
    left: 16px;
    background-color: #D9D9D9;
    background-image: url(${props=>props.src});
    background-size: cover;
    background-position: center;
    width: 80px;
    height: 80px;
    display: block;
    border-radius: 6px;
    border: 1px solid #E4EAF0;
`;
const AttractionItemTitle = styled.h3`
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
`;
const AttractionItemMemo = styled.p`
margin: 5px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #6C7680;
`;
const AttractionItemInner = styled.div`
    display:flex;
`;
const AttractionItemClass2 = styled.strong`
color: var(--change-color, #FF176B);
font-family: Spoqa Han Sans Neo;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
margin-right:10px
`;
const AttractionItemClass3 = styled.span`
color: var(--gray-700, #3D454D);
font-family: Spoqa Han Sans Neo;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
margin-right:10px

`;
const AttractionItemYear= styled.span`
color: var(--gray-500, #6C7680);
font-family: Spoqa Han Sans Neo;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
`;
const AttractionItemAddr= styled.p`
    color: var(--gray-500,#6C7680);
    font-family: Spoqa Han Sans Neo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top:15px;
    margin-left: -92px;
    margin-bottom:0;
`;

export {FanUpWrap,Inner, Header,HeaderBar,HeaderTitle,
    AttractionItemWrap,AttractionItemInner,AttractionItemAddr,AttractionItemYear,AttractionItemClass2,AttractionItemPic,AttractionItemMemo,AttractionItemClass3,AttractionItemTitle
}

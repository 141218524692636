import { useEffect, useState } from "react";
import { strict as assert } from "assert";
import useCurrentUser from "../../services/current-user";
import useAuth from "../../services/auth";
import useHandleAppError from "../../services/handle-app-error";
import { useTranslation } from "react-i18next";
import useGoBack from "../../services/go-back";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  name: string;
  email: string;
  password: string;
  editThumbnail: () => void;
  setName: (value: string) => void;
  setPassword: (value: string) => void;
  editPassword: () => void;
  cancel: () => void;
  save: () => void;
  drawal: () => void;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  name: string;
  isNameEdited: boolean;
  email: string;
  password: string;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

class EmptyPasswordError extends Error {}
class EmptyNameError extends Error {}

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const auth = useAuth();
  const currentUserService = useCurrentUser();
  const handleAppError = useHandleAppError();
  const { t } = useTranslation("pageEditProfile");
  const goBack = useGoBack();

  const tryInit = async () => {
    auth.checkIsLoggedIn();

    const user = (await currentUserService.read())!;

    setState({
      status: "LOADED",
      name: user.name,
      isNameEdited: false,
      email: user.email,
      password: "",
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const setName = (value: string) => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, name: value, isNameEdited: true };
    });
  };

  const setPassword = (value: string) => {
    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, password: value };
    });
  };

  const checkPasswordInput = () => {
    if (state.password.length === 0) {
      throw new EmptyPasswordError();
    }
  };

  const tryEditPassword = async () => {
    checkPasswordInput();

    await currentUserService.updatePassword(state.password);

    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, password: "" };
    });
  };

  const editPassword = async () => {
    try {
      await tryEditPassword();
    } catch (error) {
      if (error instanceof EmptyPasswordError) {
        alert(t("pleaseEnterPasswordToChange"));
        return;
      }

      await handleAppError(error);
    }
  };

  const editPasswordWhenNotEmpty = async () => {
    if (state.password.length !== 0) {
      await editPassword();
    }
  };

  const cancel = () => {
    goBack();
  };

  const checkNameInput = () => {
    if (state.name.length === 0) {
      throw new EmptyNameError();
    }
  };

  const trySaveName = async () => {
    if (!state.isNameEdited) {
      return;
    }

    checkNameInput();

    await currentUserService.updateName(state.name);

    setState((oldState) => {
      if (oldState.status !== "LOADED") {
        return oldState;
      }

      return { ...oldState, isNameEdited: false };
    });
  };

  const saveName = async () => {
    try {
      await trySaveName();
    } catch (error) {
      if (error instanceof EmptyNameError) {
        alert(t("cannotSetEmptyName"));
      }

      await handleAppError(error);
    }
  };

  const trySave = async () => {
    await saveName();
    await editPasswordWhenNotEmpty();
  };

  const save = async () => {
    try {
      await trySave();
    } catch (error) {
      await handleAppError(error);
    }
  };

  const drawal = async () => {
    try {
      if (window.confirm(t("drawalMsg"))) {
        await currentUserService.drawal();
        window.location.href = "/";
      }
    } catch (error) {
      await handleAppError(error);
    }
  };

  const editThumbnail = () => {
    alert(t("thisFeatureIsInDevelopment"));
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      name: state.name,
      email: state.email,
      password: state.password,
      editThumbnail,
      setName,
      setPassword,
      editPassword: async () => {
        await editPassword();
        alert(t("passwordUpdated"));
      },
      cancel,
      save: async () => {
        await save();
        alert(t("updated"));
      },
      drawal: async () => {
        await drawal();
      },
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

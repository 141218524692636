import { useEffect, useState } from "react";
import { strict as assert } from "assert";
import useCurrentSetting from "../../services/current-setting";
import useAuth from "../../services/auth";
import useHandleAppError from "../../services/handle-app-error";
import { useTranslation } from "react-i18next";
import useGoBack from "../../services/go-back";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  allow: boolean;
  save: (pushToken: string, pushAllow: boolean) => void;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  allow: boolean;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (pushToken: string): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const auth = useAuth();
  const currentSettingService = useCurrentSetting();
  const handleAppError = useHandleAppError();
  const { t } = useTranslation("pageEditProfile");
  const goBack = useGoBack();

  const tryInit = async () => {
    auth.checkIsLoggedIn();

    const result = await currentSettingService.read(pushToken);

    if (result) {
      const { pushAllow } = result;
      setState({
        status: "LOADED",
        allow: pushAllow,
      });
    } else {
      setState({
        status: "LOADED",
        allow: false,
      });
    }
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({ status: "FAILED" });
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const trySave = async (pushToken: string, pushAllow: boolean) => {
    await currentSettingService.updateAllow(pushToken, pushAllow);
  };

  const save = async (pushToken: string, pushAllow: boolean) => {
    try {
      await trySave(pushToken, pushAllow);
    } catch (error) {
      await handleAppError(error);
    }
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      allow: state.allow,
      save: async (pushToken: string, pushAllow: boolean) => {
        await save(pushToken, pushAllow);
        setState({
          status: "LOADED",
          allow: pushAllow,
        });
      },
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

import { useEffect, useState } from "react";
import { strict as assert } from "assert";
import useHandleAppError from "../../../services/handle-app-error";
import DemandingVoteLog from "../../../data-types/demanding-vote-log";
import useReadDemandingVoteLog from "../../../services/read-demanding-vote-log";
import { useParams } from "react-router-dom";
import TournamentVote from "../../../data-types/tournament-vote";
import useReadVoteById from "../../../services/read-vote-by-id";
import TournamentVoteChoice from "../../../data-types/tournament-vote-choice";
import useToLink from "../../../services/to-link";
import { useTranslation } from "react-i18next";
import useToTournamentVoteStatisticsPage from "../../../services/to-tournament-vote-statistics-page";

interface LoadingLogic {
  status: "LOADING";
  show?: boolean;
}

interface LoadedLogic {
  status: "LOADED";
  vote: TournamentVote;
  log: DemandingVoteLog;
  winningChoice: TournamentVoteChoice;
  onDetailsPress: () => void;
  onSharePress: () => void;
  onRankingPress: () => void;
  show: boolean;
  openVoteModal: () => void;
  closeVoteModal: () => void;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  vote: TournamentVote;
  log: DemandingVoteLog;
  show: boolean;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({ status: "LOADING" });

  const toLink = useToLink();
  const urlParams = useParams();
  const readVote = useReadVoteById();
  const readLog = useReadDemandingVoteLog();
  const handleAppError = useHandleAppError();
  const { t } = useTranslation("pageTournamentVoteResult");
  const toTournamentVoteStatistics = useToTournamentVoteStatisticsPage();

  const voteId: string = urlParams.id!;

  const tryInit = async () => {
    const vote = (await readVote(voteId)) as TournamentVote;
    const log = await readLog(voteId);

    if (log == null) {
      setState({ status: "FAILED" });
      return;
    }

    setState({ status: "LOADED", vote, log, show: false });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const findOfId = (targetId: string) => (curChoice: TournamentVoteChoice) =>
    targetId === curChoice.id;
  const winningChoiceID = sessionStorage.getItem("winningChoice.id");
  const winningChoice = state.vote.choices.find(
    findOfId(winningChoiceID ? winningChoiceID : state.log.choice)
  )!;

  const onDetailsPress = async () => {
    await toLink(winningChoice.content.link);
  };

  const onSharePress = () => {
    setState((oldState) => ({ ...oldState, show: true }));
    //alert(t("pageTournamentVoteResult:featureIsInDevelopment"));
  };

  const onRankingPress = () => {
    toTournamentVoteStatistics(state.vote.id);
  };

  const openVoteModal = () => {
    setState((oldState) => ({ ...oldState, show: true }));
  };

  const closeVoteModal = () => {
    setState((oldState) => ({ ...oldState, show: false }));
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      vote: state.vote,
      log: state.log,
      winningChoice,
      onDetailsPress,
      onSharePress,
      onRankingPress,
      show: state.show,
      openVoteModal,
      closeVoteModal,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };

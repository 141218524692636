import React from 'react';
import styled from 'styled-components';
import {primary} from "../../../styles";

interface Props {
  className?: string | undefined;
  onPress?: (() => void) | undefined;
  children?: any;
}

const Cont = styled.div`
  min-width: 124px;
  height: 38px;
  border: 1px solid ${primary};
  line-height: 38px;
  text-align: center;
  display: block;
  font-size: 16px;
  color: ${primary};
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
`;

const SecondaryButton: React.FC<Props> = (props) => {
  return (
    <Cont className={props.className} onClick={props.onPress}>
      {props.children}
    </Cont>
  );
};

export default SecondaryButton;

import React from 'react';
import styled from 'styled-components';

const Cont = styled.div<{ backgroundColor: string }>`
  display: inline-flex;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Text = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 14px;
`;

interface Props {
  className?: string | undefined;
  title: string;
  onClick?: (() => void) | undefined;
  color: string;
  backgroundColor: string;
}

const Button: React.FC<Props> = (props) => {
  return (
    <Cont
      className={props.className}
      onClick={props.onClick}
      backgroundColor={props.backgroundColor}
    >
      <Text color={props.color}>{props.title}</Text>
    </Cont>
  );
};

export default Button;

import React from 'react';
import styled from 'styled-components';
import { cg60, primary } from "../../styles";

const Cont = styled.div`
  margin-top: 56px;
  border-top: 1px solid ${cg60};
  
  height: 50px;
  display: flex;
  align-items: stretch;
`;

const BarInMiddle = styled.div`
  width: 1px;
  background-color: ${cg60};
`;

const ButtonLeft = styled.div`
  cursor: pointer;
  background-color: white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLeftText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #95c5ec;
`;

const ButtonRight = styled.div`
  cursor: pointer;  
  background-color: #95c5ec;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonRightText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

interface Props {
  onLeft: () => void;
  onRight: () => void;
  disableRight?: boolean | undefined;
    disableLeft?: boolean | undefined;
  leftText: string;
  rightText: string;
}

const Buttons: React.FC<Props> = (props) => {
  return (
    <Cont>
      <ButtonLeft onClick={props.onLeft} style={{display:(props.disableLeft?'none':'')}}>
        <ButtonLeftText>
          {props.leftText}
        </ButtonLeftText>
      </ButtonLeft>

      <BarInMiddle />

      <ButtonRight onClick={props.onRight} style={{display:(props.disableRight?'none':'')}}>
        <ButtonRightText>
          {props.rightText}
        </ButtonRightText>
      </ButtonRight>
    </Cont>
  );
};

export default Buttons;

class RegionNotFoundError extends Error {
  tryingToFindId: string;

  constructor(tryingToFindId: string) {
    super(`RegionNotFoundError: Cannot find Region of id ${tryingToFindId}.`);
    this.tryingToFindId = tryingToFindId;
  }
}

export default RegionNotFoundError;

import { useState } from 'react';
import useHandleAppError from "../../services/handle-app-error";
import useToRequestResetPasswordDonePage from "../../services/to-request-reset-password-done-page";
import useSendPasswordResetEmail from "../../services/send-password-reset-email";

interface Logic {
  email: string;
  setEmail: (newEmail: string) => void;
  isEmailValid: boolean;
  onNext: () => void;
  inProgress: boolean;
}

const useLogic = (): Logic => {
  const [email, setEmail] = useState<string>('');
  const [inProgress, setInProgress] = useState<boolean>(false);

  const isEmailValid = email.length >= 3 && email.includes('@');
  const sendPasswordResetEmail = useSendPasswordResetEmail();
  const handleAppError = useHandleAppError();
  const toRequestResetPasswordDone = useToRequestResetPasswordDonePage();

  const onNext = async () => {
    try {
      setInProgress(true);
      await sendPasswordResetEmail(email);
      toRequestResetPasswordDone(email);
    } catch (error) {
      await handleAppError(error);
    } finally {
      setInProgress(false);
    }
  };

  return {
    email,
    setEmail,
    isEmailValid,
    onNext,
    inProgress,
  };
};

export default useLogic;
export type { Logic };

import {useNavigate} from "react-router-dom";

const useToEventVoteStatisticsPage = () => {
  const navigate = useNavigate();

  return (voteId: string) => {
    navigate(`/event-vote-statistics/${voteId}`);
  };
};

export default useToEventVoteStatisticsPage;

import useAuth from "./auth";
import {apiAddr} from "../config";
import serializeTicketLog from '../from-server-serializers/ticket-log';

interface Params {
  limit: number;
  from?: string | undefined;
}

const useReadTicketLogs = () => {
  const auth = useAuth();

  return async (params: Params) => {
    auth.checkIsLoggedIn();

    const axiosResult = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/v1/users/${auth.userId}/vote-ticket-logs`,
      params: {limit: params.limit, from: params.from},
    });

    const logs = axiosResult.data.map(serializeTicketLog);

    return logs;
  };
};

export default useReadTicketLogs;

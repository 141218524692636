import {useEffect, useState} from "react";
import {strict as assert} from 'assert';
import Announcement from "../../data-types/announcement";
import useHandleAppError from "../../services/handle-app-error";
import useReadAnnouncements from "../../services/read-announcements";
import useToAnnouncementPage from "../../services/to-announcement-page";

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  announcements: Announcement[];
  isLoadingMore: boolean;
  loadMore: () => void;
  onAnnouncementOfIndexPress: (index: number) => void;
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  announcements: Announcement[];
  isLoadingMore: boolean;
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const [state, setState] = useState<State>({status: 'LOADING'});

  const limit = 10;

  const readAnnouncements = useReadAnnouncements();
  const handleAppError = useHandleAppError();
  const toAnnouncement = useToAnnouncementPage();

  const tryInit = async () => {
    const announcements = await readAnnouncements({limit});

    setState({
      status: 'LOADED',
      announcements,
      isLoadingMore: false,
    });
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      setState({status: 'FAILED'});
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  if (state.status === 'LOADING') {
    return {status: 'LOADING'};
  }

  if (state.status === 'FAILED') {
    return {status: 'FAILED'};
  }

  const tryLoadMore = async () => {
    try {
      setState(oldState => ({ ...oldState, isLoadingMore: true }));

      const getFrom = () => {
        if (state.announcements.length === 0) {
          return undefined;
        }

        return state.announcements[state.announcements.length - 1].id;
      }

      const newAnnouncements = await readAnnouncements({limit,  from: getFrom()});

      setState(oldState => {
        if (oldState.status !== 'LOADED') {
          return oldState;
        }

        return {
          ...oldState,
          announcements: [
            ...oldState.announcements,
            ...newAnnouncements,
          ],
        };
      });
    } finally {
      setState(oldState => ({ ...oldState, isLoadingMore: false }));
    }
  };

  const loadMore = async () => {
    try {
      await tryLoadMore();
    } catch (error) {
      await handleAppError(error);
    }
  };

  const onAnnouncementOfIndexPress = (index: number) => {
    const announcement = state.announcements[index];

    toAnnouncement(announcement.id);
  };

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      announcements: state.announcements,
      isLoadingMore: state.isLoadingMore,
      loadMore,
      onAnnouncementOfIndexPress,
    };
  }

  assert.fail();
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};

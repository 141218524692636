import Announcement from "../data-types/announcement";

const serialize = (raw: Record<string, any>): Announcement => {
  return {
    id: raw.id,
    kind: raw.kind,
    title: raw.title,
    imageUrl: raw.image != null ? raw.image : undefined,
    issuedDate: new Date(raw.issuedAt),
  };
};

export default serialize;

import axios from 'axios';
import {apiAddr} from "../config";

const useSendPasswordResetEmail = () => {
  return async (email: string) => {
    await axios({
      method: 'post',
      url: `${apiAddr}/v1/auth/forgot-password`,
      data: {email},
    });
  };
};

export default useSendPasswordResetEmail;

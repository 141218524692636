import Period from "../data-types/period";

const isDateInPeriod = (date: Date, period: Period): boolean => {
  const dateValue = date.valueOf();
  const fromValue = period.from.valueOf();
  const toValue = period.to.valueOf();

  return fromValue <= dateValue && dateValue <= toValue;
};

export default isDateInPeriod;

import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useToCurrentLocale from "../../../../services/to-current-locale";
import { ReactComponent as ArrowImg } from "../svgs/arrow.svg";
import { useTranslation } from "react-i18next";

const Cont = styled.div`
  height: 80px;
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Dark = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
`;

const InfoCont = styled.div`
  position: absolute;
  width: 288px;
  height: 26px;
  left: 25px;
  top: 27px;

  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;

  background: linear-gradient(90deg, #A329FA 20%, #FF176B 90%);
    color: transparent;
    -webkit-background-clip: text;

  /* point color */

  //color: #fd7bb2;
`;

const Arrow = styled.span`
  position: absolute;
  width: 63px;
  height: 0px;
  left: 269px;
  top: 0px;
  background: linear-gradient(90deg, #A329FA 20%, #FF176B 90%);
    color: transparent;
    -webkit-background-clip: text;
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: white;
`;

const LinkBannerFragment: React.FC = () => {
  const { t } = useTranslation("pageIdolVotes");
  const navigator = useNavigate();
  const handleClick = () => {
    navigator(`/fanup-tour`);
  };

  return (
    <Cont
      onClick={() => {
        handleClick();
      }}
    >
      <InfoCont>
        {t("linkBannerFragment")}
        <Arrow>
          <ArrowImg />
        </Arrow>
      </InfoCont>
    </Cont>
  );
};

export default LinkBannerFragment;
